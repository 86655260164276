import { PDFPageContainer } from "../components/PageContainer";
import { PdfTitle } from "../components/title";

import { col_2, convertTo2DArray, fullHeight, fullWidth, pdfFontSize } from "./utils";
import React from "react";

const isTowSubTitle = (id) => {
  return id === 'ChartId_NormalizedElectricityConsumptionVsBaselineScatterPlot' || id === 'ChartId_NormalizedGasConsumptionVsBaselineScatterPlot'
}

const FormText = ({ children }) => {
  return <p-text width={110} color="#396598" fontSize={pdfFontSize.H6} margin={[0, 2]} bold={true}>{children}</p-text>
}

const PDFSavings = (_, { pdfFormValue, pdfImages }) => {

  const savingAnalysis = pdfImages.savingAnalysis
  if (!savingAnalysis) {
    return null
  }

  const images2d = convertTo2DArray(savingAnalysis)

  const {
    pdfChart_Elec_Baseline,
    pdfChart_Elec_Post,
    pdfChart_Elec_Price,
    pdfChart_Elec_Segment,
    pdfChart_Gas_Baseline,
    pdfChart_Gas_Post,
    pdfChart_Gas_Price,
    pdfChart_Gas_Segment,
  } = pdfImages

  return (
    <PDFPageContainer lastPage={true}>
      <PdfTitle w={328} title="Baseline and Energy Saving analysis"></PdfTitle>
      <p-canvas relativePosition={{ x: 0, y: 15 }}>
        <p-rect x={0} y={0} w={515} h={70} r={10} color="#f7f7f7" ></p-rect>
      </p-canvas>
      <p-col columnGap={40} margin={[0, 15, 0, 0]}>
        <p-stack width={col_2} unbreakable={true} margin={[10, 10]}>
          <p-col>
            <FormText>{pdfChart_Elec_Baseline.split(':')[0] + ':'}</FormText>
            <FormText>{pdfChart_Elec_Baseline.split(':')[1]}</FormText>
          </p-col>
          <p-col>
            <FormText>{pdfChart_Elec_Post.split(':')[0] + ':'}</FormText>
            <FormText>{pdfChart_Elec_Post.split(':')[1]}</FormText>
          </p-col>
          <p-col>
            <FormText>{pdfChart_Elec_Price.split(':')[0] + ':'}</FormText>
            <FormText>{pdfChart_Elec_Price.split(':')[1]}</FormText>
          </p-col>
          <p-col>
            <FormText>{pdfChart_Elec_Segment.split(':')[0] + ':'}</FormText>
            <FormText>{pdfChart_Elec_Segment.split(':')[1]}</FormText>
          </p-col>
        </p-stack>
        <p-stack width={col_2} unbreakable={true} margin={[10, 10]}>
          <p-col>
            <FormText>{pdfChart_Gas_Baseline.split(':')[0] + ':'}</FormText>
            <FormText>{pdfChart_Gas_Baseline.split(':')[1]}</FormText>
          </p-col>
          <p-col>
            <FormText>{pdfChart_Gas_Post.split(':')[0] + ':'}</FormText>
            <FormText>{pdfChart_Gas_Post.split(':')[1]}</FormText>
          </p-col>
          <p-col>
            <FormText>{pdfChart_Gas_Price.split(':')[0] + ':'}</FormText>
            <FormText>{pdfChart_Gas_Price.split(':')[1]}</FormText>
          </p-col>
          <p-col>
            <FormText>{pdfChart_Gas_Segment.split(':')[0] + ':'}</FormText>
            <FormText>{pdfChart_Gas_Segment.split(':')[1]}</FormText>
          </p-col>
          {/* <p-text color="#396598" fontSize={8} margin={[0, 2]} bold={true}>{pdfChart_Gas_Baseline}</p-text>
        <p-text color="#396598" fontSize={8} margin={[0, 2]} bold={true}>{pdfChart_Gas_Post}</p-text>
        <p-text color="#396598" fontSize={8} margin={[0, 2]} bold={true}>{pdfChart_Gas_Price}</p-text>
        <p-text color="#396598" fontSize={8} margin={[0, 2]} bold={true}>{pdfChart_Gas_Segment}</p-text> */}
        </p-stack>
      </p-col>
      {
        images2d.map((imgs, index) => {
          return (
            <>
              <p-col columnGap={40} key={index}>
                {
                  imgs.map((img, idx) => {
                    if (!img) return <p-text></p-text>
                    return (
                      <p-stack key={idx} width={col_2} margin={[0, 15]} unbreakable={true}>
                        {
                          isTowSubTitle(img.id) ? <p-col>
                            <p-text width="65%" color="#396598" fontSize={pdfFontSize.H4} margin={[0, 10, 0, 2]} bold={true}>{img.pdfChart_Title}</p-text>
                            <p-stack width="*" margin={[0, 10, 0, 0]}>
                              <p-text color="#396598" fontSize={pdfFontSize.H5} margin={[0, 2]} alignment="right" bold={true}>{img.pdfChart_SubTitle1}</p-text>
                              <p-text color="#396598" fontSize={pdfFontSize.H5} margin={[0, 2]} alignment="right" bold={true}>{img.pdfChart_SubTitle2}</p-text>
                            </p-stack>
                          </p-col> : <p-text color="#396598" fontSize={pdfFontSize.H4} margin={[0, 10, 0, 2]} bold={true}>{img.pdfChart_Title}</p-text>
                        }

                        <p-img width={200} src={img.img}></p-img>
                      </p-stack>
                    );
                  })
                }
              </p-col>
            </>

          )
        })
      }
    </PDFPageContainer>

  );
};

export default PDFSavings;
