import React, {useState} from 'react';
import DetailModal from "@components/Table/DetailModal";
import {api} from "@redux/api";
import {EquipmentFormFields} from "@containers/facility/Equipment/EquipmentFormFields";

const WorkorderModal = ({
                            isVisible,
                            onClose
                        }) => {

    const [data, setData] = useState([]);
    const [modalTitle, setModalTitle] = useState('Title');


    const handleCreateEquipment = (body) => {
        return api.createEquipment(body).then(
            response => {
                return response
            }
        ).catch(error => (
            error
        ));
    }

    const formRefCallback = (formRef) => {
        console.log('formRef', formRef)
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <div>
            <DetailModal
                isVisible={isVisible}
                formFields={EquipmentFormFields}
                hanldeCreateRecords={handleCreateEquipment}
                formRefCallback={formRefCallback}
                data={data}
                modalTitle={modalTitle}
                handleClose={handleClose}
            />
        </div>
    );
};

export default WorkorderModal;
