import Input from "antd/es/input";
import React from "react";
import {Select} from "antd";
import EquipmentLookup from "@containers/Lookup/EquipmentLookup";
import ProjectLookup2 from "@containers/Lookup/ProjectLookup2";
import {store} from "@redux/store";
import CustomDatePicker from "@components/CustomDatePicker";
import moment from "moment";
import UserLookup from "@containers/Lookup/UserLookup";

const {TextArea} = Input;

const {Option} = Select;
export const WorkorderRelated = []

export const WorkorderFormFields = (formRef) => {
    const currentUser = store.getState().Auth.first_name + ' ' + store.getState().Auth.last_name;
    const company_id = store.getState().Facility.selected?.record?.company_id;
    return [
        {
            title: 'key',
            name: 'id',
            order: 0,
            visible: true,
            length: 0,
            type: 'text',
            render: <Input disabled/>
        },
        // {
        //     title: 'System',
        //     name: 'system',
        //     order: 1,
        //     visible: true,
        //     length: "w-full",
        //     type: 'text',
        //     render:
        //         <Input
        //             autoComplete="off"
        //             list="name-suggestions2"
        //             allowClear
        //         />,
        //     datalist: <datalist id="name-suggestions2">
        //         {store.getState().WorkOrder.data.map(e => (
        //             <option key={e.system} value={e.system}/>
        //         ))
        //         }
        //     </datalist>
        //
        // },

        {
            title: 'Equipment',
            name: 'equipment_name',
            order: 5,
            required: true,
            visible: true,
            length: "w-full",
            // type: 'Lookup',
            type: 'Lookup_text',
            render: (value) => <EquipmentLookup formRef={formRef} record={value}/>
        },
        {
            title: 'Equipment',
            name: 'equipment',
            order: 1,
            visible: false,
            length: "w-full",
            type: 'text',
        },
        {
            title: 'Issue',
            name: 'issue',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            required: true,
            render:
                <Input/>

        },
        {
            title: 'Description',
            name: 'description',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            render:
                <TextArea className='w-full' rows={3}/>
        },
        {
            title: 'Recommended Action',
            name: 'recommended_action',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <Input/>
        },
        {
            title: 'Responsible Party',
            name: 'responsible_party',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'text',
            render:
                <Input/>
        },
        {
            title: 'AssignTo',
            name: 'assignTo_name',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'Lookup_text',
            render: (value) => <UserLookup field={'assignTo'} formRef={formRef} record={value} company_id={company_id}/>
        },
        {
            title: 'AssignTo',
            name: 'assignTo',
            order: 5,
            visible: false,
            length: "w-full",
            type: 'text',
            render:
                <Input/>
        },
        {
            title: 'WorkOrder Number',
            name: 'workordernumber',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            render:
                <Input/>
        },
        {
            title: 'Status',
            name: 'status',
            order: 5,
            visible: true,
            length: "w-full",
            required: true,
            type: 'text',
            initialValue: 'Not Started',
            render: <Select>
                <Option value="Not Started">Not Started</Option>
                <Option value="InProgress">In Progress</Option>
                <Option value="On-Hold">On-hold</Option>
                <Option value="Verifying">Verifying</Option>
                <Option value="Completed">Completed</Option>
            </Select>
        },
        {
            title: 'Priority',
            name: 'priority',
            order: 5,
            visible: true,
            length: "w-full",
            required: true,
            type: 'text',
            initialValue: 'Low',
            render: <Select>
                <Option value="Urgent">Urgent</Option>
                <Option value="Important">Important</Option>
                <Option value="Low">Low</Option>
            </Select>
        },


        {
            title: 'Energy Impact',
            name: 'energy_impact',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <Select>
                <Option value="High">High</Option>
                <Option value="Medium">Medium</Option>
                <Option value="Low">Low</Option>
                <Option value="None">None</Option>
            </Select>
        },
        {
            title: 'Project',
            name: 'project',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'Lookup',
            render: (value) => <ProjectLookup2 record={value}/>
        },
        {
            title: 'Complete Date',
            name: 'complete_date',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <CustomDatePicker format='YYYY-MM-DD'/>,
        },

        {
            title: 'Reported By',
            name: 'reportBy',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            required: true,
            initialValue: currentUser,
            render:
                <Input/>
        },
        {
            title: 'Report Date',
            name: 'reportDate',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            initialValue: moment().format('YYYY-MM-DD'),
            render: <CustomDatePicker format='YYYY-MM-DD'/>,
        },
        {
            title: 'Created By',
            name: 'createdby_name',
            order: 17,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <Input disabled/>
        },
        {
            title: 'Created Date',
            name: 'created_date',
            order: 18,
            visible: true,
            length: "w-full",
            type: 'Date',
            render: <Input disabled/>
        },
        {
            title: 'Modified By',
            name: 'lastModified_name',
            order: 19,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <Input disabled/>
        },
        {
            title: 'Modified Date',
            name: 'modified_date',
            order: 20,
            visible: true,
            length: "w-full",
            type: 'Date',
            render: <Input disabled/>
        },

    ]
}
