import { FileManagement } from '@/components/FileManagement';
import React from 'react';



const FileManage = () => {
    return (
        <div>
            <FileManagement />
        </div>
    );
};

export default React.memo(FileManage);