import React, {Component} from 'react';
import {connect} from "react-redux";
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {jsforceResponse} from "@/config/httpService";
import {SearchOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {debounce} from "lodash";

export const Lookup_CommonWrapper = styled.div`
  //.ant-input-affix-wrapper{
  //  border-radius: 1.5rem;
  //  border: 1.9px solid #1890ff !important;
  //  overflow: hidden;
  //}

`

class Lookup_Common extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            record: null,
            value: null
        }
        this.debouncedHandleSearch = debounce(this.handleSearch2, 500);
    }

    componentDidMount() {
        // console.log('default', this.props.defaultValue)
        this.setState({
            defaultValue: this.props.defaultValue
        })
    }


    handleFocus = async (e) => {
        let options = [];
        const result = await this.props.Lookup_CommonupRequest('');
        const res = jsforceResponse(result, '', false, true)
        console.log('res', res)
        let existingKeys = new Set();
        for (let i = 0; i < res.data.length; i++) {
            if (!existingKeys.has(res.data[i][this.props.name])) {
                options.push({
                    key: res.data[i].id,
                    value: res.data[i][this.props.name],
                    label: res.data[i][this.props.name],
                    record: res.data[i].id
                })
                existingKeys.add(res.data[i][this.props.name]);
            }
        }
        this.setState({
            options
        })
    }

    handleSearch = async (e) => {
        this.setState({value: e})
        this.debouncedHandleSearch(e)
    }

    handleSearch2 = async (e) => {
        let options = [{
            key: 0,
            value: '',
            label: 'All',
            record: ''
        }];
        const result = await this.props.Lookup_CommonupRequest(e);
        const res = jsforceResponse(result, '', false, true)
        let existingKeys = new Set();
        for (let i = 0; i < res.data.length; i++) {
            if (!existingKeys.has(res.data[i][this.props.name])) {
                options.push({
                    key: res.data[i].id,
                    value: res.data[i][this.props.name],
                    label: res.data[i][this.props.name],
                    record: res.data[i].id
                })
                existingKeys.add(res.data[i][this.props.name]);
            }
        }
        this.setState({
            options
        })
    }

    handleSelect = (e, record) => {
        this.setState({value: e})
        this.props.SelectedLookup_CommonRequest(e, record)
    }

    handleOnChange = (e) => {
        if (!e) {
            this.setState({value: null})
            this.props.SelectedLookup_CommonRequest('', null)
        }
    }

    render() {
        // console.log('Lookup_Common', this.props.options)
        return (
            <Lookup_CommonWrapper>
                <AutoComplete
                    // placeholder={this.props.label}
                    style={this.props.style}
                    options={this.state.options}
                    onFocus={this.handleFocus}
                    onSelect={this.handleSelect}
                    onSearch={this.handleSearch}
                    onChange={this.handleOnChange}
                    value={this.state.value}
                    defaultValue={this.state.value}
                    dropdownMatchSelectWidth={false}
                    placeholder={this.props.placeholder}
                >
                    <Input size="medium" autosize={true} style={{textAlign: 'center'}}
                           allowClear
                           suffix={<SearchOutlined style={{color: 'grey'}}/>}/>
                </AutoComplete>
            </Lookup_CommonWrapper>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Lookup_Common);
