import React from "react";
import {Input, InputNumber, Select} from 'antd';
import CustomDatePicker from "@components/CustomDatePicker";

const {Option} = Select;

export const ScenarioFormFields = [
    {
        title: 'ID',
        name: 'id',
        order: 0,
        visible: false,
        length: 0,
        type: 'text',
    },
    {
        title: 'Name',
        name: 'name',
        order: 1,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: true,
        render: <Input autoComplete="off"/>,
    },

    {
        title: 'Baseline Start Date',
        name: 'baseline_start_date',
        order: 4,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: false,
        render: <CustomDatePicker format='YYYY-MM-DD'/>,
    },
    {
        title: 'Baseline End Date',
        name: 'baseline_end_date',
        order: 5,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: false,
        render: <CustomDatePicker format='YYYY-MM-DD'/>,
    },
    {
        title: 'Post Start Date',
        name: 'post_start_date',
        order: 6,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: false,
        render: <CustomDatePicker format='YYYY-MM-DD'/>,
    },
    {
        title: 'Post End Date',
        name: 'post_end_date',
        order: 7,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: false,
        render: <CustomDatePicker format='YYYY-MM-DD'/>,
    },
    {
        title: 'Electricity Price',
        name: 'electricity_price',
        order: 8,
        visible: true,
        length: 'w-full',
        type: 'number',
        required: false,
        render: <InputNumber precision={0}/>,
    },
    {
        title: 'Gas Price',
        name: 'gas_price',
        order: 9,
        visible: true,
        length: 'w-full',
        type: 'number',
        required: false,
        render: <InputNumber precision={0}/>,
    },
    {
        title: 'Price Type',
        name: 'price_type',
        order: 10,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: false,
        render: <Select>
            <Option value="Baseline Average">Baseline Average</Option>
            <Option value="Post-Period Average">Post-Period Average</Option>
        </Select>,
    },
    {
        title: 'Breakpoint 0',
        name: 'breakpoint0',
        order: 11,
        visible: true,
        length: 'w-full',
        required: false,
        render: <InputNumber precision={0}/>,
    },
    {
        title: 'Breakpoint 1',
        name: 'breakpoint1',
        order: 12,
        visible: true,
        length: 'w-full',
        required: false,
        render: <InputNumber precision={0}/>,
    },
    {
        title: 'Breakpoint 2',
        name: 'breakpoint2',
        order: 13,
        visible: true,
        length: 'w-full',
        required: false,
        render: <InputNumber precision={0}/>,
    },
    {
        title: 'Breakpoint 3',
        name: 'breakpoint3',
        order: 14,
        visible: true,
        length: 'w-full',
        required: false,
        render: <InputNumber precision={0}/>,
    },
    {
        title: 'Segment',
        name: 'segment',
        order: 15,
        visible: true,
        length: 'w-full',
        required: false,
        render: <InputNumber precision={0}/>,
    },
    {
        title: 'Use Breakpoints',
        name: 'use_breakpoints',
        order: 17,
        visible: true,
        length: 'w-full',
        type: 'checkbox',
        required: false,
        render: <Select>
            <Option value="Breakpoints">Breakpoints</Option>
            <Option value="Segments">Segments</Option>
        </Select>,
    }
]
