import styled from "styled-components";

const PermissionSettingStyle = styled.div`
  padding: 1rem;

  .ant-form-item {
    flex-direction: row !important;
    padding: 0.5rem 1rem;
    margin:0.5rem;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    justify-content: center;
  }

  .ant-form-item-label {
    text-transform: uppercase;
    padding: 0.2rem !important;
    padding-left: 0.4rem;
    line-height: 1.14286em;
    //color: #333;
    clear: left;
    position: relative;
    font-size: 0.8rem;
    font-weight: bold;
    display:flex;
  }

  //.ant-form-vertical .ant-form-item-label > label {
  //  padding: 0.2rem !important;
  //}

  .ant-form-item-control-input-content {
    text-align: right !important;
  }

  .ant-input[disabled] {
    color: black !important;
    border: none !important;
    border-radius: 2pt;
    min-height: 28px !important;
    padding: 0.5rem !important;
    
  }

  
  .ant-checkbox-disabled + span {
    color: black ;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after { 
    border-color: black !important;
    }
`

export default PermissionSettingStyle;
