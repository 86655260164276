import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';

export const Equipment_CategoryProps = {
    title: 'Facility Category',
    description: '',
    name: 'equipmentCategory',
}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}


export const Equipment_CategoryColumns = [
    {
        title: 'key',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIngore: true,
        width: '4%',
        hideInTable: true,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        search: false,
        edit: true,
        sorter: (a, b) => a.name?.localeCompare(b.name),
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        render: (text, record, _, action) => (
            <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
        )
    },
    {
        title: 'Spec1',
        dataIndex: 'spec1',
        key: 'spec1',
        edit: true,
        hideInTable: window.innerWidth < 760,
        sorter: (a, b) => a.location?.localeCompare(b.location),
    },
    {
        title: 'Spec2',
        dataIndex: 'spec2',
        key: 'spec2',
        edit: true,
        hideInTable: window.innerWidth < 760,
        sorter: (a, b) => a.location?.localeCompare(b.location),
    },
    {
        title: 'Spec3',
        dataIndex: 'spec3',
        key: 'spec3',
        edit: true,
        hideInTable: window.innerWidth < 760,
        sorter: (a, b) => a.location?.localeCompare(b.location),
    },
    {
        title: 'Spec4',
        dataIndex: 'spec4',
        key: 'spec4',
        edit: true,
        hideInTable: window.innerWidth < 760,
        sorter: (a, b) => a.location?.localeCompare(b.location),
    },
    {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        hideInTable: window.innerWidth < 760,
        edit: true
    },
    {
        title: '',
        valueType: 'option',
        width: '6rem',
        importIngore: true,
        render: (text, record, _, action) => (
            <div className={'space-around'}>
                <a
                    key="editable"
                    onClick={() => (action?.startEditable?.(record.id))}
                >
                    <i className="fas fa-edit text-lg"></i>
                </a>
                <a onClick={(e) => {
                    e.stopPropagation();
                    return action ? renderDrawer(text, record.id) : ''
                }}>
                    <i className="fas fa-expand-arrows-alt text-lg"></i>
                </a>
            </div>
        ),
    },
];
