import React from "react"
import { SummaryChart } from '../template/utility/summary-chart';
import { EnergyConsumptionChart } from '../template/utility/energy-consumption-chart'
import { SavingAnalysisChart } from '../template/utility/savingAnalysis-chart'
import { DashboardChart } from '../template/dashboard/dashboard-chart'
const PreViewObject = {
  recently: SummaryChart,
  period: EnergyConsumptionChart,
  savingAnalysis: SavingAnalysisChart,
  dashboard: DashboardChart,
}

const PreView = ({type, data}) => {

  const Comp = PreViewObject[type]
  if(!Comp) {
    return null
  }
  return <Comp {...data}/>
}

export default PreView;