import styled from "styled-components";

export const ChartWrapper = styled.div`
  display: block;
  position: relative;
  padding: 1rem 0rem;
  border: 1px solid #f0f0f0;
  border-radius: 6px;

  .title2 {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }
`

export const AnalysisTableWrapper = styled.div`
    .ant-table-tbody > tr > .ant-table-cell:nth-child(-n+7) {
        background-color: #e6f7ff; /* Light green */
    }

    .ant-table-tbody > tr > .ant-table-cell:not(:nth-child(-n+7)):nth-child(-n+10) {
        background-color: #d6dce1; /* Light green */
    }

    .ant-table table{
        text-align: right;
    }
`
