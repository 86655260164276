import React from "react";

export const PDFPageContainer = ({ children,lastPage = false, ...rest }) => {
  return (
    // <p-stack margin={[40, 40, 40, 0]} {...rest}>
    <p-stack{...rest}>
      {children}
      {!lastPage && <p-text pageBreak="after"></p-text>}
    </p-stack>
  );
};
