import {Button, Form, Select} from "antd";
import React, {useState} from "react";

export const ProjectFilters = (props) => {

    const [status, setStatus] = useState([]);
    const [priority, setPriority] = useState([]);

    const formRef = React.useRef()

    function handleStatus(status) {
        if (status?.includes('All')) {
            formRef.current.setFieldsValue({
                status: ['All']
            })
        }
        setStatus(status);

    }

    function handlePriority(priority) {
        if (priority?.includes('All')) {
            formRef.current.setFieldsValue({
                priority: ['All']
            })
        }
        setPriority(priority);
    }

    const statusFilter = () => {
        return (
            <Select
                mode="single"
                allowClear
                value={status}
                style={{
                    width: '100%',
                }}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder="Status"
                onChange={handleStatus}
                options={props.status_options}
                dropdownMatchSelectWidth={false}
            />
        )
    }

    const priorityFilter = () => {
        return (
            <Select
                mode="single"
                allowClear
                value={priority}
                style={{
                    width: '100%',
                }}
                dropdownMatchSelectWidth={false}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder="Priority"
                onChange={handlePriority}
                options={props.priority_options}
            />
        )
    }

    function handleSubmit(e) {
        console.log('submit', e)
        props.handleFilter(e)
    }

    return <Form
        id="myForm"
        layout="horizontal"
        onFinish={handleSubmit}
        ref={formRef}
    >

        <Form.Item name={'status'} className={'md:w-60 lg:w-60 sm:w-full'}>
            {statusFilter()}
        </Form.Item>

        <Form.Item name={'priority'} className={'md:w-60 lg:w-60 sm:w-full'}>
            {priorityFilter()}
        </Form.Item>

        <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
    </Form>

}
