import React, {Component} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {CompanyColumns, CompanyProps} from "@containers/management/Company/CompanyColumns";
import _ from "lodash";
import actions from "@redux/company/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {CompanyFormFields} from "@containers/management/Company/CompanyFormFields";
import Facility from "@containers/management/Facility/Facility";
import Lookup_Common from "@components/Lookup_common";

class Company extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            filter_dates: [],
            isLoading: false,
            formRef: null,
            query: null,
            // equipment: null
        }
    }


    handleCreateCompany = (body) => {
        body['facility'] = this.props.SelectedFacility;

        return api.createCompany(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteCompany = (id) => {
        return api.deleteCompany(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkCompanyInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteCompany(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        row['facility'] = this.props.SelectedFacility;

        return api.editCompany(key, row).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {
        this.setState({
            isLoading: true
        })
        let query_new = '';
        query_new = this.state.query + '&' + query_new
        console.log('query_new', query_new, this.state.query)
        return api.filterCompany(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getCompany = (data) => {
        this.setState({
            data,
            isLoading: false,
        })
        this.props.setCompanySuccess(data)
    }

    setFilterDates = (dates) => {
        this.setState({
            filter_dates: dates
        })

    }

    FacilityRelated = (relatedId) => {
        return <>{relatedId ? <Facility relatedList={true} relatedId={relatedId} relatedObject={'facility'}/> : ''}</>
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    handleSearchSelect = (e) => {
        console.log('select', e)
        let query = 'name=' + e;
        this.setState({
            query: query
        }, function () {
            setTimeout(() =>
                this.refresh(false), 1000)
        })
    }
    handleSearch = (e, record) => {
        let query = 'name=' + e;
        this.setState({
            query: query
        })
        return this.handleFilter()
    }
    renderSearches = () => {

        return (
            <Lookup_Common
                placeholder={'Search by Name'}
                name={'name'}
                style={{width: '100%', paddingRight: '2rem', marginBottom: '1rem'}}
                SelectedLookup_CommonRequest={this.handleSearchSelect}
                Lookup_CommonupRequest={this.handleSearch}
            />
        )
    }

    render() {
        console.log('Role', this.props.Role)
        return (

            <EditableTable
                ref={this.tableRef}
                columns={CompanyColumns}
                data={this.state.data}
                columnProps={CompanyProps}
                formFields={CompanyFormFields}
                formRelated={this.FacilityRelated}
                getRecord={this.getCompany}
                createRecords={this.handleCreateCompany}
                deleteRecord={this.handleDeleteCompany}
                bulkInsert={this.handleBulkInsert}
                bulkDelete={this.handleBulkDelete}
                handOnlineSave={this.handOnlineSave}
                handleFilter={this.handleFilter}
                setFilterDates={this.setFilterDates}
                filter_dates={this.state.filter_dates}
                relatedList={this.props.relatedList}
                isRowClickable={false}
                hide_imports={true}
                hide_search={true}
                isChat={false}
                isRelated={true}
                isAttachments={false}
                hide_date={true}
                hid_new={this.props.Role === '4.0'}
                Role={this.props.Role}
                filterDescription={this.state.filterDescription}
                renderSearches={this.renderSearches}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        SelectedFacility: _.get(state.Facility, 'selectedFacility', 'id'),
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] : null,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCompanyRequest: (e) => dispatch(actions.CompanyRequest(e)),
        setCompanySuccess: (e) => dispatch(actions.CompanySuccess(e)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Company);
