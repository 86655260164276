export const NormalizedCalculationsGas =(
    adj,
dateDiff,
    area
) => {

    let normal = adj * Math.pow(10,6) / dateDiff / area / 24;
    return normal
}

export const StandardizedCalculationsGas =(
    predicted,
    dateDiff,
    area
) => {

    let normal = predicted * dateDiff * area * 24 /Math.pow(10,6);
    return normal
}

export const NormalizedCalculationsElec =(
    adj,
    dateDiff,
    area
) => {
    let normal = adj * 1000 / dateDiff / area / 24;
    return normal
}


export const StandardizedCalculationsElec =(
    predicted,
    dateDiff,
    area
) => {
    let normal = predicted * dateDiff * area * 24 /1000;
    return normal
}
