const actions = {
    Scenario_REQUEST: 'Scenario_REQUEST',
    Scenario_SUCCESS: 'Scenario_SUCCESS',

    ScenarioRequest: () => ({
        type: actions.Scenario_REQUEST,
        payload: {},
    }),
    ScenarioSuccess: (data) => ({
        type: actions.Scenario_SUCCESS,
        payload: {data},
    }),
};
export default actions;
