import React, {Component} from 'react';
import EditableTable from "@components/Table/EditableTable";

import _ from "lodash";
import actions from "@redux/workorder/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";

import {Col, Collapse, DatePicker, Divider, Drawer, Input, Row, Select} from "antd";
import {WidgetWrapper} from "@components/WidgetBox/Widgets.styles";
import SearchLookup from "@components/SearchLookup";
import moment from "moment/moment";
import PermissionControl from "@components/Permission/Permission";
import ColumnChart from "@containers/UtilityData/Charts/ColumnChart";
import queryString from 'query-string';
import {withRouter} from "@/router/withRouter";
import {
    RelatedWorkorderColumns,
    RelatedWorkorderProps
} from "@containers/facility/Equipment/RelatedWorkorder/RelatedWorkorderColumns";
import {RelatedWorkorderFormFields} from "@containers/facility/Equipment/RelatedWorkorder/RelatedWorkorderFormFields";
import {RelatedWorkorderComment} from "@containers/facility/Equipment/RelatedWorkorder/RelatedWorkorderComment";
import Detail from "@components/Table/Detail";

const {Option} = Select;

class RelatedWorkorder extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            columnsStateMap: {},
            data: [],
            status: null,
            energy: null,
            equipment: null,
            project: null,
            project_name: null,
            equipment_name: null,
            priority: null,
            isLoading: false,
            issue_keyword: null,
            query: null,
            completeYear: null,
            completeMonth: null,
            recordYear: null,
            recordMonth: null,
            groupedStatusData: [],
            groupedPriorityData: [],
            groupedMonthlyData: [],
            panel: null,
            complete_date: [null, null],
            created_date: [null, null],
            report_date: [null, null],
            project_keyword: null,
            equipment_keyword: null,
            issue: null,
            tableRef: null,
            isOpen: false,
            selected_id: null
        }
    }

    componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        if (params?.status) {
            this.setState({
                status: params.status
            })
        }
        if (params?.priority) {
            this.setState({
                priority: params.priority
            })
        }
        if (params?.complete_date) {
            this.setState({
                complete_date: [moment().subtract(params?.complete_date, 'days'), moment()]
            })
        }
        if (params?.report_date) {
            this.setState({
                report_date: [moment().subtract(params?.report_date, 'days'), moment()]
            })
        }
        if (params.created_date) {
            this.setState({
                created_date: [moment().subtract(params?.created_date, 'days'), moment()]
            })
        }
    }

    getAllWorkOrders = (query) => {
        return api.allworkorderview(query).then(
            response => {
                let dataSource = response.data
                this.setState({
                    groupedMonthlyData: dataSource?.sorted_monthly_data
                });
            }
        ).catch(error => (
            error
        ));
    }

    handleCreateWorkorder = (body) => {
        // console.log('created', this.props.parentProject)

        body['project'] = this.props.SelectedProject;

        let parent = this.props.relatedList;
        if (parent) {
            body['priority'] = 'Urgent';
            body['project'] = this.props.parentProject;
            body['equipment'] = this.props.parentEquipment;
        }

        return api.createWorkorder(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteWorkorder = (id) => {
        return api.deleteWorkorder(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkWorkorderInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteWorkorder(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        row['project'] = this.props.SelectedProject;

        let parent = this.props.relatedList;
        if (parent) {
            row['priority'] = 'Urgent';
            row['project'] = this.props.parentProject;
        }
        return api.editWorkorder(key, row).then(
            response => {
                this.handleDetailDrawer(false, null)
                return response
            }
        ).catch(error => (
            error
        ));
    }

    handleFilter = (query) => {
        this.setState({
            isLoading: true,
        });

        let query_new = query +
            (this.state.issue ? ('&issue=' + (this.state.issue)) : '') +
            (this.state.equipment_name ? ('&equipment_name=' + (this.state.equipment_name)) : '') +
            (this.state.project_name ? ('&project_name=' + (this.state.project_name)) : '') +
            (this.state.status ? ('&status=' + (this.state.status)) : '') +
            (this.state.priority ? ('&priority=' + (this.state.priority)) : '') +
            (this.state.energy ? ('&energy_impact=' + (this.state.energy)) : '') +
            (this.state.complete_date[0] ? ('&complete_date_start=' + (moment(this.state.complete_date[0]).format('YYYY-MM-DD'))) : '') +
            (this.state.complete_date[1] ? ('&complete_date_end=' + (moment(this.state.complete_date[1]).format('YYYY-MM-DD'))) : '') +
            (this.state.report_date[0] ? ('&report_date_start=' + (moment(this.state.report_date[0]).format('YYYY-MM-DD'))) : '') +
            (this.state.report_date[1] ? ('&report_date_end=' + (moment(this.state.report_date[1]).format('YYYY-MM-DD'))) : '') +
            (this.state.created_date[0] ? ('&created_date_start=' + (moment(this.state.created_date[0]).format('YYYY-MM-DD'))) : '') +
            (this.state.created_date[1] ? ('&created_date_end=' + (moment(this.state.created_date[1]).format('YYYY-MM-DD'))) : '')
        ;
        let parent = this.props.relatedList;
        if (parent) {
            if (this.props.parentProject) {
                query_new += 'project=' + this.props.parentProject + '&'
            }
            if (this.props.parentEquipment) {
                query_new += 'equipment=' + this.props.parentEquipment + '&'
            }
        }
        return api.filterWorkorder(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getWorkorder = (data) => {

        this.setState({
            data,
            isLoading: false,
        });
        // this.props.setWorkorderSuccess(data)
    };

    handleFormRelated = (e) => {
        console.log('handleFormRelated', e);
    }

    handleClearButtonClick = (e) => {
        this.setState({
            status: null,
            priority: null,
            energy_impact: null,
            equipment: null,
            project: null,
            query: null,
        }, function () {
            this.refresh(false)
        })
    }
    handlePostComment = (e) => {
        return <RelatedWorkorderComment id={e}/>
    }

    refresh = (needRequest) => {

        return this.tableRef?.current?.refresh(needRequest);
    };

    handleLookupFilter = (e) => {
        console.log('test')
        let query_new = (this.state.issue_keyword ? ('issue=' + this.state.issue_keyword) : '') +
            (this.state.equipment_keyword ? ('equipment_name=' + this.state.equipment_keyword) : '') +
            (this.state.project_keyword ? ('project_name=' + this.state.project_keyword) : '') +
            '&limit=15';

        return api
            .filterWorkorder(query_new)
            .then((response) => {
                let dataSource = response.data.results;
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count,
                };
            })
            .catch((error) => {
                return error;
            });
    }

    handleSelect = (e, type) => {
        this.setState({
            [type]: e,
            limit: null
        }, function () {
            this.refresh(false);
        })
    }

    handleSearchSelect = (e, type) => {
        this.setState({
            [type]: e,
            limit: null
        }, function () {
            this.refresh(false);
        })
    }

    handleSearch = (e, type) => {
        this.setState({
            [type + '_keyword']: e
        });
        return this.handleLookupFilter();
    }

    onDateChange = (dates, state, index) => {
        let bDate = this.state[state];
        bDate[index] = dates

        this.setState({
            [state]: bDate
        }, function () {
            this.refresh(false);
        })

    }

    renderSearches = () => {
        return (
            <>
                <SearchLookup
                    placeholder={'issue'}
                    name={'issue'}
                    style={{width: '18rem', paddingRight: '1rem'}}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'issue')}
                    Lookup_CommonupRequest={(e) => this.handleSearch(e, 'issue')}
                />
                <SearchLookup
                    placeholder={'Equipment'}
                    name={'equipment_search_name'}
                    style={{width: '15rem', paddingRight: '1rem'}}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'equipment_name')}
                    Lookup_CommonupRequest={(e) => this.handleSearch(e, 'equipment')}
                />
                <SearchLookup
                    placeholder={'Project'}
                    name={'project_search_name'}
                    style={{width: '15rem', paddingRight: '1rem'}}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'project_name')}
                    Lookup_CommonupRequest={(e) => this.handleSearch(e, 'project')}
                />
                <SearchLookup
                    placeholder={'WO #'}
                    name={'workordernumber'}
                    style={{width: '15rem', paddingRight: '1rem'}}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'workordernumber')}
                    Lookup_CommonupRequest={(e) => this.handleSearch(e, 'workordernumber')}
                />

                <div className={'pr-3'}>
                    <Select placeholder={'Status'} mode="multiple" className={'min-multi-selector'}
                            value={this.state.status}
                            onChange={(e) => this.handleSearchSelect(e, 'status')}>

                        <Option value=""></Option>
                        <Option value="Not Started">Not Started</Option>
                        <Option value="InProgress">In Progress</Option>
                        <Option value="On-Hold">On-hold</Option>
                        <Option value="Verifying">Verifying</Option>
                        <Option value="Completed">Completed</Option>
                    </Select>
                </div>

                <div className={'pr-3'}>
                    <Select style={{width: '10rem'}} className={'mr-3'} placeholder={'Priority'}
                            value={this.state.priority}
                            onChange={(e) => this.handleSearchSelect(e, 'priority')}>
                        <Option value=""></Option>
                        <Option value="Urgent">Urgent</Option>
                        <Option value="Important">Important</Option>
                        <Option value="Low">Low</Option>
                    </Select>
                </div>

                <div className={'pr-3'}>
                    <Select style={{width: '10rem'}} className={'mr-3'} placeholder={'Energy'}
                            onChange={(e) => this.handleSearchSelect(e, 'energy')}>
                        <Option value=""></Option>
                        <Option value="High">High</Option>
                        <Option value="Medium">Medium</Option>
                        <Option value="Low">Low</Option>
                        <Option value="None">None</Option>
                    </Select>
                </div>

                <div className={'flex flex-wrap items-center'}>
                    <DatePicker
                        className={'border-right-6'}
                        value={this.state.complete_date[0]}
                        placeholder={'complete start'}
                        style={{width: '15rem'}}
                        onChange={(dates) => this.onDateChange(dates, 'complete_date', 0, '')}
                    />
                    <DatePicker
                        className={'border-left-6'}
                        value={this.state.complete_date[1]}
                        placeholder={'complete end'}
                        style={{width: '15rem'}}
                        onChange={(dates) => this.onDateChange(dates, 'complete_date', 1, '')}
                    />
                </div>

                <div className={'flex flex-wrap items-center'}>
                    <DatePicker
                        className={'border-right-6'}
                        value={this.state.report_date[0]}
                        placeholder={'report start'}
                        style={{width: '15rem'}}
                        onChange={(dates) => this.onDateChange(dates, 'report_date', 0, '')}
                    />
                    <DatePicker
                        className={'border-left-6'}
                        value={this.state.report_date[1]}
                        placeholder={'report end'}
                        style={{width: '15rem'}}
                        onChange={(dates) => this.onDateChange(dates, 'report_date', 1, '')}
                    />
                </div>

                <div className={'flex flex-wrap items-center'}>
                    <DatePicker
                        className={'border-right-6'}
                        value={this.state.created_date[0]}
                        placeholder={'created start'}
                        style={{width: '15rem'}}
                        onChange={(dates) => this.onDateChange(dates, 'created_date', 0, '')}
                    />
                    <DatePicker
                        className={'border-left-6'}
                        value={this.state.created_date[1]}
                        placeholder={'created end'}
                        style={{width: '15rem'}}
                        onChange={(dates) => this.onDateChange(dates, 'created_date', 1, '')}
                    />
                </div>

            </>
        )
    }

    handlePanelChange = (e) => {
        console.log('handlePanelChange', e)
        this.setState({
            panel: e[0]
        }, function () {
            if (e[0]) {
                // this.refresh(false)
                this.getAllWorkOrders()
            }
        })
    }

    gettableRef = (tableRef) => {
        this.setState({
            tableRef
        })
    }

    renderDrawer = (isOpen, selected_id) => {
        console.log('renderDrawer', isOpen, selected_id)
        const drawerKey = 'RelatedWorker_drawer'+selected_id;
        return <Drawer
            key={drawerKey}
            width={window.innerWidth > 900 ? '80%' : '100%'}
            placement="right"
            closable={false}
            onClose={() => this.handleDetailDrawer(false, null)}
            visible={isOpen}
            destroyOnClose={true}
            autoFocus={true}
        >
            <div>
                {isOpen ?
                    <Detail
                        key={'tablecontent_detail'}
                        formKey={'tablecontent_detail'}
                        formFields={RelatedWorkorderFormFields(this.state.tableRef)}
                        handOnlineSave={this.handOnlineSave}
                        createRecords={this.createRecords}
                        handleSaveComment={this.handleSaveComment}
                        onClose={() => {this.handleDetailDrawer(false, null)}}
                        handleDelete={this.deleteRecord}
                        columnProps={this.props.columnProps}
                        isAttachments={this.props.isAttachments}
                        formRefCallback={this.formRefCallback}
                        data={this.state.data.filter(e => e.id === selected_id)[0]}/> : ''}
            </div>
        </Drawer>
    }

    handleDetailDrawer = (e, selected_id) => {
        this.setState({
            isOpen: e,
            selected_id: selected_id
        })
    }
    render() {

        return (

            <div className="">

                <EditableTable
                    ref={this.tableRef}
                    columns={RelatedWorkorderColumns(this.handleDetailDrawer)}
                    data={this.state.data}
                    columnProps={RelatedWorkorderProps}
                    formFields={RelatedWorkorderFormFields(this.state.tableRef)}
                    formRelated={this.handleFormRelated}
                    postComment={this.handlePostComment}
                    getRecord={this.getWorkorder}
                    createRecords={this.handleCreateWorkorder}
                    deleteRecord={this.handleDeleteWorkorder}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    // renderFilter={this.renderFilter}
                    relatedList={this.props.relatedList}
                    relatedId={this.props.relatedId}
                    relatedObject={this.props.relatedObject}
                    hide_search={true}
                    isRowClickable={false}
                    isChat={true}
                    isRelated={false}
                    isAttachments={true}
                    setFilters={this.setFilters}
                    getFormRef={this.gettableRef}
                    renderSearches={this.renderSearches}
                    defaultDisplayColumns={{
                        "reportDate": {
                            "show": false
                        },
                        "created_date": {
                            "show": false
                        },"complete_date": {
                            "show": false
                        },"reportBy": {
                            "show": false
                        },"project": {
                            "show": false
                        },
                    }}
                />

                {this.renderDrawer(this.state.isOpen, this.state.selected_id)}

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        Workorder: _.get(state.Workorder, 'data'),
        SelectedEquipment: _.get(state.Equipment, 'selectedEquipment.id'),
        SelectedProject: _.get(state.Project, 'selectedProject.id')
    }
}
const mapDispatchToProps = dispatch => {
    return {

        setWorkorderRequest: (e) => dispatch(actions.WorkorderRequest(e)),
        setWorkorderSuccess: (e) => dispatch(actions.WorkorderSuccess(e)),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RelatedWorkorder));
