import React from 'react';
import {Button, Divider, Form, Input} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

function ComplexDynamicFields(props) {

    return (
        <>
            <Divider/>
            {props.value ?
                <Form.List name="externalUrl" className={'md:w-1/3 lg:w-1/3 sm:w-full'}>
                    {(fields, {add, remove}) => {
                        // console.log('fields', fields, props.value)
                        return <>
                            {fields.map(field => (

                                <div key={field.key} className={'md:w-3/4 lg:w-3/4 sm:w-full flex align-item'}>

                                    <Form.Item
                                        {...field}
                                        className={'md:w-1/4 lg:w-1/4 sm:w-full'}
                                        name={[field.name, 'label']}
                                    >
                                        <Input placeholder="name"/>
                                    </Form.Item>

                                    <Form.Item
                                        {...field}
                                        className={'md:w-2/3 lg:w-2/3 sm:w-full'}
                                        name={[field.name, 'value']}
                                    >
                                        <Input placeholder="value"/>
                                    </Form.Item>

                                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{fontSize: '150%'}}
                                                         className={'pl-5'}/>
                                </div>
                            ))}

                            <Form.Item className={'md:w-1/3 lg:w-1/3 sm:w-full'}>
                                <Button type="primary" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Add URL
                                </Button>
                            </Form.Item>
                        </>
                    }}
                </Form.List>


                : ''}
            <Divider/>
        </>
    );
}

export default ComplexDynamicFields;
