import React, {Component, PureComponent} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {ProjectColumns, ProjectProps} from "@containers/facility/ProjectManagement/Project/ProjectColumns";
import _ from "lodash";
import actions from "@redux/project/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {ProjectFormFields} from "@containers/facility/ProjectManagement/Project/ProjectFormFields";

import Workorder from "@containers/facility/Workorder/Workorder";
import {ProjectComment} from "@containers/facility/ProjectManagement/Project/ProjectComment";
import Planning from "@containers/facility/ProjectManagement/Planning/Planning";
import {removeDuplicates} from "@components/help";
import {ProjectFilters} from "@containers/facility/ProjectManagement/Project/ProjectFilter";
import {Spin} from "antd";

class Project extends PureComponent {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            project: '',

            project_options: [],
            priority_options: [],

            filtered_priority: null,
            filtered_status: null,
            isLoading: false,
        }
    }

    handleCreateProject = (body) => {
        return api.createProject(body).then(
            response => {

                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteProject = (id) => {
        return api.deleteProject(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkProjectInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteProject(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {

        let temp = {};
        for (let i = 0; i < Object.keys(row).length; i++) {
            if (row[Object.keys(row)[i]] !== 'Invalid date' &&
                row[Object.keys(row)[i]] !== null &&
                row[Object.keys(row)[i]] !== undefined
            ) {
                temp[Object.keys(row)[i]] = row[Object.keys(row)[i]]
            }
        }

        return api.editProject(key, temp).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }

    handleFilter = (query) => {

        this.setState({
            isLoading: true
        })
        let query_new =
            (this.state.filtered_status ? ('&status=' + (this.state.filtered_status)) : '') +
            (this.state.filtered_priority ? ('&priority=' + (this.state.filtered_priority)) : '')
        ;


        return api.filterProject(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                }
            }
        ).catch(error => (
            error
        ));
    }

    getProject = (data) => {
        let status_options = [];
        // status_options.push({label: 'All', value:'All'});

        let priority_options = [];
        // priority_options.push({label: 'All', value:'All'});

        for (let i = 0; i < data.length; i++) {
            status_options.push({label: data[i].status, value: data[i].status});
            priority_options.push({label: data[i].priority, value: data[i].priority});
        }

        this.setState({
            isLoading: false,
            data,
            status_options: removeDuplicates(status_options),
            priority_options: removeDuplicates(priority_options)
        })
        this.props.setProjectRequest(data)
    }

    ProjectRelated = (relatedId) => {
        return <>{relatedId ?
            <Workorder relatedList={true} parentProject={relatedId} relatedObject={'project'}/> : ''}</>
    }

    handlePostComment = (e) => {
        return <ProjectComment id={e}/>
    }

    SelectedProject = (e) => {

        this.setState({
            project: e
        })

    }

    renderFilter = () => {
        return <ProjectFilters
            status_options={this.state.status_options}
            priority_options={this.state.priority_options}
            handleFilter={this.setFilters}
        />
    }

    setFilters = (e) => {
        this.setState({
            filtered_status: e.status ? e.status : [],
            filtered_priority: e.priority ? e.priority : [],
        }, function () {
            this.refresh(false)
        })
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    render() {
        return (
            <Spin spinning={this.state.isLoading}>


                <EditableTable
                    ref={this.tableRef}
                    columns={ProjectColumns}
                    data={this.state.data}
                    columnProps={ProjectProps}
                    formFields={ProjectFormFields}
                    formRelated={this.ProjectRelated}
                    getRecord={this.getProject}
                    postComment={this.handlePostComment}
                    createRecords={this.handleCreateProject}
                    deleteRecord={this.handleDeleteProject}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}

                    renderFilter={this.renderFilter}

                    relatedList={this.props.relatedList}
                    selected_row={this.props.selected_projectId}

                    hide_date={true}
                    hide_imports={true}
                    isRowClickable={false}
                    isRowSelectable={true}
                    hide_search={true}
                    isChat={true}
                    isRelated={true}
                    isAttachments={true}
                />

                <div className="block relative p-5">
                    <Planning
                        selected_project_name={_.get(this.props, 'selected_projectName')}
                        selected_projectId={_.get(this.props, 'selected_projectId')}/>


                    {/*{!this.props.selected_projectName ?*/}
                    {/*    <div className="mask-pic">*/}
                    {/*        <div className="flex items-center justify-center w-full">*/}
                    {/*            <LockOutlined style={{fontSize: '28px'}}/>*/}
                    {/*        </div>*/}
                    {/*    </div> : ''}*/}

                </div>

                <div className="block relative p-5">

                </div>

            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        Project: _.get(state.Project, 'data'),
        selected_projectId: _.get(state.EditableTable, 'selected_projectId', null),
        selected_projectName: _.get(state.EditableTable, 'selected_projectName', ''),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setProjectRequest: (e) => dispatch(actions.ProjectSuccess(e)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Project);
