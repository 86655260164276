import { PDFPageContainer } from "../components/PageContainer";
import { col_2, convertTo2DArray, pdfFontSize } from "./utils";
import React from "react";

export const PDFDashboardCharts = (_, { pdfFormValue, pdfImages }) => {

  const dashboardCharts = pdfImages.dashboardCharts
  if (dashboardCharts.length === 0) {
    return null
  }


  const images2d = convertTo2DArray(dashboardCharts)

  return (
    <PDFPageContainer lastPage={true}>
      {
        images2d.map((imgs, index) => {
          return (
            <p-col columnGap={40} key={index}>
              {
                imgs.map((img, idx) => {
                  if (!img) return <p-text></p-text>
                  return (
                    <>
                      <p-stack key={idx} width={col_2} unbreakable={true}>
                        <p-text color="#396598" fontSize={pdfFontSize.H4} margin={[0, 10]} bold={true}>{img.pdfChart_Title}</p-text>
                        <p-img dw={400} dh={250} width={250} src={img.img}></p-img>
                      </p-stack>
                    </>

                  );
                })
              }
            </p-col>
          )
        })
      }
    </PDFPageContainer>
  );
};

