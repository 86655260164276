const actions = {

    CLOSE_NEW_MODAL_REQUEST: 'CLOSE_NEW_MODAL_REQUEST',

    CloseModalRequest: (status) => ({
        type: actions.CLOSE_NEW_MODAL_REQUEST,
        payload: {status},
    }),

    CLOSE_IMPORT_MODAL_REQUEST: 'CLOSE_IMPORT_MODAL_REQUEST',

    CloseImportModal: (status) => ({
        type: actions.CLOSE_IMPORT_MODAL_REQUEST,
        payload: {status},
    }),

    OPEN_DRAWER: "OPEN_DRAWER",
    OpenDrawer: (status, id) => ({
        type: actions.OPEN_DRAWER,
        payload: {status, id},
    }),

    SELECTED_PROJECT: "SELECTED_PROJECT",
    SelectedProject: (id, name) => ({
        type: actions.SELECTED_PROJECT,
        payload: {id, name},
    }),

    SELECTED_ROWS: "SELECTED_ROWS",
    SelectedRows: (ids) => ({
        type: actions.SELECTED_ROWS,
        payload: {ids},
    })

};
export default actions;
