import {createGlobalStyle} from 'styled-components';
import {font, palette} from 'styled-theme';
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  //.ant-btn{
  //  border-radius: 4px;
  //}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;

      .ant-checkbox-group-item {
        margin: 5px 0;

        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;

            h2 {
              margin: 0;
            }

            form {
              padding: 15px 0 3px;

              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


  /*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8;
  }

  font-family: ${font('primary', 0)}

  ;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .ant-row:not(.ant-form-item) {${'' /* margin-left: -8px;
  margin-right: -8px; */};

    &:before,
    &:after {
      display: none;
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .LeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .CenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  /********** Add Your Global CSS Here **********/

  body {
    -webkit-overflow-scrolling: touch;
  }

  html h1,
  html h2,
  html h3,
  html h4,
  html h5,
  html h6,
  html a,
  html p,
  html li,
  input,
  textarea,
  span,
  div,
  html,
  body,
  html a {
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  html ul {
    -webkit-padding-start: 0px;
    list-style: none;
    margin-bottom: 0;
  }

  .scrollbar-track-y,
  .scrollbar-thumb-y {
    width: 5px !important;
  }

  .scrollbar-track-x,
  .scrollbar-thumb-x {
    height: 5px !important;
  }

  .scrollbar-thumb {
    border-radius: 0 !important;
  }

  .scrollbar-track {
    background: rgba(222, 222, 222, 0.15) !important;
  }

  .scrollbar-thumb {
    border-radius: 0 !important;
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
  .ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
  .ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
  .ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
  .ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
    left: 0;
    margin-left: -4px;
  }

  /* Instagram Modal */

  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 935px;
    width: 100% !important;
  }

  @media only screen and (max-width: 991px) {
    .ant-modal-wrap.instagram-modal .ant-modal {
      padding: 0 60px;
    }
  }

  @media only screen and (max-width: 767px) {
    .ant-modal-wrap.instagram-modal .ant-modal {
      max-width: 580px;
    }
  }

  .ant-modal-wrap.instagram-modal .ant-modal-content {
    border-radius: 0;
  }

  .ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
    position: fixed;
    color: #fff;
  }

  .ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
    font-size: 24px;
  }

  .ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
    padding: 0;
  }

  /********** Add Your Global RTL CSS Here **********/

  /* Popover */

  html[dir='rtl'] .ant-popover {
    text-align: right;
  }

  /* Ecommerce Card */

  html[dir='rtl'] .CardInfoForm .ant-input {
    text-align: right;
  }

  /* Modal */

  html[dir='rtl'] .has-success.has-feedback:after,
  html[dir='rtl'] .has-warning.has-feedback:after,
  html[dir='rtl'] .has-error.has-feedback:after,
  html[dir='rtl'] .is-validating.has-feedback:after {
    left: 0;
    right: auto;
  }

  html[dir='rtl'] .ant-modal-close {
    right: inherit;
    left: 0;
  }

  html[dir='rtl'] .ant-modal-footer {
    text-align: left;
  }

  html[dir='rtl'] .ant-modal-footer button + button {
    margin-left: 0;
    margin-right: 8px;
  }

  html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
    margin-right: 42px;
  }

  html[dir='rtl'] .ant-btn > .anticon + span,
  html[dir='rtl'] .ant-btn > span + .anticon {
    margin-right: 0.5em;
  }

  html[dir='rtl'] .ant-btn-loading span {
    margin-left: 0;
    margin-right: 0.5em;
  }

  html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
    padding-left: 25px;
    padding-right: 29px;
  }

  html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
    margin-right: -14px;
    margin-left: 0;
  }

  /* Confirm */

  html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
    margin-left: 16px;
    margin-right: 0;
    float: right;
  }

  html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
    float: left;
  }

  html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
    margin-right: 10px;
    margin-left: 0;
  }

  /* Message */

  html[dir='rtl'] .ant-message .anticon {
    margin-left: 8px;
    margin-right: 0;
  }

  /* Pop Confirm */

  html[dir='rtl'] .ant-popover-message-title {
    padding-right: 20px;
    padding-left: 0;
  }

  html[dir='rtl'] .ant-popover-buttons {
    text-align: left;
  }

  /* Notification */

  html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
    padding-left: 24px;
    padding-right: 0;
  }

  html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
  html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
    margin-right: 48px;
  }

  html[dir='rtl'] .ant-notification-notice-close {
    right: auto;
    left: 16px;
  }

  html[dir='rtl'] .ant-notification-notice-with-icon {
    left: 0;
  }

  /* Dropzone */

  html[dir='rtl'] .dz-hidden-input {
    display: none;
  }
`;

export default GlobalStyles;
