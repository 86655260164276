import React, {PureComponent, Suspense} from 'react';
import EditableTable from "@components/Table/EditableTable";
import _ from "lodash";
import actions from "@redux/gas/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {Col, Collapse, Divider, Row} from "antd";
import {GasColumns, GasProps} from "@containers/UtilityData/Gas/GasColumns";
import {GasFormFields, GasRelated} from "@containers/UtilityData/Gas/GasFormFields";
import {Spinner} from "@components/Spinner";
import UtilityDualAxes from "@containers/UtilityData/Charts/UtilityDualAxes.js";
import moment from "moment";
import EditableTableActions from "@redux/EditableTable/actions";

import {GasFilters} from "@containers/UtilityData/Gas/GasFilter";

const Histgram = React.lazy(() =>
    import("@containers/UtilityData/Charts/Histgram.js")
)
const LineChart = React.lazy(() =>
    import("@containers/UtilityData/Charts/LineChart.js")
)

const {Panel} = Collapse;

class GasPage extends PureComponent {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            filter_dates: [],
            selected_months: [],
            selected_id: [],
            isLoading: false,
            tableRef: null,
            Selected_Rows: []
        }
    }

    componentDidUpdate(prevProps) {
        let selected_months = this.state.data.filter(e => this.state.Selected_Rows.includes(e.id)).map(e => moment(e.service_month).format('YY-MMM'))

        if (!_.isEqual(selected_months, this.state.selected_months)) {
            this.setState({ selected_months });
        }
    }

    handleCreateGas = (body) => {
        return api.createGas(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteGas = (id) => {
        return api.deleteGas(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkGasInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteGas(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        if (key < 0) {
            this.handleCreateGas(row)
        } else {
            return api.editGas(key, row).then(
                response => {

                    return response
                }
            ).catch(error => (
                error
            ));
        }

    }


    handleFilter = (query) => {
        // console.Gas('triggered', query)
        this.setState({
            isLoading: true
        })
        let query_new = query +
            (this.state.filtered_startModifiedDate ? ('&start_month=' + (this.state.filtered_startModifiedDate)) : '') +
            (this.state.filtered_endModifiedDate ? ('&end_month=' + (this.state.filtered_endModifiedDate)) : '');

        return api.filterGas(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getGas = (data) => {

        this.setState({
            data,
            isLoading: false
        })

        this.props.setGasSuccess(data)
    }
    setFilterDates = (dates) => {
        // console.Gas('filtering dates', dates)
        this.setState({
            filter_dates: dates
        })
    }

    handleChartSelect = (e) => {
        this.setState({
            selected_months: [e]
        })
        let id = _.get(this.state.data.filter(f => moment(f['service_month']).format('YY-MMM') === e)[0], 'id')
        this.setState({
            selected_id: id
        })
    }

    setFilters = (e) => {
        this.setState({
            filtered_startModifiedDate: e.startModifiedDate ? moment(e.startModifiedDate).format('YYYY-MM-DD') : [],
            filtered_endModifiedDate: e.endModifiedDate ? moment(e.endModifiedDate).format('YYYY-MM-DD') : [],
        }, function () {
            this.refresh(false)
        })
    }

    renderFilter = () => {
        return <GasFilters
            handleFilter={this.setFilters}
        />
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };
    gettableRef = (tableRef) => {
        this.setState({
            tableRef
        })
    }
    render() {
        return (
            <>

                <Suspense fallback={<Spinner/>}>
                    <Suspense fallback={<Spinner/>}>
                        <Collapse defaultActiveKey={['col1', 'col0']} ghost>
                            <Panel key={'col0'} header={<Divider/>} className={'mt-4'}>
                                <Row className={'w-full px-5'}>
                                    <Col xs={24} md={12} xl={8} className={' '}>
                                        <div className={'mr-4'}>
                                            <Histgram
                                                area={this.props.Area}
                                                data={this.state.data}
                                                selected={this.state.selected_months}
                                                container='NormalizedGas'
                                                title='Normalized Gas Use Vs. Weather'
                                                handleChartSelect={this.handleChartSelect}
                                                leftTitle={'BTU/hr-sqft'}
                                                leftVar={'ScfDaySqft'}
                                                height={232}
                                                pointSize={4}
                                                color={'brown'}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12} xl={8} className={' p-6 mb-4'}>
                                        <div className={'mr-4'}>
                                            <UtilityDualAxes
                                                height={200}
                                                data={this.state.data}
                                                selected={this.state.selected_months}
                                                x={'service_month'}
                                                y={['usage', 'costs']}
                                                container='Gas Costs'
                                                color='brown'
                                                title='Monthly Gas Consumption and Costs'
                                                handleChartSelect={this.handleChartSelect}
                                                leftTitle='MMBtu'
                                                rightTitle='MMBtu'
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12} xl={8} className={' p-6 mb-4'}>
                                        <LineChart
                                            height={232}
                                            data={this.state.data}
                                            selected={this.state.selected_months}
                                            x={'service_month'}
                                            y={['costs', 'usage']}
                                            container='Gas Prices'
                                            title='Monthly Gas Prices'
                                            handleChartSelect={this.handleChartSelect}
                                            leftTitle=''
                                        />
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Suspense>
                    <EditableTable
                        key={'Gas'}
                        ref={this.tableRef}
                        columns={GasColumns}
                        data={this.state.data}
                        columnProps={GasProps}
                        formFields={GasFormFields(this.state.tableRef)}
                        formRelated={GasRelated}
                        getRecord={this.getGas}
                        createRecords={this.handleCreateGas}
                        deleteRecord={this.handleDeleteGas}
                        bulkInsert={this.handleBulkInsert}
                        bulkDelete={this.handleBulkDelete}
                        handOnlineSave={this.handOnlineSave}
                        handleFilter={this.handleFilter}
                        setSelectedRows={(rows) => this.setState({ Selected_Rows: rows })}
                        // postComment={this.handlePostComment}
                        renderFilter={this.renderFilter}

                        relatedList={this.props.relatedList}
                        filter_dates={this.state.filter_dates}
                        setFilterDates={this.setFilterDates}
                        isRowClickable={false}
                        hide_search={true}
                        selected_row={this.state.selected_id}
                        textAlign={'table-text-right'}

                        isChat={false}
                        isRelated={false}
                        isAttachments={false}
                        getFormRef={this.gettableRef}
                    />
                </Suspense>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Gas: _.get(state.Gas, 'data'),
        Area: _.get(state.Facility.selected, 'record.area'),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setGasRequest: (e) => dispatch(actions.GasRequest(e)),
        setGasSuccess: (e) => dispatch(actions.GasSuccess(e)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GasPage);
