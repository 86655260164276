import React from "react"
import { isImage, isDoc, isMedia, isSupportIframe, isAudio } from "./utils"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { Image } from "antd"
const getViewElement = (url) => {
  if(!url) {
    return <div>Loading...</div>
  }
  if (isImage(url)) {
    return <Image rootClassName="previewImage" style={{width: '100%', height: '100%'}} src={url} alt="errorimage" />
  } else if (isDoc(url)) {
    return <DocViewer pluginRenderers={DocViewerRenderers}
      style={{ width: '100%', height: '100%' }}
      prefetchMethod="GET"
      documents={[
        { uri: url, fileType: 'pptx' }
      ]} />
  } else if (isMedia(url)) {
    return <video style={{width: '100%', height: '100%'}} src={url} />
  } else if (isSupportIframe(url)) {
    return <iframe style={{width: '100%', height: '100%'}} src={url} title="preview-file" />
  } else if (isAudio(url)) {
    return <audio controls src={url}></audio>
  } else {
    return <div style={{width: '100%', height: '100%'}}>There is no preview.</div>
  }
}

export const FileViewer = ({ url = '', currentOpenFile }) => {
  return (
    <div style={{ width: '100%', height: '86vh', textAlign: 'center' }}>
       {currentOpenFile?.isFolder === false ? getViewElement(url) : 'There is no preview!'}
    </div>
  );
}
