import React, {PureComponent} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {
    StationTemperatureColumns,
    StationTemperatureProps
} from "@containers/facility/StationTemperature/StationTemperatureColumns";
import _ from "lodash";
import actions from "@redux/StationTemperature/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {StationTemperatureFormFields} from "@containers/facility/StationTemperature/StationTemperatureFormFields";
import {StationTemperatureFilters} from "@containers/facility/StationTemperature/StationTemperatureFilter";
import {removeDuplicates} from "@components/help";
import moment from "moment/moment";

class StationTemperature extends PureComponent {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            startDate: null,
            endDate: null,
            isLoading: false,
            query: null,

        }
    }

    handleCreateStationTemperature = (body) => {
        return api.createStationTemperature(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteStationTemperature = (id) => {
        return api.deleteStationTemperature(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkStationTemperatureInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteStationTemperature(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        return api.editStationTemperature(key, row).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {

        const {startDate, endDate} = this.state;

        let query_new =
            (this.state.startDate ? ('&startDate=' + (this.state.startDate)) : '') +
            (this.state.endDate ? ('&endDate=' + (this.state.endDate)) : '');


        query_new = this.state.query + '&' + query_new

        return api.filterStationTemperature(query_new).then(
            response => {
                let dataSource = response.data.results

                return {
                    data: dataSource,
                    success: true,
                }
            }
        ).catch(error => (
            error
        ));
    }

    getStationTemperature = (data) => {

        this.props.setStationTemperatureSuccess(data)
    }

    setFilters = (e) => {
        this.setState({
            startDate: e.startDate?moment(e.startDate).format('YYYY-MM-DD') : null ,
            endDate: e.endDate?moment(e.endDate).format('YYYY-MM-DD') : null ,
        }, function () {
            this.refresh(false)
        })
    }

    handleClearButtonClick = (e) => {
        this.setState({
            startDate: null,
            endDate: null,
            query: null,
        }, function () {
            this.refresh(false)
        })
    }

    renderFilter = () => {
        return <StationTemperatureFilters
            handleRefresh = {() =>this.refresh(false)}
            handleFilter={this.setFilters}
            handleClearButtonClick={this.handleClearButtonClick}
        />
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    render() {
        return (

            <div className={'p-5'}>
                <EditableTable
                    ref={this.tableRef}
                    columns={StationTemperatureColumns}
                    data={this.state.data}
                    columnProps={StationTemperatureProps}
                    formFields={StationTemperatureFormFields}
                    getRecord={this.getStationTemperature}
                    createRecords={this.handleCreateStationTemperature}
                    deleteRecord={this.handleDeleteStationTemperature}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    renderFilter={this.renderFilter}

                    hide_search={true}
                    isRowClickable={false}
                    isRelated={true}
                    isAttachments={true}

                    setFilters={this.setFilters}

                    filterDescription={this.state.filterDescription}

                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        StationTemperature: _.get(state.StationTemperature, 'data'),
    }
}
const mapDispatchToProps = dispatch => {
    return {

        setStationTemperatureRequest: (e) => dispatch(actions.StationTemperatureRequest(e)),
        setStationTemperatureSuccess: (e) => dispatch(actions.StationTemperatureSuccess(e)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StationTemperature);
