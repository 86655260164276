import React, {Component, useEffect, useRef, useState} from 'react';
import {DataView} from "@antv/data-set";
import {DualAxes} from "@antv/g2plot";
import _ from "lodash";
import LayoutContent from "@components/utility/layoutContent";
import moment from "moment";
import {CompactFormat, CurrencyFormat, NoDecimalFormat} from "@components/Format";


const UtilityDualAxes = (props) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [total_cost, setTotalCost] = useState(0);
    const [total_elec, setTotalElec] = useState(0);

    useEffect(() => {
        draw(props);
    }, [props.data, props.selected]);

    const draw = (props) => {
        const chartDom = chartRef.current;
        const dv = new DataView().source(_.get(props, 'data', []));
        dv.transform({
            type: 'sort-by',
            fields: [props.x], // 根据指定的字段集进行排序，与lodash的sortBy行为一致
            order: 'ASC', // 默认为 ASC，DESC 则为逆序
        });

        const data = dv.rows.map(d => ({
            'Id': d['id'],
            date: moment(d[props.x]).format('YY-MMM'),
            usage: d[props.y[0]] * 1,
            costs: d[props.y[1]] * 1
        }));

        setTotalCost(_.sumBy(data, 'costs'));
        setTotalElec(_.sumBy(data, 'usage'));
        if(chartInstance.current) {
            chartInstance.current.destroy();
        }

        const dualAxes = new DualAxes(chartDom, {
            height: props.height,
            data: [data, data],
            xField: ['date'],
            yField: ['usage', 'costs'],
        });

        dualAxes.render();
        dualAxes.on('element:click', (e) => {
            let data = _.get(e, 'data.data');
            onSelect(data)
        });


        dualAxes.update({
            yAxis: {
                costs: {
                    label: {
                        formatter: (v) => {
                            return '$' + CompactFormat(v);
                        },
                    },
                },
                usage: {
                    title: {text: props.rightTitle},
                    label: {
                        formatter: (v) => {
                            return CompactFormat(v);
                        },
                    },
                },
            },
            geometryOptions: [
                {
                    geometry: 'line',
                    smooth: false,
                    color: props.color,
                    point: {
                        size: props.pointSize ? props.pointSize : 2,
                        style: (val) => {
                            let isSelected = _.size(props.selected) > 0 ? props.selected.includes(val.date) : false

                            if (isSelected) {
                                return {
                                    fill: 'Orange',
                                    stroke: 'Orange',
                                    lineWidth: 1,
                                }
                            } else {
                                return {
                                    fill: props.color,
                                    stroke: props.color,
                                    lineWidth: 1,
                                }
                            }
                        }

                    },
                },
                {
                    color: 'green',
                    point: {
                        size: props.pointSize ? props.pointSize : 2,
                        style: (val) => {
                            let isSelected = _.size(props.selected) > 0 ? props.selected.includes(val.date) : false

                            if (isSelected) {
                                return {
                                    fill: 'Orange',
                                    stroke: 'Orange',
                                    lineWidth: 1,
                                }
                            } else {
                                return {
                                    fill: 'green',
                                    stroke: 'green',
                                    lineWidth: 1,
                                }
                            }
                        }

                    },
                }
            ]


        })
        chartInstance.current = dualAxes;
    }

    const onSelect = (e) => {
        let Id = _.get(e, 'date')
        props.handleChartSelect(Id)
    }

    return (
        <LayoutContent className={'outerCardWrapper'}>
            <div className={'flex place-content-between'}>
                <div className={'small-title pdfChart_Title'}>{props.title}</div>
                <div style={{minWidth:'14rem'}} className={'word-wrap text-right'}>
                    <div className={'small-title pdfChart_SubTitle1'}>{"Cost: " + CurrencyFormat(total_cost)}</div>
                    <div className={'small-title pdfChart_SubTitle2'}>{" Usage: " + NoDecimalFormat(total_elec)}</div>
                </div>
            </div>
            <div ref={chartRef}/>
        </LayoutContent>
    );
}

export default UtilityDualAxes;
