import React, {Component} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {FileTagManageColumns, fileTagManageProps} from "@containers/management/FileTagManage/FileTagManageColumns";
import {FileTagManageFormFields} from "@containers/management/FileTagManage/FileTagManageFields";


class FileTagManage extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            name: '',
            isLoading: false,
            query: null,
            tableRef: null
        }
    }

    handleCreateFunctionalForm = (body) => {
        return api.createTags(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteFunctionalForm = (id) => {
        return api.deleteTags(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkDelete = (body) => {
        return api.bulkTags(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        return api.editTags(key, row).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {

        this.setState({
            isLoading: true,
        });

        return api.filterTags(query).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getFunctionalForm = (data) => {

        this.setState({
            isLoading: false,
            data
        })

    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    renderSearches = () => {
    }
    gettableRef = (tableRef) => {
        console.log('tableRef', tableRef)
        this.setState({
            tableRef
        })
    }

    render() {
        return (

            <div>
                <EditableTable
                    ref={this.tableRef}
                    columns={FileTagManageColumns}
                    data={this.state.data}
                    columnProps={fileTagManageProps}
                    formFields={FileTagManageFormFields(this.state.tableRef)}
                    getRecord={this.getFunctionalForm}
                    createRecords={this.handleCreateFunctionalForm}
                    deleteRecord={this.handleDeleteFunctionalForm}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    postComment={this.handlePostComment}
                    renderFilter={this.renderFilter}
                    hide_search={true}
                    isRowClickable={false}
                    isRelated={false}
                    isAttachments={false}
                    setFilters={this.setFilters}
                    filterDescription={this.state.filterDescription}
                    getFormRef={this.gettableRef}
                    hide_imports={true}
                    formClassName={'flex-form  '}
                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileTagManage);
