import React, {Component, PureComponent} from 'react';

import _ from "lodash";
import UtilityCard from "@containers/UtilityData/Summary/UtilityCard.js";
import {connect} from "react-redux";
import {Col, Radio, Row, Spin} from "antd";
import EuiGuage from "@containers/UtilityData/Summary/Charts/EUIGuage";
import {api} from "@redux/api";
import PrintPDF from "@components/PrintPDF";

import {NoDecimalFormat, TwoDecimalFormat} from "@components/Format";
import EUIPie from "@containers/UtilityData/Summary/Charts/EUIPie";

import {WidgetWrapper} from "@components/WidgetBox/Widgets.styles";
import TotalCostBreakdownChart from "@containers/UtilityData/Summary/Charts/TotalCostBreakdownChart";
import Histogram from "@containers/UtilityData/Summary/Charts/Histogram";


class SummaryPage extends PureComponent {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            electricity: [],
            gas: [],
            total_cost_breakdown: [],
            total_cost: 0,
            total_area_cost: 0,
            total_cost_months: [],
            selected_period: 12,
            annual_eui: 0,
            average_cost: 0,
            last_month_eui: 0,
            total_usage: 0,
            total_avg_cost: [],
            total_eui: 0,
            eui_pie_data: [],
            isLoading: false,
        }
    }

    componentDidMount = () => {
        this.fetchSummaryData(this.state.selected_period)
    }

    // componentWillUpdate(nextProps, nextState) {
    //     if (nextProps.selected_period !== this.props.selected_period){
    //         this.fetchSummaryData(nextProps.selected_period)
    //     }
    // }

    fetchSummaryData = (period) => {

        return Promise.all([
            api.get_electricity_summary(period),
            api.get_gas_summary(period)
        ]).then(
            response => {

                let electricity = _.get(response[0], 'data');
                let gas = _.get(response[1], 'data');
                let total_cost_breakdown = [];
                let total_cost = 0;
                let total_area_cost = 0;
                let total_cost_months = [];
                let total_eui = 0;
                let total_cost_months_sqrt = [];
                let eui_pie_data = [];

                for (let i = 0; i < electricity.electricity.length; i++) {

                    total_cost_months.push({
                        month: electricity.electricity[i].month,
                        value: electricity.electricity[i].costs + gas.gas[i].costs
                    });

                    total_cost += (electricity.electricity[i].costs + gas.gas[i].costs);

                    total_cost_breakdown.push({
                        month: electricity.electricity[i].month,
                        type: 'Electricity',
                        cost: electricity.electricity[i].costs,
                        area_cost: electricity.electricity[i].area_cost,
                        eui: TwoDecimalFormat(electricity.electricity[i].eui) * 1,
                        usage: electricity.electricity[i].usage,
                        area: electricity.electricity[i].area,
                        last_month_eui: electricity.electricity[i].last_month_eui,
                        average_cost: TwoDecimalFormat(electricity.electricity[i].costs / (electricity.electricity[i].usage === 0 ? 1 : electricity.electricity[i].usage)) * 1,
                    })

                }
                for (let i = 0; i < gas.gas.length; i++) {
                    total_cost_breakdown.push({
                        month: gas.gas[i].month,
                        type: 'gas',
                        cost: gas.gas[i].costs,
                        eui: TwoDecimalFormat(gas.gas[i].eui) * 1,
                        area_cost: gas.gas[i].area_cost,
                        usage: gas.gas[i].usage,
                        area: gas.gas[i].area,
                        last_month_eui: gas.gas[i].last_month_eui,
                        average_cost: TwoDecimalFormat(gas.gas[i].costs / (gas.gas[i].usage === 0 ? 1 : gas.gas[i].usage)) * 1
                    })
                }
                total_area_cost = gas.total_area_cost + electricity.total_area_cost;
                total_eui = gas.annual_eui + electricity.annual_eui;
                eui_pie_data.push({
                    type: 'Electricity',
                    value: electricity.annual_eui,
                })
                eui_pie_data.push({
                    type: 'Gas',
                    value: gas.annual_eui,
                })
                return {
                    electricity,
                    gas,
                    total_cost_breakdown,
                    total_cost_months,
                    total_area_cost,
                    total_cost,
                    total_eui,
                    eui_pie_data
                }
            }
        ).then((data) => {

            this.setState({
                electricity: data.electricity,
                gas: data.gas,
                total_cost_breakdown: data.total_cost_breakdown,
                total_cost_months: data.total_cost_months,
                total_cost: data.total_cost,
                total_area_cost: data.total_area_cost,
                total_eui: data.total_eui,
                eui_pie_data: data.eui_pie_data,
                isLoading: false
            })
        }).catch(error => (
            console.log('error', error)
        ));
    }

    onPrint = () => {
        this.child.current.composeManagerPDF('Report', 0);
    }

    renderTotalCostChart = (value, data) => {
        let color = 'green', title = 'Total Cost', unit = '$', label = 'Total Cost Consumption:';
        return (
            <WidgetWrapper>

                <div className={" p-6 mb-4 bg-white shadow rounded corner-flower-"}>
                    <div className="flex items-center justify-between">
                        <h3 className={'small-title overlay-top'}>{title + ' (' + unit + ')'}</h3>

                    </div>
                    <div className="flex items-center mb-3">
                        <span className="text-2xl font-bold overlay-top">{unit + ' ' + NoDecimalFormat(value)}</span>


                    </div>
                    <Histogram height={200} data={data} unit={unit} color={color} value={'value'} type={'month'}/>


                </div>

            </WidgetWrapper>
        )
    }

    renderTotalCostsSqtChart = (value, data) => {
        let color = 'green', title = 'Total Cost Per Sqft', unit = '$/sqft', label = 'Total Cost Per Sqft';
        return (
            <WidgetWrapper>

                <div className={" p-6 mb-4 bg-white shadow rounded corner-flower-"}>
                    <div className="flex items-center justify-between">
                        <h3 className={'small-title overlay-top'}>{title + ' (' + unit + ')'}</h3>

                    </div>
                    <div className="flex items-center mb-3">
                        <span
                            className="text-2xl font-bold overlay-top">{'$ ' + TwoDecimalFormat(value) + '/sqft'}</span>


                    </div>
                    <TotalCostBreakdownChart
                        type={'month'}
                        height={150}
                        seriesField={'type'}
                        value={'area_cost'}
                        data={data}
                    />


                </div>

            </WidgetWrapper>
        )
    }

    renderTotalCostBreakdownChart = (value, data) => {
        let color = 'orange', title = 'Total Cost Breakdown', unit = '$', label = 'Total Cost Breakdown';
        return (
            <WidgetWrapper>

                <div className={" p-6 mb-4 bg-white shadow rounded corner-flower-"}>
                    <div className="flex items-center justify-between">
                        <h3 className={'small-title overlay-top'}>{title + ' (' + unit + ')'}</h3>

                    </div>
                    <div className="flex items-center mb-3">
                        <span className="text-2xl font-bold overlay-top">{unit + ' ' + NoDecimalFormat(value)}</span>


                    </div>
                    <TotalCostBreakdownChart
                        type={'month'}
                        height={150}
                        seriesField={'type'}
                        value={'cost'}
                        data={data}
                    />

                </div>

            </WidgetWrapper>
        )
    }

    renderLastMonthEUI = (value, data) => {
        let color = 'orange', title = 'EUI Last 12 Months Breakdown (kBtu/sqft-yr)', unit = '',
            label = 'Total Cost Breakdown';
        return (
            <WidgetWrapper>

                <div className={" p-6 bg-white shadow rounded corner-flower-"}>
                    <div className="flex items-center justify-between">
                        <h3 className={'small-title overlay-top'}>{title}</h3>

                    </div>
                    <div className="flex items-center mb-3">
                        <span className="text-2xl font-bold overlay-top">{unit + ' ' + NoDecimalFormat(value)}</span>


                    </div>
                    <TotalCostBreakdownChart
                        type={'month'}
                        seriesField={'type'}
                        value={'eui'}
                        data={data}
                        height={150}
                    />

                </div>

            </WidgetWrapper>
        )
    }

    renderEUILastMonth = (total_eui) => {
        let color = 'orange', title = 'EUI Last 12 Month (kBtu/sqft-yr)', unit = '$', label = 'Total Cost Breakdown';
        return (
            <WidgetWrapper>

                <div className={" p-6 bg-white shadow rounded corner-flower-"}>
                    <div className="flex items-center justify-between">
                        <h3 className={'small-title overlay-top'}>{title}</h3>

                    </div>
                    <div className="flex items-center mb-3">
                        <span
                            className="text-2xl font-bold overlay-top">{NoDecimalFormat(total_eui)}</span>


                    </div>
                    <EuiGuage
                        height={140}
                        gauge={total_eui}
                    />

                </div>

            </WidgetWrapper>
        )
    }

    renderLastMonthBreakdown = (eui_pie_data) => {
        let color = 'orange', title = 'EUI Rolling 12 Months Breakdown (kBtu/sqft-yr)', unit = '$',
            label = 'Total Cost Breakdown';
        return (
            <WidgetWrapper>

                <div className={" p-6 bg-white shadow rounded corner-flower-"}>
                    <div className="flex items-center justify-between">
                        <h3 className={'small-title overlay-top'}>{title}</h3>

                    </div>

                    <EUIPie
                        height={182}
                        data={eui_pie_data}
                    />

                </div>

            </WidgetWrapper>
        )
    }

    onChange = (e) => {
        // console.log('radio', e.target.value)
        let interval;
        if (e.target.value) {
            interval = e.target.value
        } else {
            interval = e
        }
        this.setState({
            selected_period: interval
        }, function () {
            this.fetchSummaryData(interval)
        })
        // this.props.SelectedPeriodSuccess(interval)


    }

    render() {

        return (
            <>
                <PrintPDF index={0} ref={this.child}/>
                <Spin spinning={this.state.isLoading}>
                    <div id={'Report_0'}>

                        <div className={'flex mt-3 toolbar'} style={{alignItems: 'center'}}>
                            <Radio.Group onChange={this.onChange} buttonStyle="solid"
                                         defaultValue={this.state.selected_period}
                                         style={{marginLeft: 'auto', marginRight: '12rem', marginTop: '-2rem', marginBottom: 2}}>
                                <Radio.Button value="1">Last Month</Radio.Button>
                                <Radio.Button value="3">Last 3 Month</Radio.Button>
                                <Radio.Button value='6'>Last 6 Month</Radio.Button>
                                <Radio.Button value="12">Last 12 Month</Radio.Button>
                                <Radio.Button value="36">Last 36 Month</Radio.Button>
                            </Radio.Group>
                            {/* <DownloadOutlined onClick={this.onPrint} data-html2canvas-ignore/> */}
                        </div>

                        <Row>
                            <Col span={24}>
                                <Row type="flex">
                                    <UtilityCard
                                        key={0}
                                        subject="Electricity"
                                        object={'electricity'}
                                        data={this.state.electricity}
                                        unit='kWh'
                                        color='blue'
                                    />
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Row type="flex">

                                    <UtilityCard
                                        key={1}
                                        subject="Gas"
                                        object={'gas'}
                                        data={this.state.gas}
                                        unit='MMBtu'
                                        color='brown'
                                    />

                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col style={{minWidth: '240px'}} className={'w-full'}>
                                <Row type="flex" className={'mb-4'}>
                                    <Col xs={24} md={12} xl={8}>
                                        {this.renderTotalCostChart(
                                            this.state.total_cost,
                                            this.state.total_cost_months,
                                        )}
                                    </Col>

                                    <Col xs={24} md={12} xl={8}>
                                        {this.renderTotalCostsSqtChart(
                                            this.state.total_area_cost,
                                            this.state.total_cost_breakdown,
                                        )}
                                    </Col>

                                    <Col xs={24} md={12} xl={8}>
                                        {this.renderTotalCostBreakdownChart(
                                            this.state.total_cost,
                                            this.state.total_cost_breakdown
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col style={{minWidth: '240px'}} className={'w-full'}>
                                <Row type="flex" className={'mb-4'}>
                                    <Col xs={24} md={12} xl={8}>
                                        {this.renderEUILastMonth(this.state.total_eui)}

                                    </Col>

                                    <Col xs={24} md={12} xl={8}>
                                        {this.renderLastMonthBreakdown(this.state.eui_pie_data)}

                                    </Col>

                                    <Col xs={24} md={12} xl={8}>
                                        {this.renderLastMonthEUI(
                                            this.state.total_eui,
                                            this.state.total_cost_breakdown
                                        )}
                                    </Col>

                                </Row>
                            </Col>
                        </Row>

                    </div>
                </Spin>
            </>
        );
    }
}

const mapStateToProps = state => {
    // console.log('props', state)
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SummaryPage);
