const actions = {
    EquipmentComment_REQUEST: 'EquipmentComment_REQUEST',
    EquipmentComment_SUCCESS: 'EquipmentComment_SUCCESS',

    EquipmentCommentRequest: () => ({
        type: actions.EquipmentComment_REQUEST,
        payload: {},
    }),
    EquipmentCommentSuccess: (data) => ({
        type: actions.EquipmentComment_SUCCESS,
        payload: {data},
    }),
};
export default actions;
