import React, {Component} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {CustomUserColumns, CustomUserProps} from "@containers/management/UserManagement/CustomUser/CustomUserColumns";
import _ from "lodash";
import actions from "@redux/CustomUser/actions";
import {connect} from "react-redux";
import {api, RquestSignUp, update_profile} from "@redux/api";
import {CustomUserFormFields} from "@containers/management/UserManagement/CustomUser/CustomUserFormFields";
import MultiStepForm from "@components/MultiStepForm";
import {jsforceResponse} from "@config/httpService";
import {Button, Drawer, Form, Input, message, Modal, Popconfirm, Radio, Select, Upload} from "antd";
import Dragger from "antd/es/upload/Dragger";
import CompanyLookup from "@containers/Lookup/CompanyLookup";
import user_icon from "@assets/images/user.png";
import CompanyFacilityPicker from "@containers/Lookup/CompanyFacilityPicker/CompanyFacilityPicker";
import PermissionSetting from "@components/PermissionSetting/PermissionSetting";
import {removeDuplicates} from "@components/help";
import Lookup_Common from "@components/Lookup_common";
import {CustomUserFilters} from "@containers/management/UserManagement/CustomUser/CustomUserFilter";
import {RoleDict} from "@components/Permission/PermissionProvider";
import folder from "@assets/icons/folder.png";
import notification2 from "@components/Notification2";
import SearchLookup from "@components/SearchLookup";

const {Option} = Select;


class CustomUser extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            register: false,
            fileList: [],
            refresh: true,
            isLoading: false,
            formRef: null,
            newUser: null,
            openNewUserPermissions: false,
            register_default: [],
            register_default_form: [],
            isLeave: false,
            query: null,
            isChangeRole: false,
            changeRole: null,
            selectedUserPermissions: null,

            username_keyword:null,
            company_keyword:null,
        }
    }


    handleCreateCustomUser = (body) => {
        // add permission to custom user

        body['profile'] = this.props.SelectedPermission ? this.props.SelectedPermission.id : '';
        if (!body['image']) {
            delete body['image'];
        }

        body['user'] = body['user'].join(',');
        body['company'] = this.props.SelectedCompany;
        return api.createCustomUser(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteCustomUser = (id) => {
        return api.deleteCustomUser(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {

    }

    handleBulkDelete = (body) => {

    }


    handOnlineSave = (key, row) => {

        row['profile'] = this.props.SelectedUserPermission ? this.props.SelectedUserPermission.id : '';
        if (!row['image']) {
            delete row['image'];
        }

        return update_profile(key, row).then(
            response => {
                // key = user id then update user info

                return response
            }
        ).catch(error => (
            error
        ));
    }

    handleFilter = (query) => {

        this.setState({
            isLoading: true
        })

        let query_new =
            (this.state.username ? ('&username=' + (this.state.username)) : '') +
            (this.state.company ? ('&company=' + (this.state.company)) : '')
        ;

        query_new = query+'&'+ this.state.query + '&' + query_new
        console.log('query_new', query_new)
        return api.filterCustomUsers(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getCustomUser = (data) => {

        this.setState({
            isLoading: false,
            data
        })
    }


    handleClearButtonClick = (e) => {
        this.setState({
            filtered_company: null,
            filtered_role: null,
            query: null,
        }, function () {
            this.refresh(false)
        })
    }


    renderFilter = () => {
        return (
            <div className={'flex flex-wrap items-center'}>
                <SearchLookup
                    placeholder={'UserName'}
                    name={'username'}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'username')}
                    Lookup_CommonupRequest={(e) => this.handleUserNameSearch(e, 'username')}
                />
                <SearchLookup
                    placeholder={'Company'}
                    name={'name'}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'company')}
                    Lookup_CommonupRequest={(e) => this.handleCompanySearch(e, 'company')}
                />
            </div>
        )
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    handleSearchSelect = (e, type) => {
        this.setState({
            [type]: e,
            limit: null
        }, function () {
            this.refresh(false);
        })
    }

    handleUserNameSearch = (e) => {
        return this.handleUserNameLookupFilter(e);
    }

    handleUserNameLookupFilter = (e) => {
        let query_new = 'username=' + e+ '&limit=15';

        return api
            .filterCustomUsers(query_new)
            .then((response) => {
                let dataSource = response.data.results;
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count,
                };
            })
            .catch((error) => {
                return error;
            });
    }

    handleCompanySearch = (e) => {
        return this.handleCompanyLookupFilter(e);
    }
    handleCompanyLookupFilter = (e) => {
        let query_new ='name=' + e+ '&limit=15';

        return api
            .filterCompany(query_new)
            .then((response) => {
                let dataSource = response.data.results;
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count,
                };
            })
            .catch((error) => {
                return error;
            });
    }


    getFormRef = (formRef) => {
        this.setState({
            formRef
        })
    }

    handleRegister = (e, data) => {

        if (data) {

            let keys = Object.keys(data);
            let values = Object.values(data);
            let register_data = keys.map((e, index) => {
                let temp = {
                    name: keys[index],
                    value: values[index]
                }
                return temp;
            })

            this.setState({
                register_default_form: data,
                register_default: register_data,
                register: true
            })
        } else {
            this.setState({
                register: true
            })
        }
    }

    handleNewUser = async (e) => {
        var result = [];
        // e['profile'] = this.props.SelectedPermission?.id;

        result = await RquestSignUp(e);
        let result1 = jsforceResponse(result, 'Form Submitted Successfully', true, true);
        if (_.get(result1, 'status') >= 200 && _.get(result1, 'status') < 300) {

            this.setState({
                openNewUserPermissions: true,
                newUser: _.get(result, 'data')
            })
        }
        console.log('newUser', _.get(result, 'data'))
    }

    handleNewUserPermission = async () => {
        const {newUser} = this.state;
        const profileId = this.state.selectedUserPermissions?.id ?? '';

        delete newUser['image'];
        try {
            let result = await update_profile(newUser.id, {...newUser, profile: profileId});
            result = jsforceResponse(result, 'Form Submitted Successfully', true, true);
            this.setState({
                register: false,
                refresh: false,
                openNewUserPermissions: false,
            }, function () {
                this.setState({
                    refresh: true
                })
            })
        } catch (error) {
            console.error(error);
        }
    }

    handleUpload = (info) => {
        this.setState({
            fileList: [...info.fileList]
        });
    }
    onClose = (e) => {
        this.setState({
            isLeave: true,
            // register: false
        })
    }

    onConfirm = (e) => {
        this.setState({
            register: false,
            isLeave: false,
        })
    }

    onCancel = (e) => {
        this.setState({
            isLeave: false,
            // register: false
        })
    }

    UserRegiesterStep0 = (formRef) => {
        let company_name = null;
        if (this.state.register_default_form['company']) {
            company_name = this.props.Companies.find(company => company.id === this.state.register_default_form['company'])
            company_name = company_name.name;
        }

        return <>
            <Form.Item name="role"
                       className={'w-full'}
                       label={'Role'}
                       rules={[
                           {
                               required: true,
                               message: 'Please input role!',
                           },
                       ]}>

                <Radio.Group>
                    {this.props.RoleKey === '1.0' && <Radio value={1}>Super User</Radio>}
                    {(this.props.RoleKey === '1.0') && <Radio value={2.1}>Account Manager</Radio>}
                    {(this.props.RoleKey === '1.0' || this.props.RoleKey === '2.1' || this.props.RoleKey === '2.2') &&
                        <Radio value={2.2}>Portfolio Manager</Radio>}
                    {(this.props.RoleKey === '1.0' || this.props.RoleKey === '2.1' || this.props.RoleKey === '3.0') &&
                        <Radio value={3}>Company Admin</Radio>}
                    {(this.props.RoleKey === '1.0' || this.props.RoleKey === '2.1' || this.props.RoleKey === '3.0' || this.props.RoleKey === '4.0') &&
                        <Radio value={4}>Standard User</Radio>}
                </Radio.Group>

            </Form.Item>

            <Form.Item name="company"
                       label={'Company'}
                       className={'md:w-6/12 sm:w-full'}
                       rules={[
                           {
                               required: true,
                               message: 'Please input company!',
                           },
                       ]}>

                <CompanyLookup formRef={formRef.current} record={company_name}/>
            </Form.Item>

            <Form.Item name="company"
                       label={'Assign Facility'}
                       className={'w-full'}
            >
                {/*this.formRef is null at the time when CompanyFacilityPicker component is rendered, hence you are passing a null value for the formRef prop.*/}
                {formRef && <CompanyFacilityPicker formRef={formRef}
                                                   fields={this.state.register_default.length > 0 ? this.state.register_default : []}/>}

            </Form.Item>


            <Form.Item name="user"
                       label={'Facility'}
                       hidden={true}
                       className={'md:w-5/12 sm:w-full'}
                       rules={[
                           {
                               required: true,
                               message: 'Please input facility!',
                           },
                       ]}>
                <input/>
            </Form.Item>
        </>
    }
    UserRegiesterStep1 = (formRef) => {

        const props = {
            accept: "image/png, image/jpeg, image/jpg",
            multiple: false,
            maxCount: 1,
            listType: "picture",
            beforeUpload: file => {

                if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
                    message.error(`${file.name} is not a PNG,JPG,JPEG file`);
                }

                return (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') ? Upload.LIST_IGNORE : true;
            },
            onChange: (info) => this.handleUpload(info),
        };
        return <>
            <Form.Item name="username"
                       label={'User Name'}
                       className={'md:w-5/12 sm:w-full'}

            >
                <Input
                    size="large"
                    placeholder="Username"
                    autoComplete="true"
                />
            </Form.Item>

            <Form.Item name="password"
                       label={'Password'}
                       className={'md:w-5/12 sm:w-full'}
                       rules={[
                           {
                               required: true,
                               message: 'Please input your password!',
                           },
                       ]}>
                <Input.Password
                    size="large"
                    type="password"
                    placeholder="Password"
                    autoComplete="false"
                />
            </Form.Item>

            <Form.Item name="email"
                       className={'md:w-5/12 sm:w-full'}
                       label={'Email'}
                       rules={[
                           {
                               required: true,
                               message: 'Please input your email!',
                           },
                       ]}>
                <Input
                    size="large"
                    autoComplete="false"
                />
            </Form.Item>

            <Form.Item name="first_name"
                       className={'md:w-5/12 sm:w-full'}
                       label={'First Name'}
                       rules={[
                           {
                               required: true,
                               message: 'Please input your first name!',
                           },
                       ]}>
                <Input
                    size="large"
                    autoComplete="false"
                />
            </Form.Item>

            <Form.Item name="middle_name" label={'Middle Name'}
                       className={'md:w-5/12 sm:w-full'}>
                <Input
                    size="large"
                    autoComplete="false"
                    placeholder="middle name"
                />
            </Form.Item>

            <Form.Item name="last_name"
                       label={'Last Name'}
                       className={'md:w-5/12 sm:w-full'}
                       rules={[
                           {
                               required: true,
                               message: 'Please input your last name!',
                           },
                       ]}>
                <Input
                    size="large"
                    autoComplete="false"
                    placeholder="last name"
                />
            </Form.Item>

            <Form.Item name="image" label={'User Icon'}
                       className={'w-full'}
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please input company!',
                //     },
                // ]}
                       initialValues={user_icon}
                       getValueFromEvent={({file}) => file.originFileObj}>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon flex justify-center">
                        <img src={folder} alt="folder" width={'80px'}/>
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to
                        upload</p>
                    <p className="ant-upload-hint">
                        Support for a PNG,JPG,JPEG file up to 5MB.
                    </p>
                </Dragger>
            </Form.Item>
        </>
    }

    setSelectedUserPermissions = (e) => {
        this.setState({
            selectedUserPermissions: e
        })
    }

    UserRegiesterStep3 = (formRef) => {
        console.log('this.state.newUser?.id', this.state.newUser?.id)
        return <>
            <PermissionSetting
                selecteduser={this.state.newUser?.id}
                setSelectedUserPermissions={this.setSelectedUserPermissions}
            />
        </>
    }

    handleRoleChange = (e) => {

        const val = e.target.value * 1;

        this.setState({
            isChangeRole: true,
            changeRole: val
        })

    }

    handleChangeRoleConfirm = (e) => {
        let selectedId = this.state.formRef.getFieldValue('id');
        let oldDate = this.state.data.map(item => {
            if (item.id === selectedId) {
                item.role = this.state.changeRole;
            }
            return item
        })
        this.setState({
            isChangeRole: false,
            data: oldDate
        }, () => {
            this.setState({})
        })
    }

    handleChangeRoleCancel = (e) => {
        this.setState({
            isChangeRole: false
        })
    }

    isCurrentStepValid = (step, formRef) => {
        console.log('test')
        let isError = false;
        switch (step) {
            case 0:
                break;
            case 1:
                if (formRef.current.getFieldValue('username') === '' ||
                    !formRef.current.getFieldValue('username')
                ) {
                    isError = true;
                    notification2['error']({
                        message: 'missing username',
                        description: '',
                    });
                }
                if (
                    formRef.current.getFieldValue('password') === '' ||
                    !formRef.current.getFieldValue('password')) {
                    isError = true;
                    notification2['error']({
                        message: 'missing password',
                        description: '',
                    });
                }
                if (
                    formRef.current.getFieldValue('email') === '' ||
                    !formRef.current.getFieldValue('email')
                ) {
                    isError = true;
                    notification2['error']({
                        message: 'missing email',
                        description: '',
                    });
                }
                if (
                    formRef.current.getFieldValue('first_name') === '' ||
                    !formRef.current.getFieldValue('first_name')
                ) {
                    isError = true;
                    notification2['error']({
                        message: 'missing first name',
                        description: '',
                    });
                }
                if (
                    formRef.current.getFieldValue('last_name') === '' ||
                    !formRef.current.getFieldValue('last_name')
                ) {
                    isError = true;
                    notification2['error']({
                        message: 'missing last name',
                        description: '',
                    });
                }
                break;
            default:
                break;
        }
        return isError;
    };

    render() {
        const RoleKey = this.props.RoleKey; // 1.0
        const Roles = this.props.Roles;
        console.log('Role', Roles, RoleKey)
        return (

            <div >

                <Button
                    type="primary"
                    icon={<i className="fas fa-user-plus pr-2"></i>}
                    size="medium"
                    onClick={this.handleRegister}
                    style={{width: 120}}
                    className={'float-adduser-button'}
                    disabled={RoleKey === '2.2' || RoleKey === '4.0'}
                > Add a User </Button>

                {this.state.refresh ? <EditableTable
                    ref={this.tableRef}
                    columns={CustomUserColumns()}
                    data={this.state.data}
                    columnProps={CustomUserProps}
                    formFields={CustomUserFormFields(this.state.formRef, this.handleRoleChange, this.props.UserId, RoleKey, Roles)}
                    formRelated={false}
                    getRecord={this.getCustomUser}
                    createRecords={this.handleCreateCustomUser}
                    deleteRecord={this.handleDeleteCustomUser}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    relatedList={this.props.relatedList}
                    hide_date={true}
                    hide_imports={true}
                    userPermission={true}
                    isRowClickable={false}
                    hide_search={true}
                    isResetPassword={'true'}
                    hid_new={true}
                    role={this.props.RoleKey}
                    getFormRef={this.getFormRef}
                    handleRegister={this.handleRegister}

                    renderFilter={this.renderFilter}
                    setFilters={this.setFilters}
                    filterDescription={this.state.filterDescription}

                    renderSearches={this.renderSearches}
                /> : ''}

                {this.state.register ?

                    <Drawer
                        width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
                        placement="right"
                        closable={true}
                        onClose={this.onClose}
                        visible={this.state.register}
                    >
                        <Popconfirm
                            title={'Are you sure you want to leave?'}
                            description={'Are you sure you want to leave?'}
                            onConfirm={this.onConfirm}
                            okText="Yes"
                            cancelText="No"
                            visible={this.state.isLeave}
                            onCancel={this.onCancel}
                        >
                        </Popconfirm>
                        <div className="SignInForm">
                            <div className="InputWrapper">

                                {!this.state.openNewUserPermissions ? <MultiStepForm
                                        steps={[
                                            {title: 'Company', content: (formRef) => this.UserRegiesterStep0(formRef)},
                                            {title: 'User Details', content: (formRef) => this.UserRegiesterStep1(formRef)},
                                        ]}
                                        defaultValue={{
                                            user_type: 3,
                                            ...this.state.register_default_form
                                        }}
                                        handleSubmit={this.handleNewUser}
                                        isCurrentStepValid={this.isCurrentStepValid}
                                    /> :
                                    <>
                                        {this.UserRegiesterStep3()}
                                        <Button type="primary" onClick={this.handleNewUserPermission}>
                                            Submit
                                        </Button>
                                    </>

                                }

                            </div>

                        </div>

                    </Drawer> : ''}

                <Modal title="Warning!"
                       zIndex={'10000'}
                       visible={this.state.isChangeRole}
                       onOk={this.handleChangeRoleConfirm}
                       onCancel={this.handleChangeRoleCancel}>
                    <p>Are you sure you want to change role?</p>
                </Modal>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        CustomUser: _.get(state.CustomUser, 'data'),
        UserId: _.get(state.Auth, 'userId'),
        Facilities: _.get(state.Facility, 'data'),
        Companies: _.get(state.Company, 'data'),
        SelectedFacility: _.get(state.Facility, 'admin_facility'),
        SelectedCompany: _.get(state.Company, 'selectedCompany.Id'),
        SelectedPermission: _.get(state.Auth, 'permissions'),
        RoleKey: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] : null,
        Roles: _.get(state, 'Auth.role') ? _.get(state, 'Auth.role') : null,
        SelectedUserPermission: state.Auth.selected_userpermissions,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        // OpenDrawer: (e) => dispatch(tableactions.OpenDrawer(e)),
        setCustomUserRequest: (e) => dispatch(actions.CustomUserRequest(e)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomUser);
