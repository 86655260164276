import React, { Component } from 'react';
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import { CaretDownOutlined } from "@ant-design/icons";
import { api } from "@redux/api";
import { jsforceResponse } from "@config/httpService";
import _ from "lodash";

class CompanyLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            value: null,
        };
        this.debouncedSearch = _.debounce(this.performSearch, 300);
        this.throttledSetState = _.throttle(this.setState.bind(this), 1000);
    }
    async componentDidMount() {
        this.setState({ value: this.props.record });
    }
    fetchCompanies = async (query = '') => {
        try {
            const result = await api.filterCompany('name='+query);
            const res = jsforceResponse(result, '', false, true);
            const company_items = _.get(res, 'data.results', []);

            const options = company_items.map(item => ({
                id: item.id,
                label: item.name,
                value: item.name,
                record: { id: item.id, value: item.name },
            }));

            this.throttledSetState({ options });
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    }

    handleFocus = () => {
        this.fetchCompanies();
    }

    handleSearch = (searchTerm) => {
        this.setState({ value: searchTerm });
        this.debouncedSearch(searchTerm);
    }

    performSearch = async (searchTerm) => {
        await this.fetchCompanies(searchTerm);
    }

    handleSelect = (value, option) => {
        this.setState({ value: option.label });
        this.props.formRef.setFieldsValue({
            company: option.id
        });
    }

    render() {
        return (
            <AutoComplete
                style={{ width: '100%' }}
                options={this.state.options}
                onSelect={this.handleSelect}
                onSearch={this.handleSearch}
                value={this.state.value}
                dropdownMatchSelectWidth={false}
                onFocus={this.handleFocus}
            >
                <Input
                    size="medium"
                    allowClear={true}
                    autosize={true}
                    autoComplete="off"
                    placeholder={'search a company'}
                    prefix={<div style={{color: 'red'}}>*</div>}
                    suffix={<CaretDownOutlined/>}
                />
            </AutoComplete>
        );
    }
}

export default CompanyLookup;
