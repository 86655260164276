import React from 'react';
import { Dropdown, message } from 'antd';
import {
  MoreOutlined,
  FolderOpenOutlined,
  // ModalOutlined,
  FileOutlined,
  AppstoreOutlined,
  EditOutlined,
  TagOutlined,
  // CategoryOutlined,
  FileTextOutlined,
  ReloadOutlined,
  DownloadOutlined,
  DeleteOutlined,
  CopyOutlined,
  ScissorOutlined,
  GoogleOutlined,
  ChromeOutlined,
  HighlightOutlined,
  ShareAltOutlined
} from '@ant-design/icons';
import { api } from '@/redux/api';
import { canOpenInApp, getSafeUrl, openAppByUrl } from './utils';


export const DropdownMenu = ({
  record,
  currentFileRef,
  openEditModal,
  setIsDeleteOpen,
  setIsFileOpen,
  setCurrentOpenFile,
  reload,
  setCurrentTreeKeyWithClearSearch,
  deleteFile,
  openEditTagModal,
  openRenameModal,
  openMoveToModal
}) => {

  const isFolder = record.isFolder;

  const handleDownload = async (record) => {

    const { image: downloadUrl, name } = record;

    const generate_url = await api.generate_url(getSafeUrl(downloadUrl), false).then(
      response => {
        const url = response.data.url;
        return url
      })

    const link = document.createElement('a');
    link.href = generate_url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link)
  }


  const menuItems = [
    {
      key: 'group1',
      type: 'group',
      children: isFolder ? [{ key: '1', icon: <FolderOpenOutlined />, label: 'Open Folder', onClick: () => setCurrentTreeKeyWithClearSearch(record) }] :
        [
          {
            key: '2', icon: <FileOutlined />, label: 'Open', onClick() {
              setIsFileOpen(true)
              setCurrentOpenFile(record)
            }
          },
          ...(canOpenInApp(record) ? [{
            key: '3', icon: <ChromeOutlined />, label: 'Open in Browser', onClick: async () => {
              const { image: downloadUrl } = record;

              const generate_url = await api.generate_url(getSafeUrl(downloadUrl), false).then(
                response => {
                  const url = response.data.url;
                  return url
                })

              // const pdfUrl = 'https://example.com/path/to/your/document.pdf';
              const googleDocsUrl = 'https://docs.google.com/viewer?url=' + encodeURIComponent(generate_url) + '&embedded=true';
              window.open(googleDocsUrl, '_blank');
            }
          }] : []),
          ...(canOpenInApp(record) ? [{
            key: '4', icon: <AppstoreOutlined />, label: 'Open in App', onClick: async () => {
              const { image: downloadUrl } = record;

              const generate_url = await api.generate_url(getSafeUrl(downloadUrl), false).then(
                response => {
                  const url = response.data.url;
                  return url
                })
              openAppByUrl(generate_url)
            }
          }] : [])
        ],
    },
    { type: 'divider' },
    {
      key: 'group2',
      type: 'group',
      children: [
        {
          key: '5', icon: <EditOutlined />, label: 'Edit', onClick: () => {
            openEditModal(record)
          }
        },
        {
          key: '6', icon: <HighlightOutlined />, label: 'Rename', onClick() {
            openRenameModal(record)
          }
        },
        ...(isFolder ? [] : [{
          key: '7', icon: <TagOutlined />, label: 'Edit Tags', onClick() {
            openEditTagModal(record)
          }
        }])
        // { key: '7', icon: <CategoryOutlined />, label: 'Edit Category' },
        // { key: '8', icon: <FileTextOutlined />, label: 'Edit Description' },
      ],
    },
    { type: 'divider' },
    {
      key: 'group3',
      type: 'group',
      children: [
        ...(isFolder ? [] : [{
          key: '10', icon: <DownloadOutlined />, label: 'Download', onClick() {
            handleDownload(record)
          }
        }]),
        { key: '9', icon: <ReloadOutlined />, label: 'Refresh', onClick: reload },
        {
          key: '11', icon: <DeleteOutlined />, label: 'Delete', onClick() {
            deleteFile(record)
          }
        },
      ],
    },
    { type: 'divider' },
    {
      key: 'group4',
      type: 'group',
      children: [
        // { key: '12', icon: <CopyOutlined />, label: 'Copy To' },
        ...(isFolder ? [] : [{ key: '14', icon: <ShareAltOutlined />, label: 'Copy Link', onClick() {

          api.generate_url(getSafeUrl(record.image), true).then(
            response => {
              let url = response.data.url
              navigator.clipboard.writeText(url);
              message.success('Link copied to clipboard');
            }
          )


        } }]),
        { key: '13', icon: <ScissorOutlined />, label: 'Move To', onClick(){
          openMoveToModal(record)
        } },
      ],
    },
  ];

  return (
    <>
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <MoreOutlined style={{ cursor: 'pointer' }} />
      </Dropdown>
    </>
  );
};
