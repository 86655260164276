import {Checkbox, Table} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {DeleteOutlined} from "@ant-design/icons";

export const RecordListWrapper = styled.div`
  .cascadepicker-w {
  }

  .cascadepicker-layout {
    display: flex;
    align-items: center;
  }

  .ant-checkbox-inner {
    border-radius: 50%;
  }

  .parent-w {
    width: 100%;
  }

  .parent-container {

  }


  .child-h {
    overflow-x: hidden;
    overflow-y: auto;
  }




`;

const RecordList = ({lookupValue, role, onSelectionChange, parentDefault, childrenObjDefault, readOnly}) => {

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedRowItems, setSelectedRowItems] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const allRowsRef = useRef([]);


    // set default value
    useEffect(() => {
        setSelectedRowItems(parentDefault || []);
        allRowsRef.current = parentDefault || [];
        setAllRows(allRowsRef.current);
        setSelectedItems(childrenObjDefault || []);

        onSelectionChange(childrenObjDefault, parentDefault);

    }, [childrenObjDefault, onSelectionChange, parentDefault]);

    useEffect(() => {
        if (lookupValue && allRowsRef.current.findIndex((e) => e.id === lookupValue.id) === -1) {
            console.log('record', lookupValue, allRowsRef.current);
            let temp = {
                id: lookupValue.id,
                name: lookupValue.value,
                childrenObj: lookupValue.childrenObj
            };
            allRowsRef.current = [...allRowsRef.current, temp];
            setAllRows(allRowsRef.current);
        }
    }, [lookupValue]);

    const handleSelectRowItem = (item) => {

        const index = selectedRowItems.findIndex((e) => e.id === item.id);

        if (role === '3' || role === '4') {
            if (selectedRowItems.length > 0) {
                return;
            }
        }

        let subItems = []
        if (index === -1) {
            let rowItems = [...selectedRowItems, item];
            setSelectedRowItems(rowItems);
            //     if company is selected, select all facilities

            subItems = item['childrenObj'].filter(e => selectedItems.findIndex(f => f.id === e.id) === -1)

            // console.log('subItems', subItems)
            if (subItems.length > 0) {
                setSelectedItems([...selectedItems, ...subItems]);
                onSelectionChange([...selectedItems, ...subItems], rowItems);
            }
            // setSelectedItems([...selectedItems, ...subItems]);
        } else {

            let rowItem = selectedRowItems.filter((i) => i.id !== item.id);
            setSelectedRowItems(rowItem);
            //  remove all records in selectedItems that are in subItems
            subItems = selectedItems.filter(e => item['childrenObj'].findIndex(f => f.id === e.id) === -1)

            setSelectedItems(subItems);
            onSelectionChange(subItems, rowItem);
        }
    };

    const handleSelectItem = (item) => {
        console.log('handleSelectItem', role)
        if (role !== 2.1 && role !== 2.2 && role !== 3) {
            console.log('handleSelectItem', item, selectedRowItems, selectedItems)
            // need to remove selected facilties and company if company is unselected
            const index = selectedItems.findIndex((e) => e.id === item.id);
            if (index === -1) {
                // select
                // add company id;

                setSelectedItems([...selectedItems, item]);
                onSelectionChange([...selectedItems, item], selectedRowItems);
                // let rowItem = allRowsRef.current.filter(e => e['childrenObj'].filter(f => f.id === item.id).length > 0)[0]
                // console.log('rowItem', rowItem)
                // setSelectedRowItems[...selectedRowItems, ]
            } else {
                // unselect
                setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
                // remove company, if all facilities are unselected
                // find item' companyId, and see if selectedItems has any items with that companyId, if not, remove company
                let lastItem = selectedItems.filter(e => e['parentId'] === item['parentId'] && e.id !== item.id)
                if (lastItem.length === 0) {
                    let rowItems = selectedRowItems.filter(e => e['id'] !== item['parentId'])
                    setSelectedRowItems(rowItems)
                    onSelectionChange(selectedItems.filter((i) => i.id !== item.id), rowItems);
                } else {
                    onSelectionChange(selectedItems.filter((i) => i.id !== item.id), selectedRowItems);
                }
            }
        }

    };


    const handleRemoveSelectedItem = (item) => {
        if (role !== 2.2 && role !== 3 && role !== 4) {
            // remove item from allRows
            allRowsRef.current = allRowsRef.current.filter(e => e.id !== item.id)
            setAllRows(allRowsRef.current)

            // remove item from selected company
            let newSelectedRowItems = selectedRowItems.filter(e => e.id !== item.id)
            setSelectedRowItems(newSelectedRowItems)

            // remove items from facilities
            let newSelectedItems = selectedItems.filter(e => item['childrenObj'].findIndex(f => f.id === e.id) === -1)
            setSelectedItems(newSelectedItems);
            onSelectionChange(newSelectedRowItems, newSelectedItems);
        }

    }


    // table row render
    const rowRender = (row) => {
        console.log('row', row)
        return (
            <RecordListWrapper>
                <ul className={'ml-24'}>
                    {row.map((item) => (
                        <li>
                            <Checkbox
                                key={item}
                                checked={selectedItems.some((e) => e.id === item.id)}
                                onChange={() => handleSelectItem(item)}
                                style={{marginLeft: 8}}
                            >
                                {item.name}
                            </Checkbox>
                        </li>
                    ))}
                </ul>
            </RecordListWrapper>
        );
    };

    return (
        <>
            <div className="cascadepicker-layout">
                <div className="child-h parent-item flex justify-between border p-4 mt-4">
                    {readOnly?'':<Table
                        rowkey={(record) => record.id}
                        columns={[
                            {
                                title: '',
                                valueType: 'option',
                                width: '2rem',
                                importIngore: true,
                                render: (text, record, _, action) => (
                                    <div className={'space-around'}>

                                        <div>
                                            <a onClick={() => handleRemoveSelectedItem(record)}><DeleteOutlined
                                                style={{color: 'red'}}/></a>
                                        </div>

                                        <RecordListWrapper>
                                            <Checkbox
                                                key={record.id}
                                                checked={selectedRowItems.some((e) => e.id === record.id)}
                                                onChange={() => handleSelectRowItem(record)}
                                                style={{marginLeft: 8}}
                                            >
                                            </Checkbox>
                                        </RecordListWrapper>
                                    </div>
                                ),
                            },
                            {title: 'Name', dataIndex: 'name', key: 'name'}
                        ]}
                        dataSource={allRows}
                        pagination={false}
                        style={{width: '100%'}}
                        expandable={{
                            expandedRowRender: (record) => record.childrenObj && rowRender(record.childrenObj),
                        }}
                    />}

                    <div style={{marginTop: 8, width: '80%', fontSize: '12px', marginLeft: '20px'}}>
                        <div style={{marginBottom: 8}}>Selected Items:</div>
                        {selectedItems.map((item) => (
                            <div key={item.id}>
                                {readOnly?'':<Checkbox checked={true}
                                          onChange={() => handleSelectItem(item)}
                                          style={{marginRight: 8}}/>}
                                {item.name}
                            </div>
                        ))}
                    </div>
                </div>


            </div>

        </>
    );
};

export default RecordList;
