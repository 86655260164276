import React, {Component, Suspense} from 'react';

import {Spinner} from "@components/Spinner";

import {Treemap} from "@antv/g2plot";
import {DataView} from "@antv/data-set";


class TreemapChart extends Component {

    chartRef = React.createRef();

    constructor(props) {
        super(props);

    }

    componentDidMount() {
        this.draw(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.chartRef.current.innerHTML = '';
        this.draw(nextProps)
    }

    onChange = (e) => {
        let interval;
        if (e.target.value) {
            interval = e.target.value
        } else {
            interval = e
        }
        console.log('interval', this.props)
    }

    draw = (props) => {
        const chartDom = this.chartRef.current;
        const dv = new DataView().source(props.data);
        const data0 = dv.rows.map(d => {
            return {
                name: d[this.props.name],
                value: d[this.props.value]
            }
        })

        const data = {
            children: data0,
        };

        const treemapPlot = new Treemap(chartDom, {
            data,
            colorField: 'name',
            height: props.height
        });

        treemapPlot.render();
    }

    render() {
        return (
            <Suspense fallback={<Spinner/>}>


                <div className={'flex justify-between'}>
                    {/*<div className={'small-title_piechart'}>{this.props.title}</div>*/}

                    {/*<Radio.Group buttonStyle="solid" onChange={this.onChange}*/}
                    {/*             style={{ marginRight: '0.5rem'}}>*/}
                    {/*    <Radio.Button value="0.1">Last Month</Radio.Button>*/}
                    {/*    <Radio.Button value="0.25">Last 3 Month</Radio.Button>*/}
                    {/*</Radio.Group>*/}
                </div>
                <div ref={this.chartRef}/>

            </Suspense>
        );
    }
}

export default TreemapChart;
