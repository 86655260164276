import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import {Tag} from "antd";

export const ProjectProps = {
    title: 'Planning',
    name: 'project',
    description: 'Project Planning'
}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}

export const ProjectColumns = [
    {
        title: 'key',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: true,
        width: '1rem',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        search: false,
        edit: true,
        sorter: (a, b) => a.name?.localeCompare(b.name),
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },

    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        edit: true,
        sorter: (a, b) => a.status?.localeCompare(b.status),
        valueEnum: {
            New: {text: 'New'},
            Pending: {text: 'Pending',},
            Closed: {text: 'Closed',}
        },
        formItemProps: {
            initialValue: 'New'
        },
        render: (text) => {
            let val = text.props.text
            if (val === 'New') {
                return <Tag color={'orange'}>{text}</Tag>
            } else if (val === 'Pending') {
                return <Tag color={'blue'}>{text}</Tag>
            } else if (val === 'Closed') {
                return <Tag color={'green'}>{text}</Tag>
            }
        }
    },
    {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        edit: true,
        sorter: (a, b) => a.status?.localeCompare(b.status),
        formItemProps: {
            initialValue: 'New'
        },
        valueEnum: {
            New: {text: 'New'},
            Pending: {text: 'Pending',},
            Closed: {text: 'Closed',}
        },
        render: (text) => {
            let val = text.props.text
            if (val === 'New') {
                return <Tag color={'orange'}>{text}</Tag>
            } else if (val === 'Pending') {
                return <Tag color={'blue'}>{text}</Tag>
            } else if (val === 'Closed') {
                return <Tag color={'green'}>{text}</Tag>
            }
        }
    },
    {
        title: 'Baseline Start Date',
        dataIndex: 'baseline_start_date',
        key: 'baseline_start_date',
        search: false,
        edit: true
    },
    {
        title: 'Baseline End Date',
        dataIndex: 'baseline_end_date',
        key: 'baseline_end_date',
        search: false,
        edit: true
    },
    {
        title: 'Post Start Date',
        dataIndex: 'post_start_date',
        key: 'post_start_date',
        search: false,
        edit: true
    },
    {
        title: 'Post End Date',
        dataIndex: 'post_end_date',
        key: 'post_end_date',
        search: false,
        edit: true
    },
    {
        title: 'Electricity_price',
        dataIndex: 'electricity_price',
        key: 'electricity_price',
        search: false,
        edit: true
    },
    {
        title: 'Gas_price',
        dataIndex: 'gas_price',
        key: 'gas_price',
        search: false,
        edit: true
    },
    {
        title: '',
        valueType: 'option',
        width: '6rem',
        importIngore: true,
        render: (text, record, _, action) => (
            <div className={'space-around'}>
                <a
                    key="editable"
                    onClick={() => (action?.startEditable?.(record.id))}
                >
                    <i className="fas fa-edit text-lg"></i>
                </a>
                <a onClick={(e) => {
                    e.stopPropagation();
                    return action ? renderDrawer(text, record.id) : ''
                }}>
                    <i className="fas fa-expand-arrows-alt text-lg"></i>
                </a>
            </div>
        ),
    },
];
