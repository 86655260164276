
export const summaryOptions = [
  {
    label: "Last Month",
    value: 1
  },
  {
    label: "Last 3 Month",
    value: 3
  },
  {
    label: "Last 6 Month",
    value: 6
  },
  {
    label: "Last 12 Month",
    value: 12
  },
  {
    label: "Last 36 Month",
    value: 36
  },
]