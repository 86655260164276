import React, { useState, useEffect } from "react";
import _ from "lodash";
import UtilityCard from "@containers/UtilityData/Summary/UtilityCard.js";
import { Col, Row, Spin } from "antd";
import EuiGuage from "@containers/UtilityData/Summary/Charts/EUIGuage";
import { api } from "@redux/api";
import { NoDecimalFormat, TwoDecimalFormat } from "@components/Format";
import EUIPie from "@containers/UtilityData/Summary/Charts/EUIPie";

import { WidgetWrapper } from "@components/WidgetBox/Widgets.styles";
import TotalCostBreakdownChart from "@containers/UtilityData/Summary/Charts/TotalCostBreakdownChart";
import Histogram from "@containers/UtilityData/Summary/Charts/Histogram";
import { useSafeState } from "ahooks";

import { utilityCardIdMappings } from '@containers/PdfReport/PdfForm';
import { summaryOptions } from "../../PdfForm/constants";

const initState = {
  isLoading: true,
  selected_period: "1",
  data: [],
  total: 0,
  totalCost: 0,
  totalCostBreakdown: [],
  totalCostBreakdownData: [],
  totalCostBreakdownLabels: [],
  totalCostBreakdownColors: [],
  totalCostBreakdownLegend: [],
  totalCostBreakdownOptions: [],
  totalCostBreakdownOptionsPie: [],
};

export const SummaryChart = ({ recently }) => {
  const [summaryState, setSummaryState] = useSafeState(initState);

  useEffect(() => {
    fetchSummaryData(recently);
  }, [recently]);

  const fetchSummaryData = (recently) => {
    return Promise.all([
      api.get_electricity_summary(recently),
      api.get_gas_summary(recently),
    ])
      .then((response) => {
        let electricity = _.get(response[0], "data");
        let gas = _.get(response[1], "data");
        let total_cost_breakdown = [];
        let total_cost = 0;
        let total_area_cost = 0;
        let total_cost_months = [];
        let total_eui = 0;
        let eui_pie_data = [];

        for (let i = 0; i < electricity.electricity.length; i++) {
          total_cost_months.push({
            month: electricity.electricity[i].month,
            value: electricity.electricity[i].costs + gas.gas[i].costs,
          });

          total_cost += electricity.electricity[i].costs + gas.gas[i].costs;

          total_cost_breakdown.push({
            month: electricity.electricity[i].month,
            type: "Electricity",
            cost: electricity.electricity[i].costs,
            area_cost: electricity.electricity[i].area_cost,
            eui: TwoDecimalFormat(electricity.electricity[i].eui) * 1,
            usage: electricity.electricity[i].usage,
            area: electricity.electricity[i].area,
            last_month_eui: electricity.electricity[i].last_month_eui,
            average_cost:
              TwoDecimalFormat(
                electricity.electricity[i].costs /
                  (electricity.electricity[i].usage === 0
                    ? 1
                    : electricity.electricity[i].usage)
              ) * 1,
          });
        }
        for (let i = 0; i < gas.gas.length; i++) {
          total_cost_breakdown.push({
            month: gas.gas[i].month,
            type: "gas",
            cost: gas.gas[i].costs,
            eui: TwoDecimalFormat(gas.gas[i].eui) * 1,
            area_cost: gas.gas[i].area_cost,
            usage: gas.gas[i].usage,
            area: gas.gas[i].area,
            last_month_eui: gas.gas[i].last_month_eui,
            average_cost:
              TwoDecimalFormat(
                gas.gas[i].costs /
                  (gas.gas[i].usage === 0 ? 1 : gas.gas[i].usage)
              ) * 1,
          });
        }
        total_area_cost = gas.total_area_cost + electricity.total_area_cost;
        total_eui = gas.annual_eui + electricity.annual_eui;
        eui_pie_data.push({
          type: "Electricity",
          value: electricity.annual_eui,
        });
        eui_pie_data.push({
          type: "Gas",
          value: gas.annual_eui,
        });
        return {
          electricity,
          gas,
          total_cost_breakdown,
          total_cost_months,
          total_area_cost,
          total_cost,
          total_eui,
          eui_pie_data,
        };
      })
      .then((data) => {
        setSummaryState({
          electricity: data.electricity,
          gas: data.gas,
          total_cost_breakdown: data.total_cost_breakdown,
          total_cost_months: data.total_cost_months,
          total_cost: data.total_cost,
          total_area_cost: data.total_area_cost,
          total_eui: data.total_eui,
          eui_pie_data: data.eui_pie_data,
          isLoading: false,
        });
      })
      .catch((error) => console.log("error", error));
  };

  const renderTotalCostBreakdownChart = (value, data) => {
    let color = "orange",
      title = "Total Cost Breakdown",
      unit = "$",
      label = "Total Cost Breakdown";
    return (
      <WidgetWrapper>
        <div className={" p-6 mb-4 bg-white shadow rounded corner-flower-"}>
          <div className="flex items-center justify-between">
            <h3 className={"small-title overlay-top pdfChart_Title"}>
              {title + " (" + unit + ")"}
            </h3>
          </div>
          <div className="flex items-center mb-3">
            <span className="text-2xl font-bold overlay-top pdfChart_SubTitle">
              {unit + " " + NoDecimalFormat(value)}
            </span>
          </div>
          <TotalCostBreakdownChart
            type={"month"}
            height={150}
            seriesField={"type"}
            value={"cost"}
            data={data}
          />
        </div>
      </WidgetWrapper>
    );
  };

  const renderTotalCostsSqtChart = (value, data) => {
    let color = "green",
      title = "Total Cost Per Sqft",
      unit = "$/sqft",
      label = "Total Cost Per Sqft";
    return (
      <WidgetWrapper>
        <div className={" p-6 mb-4 bg-white shadow rounded corner-flower-"}>
          <div className="flex items-center justify-between">
            <h3 className={"small-title overlay-top pdfChart_Title"}>
              {title + " (" + unit + ")"}
            </h3>
          </div>
          <div className="flex items-center mb-3">
            <span className="text-2xl font-bold overlay-top pdfChart_SubTitle">
              {"$ " + TwoDecimalFormat(value) + "/sqft"}
            </span>
          </div>
          <TotalCostBreakdownChart
            type={"month"}
            height={150}
            seriesField={"type"}
            value={"area_cost"}
            data={data}
          />
        </div>
      </WidgetWrapper>
    );
  };

  const renderLastMonthEUI = (value, data) => {
    let color = "orange",
      title = "EUI Last 12 Months Breakdown (kBtu/sqft-yr)",
      unit = "",
      label = "Total Cost Breakdown";
    return (
      <WidgetWrapper>
        <div className={" p-6 bg-white shadow rounded corner-flower-"}>
          <div className="flex items-center justify-between">
            <h3 className={"small-title overlay-top pdfChart_Title"}>{title}</h3>
          </div>
          <div className="flex items-center mb-3">
            <span className="text-2xl font-bold overlay-top pdfChart_SubTitle">
              {unit + " " + NoDecimalFormat(value)}
            </span>
          </div>
          <TotalCostBreakdownChart
            type={"month"}
            seriesField={"type"}
            value={"eui"}
            data={data}
            height={150}
          />
        </div>
      </WidgetWrapper>
    );
  };

  const renderEUILastMonth = (total_eui) => {
    let color = "orange",
      title = "EUI Last 12 Month (kBtu/sqft-yr)",
      unit = "$",
      label = "Total Cost Breakdown";
    return (
      <WidgetWrapper>
        <div className={" p-6 bg-white shadow rounded corner-flower-"}>
          <div className="flex items-center justify-between">
            <h3 className={"small-title overlay-top pdfChart_Title"}>{title}</h3>
          </div>
          <div className="flex items-center mb-3">
            <span className="text-2xl font-bold overlay-top pdfChart_SubTitle">
              {NoDecimalFormat(total_eui)}
            </span>
          </div>
          <EuiGuage height={140} gauge={total_eui} />
        </div>
      </WidgetWrapper>
    );
  };

  const renderLastMonthBreakdown = (eui_pie_data) => {
    let color = "orange",
      title = "EUI Rolling 12 Months Breakdown",
      unit = "$",
      label = "Total Cost Breakdown";
    return (
      <WidgetWrapper>
        <div className={" p-6 bg-white shadow rounded corner-flower-"}>
          <div className="flex items-center justify-between">
            <h3 className={"small-title overlay-top pdfChart_Title"}>{title}</h3>
          </div>

          <EUIPie height={182} data={eui_pie_data} />
        </div>
      </WidgetWrapper>
    );
  };

  const renderTotalCostChart = (value, data) => {
    let color = "green",
      title = "Total Cost",
      unit = "$",
      label = "Total Cost Consumption:";
    return (
      <WidgetWrapper>
        <div className={" p-6 mb-4 bg-white shadow rounded corner-flower-"}>
          <div className="flex items-center justify-between">
            <h3 className={"small-title overlay-top pdfChart_Title"}>
              {title + " (" + unit + ")"}
            </h3>
          </div>
          <div className="flex items-center mb-3">
            <span className="text-2xl font-bold overlay-top pdfChart_SubTitle">
              {unit + " " + NoDecimalFormat(value)}
            </span>
          </div>
          <Histogram
            height={200}
            data={data}
            unit={unit}
            color={color}
            value={"value"}
            type={"month"}
          />
        </div>
      </WidgetWrapper>
    );
  };

  return (
    <>
      <Spin spinning={summaryState.isLoading}>
        <div>
        <div style={{color: '#396598', fontWeight: 700, fontSize: 16, margin: 10, marginTop: 0}}>Utility Summary - <span className="chartId_SummaryTitle">{summaryOptions.find(item => item.value === recently)?.label}</span></div>
          
          <div id="SummaryRecentlyFilterContentContainer">
            <Row>
              <Col span={24}>
                <Row type="flex">
                  <UtilityCard
                    key={0}
                    subject="Electricity"
                    object={"electricity"}
                    data={summaryState.electricity}
                    unit="kWh"
                    color="blue"
                  />
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Row type="flex">
                  <UtilityCard
                    key={1}
                    subject="Gas"
                    object={"gas"}
                    data={summaryState.gas}
                    unit="MMBtu"
                    color="brown"
                  />
                </Row>
              </Col>
            </Row>

            <Row>
              <Col style={{ minWidth: "240px" }} className={"w-full"}>
                <Row type="flex" className={"mb-4"}>
                  <Col xs={24} md={12} xl={8} id={utilityCardIdMappings.Total.TotalCost.id}>
                    {renderTotalCostChart(
                      summaryState.total_cost,
                      summaryState.total_cost_months
                    )}
                  </Col>

                  <Col xs={24} md={12} xl={8} id={utilityCardIdMappings.Total.TotalCostPerSqft.id}>
                    {renderTotalCostsSqtChart(
                      summaryState.total_area_cost,
                      summaryState.total_cost_breakdown
                    )}
                  </Col>

                  <Col xs={24} md={12} xl={8} id={utilityCardIdMappings.Total.TotalCostBreakdown.id}>
                    {renderTotalCostBreakdownChart(
                      summaryState.total_cost,
                      summaryState.total_cost_breakdown
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row>
            <Col style={{ minWidth: "240px" }} className={"w-full"}>
              <Row type="flex" className={"mb-4"}>
                <Col xs={24} md={12} xl={8} id={utilityCardIdMappings.EUI.EUILast12Month.id}>
                  {renderEUILastMonth(summaryState.total_eui)}
                </Col>

                <Col xs={24} md={12} xl={8} id={utilityCardIdMappings.EUI.EUIRolling12MonthsBreakdown.id}>
                  {renderLastMonthBreakdown(summaryState.eui_pie_data)}
                </Col>

                <Col xs={24} md={12} xl={8} id={utilityCardIdMappings.EUI.EUILast12MonthsBreakdown.id}>
                  {renderLastMonthEUI(
                    summaryState.total_eui,
                    summaryState.total_cost_breakdown
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};
