import React, {forwardRef, useImperativeHandle} from 'react';
import {DatePicker} from "antd";
import moment from "moment";

const CustomDatePicker = forwardRef((props, ref) => {
    const {onChange, value, format, type, ...rest} = props;

    const handleChange = (date) => {
        const formattedValue = date ? date.format(format) : null;
        onChange(formattedValue);
    };

    useImperativeHandle(ref, () => ({
        reset() {
            onChange(null);
        },
        // any other methods that you want to expose
    }));

    return (
        <DatePicker
            {...rest}
            value={value ? moment(value, format) : null}
            onChange={handleChange}
            picker={type}
        />
    );
});

export default CustomDatePicker;
