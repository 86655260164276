import React, { useState } from 'react';
import { Button, Form, Input, message, Modal, Select, Spin, Tag, Tooltip } from 'antd';
import { useRequest } from 'ahooks';
import { api } from '@/redux/api';
import { PlusOutlined } from '@ant-design/icons';

export const FormItemTag = ({ ...props }) => {

  const [isAddTagOpen, setIsAddTagOpen] = useState(false);
  const [update, setUpdate] = useState({});
  const { data = [], loading: tagLoading } = useRequest(() => api.getTags().then(e => {
    if (e.status === 200) {
      return e.data.results ?? [];
    } else {
      return []
    }
  }), {
    refreshDeps: [update]
  })

  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isAddLoading, setIsAddLoading] = useState(false);

  const handleClose = (removedTag) => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };


  const addTag = async () => {
    setIsAddLoading(true)
    Promise.all(tags.map(label => api.createTags({label}))).then(res => {
      setTags([])
      setUpdate({})
    }).catch(err => {
      message.error("Save Failed")
    }).finally(() => {
      setIsAddLoading(false)
      setIsAddTagOpen(false)
    })
  }

  return (
    <>
      <Modal
        open={isAddTagOpen}
        onCancel={() => {
          setIsAddTagOpen(false)
          setTags([])
        }}
        onOk={addTag}
        okButtonProps={{
          loading: isAddLoading
        }}
        title="Add Tag">
      <>
      {tags.map((tag, index) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag key={tag} closable onClose={() => handleClose(tag)}>
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag onClick={showInput} className="site-tag-plus">
          <PlusOutlined /> New Tag
        </Tag>
      )}
    </>
      </Modal>
      <div style={{ display: 'flex' }}>

          <Select
            mode="multiple"
            placeholder="Please select"
          style={{ width: '100%' }}
          options={ data}
          fieldNames={{
            value: 'id',
            label: 'label', 
          }}
          loading={tagLoading}
          {...props}
          value={tagLoading ? [] : props.value}
        />
        <Button style={{ marginLeft: 10 }} icon={<PlusOutlined />} onClick={() => setIsAddTagOpen(true)}>Add Tag</Button>
      </div>
    </>
  );
};
