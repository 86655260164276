import React, {Component} from 'react';
import EditableTable from "@components/Table/EditableTable";
import _ from "lodash";
import actions from "@redux/equipmentCategory/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {
    Equipment_CategoryColumns,
    Equipment_CategoryProps
} from "@containers/facility/Equipment_Category/Equipment_CategoryColumns";
import {
    Equipment_CategoryFormFields,
    Equipment_CategoryRelated
} from "@containers/facility/Equipment_Category/Equipment_CategoryFormFields";

class Equipment_Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
        }
    }

    handleCreateEquipmentCategory = (body) => {
        return api.createEquipmentCategory(body).then(
            response => {
                console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteEquipmentCategory = (id) => {
        return api.deleteEquipmentCategory(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkEquipmentCategoryInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteEquipmentCategory(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        return api.editEquipmentCategory(key, row).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {
        console.log('triggered', query)
        this.setState({
            isLoading: true
        })
        return api.filterEquipmentCategory(query).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                }
            }
        ).catch(error => (
            error
        ));
    }

    getEquipmentCategory = (data) => {
        this.setState({
            data,
            isLoading: false,
        })
        this.props.setEquipmentCategorySuccess(data)
    }

    render() {
        return (

            <div className="m-5">
                <EditableTable
                    columns={Equipment_CategoryColumns}
                    data={this.state.data}
                    columnProps={Equipment_CategoryProps}
                    formFields={Equipment_CategoryFormFields}
                    formRelated={Equipment_CategoryRelated}
                    getRecord={this.getEquipmentCategory}
                    createRecords={this.handleCreateEquipmentCategory}
                    deleteRecord={this.handleDeleteEquipmentCategory}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    relatedList={this.props.relatedList}
                    hide_date={true}
                    hide_imports={true}
                    isRowClickable={true}
                    hide_search={true}
                    isChat={false}
                    isRelated={false}
                    isAttachments={false}
                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        Facility: _.get(state.Facility, 'data'),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setEquipmentCategorySuccess: (e) => dispatch(actions.EquipmentCategorySuccess(e)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Equipment_Category);
