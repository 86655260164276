import actions from './actions';

const initState = {
    Baseline_Start: null,
    Baseline_End: null,
    Post_Start: null,
    Post_End: null,
    Electricity_Price: null,
    Gas_Price: null,
    Range0: null,
    Range1: null,
    Breakpoint0: null,
    Breakpoint1: null,
    Breakpoint2: null,
};

export default function SavingFilterReducer(state = initState, action) {
    switch (action.type) {
        case actions.Baseline_Start_REQUEST:
            return {
                ...state,
                Baseline_Start: action.payload.data
            };
        case actions.Baseline_End_REQUEST:
            return {
                ...state,
                Baseline_End: action.payload.data
            };
        case actions.Post_Start_REQUEST:
            return {
                ...state,
                Post_Start: action.payload.data
            };
        case actions.Post_End_REQUEST:
            return {
                ...state,
                Post_End: action.payload.data
            };
        case actions.Electricity_Price_REQUEST:
            return {
                ...state,
                Electricity_Price: action.payload.data
            };
        case actions.Gas_Price_REQUEST:
            return {
                ...state,
                Gas_Price: action.payload.data
            };
        case actions.Range0_REQUEST:
            return {
                ...state,
                Range0: action.payload.data
            };
        case actions.Range1_REQUEST:
            return {
                ...state,
                Range1: action.payload.data
            };
        case actions.Breakpoint0_REQUEST:
            return {
                ...state,
                Breakpoint0: action.payload.data
            };
        case actions.Breakpoint1_REQUEST:
            return {
                ...state,
                Breakpoint1: action.payload.data
            };
        case actions.Breakpoint2_REQUEST:
            return {
                ...state,
                Breakpoint2: action.payload.data
            };

        default:
            return state;
    }
}
