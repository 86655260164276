import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Spin, Table } from "antd";

import moment from "moment";
import { api } from "@redux/api";
import _ from "lodash";
import { Spinner } from "@components/Spinner";
import PrintPDF from "@components/PrintPDF";
import { CurrencyFormat, NoDecimalFormat, PercentFormat, TwoDecimalFormat } from "@components/Format";
import AnalysisLookup from "@containers/UtilityData/Analysis/AnalysisLookup";
import UtilityDualAxes from "@containers/UtilityData/Charts/UtilityDualAxes";
import LineChart from "@containers/UtilityData/Charts/LineChart";
import Histgram from "@containers/UtilityData/Charts/Histgram";
import AnalysisFilter from "@containers/UtilityData/Analysis/AnalysisFilter";
import TotalCostBreakdownChart from "@containers/UtilityData/Summary/Charts/TotalCostBreakdownChart";
import MonthlyCostSavings from "@containers/UtilityData/Analysis/Charts/MonthlyCostSavings";
import {
  NormalizedCalculationsElec,
  NormalizedCalculationsGas,
  StandardizedCalculationsElec,
  StandardizedCalculationsGas
} from "./NormalizedCalculations";
import { AnalysisTableWrapper } from "@containers/UtilityData/Analysis/Analysis.styles";

const EuiChartPromise = import("@containers/UtilityData/Analysis/Charts/EUIChart");
const EuiChart = React.lazy(() => EuiChartPromise)
const SavingsLGChart = React.lazy(() =>
  import("@containers/UtilityData/Analysis/Charts/SavingsLGChart.js")
)
const BaseUsageChartPromise = import("@containers/UtilityData/Analysis/Charts/BaseUsageChart");
const BaseUsageChart = React.lazy(() => BaseUsageChartPromise)

const CumulativeCostSavingsPromise = import("@containers/UtilityData/Analysis/Charts/CumulativeCostSavings");
const CumulativeCostSavings = React.lazy(() => CumulativeCostSavingsPromise)

const PeridCardIdMappings = {
  ElectricityNormalized: {
    id: 'ChartId_ElectricityNormalized',
    title: 'Electricity Normalized Consumption Vs. Weather'
  },
  GasNormalized: {
    id: 'ChartId_GasNormalized',
    title: 'Gas Normalized Consumption Vs. Weather'
  },
  MonthlyElectricity: {
    id: 'ChartId_MonthlyElectricity',
    title: 'Electricity Monthly Consumption and Costs'
  },
  MonthlyGas: {
    id: 'ChartId_MonthlyGas',
    title: 'Monthly Gas Consumption and Costs'
  },
  MonthlyElectricityPrices: {
    id: 'ChartId_MonthlyElectricityPrices',
    title: 'Monthly Electricity Prices'
  },
  MonthlyGasPrices: {
    id: 'ChartId_MonthlyGasPrices',
    title: 'Monthly Gas Prices'
  },
  MonthlyEUI: {
    id: 'ChartId_MonthlyEUI',
    title: 'Monthly EUI (kBtu/sqft-yr)'
  },
}

class AnalysisPage extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.Chart1 = React.createRef();

    this.state = {
      data: [],
      filter_dates: [null, null],

      dataSource: [],
      eui_data: [],
      total_cost_breakdown: [],
      annual_eui: 0,

      ElecData: [],
      ElecReg: [],
      ElecUsage: [],
      ElecCumulativeUsage: [],
      ElecMonthlyUsage: [],
      ElecR_Squared: null,
      ElecCv: null,
      ElecPostDate: [],
      ElecSelected: [],
      ElecSummaryData: [],
      ElecTotals: [],

      GasData: [],
      GasReg: [],
      GasUsage: [],
      GasCumulativeUsage: [],
      GasMonthlyUsage: [],
      GasR_Squared: null,
      GasCv: null,
      GasPostDate: [],
      GasSelected: [],
      GasSummaryData: [],
      GasTotals: [],

      isOpen: true,
      daterange: [],
      Electricity_Data: [],
      Gas_Data: [],

      columns: [],
      isLoading: false,
      selected: 0
    }
  }

  componentDidMount() {
    this.handleDateRange()
    this.setState({
      columns: [
        {
          title: '',
          children: [
            {
              title: 'Service Start',
              dataIndex: 'ServiceStart',
              key: 'ServiceStart',
            },
            {
              title: 'Service End',
              dataIndex: 'ServiceEnd',
              key: 'ServiceEnd',
            },
            {
              title: 'Usage',
              dataIndex: 'Usage',
              key: 'Usage',
            },
            {
              title: 'Adjustment',
              dataIndex: 'Adjustment',
              key: 'Adjustment',
            },
            {
              title: 'Service Month',
              dataIndex: 'ServiceMonth',
              key: 'ServiceMonth',
            },
            {
              title: 'Avg OAT',
              dataIndex: 'AvgOAT',
              key: 'AvgOAT',
              render: (text, record) => {
                return TwoDecimalFormat(text)
              }
            },
            {
              title: 'Area',
              dataIndex: 'Area',
              key: 'Area',
            }
          ]
        },
        {
          title: '',
          children: [
            {
              title: 'Service Days',
              dataIndex: 'ServiceDays',
              key: 'ServiceDays',
            },
            {
              title: 'Adjust Usage',
              dataIndex: 'AdjustUsage',
              key: 'AdjustUsage',
            },
            {
              title: 'Norm Usage',
              dataIndex: 'NormUsage',
              key: 'NormUsage'
            }]
        },
        {
          title: '',
          children: [
            {
              title: 'Model Usage',
              dataIndex: 'ModelUsage',
              key: 'ModelUsage'
            },
            {
              title: 'Base Usage',
              dataIndex: 'BasicUsage',
              key: 'BasicUsage'
            },
            {
              title: 'Saved Usage',
              dataIndex: 'SavedUsage',
              key: 'SavedUsage'
            },
            {
              title: 'Savings',
              dataIndex: 'Savings',
              key: 'Savings'
            },
            {
              title: 'Cost Savings',
              dataIndex: 'CostSavings',
              key: 'CostSavings'
            },
            {
              title: 'Cumulative Savings',
              dataIndex: 'CumulativeSavings',
              key: 'CumulativeSavings'
            }]
        }
      ]
    })
  }

  componentWillUpdate(nextProps, nextState) {
    // if (nextProps.electricity !== this.props.electricity || nextProps.gas !== this.props.gas) {
    //     this.handleDateRange()
    // }
  }

  handleDateRange = (e) => {

    api.daterange().then(
      response => {
        let dataSource = response.data;
        // console.log('handleDateRange dataSource', dataSource)
        return dataSource
      }
    ).then((data) => {
      this.setState({
        daterange: data
      })
    }
    ).catch(error => (
      error
    ));
  }

  handleRender = (
    start_date,
    gas_base,
    gas_post,
    gas_Baseline_Date,
    gas_Post_Date,
    gas_Price,
    gas_range0,
    gas_range1,
    gas_breakpoint0,
    gas_breakpoint1,
    gas_breakpoint2,
    gas_breakpoint3,
    gas_n_segments,
    elec_base,
    elec_post,
    elec_Baseline_Date,
    elec_Post_Date,
    elec_Price,
    elec_range0,
    elec_range1,
    elec_breakpoint0,
    elec_breakpoint1,
    elec_breakpoint2,
    elec_breakpoint3,
    elec_n_segments
  ) => {
      this.fetchSummaryData(moment(start_date[0]).format('YYYY-MM-DD'), moment(start_date[1]).format('YYYY-MM-DD'))
  }

  handlePrint = () => {
    this.child.current.composeManagerPDF('Report', 1);
  }


  handleLabel = (e) => {
    console.log('handleLabel', e)
    this.setState({
      selected: e * 1
    })
  }

  handleEleData = (e) => {
    this.setState({
      Electricity_Data: e
    })
  }

  handleGasData = (e) => {
    this.setState({
      Gas_Data: e
    })
  }

  fetchSummaryData = (start, end) => {
    this.setState({
      isLoading: true
    })
    let new_end = moment(end).endOf('month').format('YYYY-MM-DD');
    return Promise.all([
      api.get_electricity_eui_analysis(start, new_end),
      api.get_gas_eui_analysis(start, new_end)
    ]).then(
      response => {
        let total_cost_breakdown = [];
        let electricity = _.get(response[0], 'data');
        let gas = _.get(response[1], 'data');
        let total_eui = 0;

        for (let i = 0; i < electricity.electricity.length; i++) {
          // console.log('TwoDecimalFormat', electricity.electricity[i].eui,  TwoDecimalFormat(electricity.electricity[i].eui*1)*1)
          total_cost_breakdown.push({
            month: electricity.electricity[i].month,
            type: 'Electricity',
            eui: (electricity.electricity[i].eui) * 1
          })

        }
        for (let i = 0; i < gas.gas.length; i++) {
          total_cost_breakdown.push({
            month: gas.gas[i].month,
            type: 'gas',
            eui: (gas.gas[i].eui) * 1,
          })
        }

        total_eui = gas.annual_eui + electricity.annual_eui;


        return {
          total_eui,
          total_cost_breakdown
        }
      }
    ).then((data) => {

      this.setState({
        total_cost_breakdown: data.total_cost_breakdown,
        total_eui: data.total_eui,
        isLoading: false
      })
    }).catch(error => (
      console.log('error', error)
    ));
  }
  renderLastMonthEUI = (value, data) => {
    let color = 'orange', title = PeridCardIdMappings.MonthlyEUI.title, unit = '', label = 'Total Cost Breakdown';
    return (
      <div>

        <div className={" p-6 bg-white shadow rounded corner-flower-"}>
          <div className="flex items-center justify-between">
            <h3 className={'small-title overlay-top'}>{title}</h3>
            <span className="text-2xl font-bold overlay-top">{unit + ' ' + NoDecimalFormat(value)}</span>
          </div>
          <TotalCostBreakdownChart
            height={250}
            type={'month'}
            seriesField={'type'}
            value={'eui'}
            data={data}
          />

        </div>

      </div>
    )
  }

  handleCombinedCumulative = (providedData) => {

    const allDates = [...new Set(providedData.map(item => item.date))].sort();

    const defaultDataStructure = type => {
      let rollingValue = 0;
      return allDates.map(date => {
        const existingEntry = providedData.find(item => item.date === date && item.type === type);
        if (existingEntry) {
          rollingValue = existingEntry.value;
        }
        return {
          date,
          value: rollingValue,
          type
        };
      });
    };

    const filledElectricityData = defaultDataStructure("Electricity");
    const filledGasData = defaultDataStructure("Gas");

    return [...filledElectricityData, ...filledGasData];
  }

  handleMonthlyCumulative = () => {
    let providedData = [...this.state.ElecCumulativeUsage, ...this.state.GasCumulativeUsage];
    const allDates = [...new Set(providedData.map(item => item.date))];

    const defaultDataStructure = type => allDates.map(date => ({
      date,
      value: 0,
      type
    }));

    const defaultElectricityData = defaultDataStructure("Electricity");
    const defaultGasData = defaultDataStructure("Gas");

    const filledElectricityData = this.fillData(defaultElectricityData, this.state.ElecCumulativeUsage);
    const filledGasData = this.fillData(defaultGasData, this.state.GasCumulativeUsage);
    return [...filledElectricityData, ...filledGasData];

  }

  fillData = (defaultData, providedData) => {
    return defaultData.map(defaultItem => {
      const match = providedData.find(
        providedItem => providedItem.date === defaultItem.date && providedItem.type === defaultItem.type
      );
      return match || defaultItem;
    });
  };

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Spin spinning={this.state.isLoading}>
          <div id={'Report_1'} className={'mt-2'}>
            <div>
              <section className="w-full">
                <AnalysisFilter
                  onRender={this.handleRender}
                  daterange={this.state.daterange}
                  onPrint={this.handlePrint}
                  selected={this.state.selected}
                  handleEleData={this.handleEleData}
                  handleGasData={this.handleGasData}
                />
                <div>
                  <div className="flex flex-wrap">
                    <Row className={'w-full px-5'}>
                      <Col xs={12} md={12} xl={12} id={PeridCardIdMappings.ElectricityNormalized.id}>
                        <div className={'mr-4 mt-4'}>
                          <Histgram
                            area={this.props.Area}
                            data={this.state.Electricity_Data}
                            container='Normalized'
                            title={PeridCardIdMappings.ElectricityNormalized.title}
                            handleChartSelect={this.handleChartSelect}
                            leftTitle={'Watt / sqft'}
                            leftVar={'WattSqft'}
                            height={250}
                            pointSize={8}
                            color={'blue'}
                          />
                        </div>
                      </Col>

                      <Col xs={12} md={12} xl={12} id={PeridCardIdMappings.GasNormalized.id}>
                        <div className={'mr-4 mt-4'}>
                          <Histgram
                            area={this.props.Area}
                            data={this.state.Gas_Data}
                            container='Normalized'
                            title={PeridCardIdMappings.GasNormalized.title}
                            handleChartSelect={this.handleChartSelect}
                            leftTitle={'BTU/hr-sqft'}
                            leftVar={'ScfDaySqft'}
                            height={250}
                            pointSize={8}
                            color={'brown'}
                          />
                        </div>
                      </Col>

                      <Col xs={12} md={12} xl={12} id={PeridCardIdMappings.MonthlyElectricity.id}>
                        <div className={'mr-4 mt-4'}>
                          <UtilityDualAxes
                            data={this.state.Electricity_Data}
                            handleChartSelect={this.handleChartSelect}
                            x={'service_month'}
                            y={['usage', 'costs']}
                            height={250}
                            pointSize={6}
                            container='ElectricityCosts'
                            title={PeridCardIdMappings.MonthlyElectricity.title}
                            leftTitle='kWh'
                            rightTitle='kWh'
                            color={'blue'}
                          />
                        </div>
                      </Col>

                      <Col xs={12} md={12} xl={12} id={PeridCardIdMappings.MonthlyGas.id}>
                        <div className={'mr-4 mt-4'}>
                          <UtilityDualAxes
                            data={this.state.Gas_Data}
                            handleChartSelect={this.handleChartSelect}
                            x={'service_month'}
                            y={['usage', 'costs']}
                            container='Gas Costs'
                            height={250}
                            pointSize={6}
                            title={PeridCardIdMappings.MonthlyGas.title}
                            leftTitle='MMBtu'
                            rightTitle='MMBtu'
                            color={'brown'}
                          />
                        </div>
                      </Col>

                      <Col className='xyz' xs={12} md={12} xl={12} id={PeridCardIdMappings.MonthlyElectricityPrices.id}>
                        <div className={'mr-4 mt-4'}>
                          <LineChart
                            height={250}
                            pointSize={6}
                            data={this.state.Electricity_Data}
                            handleChartSelect={this.handleChartSelect}
                            x={'service_month'}
                            y={['costs', 'usage']}
                            container='ElectricityPrices'
                            title={PeridCardIdMappings.MonthlyElectricityPrices.title}
                            leftTitle='$/kWh'
                          />
                        </div>
                      </Col>

                      <Col xs={12} md={12} xl={12} id={PeridCardIdMappings.MonthlyGasPrices.id}>
                        <div className={'mr-4 mt-4'}>
                          <LineChart
                            height={250}
                            pointSize={6}
                            data={this.state.Gas_Data}
                            handleChartSelect={this.handleChartSelect}
                            x={'service_month'}
                            y={['costs', 'usage']}
                            container='Gas Prices'
                            title={PeridCardIdMappings.MonthlyGasPrices.title}
                            leftTitle='$/kWh'
                          />
                        </div>
                      </Col>
                      <Col xs={24} md={24} xl={24} id={PeridCardIdMappings.MonthlyEUI.id}>
                        <div className={'mr-4 mt-4'}>
                          {this.renderLastMonthEUI(
                            this.state.total_eui,
                            this.state.total_cost_breakdown
                          )}
                        </div>
                      </Col>
                    </Row>

                  </div>
                </div>
              </section>
            </div>
          </div>

        </Spin>

      </Suspense>
    )
      ;
  }
}

const mapStateToProps = state => {
  // console.log('props', state)
  return {

    Area: _.get(state.Facility.selected, 'record.area'),
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalysisPage);
