import React, {Component} from 'react';
import _ from 'lodash'
import {connect} from "react-redux";

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {jsforceResponse} from "@/config/httpService";
import {PlusOutlined} from "@ant-design/icons";
import {api} from "@redux/api";
import actions from "@redux/equipment/actions";
import {Button, notification} from "antd";
import WorkorderModal from "@containers/facility/Equipment/WorkorderModal";


class FunctionalTestFormEquipmentLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: null,
            Contact: null,
            value: null,
            isVisible: false,
            category: null,
            disabled: false
        }
    }

    componentDidMount() {
        // console.log('componentDidMount', this.props.record, this.props.formRef)
        let category = this.props.selectedTemplateType;
        console.log('category: ', this.props.selected_record, this.props.record)
        let disabled = false;
        if(this.props.selected_record ||  this.props.record){
            disabled = true
        }
        this.setState({
            value: this.props.record?this.props.record: this.props.selected_record?.name,
            isModalVisible: false,
            category: category,
            disabled
        })
    }

    handleFocus = async (e) => {
        let query = 'category=' + this.state.category;
        const result1 = await api.filterEquipment(query);
        const res1 = jsforceResponse(result1, '', false, true)
        let data = _.get(res1, 'data.results', []);

        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i].name+' '+data[i].building?data[i].building:'',
                value: data[i].name,
                id: data[i].id,
                record: {Id: data[i].id, value: data[i].name},
            })
        }
        this.setState({
            options
        })
    }

    handleSearch = async (e) => {
        let query = 'name=' + e + '&category=' + this.state.category;
        const result1 = await api.filterEquipment(query);
        const res1 = jsforceResponse(result1, '', false, true)
        let data = _.get(res1, 'data.results', []);
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i].name+' '+data[i].building?data[i].building:'',
                value: data[i].name,
                id: data[i].id,
                record: {Id: data[i].id, value: data[i].name},
            })
        }
        this.setState({
            options
        })
    }

    handleSelect = (e, record) => {
        // console.log('handleSelect', this.props.formRef, record)

        this.setState({
            selected: record.record,
            value: e
        })
        this.props.formRef.setFieldsValue({
            equipment: record.id,
            equipment__id: record.id,
        })
    }

    handleAddEquipment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isVisible: true
        })
    }
    renderAddButton = () => {
        return (
            <div>
                <Button type="primary" shape="circle" icon={<PlusOutlined/>} size={'middle'}
                        onClick={(e) => this.handleAddEquipment(e)}></Button>
            </div>
        )
    }

    render() {
        console.log('equipment', this.state.value)
        return (
            <>
                <div className={'flex items-center justify-center'}>
                    <AutoComplete
                        style={{width: '100%'}}
                        options={this.state.options}
                        onFocus={this.handleFocus}
                        onSelect={this.handleSelect}
                        onSearch={this.handleSearch}
                        value={this.state.value}
                        disabled={this.state.disabled}
                        dropdownMatchSelectWidth={false}
                    >
                        <Input size="medium" autosize={true}
                               autoComplete="off"
                        />
                    </AutoComplete>
                    {this.renderAddButton()}
                </div>
                <WorkorderModal
                    isVisible={this.state.isVisible}
                    onClose={(e) => this.setState({isVisible: false})}
                />
            </>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {
        SelectedEquipment: (e) => dispatch(actions.SelectedEquipment(e))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionalTestFormEquipmentLookup);
