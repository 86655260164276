import {Drawer} from "antd";
import FunctionalTestingDetail from "@containers/facility/Equipment/FunctionalTesting/FunctionalTestingDetail";
import React, {Component} from "react";

class FunctionTestingClone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenNew: true,
            selectedTemplateType: null,
            selectedTypeOption: null,
        }
    }

    onClose = () => {
        this.props.toggleCloneDrawer(false)
    }

    onCreateRecords = (e) => {
        this.props.toggleCloneDrawer(false)
        this.props.createRecords(e)
    }

    render() {
        return(
            <>
                <Drawer
                    width={window.innerWidth > 900 ? '90%' : '100%'}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.props.isCloneOpen}
                    destroyOnClose={true}
                    autoFocus={true}
                >
                    <div>
                        <FunctionalTestingDetail
                            key={'FunctionalTesting_detail2'}
                            formKey={'FunctionalTesting_detail2'}
                            selectedTemplateType={this.props.selectedTemplateType}
                            selectedTypeOption={this.props.selectedTypeOption}

                            columnProps={this.props.columnProps}
                            createRecords={this.onCreateRecords}
                            formClassName={'flex-form grid grid-cols-4 '}
                            isNewRecords={false}
                            onClose={this.onClose}
                            formRefCallback={this.formRefCallback}
                            data={this.props.data}
                            isClone={true}
                        />
                    </div>
                </Drawer>
            </>
        )
    }
}

export default FunctionTestingClone;
