import styled from "styled-components";

export const DetailWrapper = styled.div`
  .site-page-header .ant-page-header-heading {
    justify-content: center;
  }
  #myform .ant-form-item {
    margin: 0px !important;
  }

  .flex-form .ant-form-item {
    margin-bottom: 1px !important;
  }

  .ant-btn {
    z-index: 16;
    //border-radius: 27px !important;
    //box-shadow: 0px 4px 16px 0px rgb(179 192 231) !important;
    width: 100%;
    justify-content: center;
    align-items: center !important;
  }

  form.ant-form.ant-form-vertical {
    column-gap: 32px;
    display: flex;
    flex-wrap: wrap;
  }

  .slds-text-title--caps, .slds-text-body--small, .slds-align-middle {
    display: grid;
    justify-content: center;
  }

  .disabled-input {
    color: black !important;
  }

  .slds-input {
    border: 1px solid #dddbda !important;
  }

  .ant-input[disabled] {
    color: black !important;
    background-color: rgb(247 247 247 / 55%);
  }

  .ant-form-item-control-input-content {
    text-align: justify;
  }


  .ant-form-item-control-input {
    //background-color: #E6E9F4 !important;
    border-radius: 2pt;
    min-height: 28px !important;
  }

  .ant-form-item-label {
    //text-transform: uppercase;
    padding: 0.2rem !important;
    padding-left: 0.6rem !important;
    line-height: 1.14286em;
    //color: #333;
    clear: left;
    position: relative;
  }

  .AddAndDelete {
    .main {
      border: 1px solid #E9E9E9;
      border-radius: 4px;
      padding: 5px;
      width: 100%;
    }

    .tag {
      position: relative;
      margin: 3px;
      border: 1px dashed #3b9de9;
      border-radius: 4px;
      padding: 0 8px;
      line-height: 30px;
      color: #666666;
      background: rgba(255, 255, 255, 0.7);
    }

    .delete {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    .inputs {
      margin-top: 10px;
    }

    input {
      position: relative;
      top: -1px;
      width: 10rem !important;
      box-sizing: border-box;
      margin: 0;
      padding: 5px !important;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      display: inline-block;
      min-width: 0;
      color: rgba(0, 0, 0, 0.85);
      
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s;
    }

    button {
      margin-left: 10px;
      background-color: #3789c7;
      border: none;
      color: white;
      padding: 5px 15px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      border-radius: 2px;
    }
  }

  @media (min-width: 1024px) {
    .lg\\:w-1\\/3 {
      width: 30% !important;
    }

    .lg\\:w-1\\/4 {
      width: 21% !important;
    }
  }

  @media (min-width: 768px) {
    .md\\:w-1\\/2 {
      width: 45%;
    }

    .md\\:w-1\\/4 {
      width: 20%;
    }
  }

  @media (max-width: 568px) {
    .sm\\:w-full {
      width: 100%;
    }
  }


`;
