import React from "react";
import {Column} from "@antv/g2plot";
import {TwoDecimalFormat} from "@components/Format";

class TotalCostBreakdownChart extends React.Component {
    chartRef = React.createRef();

    componentWillReceiveProps(nextProps) {
        this.chartRef.current.innerHTML = '';
        this.draw(nextProps)
    }

    draw = (props) => {
        const chartDom = this.chartRef.current;
        const stackedColumnPlot = new Column(chartDom, {
            data: props.data,
            height: props.height,
            isStack: true,
            xField: props.type,
            yField: props.value,
            color: ['blue', 'brown'],
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            tooltip: {
                formatter: (datum) => {
                    return {name: datum.type, value: TwoDecimalFormat(datum[props.value])};
                },
            },
            legend: {
                layout: 'horizontal',
                position: 'top-right'
            },
            seriesField: props.seriesField,
            label: false,
        });

        stackedColumnPlot.render();
    }

    render() {
        return (
            <>
                <div ref={this.chartRef} className={''}/>
            </>
        );
    }
}

export default TotalCostBreakdownChart;



