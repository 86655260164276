import React, {Component, PureComponent} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {NotificationColumns, NotificationProps} from "@containers/facility/Notification/NotificationColumns";
import _ from "lodash";
import actions from "@redux/notification/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {Button, Form} from "antd";
import SearchLookup from "@components/SearchLookup";
import {withRouter} from "@/router/withRouter";
import {NotificationFormFields} from "@containers/facility/Notification/NotificationFormFields";

class Notification extends PureComponent {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.filterFormRef = React.createRef();
        this.state = {
            data: [],
            desc: '',
            desc_keyword: '',
        }
    }

    handleDeleteNotification = (id) => {
        const query = ('&user_id=' + this.props.userId)
        return api.deleteNotification(id, query).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkDelete = (body) => {
        return api.deleteBulkNotification(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleMarkAllAsReadClick = () => {
        return api.deleteBulkNotification()
            .then(response => {
                this.refresh(false)
            })
            .catch(error => console.error('Error updating notification status:', error));
    }

    handleFilter = (query) => {

        this.setState({
            isLoading: true,
        });

        let query_new = query +
            ('&user_id=' + this.props.userId) +
            (this.state.name ? ('&description=' + (this.state.desc)) : '')
        return api.filterNotification(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getNotification = (data) => {

        this.setState({
            isLoading: false,
            data
        })

        this.props.setNotificationSuccess(data);
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    handleLookupFilter = (e) => {

        let query_new = (this.state.desc_keyword ? ('name=' + this.state.desc_keyword) : '') +
            '&limit=15';

        return api
            .filterNotification(query_new)
            .then((response) => {
                let dataSource = response.data.results;
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count,
                };
            })
            .catch((error) => {
                return error;
            });
    }

    handleSearchSelect = (e, type) => {
        this.setState({
            [type]: e,
            limit: null
        }, function () {
            this.refresh(false);
        })
    }

    handleSearch = (e, type) => {
        this.setState({
            [type + '_keyword']: e
        });
        return this.handleLookupFilter();
    }

    renderFilter = () => {
        return [<SearchLookup
            placeholder={'Description'}
            name={'description'}

            SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'desc')}
            Lookup_CommonupRequest={(e) => this.handleSearch(e, 'desc')}
        />,

            <Button type={'primary'} onClick={this.handleMarkAllAsReadClick} className={'mt-1'}>Mark All As Read</Button>

        ]
    }

    resetFilters = () => {
        this.filterFormRef.current.resetFields();
        this.setState({}, function () {
            this.refresh(false)
        })
    }
    renderSearches = () => {

        return (
            <Form ref={this.filterFormRef} className={'filter-form'}>

            </Form>
        )
    }

    handleSelectedRecord = (record) => {
        this.setState({
            selectedRecord: record
        })
    }

    markAsRead = (rows) => {
        return () => {
            const ids = Object.values(rows).map(row => row.id);

            const body = {
                ids: ids
            };

            return api.markAsRead(body)
                .then(response => {
                    this.refresh(false);
                })
                .catch(error => {
                    console.error(error);
                    return error;
                });
        }
    }
    customButton = (rows) => {
        return <Button type={'primary'} onClick={this.markAsRead(rows)}>Mark As Read</Button>
    }

    render() {
        return (

            <div className={'p-5'}>

                <EditableTable
                    ref={this.tableRef}
                    columns={NotificationColumns(this.handleSelectedRecord)}
                    data={this.state.data}
                    columnProps={NotificationProps}
                    formFields={NotificationFormFields()}
                    getRecord={this.getNotification}
                    deleteRecord={this.handleDeleteNotification}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    postComment={this.handlePostComment}
                    renderFilter={this.renderFilter}
                    resetFilters={this.resetFilters}
                    formRelated={null}
                    hide_search={true}
                    isRowClickable={false}
                    hid_new={true}
                    // isChat={true}
                    isNewRecords={false}
                    isRelated={false}
                    readonly={true}
                    isAttachments={false}
                    setFilters={this.setFilters}
                    // formClassName={'flex-form'}
                    filterDescription={this.state.filterDescription}
                    renderSearches={this.renderSearches}
                    isRowHover={true}
                    isHideRefreshNews={true}
                    customButton={this.customButton}
                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        Notification: _.get(state.Notification, 'data'),
        userId: state.Auth.userId
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setNotificationSuccess: (e) => dispatch(actions.notificationSuccess(e)),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Notification));
