import React from 'react';
import {
  FilePdfFilled,
  FilePdfOutlined,
  FolderFilled,
  FolderOpenFilled,
  FileTextFilled,
  FileWordFilled,
  FileExcelFilled,
  FilePptFilled,
  FileImageFilled,
  FileZipFilled,
  PlayCircleFilled,
  PlaySquareFilled,
  FileOutlined,
  FileFilled

} from '@ant-design/icons'

const getFileExtension = (filename) => {
  return (filename || '').split('.').pop().toLowerCase();
}

export const FileIcon = ({
  isFolder,
  size = 18,
  title,
}) => {
  if (isFolder) {
    return <FolderFilled style={{ fontSize: '24px', color: '#eabf45' }} />
  }

  const ext = getFileExtension(title)
  switch (ext) {
    case 'txt':
      return <FileTextFilled style={{ fontSize: size, color: '#463f00' }} />
    case 'doc':
    case 'docx':
      return <FileWordFilled style={{ fontSize: size, color: '#0a6cff' }} />
    case 'xlsx':
    case 'xls':
      return <FileExcelFilled style={{ fontSize: size, color: '#15c14f' }} />
    case 'png':
    case 'gif':
    case 'webp':
    case 'jpg':
    case 'jpeg':
    case 'bmp':
      return <FileImageFilled style={{ fontSize: size, color: '#00ff00' }} />
    case 'ppt':
      return <FilePptFilled style={{ fontSize: size, color: '#ec6a14' }} />
    case 'wav':
    case 'mp3':
      return <PlayCircleFilled style={{ fontSize: size, color: '#b96e95' }} />
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'flv':
    case 'rmvb':
    case 'wmv':
      return <PlaySquareFilled style={{ fontSize: size, color: '#9452ec' }} />
    case 'rar':
    case 'zip':
    case '7z':
      return <FileZipFilled style={{ fontSize: size, color: '#4e51c4' }} />
    case 'pdf':
      return <FilePdfFilled style={{ fontSize: size, color: '#e73a34' }} />

    default:
      return <FileOutlined style={{ fontSize: size, color: '#000' }} />
  }
}

export const FolderIcon = ({ isOpen, size = 16 }) => {

  const Icon = isOpen ? FolderOpenFilled : FolderFilled;

  return <Icon style={{ fontSize: size, color: '#eabf45' }} />
}


