import React, {Component} from "react";
import LayoutContent from "@components/utility/layoutContent";
import {Bar} from '@antv/g2plot';

class BenchmarkBarChart extends Component {
    chartRef = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.draw(this.props);
    }

    componentWillReceiveProps(nextProps) {
        console.log("barchart", nextProps.data);
        this.chartRef.current.innerHTML = "";
        if (nextProps.data) {
            this.draw(nextProps);
        }
    }

    draw = (props) => {
        const chartDom = this.chartRef.current;
        const chart = new Bar(chartDom, {
            data: props.data,
            height: props.height,
            // isStack: true,
            label: {
                position: 'right', // 'left', 'middle', 'right'
                offset: -20,
            },
            meta: {
                yAxis: {
                    alias: 'Facility',
                    nice: true,
                },
                xAxis: {
                    alias: 'EUI',
                    nice: true,
                },
                isCurrent: {
                    alias: 'isCurrent',
                    nice: true,
                },
            },
            seriesField: 'isCurrent',

            barBackground: {
                style: {
                    fill: 'rgba(0,0,0,0.1)',
                },
            },
            legend: false,
            xField: 'xAxis',
            yField: 'yAxis',
        });

        chart.render();
    };

    render() {
        console.log('BenchmarkBarChart')
        return (
            <LayoutContent className={"outerCardWrapper"}>

                <div style={{marginBottom: '1rem'}}>
                    <div className={'small-title_piechart'}>{this.props.title}</div>
                    <span style={{top: '10px', right: '15px'}}
                          className="absolute p-2 mt-2 mr-2 rounded text-xs text-white">
                          </span>
                </div>

                <div ref={this.chartRef}/>
            </LayoutContent>
        );
    }
}

export default BenchmarkBarChart;
