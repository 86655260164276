import React from 'react';
import {Provider} from 'react-redux';
import {persistor, store} from './redux/store';
import AppThemeProvider from "./themeprovider/ThemeProvider";
import Routes from './router';
import 'antd/dist/antd.css';
import GlobalStyles from "./themeprovider/globalStyle";
import {PersistGate} from 'redux-persist/lib/integration/react';
import enUS from "antd/lib/locale/en_US";
import InactivityTimer from "./components/InactivityTimer";
import { ConfigProvider } from 'antd';

const App = () => {

   return <Provider store={store}>
        <AppThemeProvider>
            <ConfigProvider locale={enUS}>
            <>
                <PersistGate persistor={persistor}>
                    {/*<InactivityTimer  />*/}
                    <GlobalStyles/>
                    <Routes/>
                </PersistGate>

            </>
            </ConfigProvider>
        </AppThemeProvider>
    </Provider>
};


export default App;
