import React, {Component} from 'react';
import "react-csv-importer/dist/index.css";

import TableContent from "@components/Table/TableContent";
import {connect} from "react-redux";
import PageHeader from "@components/Table/PageHeader";

class EditableTable extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            searchString: null,
            filter_dates: [],
            triggered: false,
            isImportModalClosed: true,
            scroll: null
        }
        this.calcScrollonResizeBind = this.calcScrollonResize.bind(this);
    }

    getTableHight() {
      try {
        const wrapHeight = document.body.clientHeight;
        const menuHeight = 70;
        const searchBarHeight = 70;
        const collapseHeight = document.getElementsByClassName('facility-calc-table-resize-collapse')[0]?.clientHeight;

        const diff = 120
        const tableHeight = wrapHeight - menuHeight - diff - (collapseHeight?collapseHeight+20:100);
        return Math.ceil(tableHeight)
      } catch (error) {
        console.error(error)

      }
    }

    calcScrollonResize() {
      const y = this.getTableHight()
      if(y && y !== this.state.scroll?.y) {
        this.setState({
          scroll: {
            x: 800,
            y
          }
        })
      }
    }

    componentDidMount() {
        this.calcScrollonResize();
        window.addEventListener('resize', this.calcScrollonResizeBind)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcScrollonResizeBind)
    }

    handleFresh = (e) => {
        this.tableRef.current.handleFresh()
    }

    createRecords = (e) => {
        return this.props.createRecords(e);
    }

    deleteRecord = (body) => {
        return this.props.deleteRecord(body)
    }

    bulkInsert = (rows) => {
        return this.props.bulkInsert(rows)
    }

    bulkDelete = (body) => {
        return this.props.bulkDelete(body)
    }

    editRecords = (key, row) => {
        this.props.editRecords(key, row)
    }

    handOnlineSave = (key, row) => {
        return this.props.handOnlineSave(key, row)
    }

    handleFilter = (query) => {
        return this.props.handleFilter(query)
    }

    handleSelect = (e) => {
        e = e ? e : null
        this.setState({
            searchString: e
        })
    }

    getRecord = (data) => {
        this.props.getRecord(data)
    }

    setFilterDates = (dates) => {
        this.setState({
            filter_dates: dates,
            triggered: !this.state.triggered
        }, function () {
            this.props.setFilterDates(dates)
        })

    }

    setFilters = (e) => {
        this.props.setFilters(e)
    }

    refresh = (needRequest = true) => {
        this.tableRef.current.refresh(needRequest);
    };

    getFormRef = (formRef) => {

        if (this.props.getFormRef) {
            this.props.getFormRef(formRef);
        }
    }

    handleRegister = (e, data) => {
        this.props.handleRegister(e, data)
    }

    handleCloseImportModalRequest = (e) => {
        console.log('CloseImportModalRequest', e)
        this.setState({
            isImportModalClosed: e
        })
    }



    render() {

        return (
            <div className={this.props.isRelated ? '' : ''}>




                <section className="bg-white cardWrapper outerCardWrapper">

                    <TableContent
                        ref={this.tableRef}
                        data={this.props.data}
                        formClassName={this.props.formClassName}
                        columnProps={this.props.columnProps}
                        columns={this.props.columns}
                        postComment={this.props.postComment}
                        formFields={this.props.formFields}
                        getRecord={this.getRecord}
                        createRecords={this.createRecords}
                        deleteRecord={this.deleteRecord}
                        bulkInsert={this.bulkInsert}
                        bulkDelete={this.bulkDelete}
                        handOnlineSave={this.handOnlineSave}
                        handleFresh={this.handleFresh}
                        handleFilter={this.handleFilter}
                        searchString={this.state.searchString}
                        handleGlobalSearch={this.handleSelect}
                        formRelated={this.props.formRelated}
                        relatedList={this.props.relatedList}
                        filter_dates={this.props.filter_dates}
                        triggered={this.state.triggered}
                        isRowClickable={this.props.isRowClickable}
                        isRowSelectable={this.props.isRowSelectable}
                        selected_row={this.props.selected_row}
                        textAlign={this.props.textAlign}
                        userPermission={this.props.userPermission}
                        setSelectedRows={this.props.setSelectedRows}
                        relatedObject={this.props.relatedObject}
                        relatedId={this.props.relatedId}
                        isChat={this.props.isChat}
                        isRelated={this.props.isRelated}
                        isAttachments={this.props.isAttachments}
                        hide_search={this.props.hide_search}
                        isRowSelection={this.props.isRowSelection}
                        isResetPassword={this.props.isResetPassword}
                        handleRegister={this.handleRegister}
                        expandedRowRender={this.props.expandedRowRender}
                        getFormRef={this.getFormRef}
                        readonly={this.props.readonly}
                        customButton={this.props.customButton}
                        isImportModalClosed={this.state.isImportModalClosed}
                        onCloseImportModalRequest={this.handleCloseImportModalRequest}
                        isRowHover = {this.props.isRowHover}
                        isAdditionalForm={this.props.isAdditionalForm}
                        renderAdditionalForm={this.props.renderAdditionalForm}
                        scroll={this.state.scroll}
                        pageHeader = {  !this.props.relatedList ?<PageHeader
                            data={this.props.data}
                            formFields={this.props.formFields}
                            createRecords={this.createRecords}
                            columnProps={this.props.columnProps}
                            handleFresh={this.handleFresh}
                            handleSelect={this.handleSelect}
                            filter_dates={this.state.filter_dates}
                            setFilterDates={this.setFilterDates}
                            hide_date={this.props.hide_date}
                            hide_search={this.props.hide_search}
                            hide_imports={this.props.hide_imports}
                            setFilters={this.setFilters}
                            renderFilter={this.props.renderFilter}
                            resetFilters={this.props.resetFilters}
                            filterHasValue={this.props.filterHasValue}
                            userPermission={this.props.userPermission}
                            isHideRefreshNews={this.props.isHideRefreshNews}
                            hid_new={this.props.hid_new}
                            filterDescription={this.props.filterDescription}
                            renderSearches={this.props.renderSearches}
                            formClassName={this.props.formClassName}
                            getFormRef={this.getFormRef}
                            onCloseImportModalRequest={this.handleCloseImportModalRequest}
                        />:'' }
                        defaultDisplayColumns={this.props.defaultDisplayColumns}
                    />

                </section>
            </div>
        );
    }

}


const mapStateToProps = state => {
    return {
        loading: state.EditableTable.load
    }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(EditableTable);


