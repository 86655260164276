import React, {Component, Suspense} from 'react';
import actions from "@redux/EditableTable/actions";
import {connect} from "react-redux";
import enUSIntl from "antd/lib/locale/en_US";
// import ProTable from "@ant-design/pro-table";
import _ from "lodash";
import Space from "antd/es/space";
import {Button, ConfigProvider, Popconfirm} from "antd";
import {Spinner} from "@components/Spinner";
import {EditableProTable} from "@ant-design/pro-table";

class FacilityBenchmarkTable extends Component {

    actionRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            isImportCSVModal: false,
            isNewModalOpen: false,
            searchString: null,
            filter_dates: [null, null],
            editableKeys: [],
            visible: false,
            isOutSideVisible: false,
            data: [],
            valueChanged: false,
            newValues: [],
            open: true
        }

        this.box = React.createRef();
        this.box2 = React.createRef();
    }

    Query = () => {
        this.props.handleGlobalSearch('');
        let state = Object.keys(this.state).filter(e => e.startsWith('Search'));
        let query = [];
        for (let i = 0; i < state.length; i++) {
            query += state[i].split('_')[1] + '=' + this.state[state[i]] + '&'
        }
        // console.log('query', query)
        this.props.handleFilter(query)
        this.actionRef.current.reload();
    }

    handleReset = (clearFilters) => {
        clearFilters();
        let state = Object.keys(this.state).filter(e => e.startsWith('Search'));
        for (let i = 0; i < state.length; i++) {
            this.setState({
                [state[i]]: '',
            })
        }
        this.Query()
    };

    handleFresh = (e) => {
        // console.log('refresh')
        this.props.handleGlobalSearch('')
        this.setState({
            searchString: null
        }, function () {
            this.actionRef.current.reload();
        })

    }


    handleOutSideConfirm = async (e) => {

        await this.handOnlineSave(_.get(this.state.newValues, 'id'), this.state.newValues);
        this.setState({
            editableKeys: [],
            isOutSideVisible: false
        })
        this.setState({
            open: false
        }, function () {
            this.setState({
                open: true
            })
        })
    }


    onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        //when selected, disable edit
        this.setState({selectedRowKeys, editableKeys: []});
    };


    setEditableRowKeys = (e, row) => {
        console.log('editableKeys', e, row)
        this.setState({
            editableKeys: e
        })
    }

    handleValueClicked = (record, recordList) => {
        console.log('handleValueClicked1', record)
        let newValuesdata = this.state.data


        let newValues = record;
        let oldValues = this.state.data.filter(e => e.id === record.id)[0];


        let result = false;
        if (oldValues) {

            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].id === record.id) {
                    newValuesdata[i] = record
                }
            }

            for (let i = 0; i < Object.keys(oldValues).length; i++) {
                for (let j = 0; j < Object.keys(newValues).length; j++) {
                    if (oldValues[Object.keys(oldValues)[i]] === newValues[Object.keys(newValues)[j]] &&
                        Object.keys(oldValues)[i] === Object.keys(newValues)[j]
                    ) {
                        result = true;
                    }
                }
            }
        } else {

            let oldValuesData = this.state.data.filter(e => e.id !== record.id);
            newValuesdata = [...oldValuesData, record]
            if (this.state.data.filter(e => e.id === record.id).length === 0) {
                result = true;
            }
        }
        this.setState({
            valueChanged: result,
            newValues: record,
            // data: newValuesdata,
            editableKeys: [record.id]
        })
    }

    refresh = (needRequest = true) => {
        this.setState({
            needRequest,
        }, this.actionRef.current.reload)
    };

    render() {

        let oldcolumns = this.props.columns;
        let columns = [];
        for (let i = 0; i < oldcolumns.length; i++) {

            let col = []

            col = {
                ...oldcolumns[i],
            }

            columns.push(col)

        }


        let params = [
            this.state.searchString,
        ]


        return (
            <Suspense fallback={<Spinner/>}>
                {this.state.open ? <div className={'editable'} ref={this.box}>
                    <ConfigProvider locale={enUSIntl}>
                        <Popconfirm
                            title="You have unsaved changes, do you want to save them"
                            visible={this.state.isOutSideVisible}
                            onConfirm={() => this.handleOutSideConfirm()}
                            onCancel={() => this.handleOutSideCancel()}
                        >
                            <EditableProTable
                                value={this.state.data}
                                showSorterTooltip={false}
                                recordCreatorProps={false}
                                className={' table-auto common-table ' + this.props.textAlign}
                                rowClassName={(record) => this.props.selected_row === record.id ||
                                (_.get(record, 'facility_id') && this.props.selected_row === _.get(record, 'facility_id')) ? 'selected_row' : ''}
                                columns={columns}
                                actionRef={this.actionRef}
                                // scroll={{ x: 1300 }}
                                params={params}
                                request={async (params = {}, sort, filter) => {
                                    let query = 'page=' + params.current + '&page_size=' + params.pageSize + '&';

                                    let state = Object.keys(this.state) ? Object.keys(this.state).filter(e => e.startsWith('Search')) : [];

                                    for (let i = 0; i < state.length; i++) {
                                        query += state[i].split('_')[1] + '=' + this.state[state[i]] + '&'
                                    }

                                    let result = await this.props.handleFilter(query);
                                    let result_data = _.get(result, 'data', []);

                                    this.setState({
                                        data: result_data,
                                    })

                                    this.props.getRecord(result_data)
                                    return result
                                }}
                                rowSelection={
                                    {
                                        selections: true,
                                        onChange: this.onSelectChange,
                                    }
                                }
                                tableAlertRender={(e) => {
                                    //when row is selected
                                    this.props.SelectedRows(e.selectedRowKeys)

                                    return <Space size={24}>
                                      <span>
                                        Selected {e.selectedRowKeys.length}

                                      </span>
                                    </Space>
                                }}
                                tableAlertOptionRender={(e) => {
                                    let rowIds = _.get(e, 'selectedRowKeys', []);
                                    let rows = _.get(e, 'selectedRows', []);

                                    if (!rows[0]) {
                                        rows = []
                                        if (rowIds) {
                                            rowIds.map(e => {
                                                return rows.push(e)
                                            })
                                        } else {
                                            rows = []
                                        }
                                    }

                                    // console.log('rows', exportRow)

                                    return (
                                        <Space size={16}>

                                            <Button type={'primary'} onClick={() => this.showPopconfirm()}>
                                                Query
                                            </Button>

                                        </Space>
                                    );
                                }}
                                rowKey="id"
                                scroll={{x: 800}}
                                toolBarRender={false}
                                pagination={{
                                    pageSizeOptions: [10, 20, 50, 100, 1000],
                                    showQuickJumper: true,
                                    pageSize: 24,
                                    showTotal: (total, range) => (
                                        <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
                                    ),
                                }}


                            />
                        </Popconfirm>


                    </ConfigProvider>
                </div> : ''}
            </Suspense>
        );
    }
}


const mapStateToProps = state => {
    return {
        isNewModalClosed: state.EditableTable.isNewModalClosed,
        isImportModalClosed: state.EditableTable.isImportModalClosed,
        loading: state.EditableTable.load,
        drawer: state.EditableTable.drawer,
        id: state.EditableTable.id,
        facility_id: _.get(state.Facility, 'selected.Id'),
        selected_projectId: _.get(state.Project, 'selectedProject.id')
    }
}
const mapDispatchToProps = dispatch => {
    return {
        CloseModalRequest: (e) => dispatch(actions.CloseModalRequest(e)),
        CloseImportModalRequest: (e) => dispatch(actions.CloseImportModal(e)),
        OpenDrawer: (e) => dispatch(actions.OpenDrawer(e)),
        SelectedRows: (ids) => dispatch(actions.SelectedRows(ids))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(FacilityBenchmarkTable);
