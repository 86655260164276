import React, { useRef, useEffect } from 'react';
import { Area } from '@antv/g2plot';
import { DataView } from "@antv/data-set";
import _ from "lodash";
import LayoutContent from "@components/utility/layoutContent";
import moment from "moment";
import { ThreeDecimalFormat, TwoDecimalFormat } from "@components/Format";

const LineChart = (props) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (props.data) {
            draw(props);
        }
        return () => {
            // Cleanup if necessary
        };
    }, [props.data, props.selected]);

    const draw = (props) => {
        console.log('draw')
        const chartDom = chartRef.current;
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
        const dv = new DataView().source(_.get(props, 'data', []));
        dv.transform({
            type: 'sort-by',
            fields: [props.x], // 根据指定的字段集进行排序，与lodash的sortBy行为一致
            order: 'ASC', // 默认为 ASC，DESC 则为逆序
        });

        const data = dv.rows.map(d => ({
            Id: d['id'],
            Price: ThreeDecimalFormat(d[props.y[0]] / (d[props.y[1]] ? d[props.y[1]] : 1)) * 1,
            Month: moment(d[props.x]).format('YY-MMM'),
        }));

        // console.log('linechart', data)
        const line = new Area(chartDom, {
            data,
            xField: 'Month',
            yField: 'Price',
            height: props.height,
            color: 'green',
            yAxis: {
                title: false,
                nice: true,
                line: {
                    style: {
                        stroke: '#aaa',
                    },
                },
                label: {
                    formatter: (text, item) => ('$' + TwoDecimalFormat(text)),
                },
            },
            areaStyle: () => {
                return {
                    fill: 'l(270) 0:#ffffff 0.5:#86C7C0 1:green',
                };
            },
            point: {
                size: 2,
                color: 'green',
                style: {
                    stroke: 'green',
                    lineWidth: 2,
                },
            },
            tooltip: {showMarkers: false},
            interactions: [{type: 'marker-active'}],
        });

        line.render();
        line.on('element:click', (e) => {
            let data = _.get(e, 'data.data');
            onSelect(data)
        });
        // line.on('tooltip:change', (e) => {
        //     console.log('changed', e)
        //     let data = _.get(e, 'data.items[0].data');
        //     this.onSelect(data)
        // });
        line.update({
            point: {
                style: (val) => {
                    let isSelected = _.size(props.selected) > 0 ? props.selected.includes(val.Month) : false

                    if (isSelected) {
                        return {
                            fill: 'Orange',
                            stroke: 'Orange',
                            // lineWidth: 2,
                        }
                    } else {
                        return {
                            fill: 'green',
                            stroke: 'green',
                            // lineWidth: 2,
                        }
                    }
                }

            },

        })

        chartInstanceRef.current = line;
    };

    const onSelect = (e) => {
        let Id = _.get(e, 'Month')
        props.handleChartSelect(Id)
    };

    return (
        <LayoutContent className={'outerCardWrapper'}>
            <div className={'small-title pdfChart_Title'}>{props.title}</div>
            <div ref={chartRef}/>
        </LayoutContent>
    );
};

export default LineChart;
