import {Button, DatePicker, Form} from "antd";
import React, {useState} from "react";
import {CloseCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {api} from "@redux/api";
import moment from "moment";

export const StationTemperatureFilters = (props) => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [loading, setLoading] = useState(false);
    const formRef = React.useRef()


    function handleSubmit(e) {
        console.log('submit', e)
        props.handleFilter(e)
    }

    const startDateFilter = () => {
        return (
            <DatePicker
                allowClear
                placeholder="Start Date"
                value={startDate}
                getPopupContainer={trigger => trigger.parentNode}
                onChange={setStartDate}
            />
        )
    }

    const endDateFilter = () => {
        return (
            <DatePicker
                allowClear
                placeholder="End Date"
                value={endDate}
                getPopupContainer={trigger => trigger.parentNode}
                onChange={setEndDate}
            />
        )
    }

    function handleClearButtonClick() {
        formRef.current.setFieldsValue({
            startDate: null,
            endDate: null,
        })
        props.handleClearButtonClick();
    }

    function handleRunFlow() {
        const yearPicker = moment(year).format('YYYY');
        const monthPicker = moment(month).format('M');
        setLoading(true);
        api.run_station_playwright(yearPicker.toString(), monthPicker.toString()).then(
            response => {
                // console.log('create', response);
                props.handleRefresh();
                setLoading(false);
                return response.data
            }
        ).catch(error => {
            setLoading(false);
        });
    }

    return <div className={'flex items-center flew-wrapper'}>

        <Form
        id="form_filter"
        layout="horizontal"
        onFinish={handleSubmit}
        ref={formRef}
        style={{background: '#eee8e8'}}
    >

        <Form.Item name={'startDate'} className={'md:w-48 lg:w-48 sm:w-full'}>
            {startDateFilter()}
        </Form.Item>

        <Form.Item name={'endDate'} className={'md:w-48 lg:w-48 sm:w-full'}>
            {endDateFilter()}
        </Form.Item>

        <Form.Item>
            <div className={'flex justify-center'}>

                <Button type="primary" className={'mr-2'} icon={<QuestionCircleOutlined/>}
                        htmlType="submit">Query</Button>
                <Button type="" icon={<CloseCircleOutlined/>} onClick={handleClearButtonClick}>Clear</Button>
            </div>
        </Form.Item>
    </Form>

        <Form
            id="form_filter"
            layout="horizontal"
            ref={formRef}
            style={{background: '#eee8e8', marginLeft: '2rem'}}
        >
            <Form.Item name={'Year'} className={'md:w-48 lg:w-48 sm:w-full'}>
                <DatePicker
                    allowClear
                    placeholder="Year"
                    picker={'year'}
                    value={year}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={setYear}
                />
            </Form.Item>

            <Form.Item name={'Month'} className={'md:w-48 lg:w-48 sm:w-full'}>
                <DatePicker
                    allowClear
                    placeholder="Month"
                    value={month}
                    picker={'month'}
                    getPopupContainer={trigger => trigger.parentNode}
                    onChange={setMonth}
                />
            </Form.Item>

            <Form.Item name={''} className={'md:w-48 lg:w-48 sm:w-full'}>
                <Button type="primary" loading={loading} icon={<i className="fas fa-laptop-code pr-3"></i>} onClick={handleRunFlow}>Run Flow</Button>
            </Form.Item>
        </Form>
    </div>

}
