import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';

export const ScenarioProps = {
    title: 'Scenario',
    name: 'Scenario',
    description: '',

}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}

export const ScenarioColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        edit: false,
        search: false,
        importIgnore: true,
        hideInTable: true,
        width: '4%',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        edit: true,
        sorter: (a, b) => a.name?.localeCompare(b.name),
        render: (text, record, _, action) => (
            <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
        ),
    },
    {
        title: 'Facility',
        dataIndex: 'facility',
        key: 'facility',
        edit: true,
        sorter: (a, b) => a.facility?.name?.localeCompare(b.facility?.name),
        render: (facility) => facility?.name,
        formItemProps: {},
    },
    {
        title: 'Baseline Start Date',
        dataIndex: 'baseline_start_date',
        key: 'baseline_start_date',
        edit: true,
        sorter: (a, b) => a.baseline_start_date?.localeCompare(b.baseline_start_date),
        formItemProps: {},
    },
    {
        title: 'Baseline End Date',
        dataIndex: 'baseline_end_date',
        key: 'baseline_end_date',
        edit: true,
        sorter: (a, b) => a.baseline_end_date?.localeCompare(b.baseline_end_date),
        formItemProps: {},
    },
    {
        title: 'Post Start Date',
        dataIndex: 'post_start_date',
        key: 'post_start_date',
        edit: true,
        sorter: (a, b) => a.post_start_date?.localeCompare(b.post_start_date),
        formItemProps: {},
    },
    {
        title: 'Post End Date',
        dataIndex: 'post_end_date',
        key: 'post_end_date',
        edit: true,
        sorter: (a, b) => a.post_end_date?.localeCompare(b.post_end_date),
        formItemProps: {},
    },
    {
        title: 'Electricity Price',
        dataIndex: 'electricity_price',
        key: 'electricity_price',
        edit: true,
        sorter: (a, b) => a.electricity_price - b.electricity_price,
        formItemProps: {},
    },
    {
        title: 'Gas Price',
        dataIndex: 'gas_price',
        key: 'gas_price',
        edit: true,
        sorter: (a, b) => a.gas_price - b.gas_price,
        formItemProps: {},
    },
    {
        title: 'Price Type',
        dataIndex: 'price_type',
        key: 'price_type',
        edit: true,
        sorter: (a, b) => a.price_type?.localeCompare(b.price_type),
        formItemProps: {},
    },
    {
        title: 'Breakpoint 0',
        dataIndex: 'breakpoint0',
        key: 'breakpoint0',
        edit: true,
        formItemProps: {},
    },
    {
        title: 'Breakpoint 1',
        dataIndex: 'breakpoint1',
        key: 'breakpoint1',
        edit: true,
        formItemProps: {},
    },
    {
        title: 'Breakpoint 2',
        dataIndex: 'breakpoint2',
        key: 'breakpoint2',
        edit: true,
        formItemProps: {},
    },
    {
        title: 'Breakpoint 3',
        dataIndex: 'breakpoint3',
        key: 'breakpoint3',
        edit: true,
        formItemProps: {},
    },
    {
        title: 'Segment',
        dataIndex: 'segment',
        key: 'segment',
        edit: true,
        formItemProps: {},
    },

];

