import React, {useEffect, useRef, useState} from 'react';
import ProTable from '@ant-design/pro-table';

const withResizableColumns = (WrappedTableComponent) => {
    const ResizableColumnsComponent = (props) => {

        const resizerRefs = useRef([]);
        const [columnWidths, setColumnWidths] = useState({});

        useEffect(() => {
            resizerRefs.current.forEach((resizer, index) => {
                let isResizing = false;
                let startWidth = 0;
                let startX = 0;

                const handleMouseDown = (e) => {


                    e.stopPropagation();
                    isResizing = true;

                    e.target.style.borderRight = 'none'; // hide the default right border
                    e.target.classList.add('resizing');

                    document.querySelectorAll('.ant-table-thead th').forEach(th => {
                        th.style.pointerEvents = 'none';
                    });

                    // trnaverse up to find the th element that contains the resizer that was clicked on.
                    const th = e.target.closest('th');
                    startWidth = th.offsetWidth;
                    //where the mouse started
                    startX = e.clientX;

                    document.addEventListener('mousemove', handleMouseMove);
                    document.addEventListener('mouseup', handleMouseUp);
                };

                const handleMouseMove = (e) => {
                    e.stopPropagation();


                    if (!isResizing) return;
                    //e.clientX: This provides the horizontal coordinate within the viewport of the mouse pointer when the event occurred.
                    const movement = e.clientX - startX;
                    const dataIndex = props.columns[index].dataIndex;
                    setColumnWidths((prev) => ({
                        ...prev,
                        [dataIndex]: startWidth + movement
                    }));

                };

                const handleMouseUp = (e) => {
                    e.stopPropagation();
                    isResizing = false;

                    document.removeEventListener('mousemove', handleMouseMove);
                    document.removeEventListener('mouseup', handleMouseUp);

                    document.querySelectorAll('.resizer').forEach(resizer => {
                        resizer.classList.remove('resizing');
                    });

                    setTimeout(() => {
                        document.querySelectorAll('.ant-table-thead th').forEach(th => {
                            th.style.pointerEvents = '';
                        });
                    }, 100);
                };

                if (resizer){
                    resizer.addEventListener('mousedown', handleMouseDown);
                }

                return () => {
                    if (resizer) {
                        resizer.removeEventListener('mousedown', handleMouseDown);
                    }
                };
            });
        }, [props.columns]);

        const columnsWithResizer = props.columns.map((col, index) => ({
            ...col,
            width: columnWidths[col.dataIndex] || col.width,
            title: (
                <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                    {col.title}
                    <div
                        ref={(el) => {
                            resizerRefs.current[index] = el
                        }}
                        className="resizer"
                        style={{
                            cursor: 'col-resize',
                            borderRight: '4px solid transparent',
                            height: '100%',
                            marginLeft: '8px',
                            width: '15px',
                            userSelect: 'none',
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                        onMouseOver={() => {
                            if (resizerRefs.current && resizerRefs.current[index]) {
                                resizerRefs.current[index].style.borderRight = '4px solid blue';
                            }
                        }}
                        onMouseOut={() => {
                            if (resizerRefs.current && resizerRefs.current[index]) {
                                resizerRefs.current[index].style.borderRight = '4px solid transparent';
                            }
                        }}

                    />
                </div>
            ),
        }));

        return <WrappedTableComponent {...props} columns={columnsWithResizer}/>;

    };
    return React.memo(ResizableColumnsComponent);
};

const ResizableProTable = withResizableColumns(ProTable);

export default ResizableProTable;
