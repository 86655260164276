import React, {useEffect, useRef} from "react";
import LayoutContent from "@components/utility/layoutContent";
import {Scatter} from '@antv/g2plot';

const BubblePlot = (props) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const draw = () => {
        const chartDom = chartRef.current;
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        const chart = new Scatter(chartDom, {
            data: props.data,
            xField: 'xAxis',
            yField: 'yAxis',
            sizeField: 'size',
            colorField: 'isCurrent',
            color: (datum) => {
                return datum.isCurrent ? 'orange' : '#1890ff';
            },
            pointSize: [2, 20],
            height: 400,
            xAxis: {
                title: {text: props.xLabel},
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },

            yAxis: {
                title: {text: props.xLabel},
                nice: true
            },
            pointStyle: {
                lineWidth: 1,
                strokeOpacity: 1,
                fillOpacity: 0.5,
                opacity: 0.65,
            },
            shape: (datum) => {
                // console.log('datum:', datum)
                
                return 'circle';
            },

            size: [4, 65],
            style: {
                type: (type) => {
                    console.log('type', type);
                    return {
                        lineWidth: 1,
                        strokeOpacity: 1,
                        fillOpacity: 0.5,
                        opacity: 0.65,
                        // stroke: props.colors[type]
                    };
                },
            },
            legend: {
                itemName: {
                    style: {
                        fill: '#000',
                    },
                    formatter: (name, item) => {
                        console.log('name', name, item);
                        let new_item = item.name === 'true' ? 'Current' : 'Other';
                        return new_item;
                    },
                },
            }
        });

        chart.render();
        chartInstance.current = chart;

    };

    useEffect(() => {
        draw(props)
    }, [props.data]);
    console.log('BubblePlot')
    return (
        <LayoutContent className={"outerCardWrapper"}>
            <div style={{marginBottom: '1rem'}}>
                <div className={'small-title_piechart'}>{props.title}</div>

            </div>
            <div ref={chartRef}/>
        </LayoutContent>
    );
}

export default BubblePlot;
