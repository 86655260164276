import React, {useEffect, useState} from 'react';
import {Button, Input, Space, Table} from "antd";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";


const FunctionalFormFieldsEditor = (props) => {

    const {value, formRef} = props;
    console.log('value', value, formRef);
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(value ? value : []);
    }, [value])

    const handleInput = (key, index, e) => {

        const updatedData = [...data];

        updatedData[index][key] = e.target.value;

        formRef.setFieldsValue({
            fields: updatedData
        })
    };

    const handleDelete = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
        formRef.setFieldsValue({
            fields: newData
        })
    }

    const handleAdd = (index) => {
        const newData = [...data];
        const newRecord = {
            id: Date.now(), // For uniqueness, using the current timestamp (or you can use a more sophisticated ID generation method)
            name: "",
            type: "",
            description: ""
        };
        newData.splice(index + 1, 0, newRecord);
        setData(newData);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, _, index) => (
                <Input
                    defaultValue={text}
                    onChange={(e) => handleInput('name', index, e)}
                />
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text, _, index) => (
                <Input
                    defaultValue={text}
                    onChange={(e) => handleInput('type', index, e)}
                />
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, _, index) => (
                <Input
                    defaultValue={text}
                    onChange={(e) => handleInput('description', index, e)}
                />
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record, index) => (
                <Space size="middle">
                    <Button type={'primary'} icon={<PlusCircleOutlined/>} shape={'circle'}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleAdd(index);
                            }}
                    ></Button>
                    <Button icon={<MinusCircleOutlined/>} shape={'circle'}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(index);
                            }}
                    ></Button>
                </Space>
            )
        },
        {
            title: 'key',
            dataIndex: 'id',
            key: 'id',
            width: '0%',
            className: 'hidden'
        },
    ];

    return (
        <div className={'mt-5'}>
            <Table columns={columns} dataSource={data} pagination={false} rowKey="id"/>
            <Button type={'primary'}
                    onClick={() => setData([...data, {id: Date.now(), name: '', type: '', description: ''}])}>
                Add New Field
            </Button>
        </div>
    )
}

export default FunctionalFormFieldsEditor;
