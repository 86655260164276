import Input from "antd/es/input";
import React from "react";

import {InputNumber} from "antd";

export const StationTemperatureFormFields = [
    {
        title: 'key',
        name: 'id',
        order: 0,
        visible: false,
        length: 0,
        type: 'text',
    },
    {
        title: 'Address',
        name: 'address',
        order: 2,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: false,
        render: <Input autoComplete="off"/>,
    }, {
        title: 'Source',
        name: 'source',
        order: 2,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: false,
        render: <Input autoComplete="off"/>,
    },
    {
        title: 'Date',
        name: 'date',
        order: 3,
        visible: true,
        length: 'w-full',
        type: 'text',
        required: false,
        render: <Input autoComplete="off"/>,
    },
    {
        title: 'Temperature',
        name: 'temperature',
        order: 4,
        visible: true,
        length: 'w-full',
        type: 'number',
        required: false,
        render: <InputNumber autoComplete="off"/>,
    }
]
