import React, {useEffect, useRef, useState} from 'react';
import Timeline, {DateHeader, TimelineHeaders, TimelineMarkers, TodayMarker} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import {Input, Modal} from 'antd';
import styled from "styled-components";
import SidebarHeader from "react-calendar-timeline/lib/lib/headers/SidebarHeader";

const TimelineItem = styled.div`
  position: relative;
  &.on-time {
    background-color: blue !important;
  }

  &.past-due {
    background-color: orange !important;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: ${props => props.completion}%;
    height: 5px;
    background-color: darkgreen;
  }
  
`;


const GanttChart = ({tasks, minTaskDate, maxTaskDate, project_name}) => {
    console.log('ganttchart', tasks, minTaskDate, maxTaskDate)
    const [items, setItems] = useState([]);
    const [openGroups, setOpenGroups] = useState({});
    const [newSubtaskName, setNewSubtaskName] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleGroup = (groupId) => {
        setOpenGroups((prevOpenGroups) => ({
            ...prevOpenGroups,
            [groupId]: !prevOpenGroups[groupId],
        }));
    };

    const newGroups = [];
    tasks.forEach((task) => {
        // Only add root tasks to the group
        newGroups.push({
            id: task.id,
            title: (
                <div
                    onClick={() => handleToggleGroup(task.id)}
                    style={{cursor: 'pointer'}}
                >
                    {task.children.length > 0 ? (openGroups[task.id] ? '[-]' : '[+]') : ""} {task.name}
                </div>
            ),
            root: true,
        });

        // Add each subtask as a subgroup if the parent task is open
        if (openGroups[task.id]) {
            task.children.forEach((subtask) => {
                newGroups.push({
                    id: subtask.id,
                    title: <span className="pl-8">{subtask.name}</span>,
                    parent: task.id,
                });
            });
        }
    });

    useEffect(() => {
        const newItems = tasks.flatMap((task) => {
            const taskItems = [
                {
                    id: task.id,
                    group: task.id,
                    title: task.name,
                    start_time: task.start_date ? new Date(task.start_date) : null,
                    end_time: task.end_date ? new Date(task.end_date) : null,
                    canMove: true,
                    canResize: 'both',
                    isPastDue: false, // Set the initial value for the task
                    completion: task.completion || 0,
                    color: 'blue'
                },
            ];

            const subtaskItems = openGroups[task.id]
                ? task.children.map((subtask) => {
                    const currentTime = new Date().getTime();
                    const endDateTime = subtask.end_date ? new Date(subtask.end_date).getTime() : currentTime;
                    const isPastDue = endDateTime < currentTime;
                    const completion = subtask.completion || 0;

                    return {
                        id: subtask.id,
                        group: subtask.id,
                        title: subtask.name,
                        start_time: subtask.start_date ? new Date(subtask.start_date) : null,
                        end_time: subtask.end_date ? new Date(subtask.end_date) : null,
                        canMove: true,
                        canResize: 'both',
                        isPastDue,
                        completion,
                        color: 'orange'
                    };
                })
                : [];

            return [...taskItems, ...subtaskItems];
        });

        setItems(newItems);
    }, [tasks, openGroups]);


    const itemRenderer = ({item, timelineContext, itemContext, getItemProps, getResizeProps}) => {
        const {left: leftResizeProps, right: rightResizeProps} = getResizeProps();
        const currentTime = new Date().getTime();
        const totalTime = item.end_time - item.start_time;
        const pastDueTime = currentTime - item.start_time;
        const pastDuePercentage = (pastDueTime / totalTime) * 100 + 1;

        let isDragging = false;
        return (
            <TimelineItem
                completion={item.completion}
                {...getItemProps({
                    style: {
                        ...itemContext.style,
                        background: `linear-gradient(90deg, ${item.color} ${pastDuePercentage}%, orange ${pastDuePercentage}%, orange 100%)`,
                        borderColor: item.color,
                        color: 'white',
                    },
                    onMouseDown: () => {
                        isDragging = false;
                    },
                    onMouseMove: () => {
                        isDragging = true;
                    },
                    onMouseUp: (e) => {
                        e.stopPropagation();
                    }
                })}
            >
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

                <div
                    style={{
                        height: itemContext.dimensions.height,
                        overflow: 'hidden',
                        paddingLeft: 3,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {itemContext.title}
                </div>

                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
            </TimelineItem>
        );
    };


    const timelineRef = useRef();

    const handleItemMove = (itemId, dragTime, newGroupOrder) => {
        setItems((prevItems) =>
            prevItems.map((item) =>
                item.id === itemId
                    ? {
                        ...item,
                        start_time: dragTime,
                        end_time: dragTime + (item.end_time - item.start_time),
                        group: newGroupOrder,
                    }
                    : item
            )
        );
    };

    const handleItemResize = (itemId, time, edge) => {
        setItems((prevItems) =>
            prevItems.map((item) =>
                item.id === itemId
                    ? {
                        ...item,
                        start_time: edge === 'left' ? time : item.start_time,
                        end_time: edge === 'left' ? item.end_time : time,
                    }
                    : item
            )
        );
    };


    const handleCreateSubtask = () => {
        setIsModalVisible(true);
    };

    const handleModalOk = () => {
        const newSubtask = {
            id: `subtask_${items.length + 1}`,
            group: 'task_1', // Replace 'task_1' with the actual group ID
            title: newSubtaskName,
            start_time: null, // Set the start time to null
            end_time: null, // Set the end time to null
            canMove: true,
            canResize: 'both',
        };

        setItems((prevItems) => [...prevItems, newSubtask]);
        setNewSubtaskName('');
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setNewSubtaskName('');
        setIsModalVisible(false);
    };

    const handleItemDoubleClick = (itemId) => {
        const itemToUpdate = items.find((item) => item.id === itemId);
        if (itemToUpdate) {
            setIsModalVisible(true);
            setNewSubtaskName(itemToUpdate.title);
        }
    };


    return (
        <div className={'p-2'}>

            <Modal
                title="Create Subtask"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Input
                    placeholder="Enter subtask name"
                    value={newSubtaskName}
                    onChange={(e) => setNewSubtaskName(e.target.value)}
                />
            </Modal>

            <Timeline
                ref={timelineRef}
                groups={newGroups}
                items={items}

                canvasTimeStart={minTaskDate}
                canvasTimeEnd={maxTaskDate}
                defaultTimeStart={minTaskDate}
                defaultTimeEnd={maxTaskDate}

                lineHeight={30}
                stackItems
                itemHeightRatio={0.8}
                canResize={({canResize}) => canResize}
                onItemMove={handleItemMove}
                onItemResize={handleItemResize}
                onItemDoubleClick={handleItemDoubleClick}
                itemRenderer={itemRenderer}
                canChangeGroup={false}
            >
                <TimelineHeaders>
                    <SidebarHeader>
                        {({getRootProps}) => {
                            return <div {...getRootProps()} className={'text-white'}>{project_name}</div>;
                        }}
                    </SidebarHeader>
                    <SidebarHeader>
                        {({getRootProps}) => {
                            return <div {...getRootProps()} className={'text-white'}>{project_name}</div>;
                        }}
                    </SidebarHeader>
                    <DateHeader unit="primaryHeader"/>
                    <DateHeader unit="month"/>
                    <DateHeader unit="week"/>
                </TimelineHeaders>
                <TimelineMarkers>
                    <TodayMarker/>
                </TimelineMarkers>
            </Timeline>
        </div>
    );
};

export default GanttChart;
