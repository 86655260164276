import React, {Component, Suspense} from 'react';
import {Column} from '@antv/g2plot';
import {Spinner} from "@components/Spinner";
import {TwoDecimalFormat} from "@components/Format";

class Histogram extends Component {

    chartRef = React.createRef();

    constructor(props) {
        super(props);

    }

    // componentDidMount() {
    //     this.draw(this.props)
    // }

    componentWillReceiveProps(nextProps) {
        this.chartRef.current.innerHTML = '';
        this.draw(nextProps)
    }

    draw = (props) => {
        const chartDom = this.chartRef.current;

        var data = props.data;
        var unit = props.unit;
        var type = props.type;
        var value = props.value;
        var animation = props.animation === undefined || props.animation === true ? true : false
        const columnPlot = new Column(chartDom, {
            data: data,
            xField: type,
            yField: value,
            height: 150,
            animation,
            // 设置圆角
            // columnStyle: {
            //     radius: [20,20,0,0]
            // },
            // label: {
            //     // 可手动配置 label 数据标签位置
            //     position: 'middle', // 'top', 'bottom', 'middle',
            //     // 配置样式
            //     style: {
            //         fill: '#FFFFFF',
            //         opacity: 0.6,
            //     },
            // },
            label: false,
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            color: this.props.color,
            tooltip: {
                customContent: function (x, data) {
                    // console.log('tooltip', data)
                    return `${data[0]?.title}:  ${TwoDecimalFormat(data[0]?.value)} ${unit}`;
                },
            },
        });
        
        columnPlot.render();
    }

    render() {
        return (
            <Suspense fallback={<Spinner/>}>
                <div ref={this.chartRef}/>
            </Suspense>
        );
    }
}

export default Histogram;
