import styled from "styled-components";

export const FileManagerWrapper = styled.div`
  tr.selected_row td:first-child:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 0.3rem;
    height: 100%;
    background: #1d4567;
  }

  table {
    table-layout: auto !important;
  }

  .ant-modal-body {
    height: 60vh !important;
  }

  .filemanager-table table {
    table-layout: auto !important;
  }
`
