import styled from "styled-components";

export const NationalBenchmarkStyles = styled.div`
  table {
    table-layout: fixed !important;
    padding: 1rem;
  }

  .title {
    padding: 0.5rem !important;
    font-weight: bold !important;
    font-size: 13px !important;
  }

  //.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  //  padding: 0 !important;
  //}

  .ant-divider-horizontal{
    padding: 0 !important;
  }

  .ant-collapse-content{
    padding: 0 !important;
  }

  .ant-collapse-content > .ant-collapse-content-box{
    padding: 0 !important;
  }
`
