import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import moment from "moment";

export const StationTemperatureProps = {
    title: 'StationTemperature',
    name: 'StationTemperature',
    description: '',

}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}


export const StationTemperatureColumns = [
    {
        title: 'key',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIgnore: true,
        hideInTable: true,
        width: '4%',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        search: false,
        edit: true,

        sorter: (a, b) => a.date?.localeCompare(b.date),
        render: (text, record) => {
            let date = moment(text).format('YYYY-MM-DD'); // Format the date using moment.js
            return date;
        },
        formItemProps: {},
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        search: false,
        edit: true,
        width: '20%',
        sorter: (a, b) => a.address?.localeCompare(b.address),
        formItemProps: {},
    },{
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        search: false,
        edit: true,
        width: '20%',
        sorter: (a, b) => a.source?.localeCompare(b.source),
        render: (text, record) => {
            let source = text?.split('https://www.wunderground.com/history/monthly/')[1];
            return <a href={text} target={'blank'} >{source}</a>;
        },
        formItemProps: {},
    },

    {
        title: 'Temperature',
        dataIndex: 'temperature',
        key: 'temperature',
        search: false,
        edit: true,
        sorter: (a, b) => a.temperature - b.temperature,
        formItemProps: {},
    },
    {
        title: 'Facility',
        dataIndex: 'facility_name',
        key: 'facility_name',
        search: false,
        edit: true,
        sorter: (a, b) => a.facility_name?.localeCompare(b.facility_name),
        formItemProps: {},
    },
    {
        title: '',
        valueType: 'option',
        width: '10rem',
        fixed: 'right',
        render: (text, record, _, action) => (
            <div className={'space-around'}>
                <a
                    key="editable"
                    onClick={() => (action?.startEditable?.(record.id))}
                >
                    <i className="fas fa-edit text-lg"></i>
                </a>
                <a onClick={(e) => {
                    e.stopPropagation();
                    return action ? renderDrawer(text, record.id) : ''
                }}>
                    <i className="fas fa-expand-arrows-alt text-lg"></i>
                </a>
            </div>
        ),
    },
];

