import {all} from 'redux-saga/effects';
import LoginSaga from "./auth/saga";
import ElectricitySage from "@redux/electricity/saga";
import GasSage from "@redux/gas/saga";
import WaterSage from "@redux/water/saga";
import WorkOrderSage from "@redux/workorder/saga";

export default function* rootSaga(getState) {
    yield all([
        LoginSaga(),
        ElectricitySage(),
        GasSage(),
        WaterSage(),
        WorkOrderSage()
    ]);
}
