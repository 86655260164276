import React, {Component} from 'react';
import _ from 'lodash'
import {connect} from "react-redux";

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {jsforceResponse} from "@/config/httpService";
import {CaretDownOutlined} from "@ant-design/icons";
import {api} from "@redux/api";
import actions from "@redux/project/actions";


class ProjectLookup2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: null,
            Contact: null,
            value: null
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.record
        })
        this.props.SelectedProject('')
    }

    handleFocus = async (e) => {

        const result1 = await api.filterProject('');
        const res1 = jsforceResponse(result1, '', false, true)
        let data = _.get(res1, 'data.results', []);
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i].name,
                value: data[i].name,
                id: data[i].id,
                record: data[i]
            })
        }
        this.setState({
            options
        })
    }

    handleSearch = async (e) => {
        let query = 'issue=' + e
        const result1 = await api.filterProject(query);
        const res1 = jsforceResponse(result1, '', false, true)
        let data = _.get(res1, 'data.results', []);
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i].name,
                value: data[i].name,
                id: data[i].id,
                record: data[i]
            })
        }
        this.setState({
            options
        })
    }

    handleSelect = (e, record) => {
        // console.log('handleSelect', e, record)

        this.setState({
            selected: record.record,
            value: e
        })
        this.props.SelectedProject(record)
    }

    render() {
        // console.log('Project', this.state.value)
        return (
            <AutoComplete
                style={{width: '100%'}}
                options={this.state.options}
                onFocus={this.handleFocus}
                onSelect={this.handleSelect}
                onSearch={this.handleSearch}
                value={this.state.value}
                dropdownMatchSelectWidth={false}
            >
                <Input size="medium" autosize={true}
                       autoComplete="off"

                       suffix={<CaretDownOutlined/>}/>
            </AutoComplete>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {
        SelectedProject: (e) => dispatch(actions.SelectedPROJECT(e))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectLookup2);
