import { createContext, useContext } from 'react';


const context = createContext();

export const usePdfContext = () => {
    return useContext(context)
}

const PdfProvider = context.Provider;

export {
    PdfProvider
}