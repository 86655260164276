import React, {Component, Suspense} from 'react';
import {Pie} from '@antv/g2plot';
import {Spinner} from "@components/Spinner";
import LayoutContent from "@components/utility/layoutContent";
import {NoDecimalFormat, SinglePercentFormat} from "@components/Format";
import {G2} from '@ant-design/plots';
import theme from "@/themeprovider/theme";
import _ from "lodash";
import styled from 'styled-components';

const ChartWrap = styled.div`
    .g2-html-annotation {
        opacity: 0;
    }
`

class PeiChart extends Component {

    chartRef = React.createRef();

    constructor(props) {
        super(props);

    }

    // componentDidMount() {
    //     this.draw(this.props)
    // }

    componentWillReceiveProps(nextProps) {
        this.chartRef.current.innerHTML = '';
        this.draw(nextProps)
    }

    // onChange = (e) => {
    //     let interval;
    //     if (e.target.value) {
    //         interval = e.target.value
    //     } else {
    //         interval = e
    //     }
    //     // console.log('interval', this.props)
    // }

    draw = (props) => {
        const chartDom = this.chartRef.current;

        const G = G2.getEngine('canvas');
        // console.log('data', props.data)

        const piePlot = new Pie(chartDom, {
            appendPadding: 10,
            data: props.data,
            angleField: 'value',
            colorField: 'type',
            height: props.height,
            radius: 0.8,
            innerRadius: 0.7,
            legend: false,

            color: (props.colors ? (e => {
                // console.log('e', e)
                if (props.colors && props.colors.hasOwnProperty(e.type)) {
                    return props.colors[e.type];
                }
            }) : theme.palette.color),
            label: {
                type: 'spider',
                labelHeight: 40,
                formatter: (data, mappingData) => {
                    const group = new G.Group({});
                    group.addShape({
                        type: 'circle',
                        attrs: {
                            x: 0,
                            y: 0,
                            width: 40,
                            height: 50,
                            r: 5,
                            fill: mappingData.color,
                        },
                    });
                    group.addShape({
                        type: 'text',
                        attrs: {
                            x: 10,
                            y: 8,
                            text: `${data.type}`,
                            fill: mappingData.color,
                        },
                    });
                    group.addShape({
                        type: 'text',
                        attrs: {
                            x: 0,
                            y: 25,
                            text: `${data.value}  (${SinglePercentFormat(data.percent)})`,
                            fill: 'rgba(0, 0, 0, 0.65)',
                            fontWeight: 700,
                        },
                    });
                    return group;
                },
            },
            annotations: [
                {
                    type: 'text',
                    position: ['50%', '42%'],
                    content: `Total`,
                    animate: true,
                    style: {
                        textAlign: 'center',
                        fontSize: 16,
                        fill: '#000',
                        fontWeight: 600,
                    },
                },
                {
                    type: 'text',
                    position: ['50%', '58%'],
                    content: `${Math.round(props.data.reduce((p, v) => p + v.value, 0))}`,
                    animate: true,
                    style: {
                        textAlign: 'center',
                        fontSize: 24,
                        fill: '#000',
                        fontWeight: 600,
                    },
                },
            ],
            meta: {
                value: {
                    formatter: (v) => `${NoDecimalFormat(v)}`,
                },
            },
            interactions: [{type: 'element-selected'}, {type: 'element-active'}],
            statistic: {
                title: {
                    offsetY: -4,
                },
                content: {
                    offsetY: 4,
                    style: {
                        fontSize: '32px',
                    },
                },
            },
        });

        piePlot.on('element:click', (e) => {

            let data = _.get(e, 'data.data');
            props.handleChartClick(data)
        });

        piePlot.render();
    }

    render() {
        return (
            <Suspense fallback={<Spinner/>}>

                <LayoutContent className={'outerCardWrapper'}>
                    <div className={'flex justify-between'} style={{borderBottom: '1px solid #e9edf4'}}>
                        <div className={'small-title pdfChart_Title'}>{this.props.title}</div>

                        {/*<Radio.Group buttonStyle="solid" onChange={this.onChange}*/}
                        {/*             style={{ marginRight: '0.5rem'}}>*/}
                        {/*    <Radio.Button value="0.1">Last Month</Radio.Button>*/}
                        {/*    <Radio.Button value="0.25">Last 3 Month</Radio.Button>*/}
                        {/*</Radio.Group>*/}
                    </div>
                    <ChartWrap ref={this.chartRef}/>
                </LayoutContent>
            </Suspense>
        );
    }
}

export default PeiChart;
