import React, {Component} from 'react';
import _ from 'lodash'
import {connect} from "react-redux";

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {jsforceResponse} from "@/config/httpService";
import {CaretDownOutlined} from "@ant-design/icons";
import {api} from "@redux/api";
import actions from "@redux/equipmentCategory/actions";


class EquipmentCategoryLookup extends Component {
    state = {
        value: this.props.value
    }

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: null
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        })
        this.props.SelectedEquipmentCategory('')
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    handleFocus = async (e) => {

        const result1 = await api.filterEquipmentCategory([]);
        const res1 = jsforceResponse(result1, '', false, true)
        let data = _.get(res1, 'data.results', []);
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i].name,
                value: data[i].name,
                id: data[i].id,
                record: data[i]
            })
        }
        this.setState({
            options
        })
    }

    handleSearch = async (e) => {
        let query = 'issue=' + e
        const result1 = await api.filterEquipmentCategory(query);
        const res1 = jsforceResponse(result1, '', false, true)
        let data = _.get(res1, 'data.results', []);
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i].name,
                value: data[i].name,
                id: data[i].id,
                record: data[i]
            })
        }
        this.setState({
            options
        })
    }

    handleSelect = (e, record) => {

        console.log('handleSelect', e, record)

        this.setState({
            selected: record.record,
            value: e
        })
        this.props.SelectedEquipmentCategory(record)

        this.props.formRef && this.props.formRef.setFieldsValue({
            type: e
        })
    }


    onChange = (e) => {
        this.setState({
            value: e
        })
    }

    render() {
        console.log('cateogryvalue', this.state.value, this.props.value)
        return (
            <AutoComplete
                style={{width: '100%'}}
                options={this.state.options}
                onFocus={this.handleFocus}
                onSelect={this.handleSelect}
                onSearch={this.handleSearch}
                value={this.state.value}
                dropdownMatchSelectWidth={false}
                onChange={this.onChange}
            >
                <Input size="medium" autosize={true}
                       autoComplete="none"
                       onFocus={this.handleFocus}
                       prefix={<div style={{color: 'red'}}>*</div>}
                       suffix={<CaretDownOutlined/>}/>
            </AutoComplete>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {
        SelectedEquipmentCategory: (e) => dispatch(actions.SelectedEquipmentCategory(e))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EquipmentCategoryLookup);
