import Input from "antd/es/input";
import React from "react";
import {store} from "@redux/store";

export const Equipment_CategoryRelated = []

export const Equipment_CategoryFormFields = [
    {
        title: 'key',
        name: 'id',
        order: 0,
        visible: false,
        length: 0,
        type: 'text',
    },
    {
        title: 'Name',
        name: 'name',
        order: 1,
        visible: true,
        length: "w-full",
        type: 'text',
        required: true,
        render:
            <Input
                autoComplete="off"
                list="equipmentcategory-suggestions"
                allowClear
            />,
        datalist: <datalist id="equipmentcategory-suggestions">
            {store.getState().EquipmentCategory.data.map(e => (
                <option key={e.name} value={e.name}/>
            ))
            }
        </datalist>

    },

    {
        title: 'Spec1',
        name: 'spec1',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Spec2',
        name: 'spec2',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Spec3',
        name: 'spec3',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Spec4',
        name: 'spec4',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Spec5',
        name: 'spec5',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Spec6',
        name: 'spec6',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Spec7',
        name: 'spec7',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Spec8',
        name: 'spec8',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Comment',
        name: 'comment',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input/>
    },

    // {
    //     title: 'Separator',
    //     name: 'Separator',
    // },
    {
        title: 'Created By',
        name: 'createdby_name',
        order: 17,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input disabled/>
    },

    {
        title: 'Created Date',
        name: 'created_date',
        order: 18,
        visible: true,
        length: "w-full",
        type: 'Date',
        render: <Input disabled/>
    },
    {
        title: 'Modified By',
        name: 'lastModified_name',
        order: 19,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input disabled/>
    },
    {
        title: 'Modified Date',
        name: 'modified_date',
        order: 20,
        visible: true,
        length: "w-full",
        type: 'Date',
        render: <Input disabled/>
    },

]
