import React, { useState } from "react";
import {
  Checkbox,
  Form,
  Radio,
  DatePicker,
  Modal,
  Tooltip,
  message,
  Button,
  Row,
  Col,
  Input,
} from "antd";
import { Visible } from "@/components/ui";
import { useBoolean, useUpdate } from "ahooks";
import PreView from "../preView";
import { FundViewOutlined } from "@ant-design/icons";
import { MenuActionsWrap } from "../components/MenuActions";
import { usePdfContext } from "../context";
import { SavingAnalysisForm } from "./SavingAnalysisForm";
import { SavingAnalysisCardIdMappings } from "../template/utility/savingAnalysis-chart";
import { PeridCardIdMappings } from "../template/utility/energy-consumption-chart";
import { ScenarioForm } from "./ScenarioForm";
import styled from "styled-components";

const CardItem = styled.div`
  background-color: #f8faff;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 
              -1px -1px 3px rgba(255, 255, 255, 0.5);
`

const CardWrap = styled.div`
  padding: 12px;
`

const { RangePicker } = DatePicker;

const PdfForm = () => {
  const [open, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean(false);

  const update = useUpdate();

  const [modalData, setModalData] = useState({
    type: "",
    data: {},
    title: ''
  });

  const { setPdfFormValue, goToLoadingStep, form } = usePdfContext();

  const { recentlyChecked, recentlyIndeterminate } = getRecentlyCheckedState(form)
  const { periodChecked, periodIndeterminate } = getPeriodCheckedState(form)
  const { savingAnalysisChecked, savingAnalysisIndeterminate } = getSavingAnalysisCheckedState(form)

  const onLabelClick = (type, title) => {
    const values = form.getFieldsValue();

    if (utilityFields.includes(type) && !values[type]) {
      message.warn("Please select a value");
      return;
    }
    setModalData({
      type: type,
      data: values,
      title
    });
    openModal();
  };

  const onFinish = () => {
    form.validateFields().then(({ period, ...values }) => {
      const formatValues = {};
      if (Array.isArray(period)) {
        formatValues.period = period.map((item) => item.format("YYYY-MM-DD"));
      }

      setPdfFormValue({
        ...values,
        ...formatValues,
      });
      goToLoadingStep();
    });
  };

  const onRecentlyCheckedChange = (e) => {
    const checked = e.target.checked;
    form.setFieldValue("recentlyItem", checked ? utilityOptions.map((item) => item.id) : [])
    update();
  };

  const onPeriodCheckedChange = (e) => {
    const checked = e.target.checked;
    form.setFieldValue(
      "periodItem",
      checked ? periodOptions.map((item) => item.id) : []
    );
    update();
  };
  const onSavingAnalysisChange = (e) => {
    const checked = e.target.checked;
    form.setFieldValue(
      "savingAnalysisItem",
      checked ? savingAnalysisOptions.map((item) => item.id) : []
    );
    update();
  };

  const validatorPeriod = (form) => {
    const { periodItem, period } = form.getFieldsValue();

    if (form) {
      return {
        validator() {
          const hasPeriodItem = periodItem?.length > 0;
          const isEmptyValue = isEmptyRangeDate(period);
          if (hasPeriodItem && isEmptyValue) {
            return Promise.reject("Please Select Date");
          } else {
            return Promise.resolve();
          }
        },
      };
    }
  };
  const validatorSavingAnalysis = (form) => {
    const { savingAnalysisItem, savingAnalysis } = form.getFieldsValue();

    if (form) {
      return {
        validator() {
          const hasSavingAnalysisItem = savingAnalysisItem?.length > 0;
          const isEmptyValue =
            !savingAnalysis ||
            isEmptyRangeDate(savingAnalysis.elec_Post_Date) ||
            isEmptyRangeDate(savingAnalysis.gas_Post_Date) ||
            isEmptyRangeDate(savingAnalysis.gas_Baseline_Date) ||
            isEmptyRangeDate(savingAnalysis.elec_Baseline_Date);

          if (hasSavingAnalysisItem && isEmptyValue) {
            return Promise.reject("Please Select Date");
          } else {
            return Promise.resolve();
          }
        },
      };
    }
  };

  const setSavingAnalysisState = (state) => {
    form.setFieldValue("savingAnalysis", {
      ...state,
    });
  };

  const copySavingAnalysisElecToGas = () => {
    const state = form.getFieldValue("savingAnalysis");
    form.setFieldValue("savingAnalysis", {
      ...state,
      gas_Baseline_Date: state.elec_Baseline_Date,
      gas_Post_Date: state.elec_Post_Date,
      gas_Option: state.elec_Option,
      gas_Custom_Price: state.elec_Custom_Price,
      gas_usedModuleValue: state.elec_usedModuleValue,
      gas_breakpoint0: state.elec_breakpoint0,
      gas_breakpoint1: state.elec_breakpoint1,
      gas_breakpoint2: state.elec_breakpoint2,
      gas_breakpoint3: state.elec_breakpoint3,
      gas_n_segments: state.elec_n_segments,
    });
  };

  return (
    <>
      <Modal
        forceRender={true}
        open={open}
        onCancel={closeModal}
        title={<div className="flex justify-center">
          {modalData.title}
        </div>}
        width="90%"
        footer={null}
      >
        <PreView {...modalData} key={JSON.stringify(modalData)} />
      </Modal>
      <Form
        // initialValues={initialValues}
        form={form}
        onFinish={onFinish}
      >
        <MenuActionsWrap>
          <Button type="primary" htmlType="submit">
            Preview
          </Button>
        </MenuActionsWrap>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <div className="flex items-center">
          <div style={{ fontWeight: 550, color: '#6A74A5', marginBottom: 24 }}>Dashboard<Tooltip title="Preview">
            <FundViewOutlined
              style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
              onClick={() => onLabelClick("dashboard", "Dashboard")}
            />
          </Tooltip>：
          </div>
          <Form.Item
            name="dashboardVisible"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

        </div>

        <Form.Item
          name="utilityVisible"
          label="Utility"
          valuePropName="checked"
        >
          <Checkbox onChange={update}/>
        </Form.Item>

        <Visible visible={form.getFieldValue('utilityVisible')}>
          <CardWrap>
            <CardItem>
              <div className="flex">
                <Form.Item>
                  <Checkbox
                    checked={recentlyChecked}
                    onChange={onRecentlyCheckedChange}
                    indeterminate={recentlyIndeterminate}
                    style={{ marginRight: 10 }}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <ViewLabel
                      label="Summary"
                      onClick={() => onLabelClick("recently", "Summary")}
                    />
                  }
                  name="recently"
                  rules={[
                    (form) => {
                      const { recentlyItem } = form.getFieldsValue();
                      if (recentlyItem?.length > 0) {
                        return {
                          required: true,
                          message: "Please select recently summary",
                        };
                      }
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value={1}>Last Month</Radio.Button>
                    <Radio.Button value={3}>Last 3 Month</Radio.Button>
                    <Radio.Button value={6}>Last 6 Month</Radio.Button>
                    <Radio.Button value={12}>Last 12 Month</Radio.Button>
                    <Radio.Button value={36}>Last 36 Month</Radio.Button>
                  </Radio.Group>
                </Form.Item>

              </div>

              <Form.Item name="recentlyItem" label="">
                <Checkbox.Group style={{ width: "100%" }} onChange={update}>
                  {renderRecentlyItems()}
                </Checkbox.Group>
              </Form.Item>
            </CardItem>
            <CardItem style={{ marginTop: 12 }}>
              <div className="flex">
                <Form.Item>
                  <Checkbox
                    checked={periodChecked}
                    onChange={onPeriodCheckedChange}
                    style={{ marginRight: 10 }}
                    indeterminate={periodIndeterminate}
                  />
                </Form.Item>
                <Form.Item
                  className={"w-30 flex"}
                  name="period"
                  label={
                    <ViewLabel
                      label="Energy consumption, Cost and EUI"
                      onClick={() => onLabelClick("period", "Energy consumption, Cost and EUI")}
                    />
                  }
                  rules={[validatorPeriod]}
                >
                  <RangePicker
                    picker="month"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  />
                </Form.Item>
              </div>
              <Form.Item name="periodItem" label="">
                <Checkbox.Group style={{ width: "100%" }} onChange={update}>
                  {renderPeriodItems()}
                </Checkbox.Group>
              </Form.Item>
            </CardItem>
            <CardItem style={{ marginTop: 12 }}>
              <div className="flex">
                <Form.Item>
                  <Checkbox
                    checked={savingAnalysisChecked}
                    onChange={onSavingAnalysisChange}
                    style={{ marginRight: 10 }}
                    indeterminate={savingAnalysisIndeterminate}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <ViewLabel
                      label="Baseline and Energy Saving analysis"
                      onClick={() => onLabelClick("savingAnalysis", "Baseline and Energy Saving analysis")}
                    />
                  }
                ></Form.Item>
              </div>

              <Form.Item name="savingAnalysisItem" label="">
                <Checkbox.Group style={{ width: "100%" }} onChange={update}>
                  {renderSavingAnalysisItem()}
                </Checkbox.Group>
              </Form.Item>
              <ScenarioForm
                setSavingAnalysisState={setSavingAnalysisState}
                copySavingAnalysisElecToGas={copySavingAnalysisElecToGas}
              />
              <Form.Item name="savingAnalysis" rules={[validatorSavingAnalysis]}>
                <SavingAnalysisForm />
              </Form.Item>
            </CardItem>
          </CardWrap>
        </Visible>
      </Form>
    </>
  );
};

const isEmptyRangeDate = (date) => {
  return !date || date?.length !== 2;
};

const utilityFields = ["period", "recently", "savingAnalysis"]

export const periodOptions = Object.values(PeridCardIdMappings);
export const savingAnalysisOptions = Object.values(SavingAnalysisCardIdMappings);

const renderRecentlyItems = () => {
  return (
    <>
      <Row>
        {Object.values(utilityOptions).map(({ id, title }) => {
          return (
            <Col span={7} key={id}>
              <Checkbox value={id}><div className="overflow-ellipsis overflow-hidden whitespace-nowrap">{title}</div> </Checkbox>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

const renderPeriodItems = () => {
  return (
    <>
      <Row>
        {Object.values(periodOptions).map(({ id, title }) => {
          return (
            <Col span={7} key={id}>
              <Checkbox value={id}>{title}</Checkbox>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
const renderSavingAnalysisItem = () => {
  return (
    <>
      <Row>
        {Object.values(savingAnalysisOptions).map(({ id, title }) => {
          return (
            <Col span={7} key={id}>
              <Checkbox value={id}>{title}</Checkbox>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

const ViewLabel = ({ label, onClick }) => {
  return (
    <>
      {label}
      <Tooltip title="Preview">
        <FundViewOutlined
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
          onClick={onClick}
        />
      </Tooltip>
    </>
  );
};

const getRecentlyCheckedState = (form) => {
  const recentlyItemLength = form.getFieldValue("recentlyItem").length;
  return {
    recentlyChecked: recentlyItemLength === utilityOptions.length,
    recentlyIndeterminate: recentlyItemLength !== utilityOptions.length && recentlyItemLength !== 0,
  }
}
const getPeriodCheckedState = (form) => {
  const periodItemLength = form.getFieldValue("periodItem").length;
  return {
    periodChecked: periodItemLength === periodOptions.length,
    periodIndeterminate: periodItemLength !== periodOptions.length && periodItemLength !== 0,
  }
}
const getSavingAnalysisCheckedState = (form) => {
  const savingAnalysisItemLength = form.getFieldValue("savingAnalysisItem").length;
  return {
    savingAnalysisChecked: savingAnalysisItemLength === savingAnalysisOptions.length,
    savingAnalysisIndeterminate: savingAnalysisItemLength !== savingAnalysisOptions.length && savingAnalysisItemLength !== 0,
  }
}

export const utilityCardIdMappings = {
  Electricity: {
      Consumption: {
          id: 'ChartId_ElectricityConsumption',
          title: 'Electricity Consumption (kWh)',
      },
      PricePer: {
          id: 'ChartId_ElectricityPricePerkWh',
          title: 'Electricity Price Per kWh ($)'
      },
      Cost: {
          id: 'ChartId_ElectricityCost',
          title: 'Electricity Cost ($)',
      }
  },
  Gas: {
      Consumption: {
          id: 'ChartId_GasConsumption',
          title: 'Gas Consumption (MMBtu)',
      },
      PricePer: {
          id: 'ChartId_GasPricePer',
          title: 'Gas Price Per MMBtu ($)'
      },
      Cost: {
          id: 'ChartId_GasCost',
          title: 'Gas Cost ($)',
      }
  },
  Total: {
    TotalCost: {
      id: 'ChartId_TotalCost',
      title: 'Total Cost ($)',
    },
    TotalCostPerSqft: {
      id: 'ChartId_TotalCostPerSqft',
      title: 'Total Cost Per Sqft ($/sqft)',
    },
    TotalCostBreakdown: {
      id: 'ChartId_TotalCostBreakdown',
      title: 'Total Cost Breakdown ($)',
    }
  },
  EUI: {
    EUILast12Month: {
      id: 'ChartId_EUILast12Month',
      title: 'EUI Last 12 Month (kBtu/sqft-yr)',
    },
    EUIRolling12MonthsBreakdown: {
      id: 'ChartId_EUIRolling12MonthsBreakdown',
      title: 'EUI Rolling 12 Months Breakdown',
    },
    EUILast12MonthsBreakdown: {
      id: 'ChartId_EUILast12MonthsBreakdown',
      title: 'EUI Last 12 Months Breakdown',
    }
  }
}

export const utilityOptions = [
  ...Object.values(utilityCardIdMappings.Electricity),
  ...Object.values(utilityCardIdMappings.Gas),
  ...Object.values(utilityCardIdMappings.Total),
  ...Object.values(utilityCardIdMappings.EUI),
];

export default PdfForm;
