import React from "react";
import { CaretDownOutlined, CopyOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Space, Tooltip } from "antd";
import { api } from "@/redux/api";
import { jsforceResponse } from "@/config/httpService";
import _ from "lodash";
import { useRequest, useSafeState } from "ahooks";
import styled from "styled-components";

const CopyOutlinedStyle = styled(CopyOutlined)`
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: #6a74a5;
  }
`;

const fetchScenario = async () => {
  const result1 = await api.filterScenario("");
  const res1 = jsforceResponse(result1, "", false, true);
  return _.get(res1, "data.results", []).map((item) => {
    return {
      label: item.name,
      value: item.id,
      record: item,
    };
  });
};

export const ScenarioForm = ({
  setSavingAnalysisState,
  copySavingAnalysisElecToGas,
}) => {
  const { data: options = [] } = useRequest(fetchScenario);
  const [value, setValue] = useSafeState('')
  const handleSelect = (_, { record }) => {
    setValue(record.name)
    setSavingAnalysisState({
      elec_Baseline_Date: [
        record.elec_baseline_start_date,
        record.elec_baseline_end_date,
      ],
      elec_Post_Date: [record.elec_post_start_date, record.gas_post_end_date],
      elec_Option: record.elec_type || "Baseline Average",
      elec_Custom_Price: record.elec_price,
      elec_usedModuleValue: record.elec_type || "Segments",
      elec_breakpoint0: record.elec_breakpoint0,
      elec_breakpoint1: record.elec_breakpoint1,
      elec_breakpoint2: record.elec_breakpoint2,
      elec_breakpoint3: record.elec_breakpoint3,
      elec_n_segments: record.elec_segment,
      // gas
      gas_Baseline_Date: [
        record.gas_baseline_start_date,
        record.gas_baseline_end_date,
      ],
      gas_Post_Date: [record.gas_post_start_date, record.gas_post_end_date],
      gas_Option: record.gas_Option || "Baseline Average",
      gas_Custom_Price: record.gas_price,
      gas_usedModuleValue: record.gas_type || "Segments",
      gas_breakpoint0: record.gas_breakpoint0,
      gas_breakpoint1: record.gas_breakpoint1,
      gas_breakpoint2: record.gas_breakpoint2,
      gas_breakpoint3: record.gas_breakpoint3,
      gas_n_segments: record.gas_segment,
    });
  };

  return (
    <Space style={{ paddingLeft: 10, marginBottom: 14 }}>
      <AutoComplete
        style={{ width: "250px" }}
        options={options}
        onSelect={handleSelect}
        value={value}
      >
        <Input
          size="medium"
          autosize={true}
          allowClear
          placeholder="Select a Scenario"
          autoComplete="off"
          prefix={
            <div style={{ color: "orange" }}>
              <i className="fas fa-arrow-alt-circle-right"></i>
            </div>
          }
          suffix={<CaretDownOutlined />}
        />
      </AutoComplete>
      <Tooltip title="Same as Electricity">
        <CopyOutlinedStyle
          style={{ fontSize: 20 }}
          onClick={copySavingAnalysisElecToGas}
        />
      </Tooltip>
    </Space>
  );
};
