import React, {Component} from 'react';
import {Column} from '@antv/g2plot';
import LayoutContent from "@components/utility/layoutContent";
import _ from "lodash";

class ColumnChart extends Component {

    chartRef = React.createRef();

    constructor(props) {
        super(props);

    }

    // componentDidMount() {
    //     this.draw(this.props)
    // }

    componentWillReceiveProps(nextProps) {
        // console.log('chart', this.chartRef.current)
        this.chartRef.current.innerHTML = '';
        if (nextProps.data) {
            this.draw(nextProps)
        }
    }

    draw = (props) => {
        const chartDom = this.chartRef.current;
        const stackedColumnPlot = new Column(chartDom, {
            data: props.data,
            timeseries: true,
            height: props.height,
            isStack: props.isStack,
            isGroup: props.isGroup,
            xField: props.type,
            yField: props.value,
            seriesField: props.seriesField,
            slider: props.slider ? {
                start: 0.5,
                end: 1,
            } : false,
            maxColumnWidth: 30,
            color: (e => {
                // console.log('column', e)
                if (props.colors && props.colors.hasOwnProperty(e.status)) {
                    return props.colors[e.status];
                } else {
                    // Fallback color or logic
                    return e; // Or any default color you wish to return
                }
            }),
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            legend: {
                layout: 'horizontal',
                position: 'top-right'
            },

            label: {
                // 可手动配置 label 数据标签位置
                position: 'top', // 'top', 'middle', 'bottom'
                // 可配置附加的布局方法
                layout: [
                    // 柱形图数据标签位置自动调整
                    {type: 'interval-adjust-position'},
                    // 数据标签防遮挡
                    {type: 'interval-hide-overlap'},
                    // 数据标签文颜色自动调整
                    {type: 'adjust-color'},
                ],
            },
        });

        stackedColumnPlot.render();

        stackedColumnPlot.on('element:click', (e) => {

            let data = _.get(e, 'data.data');
            props.handleChartClick(data)
        });
    }

    render() {
        return (
            <LayoutContent className={'outerCardWrapper'}>
                <div className={'small-title pdfChart_Title'}>{this.props.title}</div>
                <div ref={this.chartRef}/>
            </LayoutContent>
        );
    }
}

export default ColumnChart;
