import { jsxToPdfDocument } from "react-jsx2pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getPdfMakeInstance = (document, ctx) => {
  return pdfMake.createPdf(
    jsxToPdfDocument(document, {
      ctx,
    })
  );
};

export const getUrlByDocment = (document, ctx) => {
  const pdfInstance = getPdfMakeInstance(document, ctx);
  return new Promise((resolve) => {
    pdfInstance.getBlob((blob) => resolve(URL.createObjectURL(blob)));
  });
};

export const downloadPdfByDocument = (document, ctx) => {
  const pdfInstance = getPdfMakeInstance(document, ctx);
  return new Promise((resolve) => {
    pdfInstance.download(() => resolve());
  });
};


export const paddingX = 40; 
export const paddingY = 40
export const fullWidth = 595.276;
export const fullHeight = 841.890;

export const paddingWidth = fullWidth - (paddingX * 2)
export const paddingHeight = fullWidth - (paddingY * 2)

export const col_2 = (paddingWidth - 40) / 2

export const convertTo2DArray = (arr) => {
  let result = [];
  for (let i = 0; i < arr.length; i += 2) {
      if (i === arr.length - 1) {
          result.push([arr[i], null]);
      } else {
          result.push([arr[i], arr[i + 1]]);
      }
  }
  return result;
}

export const pdfFontSize = {
  H1: 24,
  H2: 18,
  H3: 14,
  H4: 12,
  H5: 10,
  H6: 8,
} 