import {Button} from "antd";
import React, {Component} from "react";
import notification2 from "@components/Notification2";
import moment from "moment/moment";
import {api} from "@redux/api";
import ScenarioLookup from "@containers/Lookup/ScenarioLookup";

class ScenarioFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: null,
            scenario_name: null,
            usedModuleValue: null,
            selectedScenarioId: null,
            componentKey: 0,

            gas_price_type: null,
            gas_Baseline_Date: null,
            gas_Start_Date: null,
            gas_Post_Date: null,
            gas_Price: null,
            gas_Option: null,
            gas_type: null,
            gas_breakpoint0: null,
            gas_breakpoint1: null,
            gas_breakpoint2: null,
            gas_breakpoint3: null,
            gas_n_segments: null,
            gas_range0: null,
            gas_range1: null,

            elec_price_type: null,
            elec_Baseline_Date: null,
            elec_Start_Date: null,
            elec_Post_Date: null,
            elec_Price: null,
            elec_Option: null,
            elec_type: null,
            elec_breakpoint0: null,
            elec_breakpoint1: null,
            elec_breakpoint2: null,
            elec_breakpoint3: null,
            elec_n_segments: null,
            elec_range0: null,
            elec_range1: null,

            public: null,
        }
    }


    openScenario = (e, isNewScenario) => {
        this.setState({
            visible: true,
            isNewScenario
        })
        this.props.handleOpenScenario(true, isNewScenario)
    }

    handleSelectedScenario = (selectedScenario) => {
        // console.log('handleSelectedScenario', selectedScenario)
        const record = selectedScenario.record;
        const newState = {
            name: record.name,
            scenario_name: record.name,
            usedModuleValue: record.use_breakpoints,
            selectedScenarioId: record.id,

            gas_price_type: record.gas_type,
            gas_Baseline_Date: [record.gas_baseline_start_date ? moment(record.gas_baseline_start_date) : null,
                record.gas_baseline_end_date ? moment(record.gas_baseline_end_date) : null],
            gas_Start_Date: [moment().subtract(12, 'months'), moment()],
            gas_Post_Date: [record.gas_post_start_date ? moment(record.gas_post_start_date) : null,
                record.gas_post_end_date ? moment(record.gas_post_end_date) : null],
            gas_Price: record.gas_price,
            gas_Option: record.gas_type,
            gas_type: record.gas_type,
            gas_breakpoint0: record.gas_breakpoint0,
            gas_breakpoint1: record.gas_breakpoint1,
            gas_breakpoint2: record.gas_breakpoint2,
            gas_breakpoint3: record.gas_breakpoint3,
            gas_n_segments: record.gas_segment,
            gas_usedModuleValue: record.gas_use_breakpoints || 'Breakpoints',
            gas_range0: record.gas_range0,
            gas_range1: record.gas_range1,

            elec_price_type: record.elec_type,
            elec_Baseline_Date: [record.elec_baseline_start_date ? moment(record.elec_baseline_start_date) : null,
                record.elec_baseline_end_date ? moment(record.elec_baseline_end_date) : null],
            elec_Start_Date: [moment().subtract(12, 'months'), moment()],
            elec_Post_Date: [record.elec_post_start_date ? moment(record.elec_post_start_date) : null, record.elec_post_end_date ? moment(record.elec_post_end_date) : null],
            elec_Price: record.elec_price,
            elec_Option: record.elec_type,
            elec_type: record.elec_type,
            elec_breakpoint0: record.elec_breakpoint0,
            elec_breakpoint1: record.elec_breakpoint1,
            elec_breakpoint2: record.elec_breakpoint2,
            elec_breakpoint3: record.elec_breakpoint3,
            elec_n_segments: record.elec_segment,
            elec_usedModuleValue: record.elec_use_breakpoints || 'Breakpoints',
            elec_range0: record.elec_range0,
            elec_range1: record.elec_range1,

            public: record.public,
        };
        this.props.handleUpdateState(newState);
    }

    handleEdit = (record) => {
        const newState = {
            name: record.name,
            scenario_name: record.name,
            usedModuleValue: record.use_breakpoints,
            selectedScenarioId: record.id,

            gas_price_type: record.gas_type,
            gas_Baseline_Date: [record.gas_baseline_start_date ? moment(record.gas_baseline_start_date) : null,
                record.gas_baseline_end_date ? moment(record.gas_baseline_end_date) : null],
            gas_Start_Date: [moment().subtract(12, 'months'), moment()],
            gas_Post_Date: [record.gas_post_start_date ? moment(record.gas_post_start_date) : null,
                record.gas_post_end_date ? moment(record.gas_post_end_date) : null],
            gas_Price: record.gas_price,
            gas_Option: record.gas_type,
            gas_type: record.gas_type,
            gas_breakpoint0: record.gas_breakpoint0,
            gas_breakpoint1: record.gas_breakpoint1,
            gas_breakpoint2: record.gas_breakpoint2,
            gas_breakpoint3: record.gas_breakpoint3,
            gas_n_segments: record.gas_segment,
            gas_range0: record.gas_range0,
            gas_range1: record.gas_range1,

            elec_price_type: record.elec_type,
            elec_Baseline_Date: [record.elec_baseline_start_date ? moment(record.elec_baseline_start_date) : null,
                record.elec_baseline_end_date ? moment(record.elec_baseline_end_date) : null],
            elec_Start_Date: [moment().subtract(12, 'months'), moment()],
            elec_Post_Date: [record.elec_post_start_date ? moment(record.elec_post_start_date) : null, record.elec_post_end_date ? moment(record.elec_post_end_date) : null],
            elec_Price: record.elec_price,
            elec_Option: record.elec_type,
            elec_type: record.elec_type,
            elec_breakpoint0: record.elec_breakpoint0,
            elec_breakpoint1: record.elec_breakpoint1,
            elec_breakpoint2: record.elec_breakpoint2,
            elec_breakpoint3: record.elec_breakpoint3,
            elec_n_segments: record.elec_segment,
            elec_range0: record.elec_range0,
            elec_range1: record.elec_range1,

            public: record.public,

        };

        this.setState({
            visible: true,
            isNewScenario: false
        })
        this.props.handleOpenScenario(true, false, newState)
    }

    handleDelete = (id) => {
        api.deleteScenario(id)
            .then(response => {
                notification2['success']({
                    message: 'Success',
                    description: 'Scenario was successfully deleted', placement: 'topLeft'
                });
                this.setState(prevState => ({
                    componentKey: prevState.componentKey + 1,
                }));
                this.setState({
                    visible: false,
                })

            })
            .catch(err => {
                notification2['error']({
                    message: 'Error',
                    description: err.message, placement: 'topLeft'
                });
            });
    }

    render() {
        return (
            <>
                <div className={'mr-5 mt-auto'}>
                    <ScenarioLookup
                        key={this.state.componentKey}
                        SelectedScenario={this.handleSelectedScenario}
                        handleEdit={this.handleEdit}
                        handleDelete={this.handleDelete}
                        handleOpenScenario={this.openScenario}
                    />
                </div>
                {/*<div className={'ml-5 mt-auto'}>*/}
                {/*    <Button type={'primary'} onClick={(e) => this.openScenario(e, true)}>Save New Scenario</Button>*/}
                {/*</div>*/}
            </>
        )
    }
}

export default ScenarioFilter;
