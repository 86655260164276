import React from "react";
import { store } from '@redux/store';
import actions from '@redux/EditableTable/actions';
import {Badge, Tooltip, Button, Avatar} from "antd";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id));
}
export const NotificationProps = {
    title: 'Notification',
    description: '',
    name: 'Notification'
}

export const NotificationColumns = () => ([
    {
        title: 'Key',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIgnore: true,
        hideInTable: false,
        width: 60,
        sorter: (a, b) => a.id - b.id,
        render: (text, record) => {
            const { record_id, table } = record || {};

            if (record_id) {
                const url = `/${table}/?id=${record_id}`;
                return (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-link mr-2"></i>
                        {record.record_id}
                    </a>
                );
            }

            return text;
        }
    },
    {
        title: 'Type',
        dataIndex: 'type_of_notification',
        key: 'type_of_notification',
        editable: false,
        search: true,
        importIgnore: false,
        hideInTable: false,
        width: 100,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 200,
        search: false,
        edit: true,
        hideInTable: false,
        sorter: (a, b) => a.description?.localeCompare(b.description),
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        render: (text, record, _, action) => (
            <Tooltip title={text}>
                <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
            </Tooltip>
        ),
        renderFormItem: () => <TextArea rows={1}/>,
    },
    {
        title: 'Sender',
        dataIndex: 'sender_name',
        key: 'sender_name',
        editable: false,
        search: true,
        importIgnore: false,
        hideInTable: false,
        width: 100,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        editable: false,
        search: false,
        importIgnore: false,
        hideInTable: false,
        width: 100,
        render: (status) => (
            <Badge
                status={status === 'unread' ? 'error' : 'success'}
                text={status.charAt(0).toUpperCase() + status.slice(1)}
            />
        ),
    },
    {
        title: 'Date',
        dataIndex: 'created_date',
        key: 'created_date',
        editable: false,
        search: true,
        importIgnore: false,
        hideInTable: false,
        width: 150,
        render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
        title: 'User Icon',
        dataIndex: 'image',
        key: 'image',
        editable: false,
        search: false,
        importIgnore: false,
        hideInTable: false,
        width: 100,
        render: (image) => <Avatar src={image} />,
    }
]);

export default NotificationColumns;
