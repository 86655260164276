const actions = {
    StationTemperature_REQUEST: 'StationTemperature_REQUEST',
    StationTemperature_SUCCESS: 'StationTemperature_SUCCESS',

    StationTemperatureRequest: () => ({
        type: actions.StationTemperature_REQUEST,
        payload: {},
    }),
    StationTemperatureSuccess: (data) => ({
        type: actions.StationTemperature_SUCCESS,
        payload: {data},
    }),
};
export default actions;
