const PROD_ENV = {
  backendUrl: "https://backend.facilitykpi.com",
  wsURL: "wss://backend.facilitykpi.com/ws/socket-server/",
}

const TEST_ENV = {
  backendUrl: "http://facilitykpi-sandbox.us-east-2.elasticbeanstalk.com",
  wsURL: "wss://facilitykpi-sandbox.us-east-2.elasticbeanstalk.com/ws/socket-server/",
}

const DEV_ENV = {
  backendUrl: process.env.REACT_APP_BACKEND_DEV || "http://facilitykpi-sandbox.us-east-2.elasticbeanstalk.com",
  wsURL: process.env.REACT_APP_WS_DEV || "wss://facilitykpi-sandbox.us-east-2.elasticbeanstalk.com/ws/socket-server/",
}

const ENV_MAP = {
  "http://staff.facilitykpi.com.s3-website.us-east-2.amazonaws.com": TEST_ENV,
  "https://facilitykpi.com": PROD_ENV

}

const getEnv = () => {

  if(process.env.NODE_ENV === 'development') {
    return DEV_ENV
  }

  // For compatible with IE
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  }

  const currentEnv = ENV_MAP[window.location.origin]

  if(!currentEnv) { // Notice the ! sign here
    throw new Error('environment error!')
  }

  return currentEnv
}


export const ENV = getEnv();
