import React, { useState } from "react";

import Space from "antd/es/space";
import { Button, Drawer, Popconfirm, Tooltip } from "antd";
import ResizableProTable from "@components/Table/ResizableProTable";

import moment from "moment";
import { DownloadOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { deleteReport, getPdfReportList } from "@/redux/api";
import { usePdfContext } from "./context";

const handleDownload = (record) => {
  const link = document.createElement('a');
  link.href = record.attachment;
  link.attachment = `321.pdf`
  link.download = `${record.name}.pdf`;
  link.click();
};

const deleteCurrentReport = (re) => {
  deleteReport(re.id)
}



const request = async (params, sort, filter) => {
  const { pageSize, current } = params;
  return getPdfReportList({ pageSize, current }).then(res => {
    if (res?.status === 200) {
      return {
        data: res?.data?.results || [],
        total: res?.data?.count || 0,
        success: true
      }
    }
  })

}

const PdfTable = () => {

  const [viewPdf, setViewPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const { openDrawer, tableActionRef, goToFormStep, initForm } = usePdfContext();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      search: false
    },
    {
      title: "Create By",
      dataIndex: "createdby_name",
      key: "createBy",
      sorter: true,
      search: false
    },
    {
      title: "Create Date",
      dataIndex: "created_date",
      key: "createDate",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
      search: false
    },
    {
      title: "Operations",
      key: "action",
      search: false,
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Download attachment">
            <Button type="link" onClick={() => handleDownload(record)}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="View pdf">
            <Button type="link" onClick={() => {
              setViewPdf(true)
              setPdfUrl(record.attachment)
            }}>
              <EyeOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this report?"
              onConfirm={() => {
                deleteCurrentReport(record)
                tableActionRef.current.reload();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handlePdfBuilderClick = () => {
    goToFormStep();
    initForm();
    openDrawer();
  }

  return (
    <div style={{ padding: 5 }}>
      <Drawer width={'100%'} open={viewPdf} onClose={() => setViewPdf(false)}>
        {viewPdf && pdfUrl && <iframe key={pdfUrl} style={{ width: '100%', height: '86vh' }} title="viewpdf" src={pdfUrl}></iframe>}
      </Drawer>
      <ResizableProTable
        actionRef={tableActionRef}
        search={false}
        columns={columns}
        toolBarRender={() => 
          <Space className="my-4" size={30}>
            <Button className="reload-refresh-btn"><i className="fas fa-sync pr-3"></i>Refresh</Button>
            <Button type="primary" onClick={handlePdfBuilderClick}><i className="fas fa-plus pr-3"></i>Report Builder</Button>
          </Space>
        }
        request={request}
        options={{
          setting: true,
          density: false,
          fullScreen: false,
          reload: false
        }}
      />
    </div>
  );
};

export default PdfTable;
