import React, {Component} from 'react';
import {connect} from "react-redux";
import {api, update_profile} from "@redux/api";
import {Checkbox, Form, Input, Select, Spin, Table} from "antd";
import {RoleDict} from "@components/Permission/PermissionProvider";
import {permissionOptions, roleOptions} from "@containers/management/UserManagement/Profile/ProfileFormFields";
import PermissionControl from "@components/Permission/Permission";
import SearchLookup from "@components/SearchLookup";


const {Option} = Select;


class CustomUserRelated extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            formFields: [],
            data: [],
            columns: [],
            loading: false,
        }
    }

    handleSubscribe = (record, checked) => {
        console.log('record', record)
        console.log('checked', checked)
        this.setState({
            loading: true
        })
        let data = {
            subscribe_workorder_report: checked
        }
        api.changeCustomUserSubscription(record.auth_CustomUser, data).then(
            response => {
                return this.handleFilter();
            }
        ).catch(error => (
            error
        ));

    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        let columns = [
            {
                title: 'key',
                dataIndex: 'id',
            },
            {
                title: 'User Name',
                sorter: (a, b) => a.auth_CustomUser_name?.localeCompare(b.auth_CustomUser_name),
                dataIndex: 'auth_CustomUser_name',
            },
            {
                title: 'Email',
                dataIndex: 'email',
            },
            {
                title: 'Subscribe to reports email',
                key: 'subscribe_workorder_report',
                render: (text, record) => {
                    console.log('checkbox', record.subscribe_workorder_report)
                    return <Checkbox checked={record.subscribe_workorder_report === 'True'}
                                     onChange={(e) => this.handleSubscribe(record, e.target.checked)}
                                     value={text}/>
                },
            },
        ]
        this.setState({
            columns: columns
        })
        this.handleFilter()
    }

    handleFilter = () => {
        return api.getFacilityRelatedUsers(this.props.relatedId).then(
            response => {
                let dataSource = response.data.results
                console.log('dataSource', dataSource)
                this.setState({
                    data: dataSource,
                    loading: false,
                })
            }
        ).catch(error => (
            error
        ));
    }

    render() {

        return (
            <>{this.props.relatedId?<Spin spinning={this.state.loading}>

                <Table bordered dataSource={this.state.data} tableRef={this.tableRef}  pagination={{
                    pageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50'],
                }}
                       columns={this.state.columns}/>

            </Spin>:''}</>
        );
    }
}

const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomUserRelated);
