import React, {Component} from 'react';
import _ from "lodash";
import {Checkbox, Form, Input, PageHeader} from "antd";

import PermissionSettingStyle from "@components/PermissionSetting/PermissionSettingStyle";
import {api} from "@redux/api";

import {connect} from "react-redux";
import authActions from "@redux/auth/actions";
import Lookup_Common from "@components/Lookup_common";
import {permissionOptions, roleOptions} from "@containers/management/UserManagement/Profile/ProfileFormFields";
import PermissionControl from "@components/Permission/Permission";


class PermissionSetting extends Component {
    constructor(props) {
        super(props);
        this.permissionformRef = React.createRef();
        this.state = {
            fields: [],
            fileList: [],
            tags: [
                {id: 1, content: 'Record'}
            ],
            data: null
        }
    }

    async componentDidMount() {

        let permissions = await this.init(this.props)

        if (_.get(permissions, 'results')) {
            this.setState({
                data: _.get(permissions, 'results[0]')
            })
        }
        if (this.props.setPermissions) {
            this.props.setPermissions(_.get(permissions, 'results[0]'))
        }

    }

    async componentWillReceiveProps(nextProps) {
        if (this.props.selecteduser !== nextProps.selecteduser) {

            let permissions = await this.init(nextProps)
            if (_.get(permissions, 'results')) {
                this.setState({
                    data: _.get(permissions, 'results[0]')
                })
            }
        }

    }

    init = async (props) => {
        console.log('selecteduser', props.selecteduser)
        if (props.selecteduser) {
            return await api.getUserpermissions(props.selecteduser).then(
                response => {

                    return response.data
                }
            ).catch(error => (
                error
            ));
        }
    }


    Lookup_CommonupRequest = (e) => {
        let query = 'name=' + e + '&role=' + this.props.role;
        return api.filterUserPermissions(query).then(
            response => {
                console.log('response', response)
                let data = response?.data?.results;

                return {
                    data: data,
                    success: true,
                }
            }
        ).catch(error => (
            error
        ));
    }

    SelectedLookup_CommonRequest = (e, record) => {
        if (!record) {
            return false
        }
        let query = 'id=' + record?.record + '&role=' + this.props.role;
        console.log('SelectedLookup_CommonRequest')
        api.filterUserPermissions(query).then(
            response => {
                console.log('response', response)
                let data = response?.data?.results;
                this.setState({
                    data: data[0]
                })
                if (this.props.setSelectedUserPermissions) {
                    this.props.setSelectedUserPermissions(data[0])
                }
                if (this.props.setPermissions) {
                    this.props.setPermissions(data[0])
                }

            }
        ).catch(error => (
            error
        ));
    }

    render() {

        let data = this.state.data;
        let formFields = [
            {
                title: 'key',
                name: 'id',
                order: 0,
                visible: false,
                length: 0,
                type: 'text',
            },
            {
                title: 'Name',
                name: 'name',
                order: 1,
                visible: true,
                length: "w-full ",
                type: 'text',
                render: <Input disabled className={'text-right'}/>,
            },
            {
                title: 'Comment',
                name: 'comment',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Input disabled className={'text-right'}/>,
            },
            {
                title: 'Role',
                name: 'role',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render: <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'role')} options={roleOptions}/>,
            },
            {
                title: 'Electricity',
                name: 'electricity',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:

                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'electricity')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.electricity;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}
                    />
                ,

            },

            {
                title: 'Gas',
                name: 'gas',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'gas')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.gas;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,

            },
            {
                title: 'Company',
                name: 'company',
                order: 1,
                visible: this.props.Role <= 2.1,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'company')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.company;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
            {
                title: 'Facility',
                name: 'facility',
                order: 1,
                visible: this.props.Role <= 2.1 || this.props.Role === 3,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'facility')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.facility;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}
                    />
                ,
            },
            {
                title: 'Equipment',
                name: 'equipment',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'equipment')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.equipment;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
            {
                title: 'Workorder',
                name: 'workorder',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'workorder')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.workorder;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
            {
                title: 'EquipmentCategory',
                name: 'equipmentCategory',
                order: 1,
                visible: this.props.Role === 1,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'equipmentCategory')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.equipmentCategory;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
            {
                title: 'User',
                name: 'user',
                order: 1,
                visible: this.props.Role <= 3 && this.props.Role !== 2.2,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'user')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.user;
                                        if (perm && perm !== 0) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}
                    />
                ,
            },
            // {
            //     title: 'Project',
            //     name: 'project',
            //     order: 1,
            //     visible: true,
            //     length: "w-full",
            //     type: 'text',
            //     render: <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'project')} options={permissionOptions}/>,
            //     hidden: !perm['Project']
            // },
            {
                title: 'Benchmark',
                name: 'benchmark',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'benchmark')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.benchmark;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
            {
                title: 'CustomReport',
                name: 'customreport',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'customreport')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.customreport;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
            {
                title: 'Functional Form',
                name: 'functionform',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'functionform')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.functionform;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
            {
                title: 'Functional Testing',
                name: 'functiontesting',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'functiontesting')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.functiontesting;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
            {
                title: 'Log',
                name: 'log',
                order: 1,
                visible: true,
                length: "w-full",
                type: 'text',
                render:
                    <Checkbox.Group disabled onChange={(e) => this.handleChange(e, 'log')}
                                    options={permissionOptions.filter(e => {
                                        let perm = this.props.Permission?.log;
                                        perm = perm?.toString();
                                        if (perm) {
                                            return <PermissionControl
                                                allowedPermissions={perm + ':' + this.props.columnProps?.name}
                                                returnBool={true}>
                                                {e}
                                            </PermissionControl>
                                        }
                                    })}/>
                ,
            },
        ]


        let fields = [];
        if (formFields) {
            formFields.forEach(e => {
                    let field = e
                    field['value'] = data ? data[e.name]?.toString() : '';
                    fields.push(field)
                }
            )
        }

        console.log('permission', data, formFields)

        return (
            <PermissionSettingStyle>
                {/*auto complete lookup for user to select a profile*/}
                {/*add a label to look*/}

                {this.props.readOnly?'':<Lookup_Common Lookup_CommonupRequest={this.Lookup_CommonupRequest}
                               defaultValue={data?.name}
                               placeholder={'Select a profile'}
                               name={'name'}
                               style={{width: window.innerWidth < 760 ? '100%' : 'auto'}}
                               SelectedLookup_CommonRequest={this.SelectedLookup_CommonRequest}
                />}
                <div className="mb-5"/>
                <div className={'slds-m-around_x-small'}>
                    <PageHeader
                        title={''}
                        className="site-page-header"

                        extra={[
                            // <Button type='primary' key="submit" form="permissionForm" htmlType="submit">Save</Button>
                        ]}

                    />
                </div>

                <div className={''}>
                    <Form
                        onFinish={this.handleSubmit}
                        layout="horizontal"
                        fields={fields}
                        ref={this.permissionformRef}
                        id="permissionForm"
                    >

                        {fields.map(e => {
                                return (
                                    <Form.Item label={e.title} name={e.name} className={e.length}
                                               hidden={!e.visible}>
                                        {e.render}
                                    </Form.Item>
                                )
                            }
                        )}

                    </Form>
                </div>
            </PermissionSettingStyle>
        );
    }
}

const mapStateToProps = state => {
    return {
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] * 1 : null,
        company: _.get(state.Facility.selected, 'record.company_id', null),
        Permission: state.Auth.permission
        // SelectedUserPermission: state.Auth.selected_userpermissions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPermissions: (e) => dispatch(authActions.selected_userpermissions(e)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermissionSetting);

