import styled from "styled-components";

export const FacilityBenchmarkStyles = styled.div`
    padding-top: 1rem;
  table{
    table-layout: fixed !important;
    padding: 1rem;
  }
    


  .title{
    padding:0.5rem !important;
    font-weight:bold !important;
    font-size: 13px !important;
  }

  .setting-drawer-handle {
    position: absolute;
    top: 10%;
    right: 0px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background-color: #4090FF;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    pointer-events: auto;
    transition:none !important;
  }

  .setting-drawer-handle-close {
    position: absolute !important;
    top: 10%;
    right: 515px;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background-color: #4090FF !important;
    cursor: pointer;
    pointer-events: auto;
    color: white !important;
    border: 1px solid #4090FF;
    transition:none !important;
  }

  .ant-btn:hover, .ant-btn:focus{
    background: #4090FF;
  }
`
