import {useEffect, useRef} from 'react';


import _ from 'lodash'
import moment from "moment";
import notification2 from "@components/Notification2";

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const openNotificationWithIcon = (type, message, description) => {
    return notification2[type]({
        message: message,
        description: description
    });
};

export const errorCheck = (data, module) => {
    // console.log(data.data[0]+module)
    let isError = false;
    if (!data.data) {
        isError = true;
        openNotificationWithIcon('info', module, 'API Return Null');
        return isError;
    } else if (_.get(data.data[0], 'errorCode') != null) {
        if (data.data[0].errorCode === 'API_Error') {
            isError = true;
            openNotificationWithIcon('info', module + ' API Error', data.data[0].message);
            return isError;
        } else if (data.data[0].errorCode === 'APEX_ERROR') {
            isError = true;
            openNotificationWithIcon('info', module + ' APEX ERROR', data.data[0].message);
            return isError;
        }
    } else if (data.data.length === 0) {
        isError = true;
        openNotificationWithIcon('info', module, 'No UMA Found');
        return isError;
    }
}


export const asOfDate = () => {
    return moment().format('MMM YYYY')
}

export const removeDuplicates = (arr) => {
    let obj = {};
    let new_obj = [];
    // console.log('removing duplicates', arr)
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].label !== undefined) {
            obj[arr[i].label] = arr[i].value
        }
    }
    for (var key in obj) {
        new_obj.push(key);
    }

    let result = []
    for (let i = 0; i < new_obj.length; i++) {
        result.push({
            label: new_obj[i],
            value: new_obj[i]
        })
    }
    return result
}

export const convertUTCtoLocal = (utcDateString) => {
    const localDate = moment.utc(utcDateString).local();
    return localDate.format('YYYY-MM-DD');
}

export const formatTimeDifference = (timeDifference) => {
    const seconds = Math.floor(timeDifference / 1000);
    if (seconds < 60) {
        return `1 second ago`;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    }

    const days = Math.floor(hours / 24);
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
}

export const hasValues = (obj) => {
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            // Check if it's an array
            if (obj[key].some(item => item !== null && item !== undefined && item !== "")) {
                return true;
            }
        } else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
            return true;
        }
    }
    return false;
}
