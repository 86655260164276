import SearchLookup from "@/components/SearchLookup";
import {Button, Form, Select} from "antd";
import React, {useState} from "react";
import { facility_category_options } from "./FacilityFormFields";
import CompanyLookup from "@/containers/Lookup/CompanyLookup";
import { AutoSearchSelect } from "@/components/auto-search";
import { api } from "@/redux/api";
import { jsforceResponse } from "@/config/httpService";
import _ from "lodash";

const fetchCompanyOptions = async (name) => {
    const result = await api.filterCompany(`name=${name}`);
    const res = jsforceResponse(result, '', false, true)
    const results = _.get(res, 'data.results', []);

    return results.map(item => ({
        label: item.name,
        value: item.name
    }))
}

export const FacilityFilters = (props) => {

    const formRef = React.useRef()

    const categoryFilter = () => {
        return (
            <AutoSearchSelect
                request={(name) => {
                    if(!name) {
                        return facility_category_options
                    }else {
                        return facility_category_options.filter(item => item.label.toLowerCase().includes(name.toLowerCase()))
                    }
                }}
                placeholder="Category"
            />
        )
    }

    const companyFilter = () => {
        return (
            <AutoSearchSelect
                request={fetchCompanyOptions}
                placeholder="Company"
            />
        )
    }

    // function handleSubmit(e) {
    //     console.log('submit', e)
    //     props.handleFilter(e)
    // }

    const handleValuesChange = (_, allFields) => {
        props.handleFilter(allFields)
    }

    return <Form
        id="myForm"
        layout="horizontal"
        // onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
        ref={formRef}
    >

        <Form.Item name={'Company'} className={'md:w-96 lg:w-96 sm:w-full'}>
            {companyFilter()}
        </Form.Item>

        <Form.Item name={'Category'} className={'md:w-96 lg:w-96 sm:w-full'}>

            {categoryFilter()}
        </Form.Item>

        {/* <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item> */}
    </Form>

}
