import React, {useState} from 'react';
import {Button, Dropdown, Input, Menu} from 'antd';
import styled from "styled-components";
import {DownOutlined, ProfileOutlined} from "@ant-design/icons";
import {api} from "@redux/api";

const GlobalSearchInputWrapper = styled.div`
  display: flex;
  justify-content: center;

  .ant-input-lg {
    padding: 9.5px 11px
  }
`
const GlobalSearchInput = (props) => {

    const [searchText, setSearchText] = useState();
    const [object, setObject] = useState('Workorder');
    const onSearch = async (e) => {
        // console.log('onSearch', e)
        let func = null;
        switch (object) {
            case 'Workorder':
                func = 'searchGlobalWorkorder';
                break;
            case 'Equipment':
                func = 'searchGlobalEquipment';
                break;
            case 'Facility':
                func = 'searchGlobalFacility';
                break;
            default:
                func = 'searchGlobalWorkorder';

        }
        let result = await api[func](e).then(
            response => {
                let dataSource = response.data.results
                return dataSource
            }
        ).catch(error => (
            error
        ));

        props.onSearch(result)
    }
    const handleMenuClick = (e) => {
        // console.log('handleMenuClick', e)
        setObject(e.key)
    }
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="Facility" icon={<ProfileOutlined/>}>
                Facility
            </Menu.Item>
            <Menu.Item key="Equipment" icon={<ProfileOutlined/>}>
                Equipment
            </Menu.Item>
            <Menu.Item key="Workorder" icon={<ProfileOutlined/>}>
                Workorder
            </Menu.Item>
        </Menu>
    );
    return (
        <GlobalSearchInputWrapper>
            <Dropdown overlay={menu}>
                <Button style={{height: 'auto', width: '8rem', justifyContent: 'space-between'}}>
                    {object} <DownOutlined/>
                </Button>
            </Dropdown>
            <Input.Search
                placeholder="Search..."
                enterButton="Search"
                size="large"
                value={searchText}
                onChange={(e) => {
                    setSearchText(e.target.value);
                }}
                onSearch={onSearch}
                style={{maxWidth: 522, width: '100%'}}
            />
        </GlobalSearchInputWrapper>
    );
}

export default GlobalSearchInput;
