import React from 'react';
import { Modal } from 'antd';
import _ from 'lodash';
import {Importer, ImporterField} from "react-csv-importer";
import notification2 from "../Notification2";

const ImportModalComponent = (props) => {
    const handleImportCancel = (e) => {
        props.CloseImportModalRequest(true);
    };

    return (
        <Modal
            title={"Import CSV to "+_.get(props.columnProps, 'name', [])}
            open={!props.isImportModalClosed}
            onCancel={handleImportCancel}
            footer={''}
            destroyOnClose={true}
            width={'80%'}
        >
            <Importer
                chunkSize={10000} // optional, internal parsing chunk size in bytes
                defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                restartable={false} // optional, lets user choose to upload another file when import is complete
                skipHeaders={false} // optional, skips CSV headers when previewing data
                dataHandler={async (rows) => {
                    // required, receives a list of parsed objects based on defined fields and user column mapping;
                    // may be called several times if file is large
                    // (if this callback returns a promise, the widget will wait for it before parsing more data)
                    // console.log("received batch of rows", rows);

                    if (rows.length === 0) {
                        return null
                    }
                    const processedRows = rows.map((row) => {
                        const processedRow = {};
                        for (const key in row) {
                            if (Object.prototype.hasOwnProperty.call(row, key)) {
                                let value = row[key];
                                if (typeof value === 'string' && value.startsWith('"') && value.endsWith('"')) {
                                    value = value.slice(1, -1);
                                }
                                processedRow[key] = value;


                                if (key === value) {
                                    // Filter out key-value pairs where the key and value are equal
                                    delete processedRow[key];
                                }
                            }
                        }
                        return processedRow;
                    });
                    console.log('importerrows', processedRows)
                    let result = await props.bulkInsert(processedRows);
                    // console.log('result', result)
                    if (_.get(result.response, 'status') > 300) {
                        for (let key in result.response.data) {
                            // For each key, get the first error message and display it
                            let errorMessage = result.response.data[key][0];
                            notification2['error']({
                                message: errorMessage,
                                description: errorMessage
                            });
                        }
                    } else {
                        this.actionRef.current.reload();
                    }


                }}
                onClose={() => {
                    props.CloseImportModalRequest(true)

                }}
            >
                {
                    props.columns.map((e, index) => {
                        if (!e.importIngore) {
                            return <ImporterField name={e.dataIndex} label={e.dataIndex} key={index}
                                                  optional={!e.required ? true : false}/>
                        }
                    })
                }
            </Importer>
        </Modal>
    );
};

export default ImportModalComponent;
