import actions from './actions';

const initState = {
    isNewModalClosed: true,
    isImportModalClosed: true,
    load: false,
    drawer: false,
    id: null,
    selected_projectId: null,
    selected_projectName: null,
    selected_rows: []
};

export default function EditableTableReducer(state = initState, action) {
    switch (action.type) {
        case actions.CLOSE_NEW_MODAL_REQUEST:
            return {
                ...state,
                isNewModalClosed: action.payload.status
            };
        case actions.CLOSE_IMPORT_MODAL_REQUEST:
            return {
                ...state,
                isImportModalClosed: action.payload.status
            };
        case actions.OPEN_DRAWER:
            console.log('drawer', action.payload)
            return {
                ...state,
                drawer: action.payload.status,
                id: action.payload.id
            }
        case actions.SELECTED_PROJECT:
            return {
                ...state,
                selected_projectId: action.payload.id,
                selected_projectName: action.payload.name
            }
        case actions.SELECTED_ROWS:
            return {
                ...state,
                selected_rows: action.payload.ids
            }
        default:
            return state;
    }
}
