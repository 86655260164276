const actions = {
    CustomUser_REQUEST: 'LOGIN_REQUEST',
    CustomUser_SUCCESS: 'CustomUser_SUCCESS',

    CustomUserRequest: () => ({
        type: actions.CustomUser_REQUEST,
        payload: {},
    }),
    CustomUserSuccess: (data) => ({
        type: actions.CustomUser_SUCCESS,
        payload: {data},
    }),

};
export default actions;
