import React, {useRef, useState} from 'react';
import {Avatar, Button, Comment, Form, Input, List, Spin, Tooltip} from 'antd';
import moment from 'moment';
import {useSelector} from "react-redux";
import _ from "lodash";
import {api} from "@redux/api";
import notification2 from "@components/Notification2";

const {TextArea} = Input;

export const WorkorderComment = ({id, boxHeight}) => {

    const messagesEndRef = useRef(null)
    const [comments, setComments] = useState([]);

    const userImage = useSelector((state) => state.Auth.image);
    const userName = useSelector((state) => state.Auth.user);
    const userId = useSelector((state) => state.Auth.userId);
    const [openEditor, setOpenEditor] = useState(false);
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        if (id) {
            getWorkorderComments(id)
        }
    }, [id]);

    function getWorkorderComments(workorderId) {
        setLoading(true)
        return api.getWorkorderComment(workorderId).then(items => {
            if (_.get(items, 'status') === 200) {
                let data = items?.data.results;
                if (data) {
                    data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
                }
                setComments(data ? data : [])
            } else {
                notification2['error']({
                    message: 'error',
                    description: _.get(items, 'response.statusText'),
                });
            }
        }).finally(() => setLoading(false))
    }

    const CommentContent = (comments) => {
        // console.log('comments', comments)
        let data = comments.comment;
        const deleteWorkorderComment = (commentId) => {
            setLoading(true)
            api.deleteWorkorderComment(commentId).then(
                items => {
                    getWorkorderComments(id)
                }
            ).finally(() => setLoading(false))
        }
        console.log('CommentContent', data)
        return (

            <Comment
                // actions={actions}
                author={<a>{_.get(data, 'createdby_name')}</a>}
                avatar={
                    <Avatar
                        src={_.get(data, 'createdby_image')}
                    />
                }
                content={
                    _.get(data, 'content')
                }
                datetime={
                    [<div title={moment(_.get(data, 'modified_date')).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(_.get(data, 'modified_date')).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </div>,
                        <div key="comment-delete" title="delete" className={data.createdby === userId? '': 'hidden'}>
                            <a key="comment-delete" onClick={() => deleteWorkorderComment(data.id)}><i
                                className="ml-4 fas fa-trash-alt"></i></a>
                        </div>]
                }
            />
        )
    }

    // const actions = openEditor ? [<span key="comment-basic-reply-to" onClick={(e) => handleReplyTo(e, false)}>Close Reply</span>]
    //     : [<span key="comment-basic-reply-to" onClick={(e) => handleReplyTo(e, true)}>Reply</span>];

    // function handleReplyTo(e, status) {
    //     // console.log('status: ' + status)
    //     setOpenEditor(status)
    // }

    const Editor = () => {
        const [value, setValue] = useState(null);

        function handleChange(e) {
            setValue(e.target.value)
        }

        function handleSubmit() {
            setLoading(true)
            let row = {
                content: value,
                workorder: id
            }
            return api.createWorkorderComment(row).then(
                response => {
                    getWorkorderComments(response.data.workorder)
                }
            ).catch(error => (
                error
            )).finally(
                () => setLoading(false)
            );

        }


        return (
            <Comment
                content={
                    <div >
                        <TextArea rows={3} onChange={handleChange} value={value} />
                        <Button htmlType="submit" onClick={handleSubmit} type="primary" className={'mt-2'}>
                            Add Comment
                        </Button>
                    </div>
                }
            />
        )
    }

    return (
        <Spin spinning={loading}>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: boxHeight ? boxHeight : '70vh',
            }}>
                <div style={{flex: 1, overflowY: 'auto', padding: '10px'}} className="comment-list">
                    <List
                        ref={messagesEndRef}
                        style={{overflowY: 'auto'}}
                        dataSource={comments}
                        header={`${comments?.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                        itemLayout="horizontal"
                        renderItem={e => <CommentContent comment={e}/>}
                    />
                    <div ref={messagesEndRef}/>
                </div>
                    <Editor/>
            </div>
        </Spin>
    );
};

