import {Button, Form, Select} from "antd";
import React, {useState} from "react";
import {CloseCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";

export const ScenarioFilters = (props) => {

    const [Facility, setFacility] = useState([]);
    const [Address, setAddress] = useState([]);
    const formRef = React.useRef()

    function handleFacility(Facility) {
        if (Facility?.includes('All')) {
            formRef.current.setFieldsValue({
                Facility: ['All']
            })
        }
        setFacility(Facility)

    }

    const facilityFilter = () => {
        return (
            <Select
                mode="single"
                allowClear
                value={Facility}
                style={{
                    width: '100%',
                }}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder="facility"
                onChange={handleFacility}
                options={props.facility_options}
                dropdownMatchSelectWidth={false}
            />
        )
    }

    function handleAddress(Address) {
        if (Address?.includes('All')) {
            formRef.current.setFieldsValue({
                Address: ['All']
            })
        }
        setAddress(Address)

    }

    const addressFilter = () => {
        return (
            <Select
                mode="single"
                allowClear
                value={Address}
                style={{
                    width: '100%',
                }}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder="address"
                onChange={handleAddress}
                options={props.address_options}
                dropdownMatchSelectWidth={false}
            />
        )
    }

    function handleSubmit(e) {
        console.log('submit', e)
        props.handleFilter(e)
    }

    function handleClearButtonClick() {
        formRef.current.setFieldsValue({
            address: '',
            facility: '',
        })
        props.handleClearButtonClick();
    }

    return <Form
        id="myForm"
        layout="horizontal"
        onFinish={handleSubmit}
        ref={formRef}
    >

        <Form.Item name={'Address'} className={'md:w-60 lg:w-60 sm:w-full'}>

            {addressFilter()}
        </Form.Item>

        <Form.Item name={'Facility'} className={'md:w-60 lg:w-60 sm:w-full'}>

            {facilityFilter()}
        </Form.Item>

        <Form.Item>
            <div className={'flex justify-center'}>
                <Button type="primary" className={'mr-5'} icon={<QuestionCircleOutlined/>}
                        htmlType="submit">Query</Button>
                <Button type="" icon={<CloseCircleOutlined/>} onClick={handleClearButtonClick}>Clear</Button>
            </div>
        </Form.Item>
    </Form>

}
