import React, {Component, Suspense, PureComponent} from 'react';
import {connect} from "react-redux";
import {Spinner} from "@components/Spinner";
import RecordAuditTimeline from "@components/Table/RecordAuditLog";
import {Pagination} from "antd";


class Log extends PureComponent {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <Suspense fallback={<Spinner/>}>
                <section className=" p-5">
                    <div className={'p-5'}>
                    <RecordAuditTimeline moduleId='All' module='All'/>
                    </div>
                </section>
            </Suspense>
    );
    }
    }


    const mapStateToProps = state => {
        return {}
}
const mapDispatchToProps = dispatch => {
    return {}
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Log);

