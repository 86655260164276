import React from 'react';

import {connect} from "react-redux";
import moment from "moment";
import {Button, Card, ConfigProvider, Input, Popconfirm} from "antd";
import enUSIntl from "antd/lib/locale/en_US";
import enUS from "antd/lib/locale/en_US";

import ProTable from "@ant-design/pro-table";
import {ModalForm, ProFormUploadDragger} from '@ant-design/pro-form';
import {jsforceResponse} from "@/config/httpService";
import _ from "lodash";
import DocViewer, {DocViewerRenderers} from "react-doc-viewer";


// import authActions from "@redux/Auth/actions";
import {api} from "@redux/api";
import {FileManagerWrapper} from "@components/FileManager/FileManagerWrapper";

import Modal from "antd/es/modal/Modal";
import Space from "antd/es/space";
import notification2 from "@components/Notification2";

class FileManager extends React.Component {
    actionRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            recordId: null,
            userId: null,
            authorization: [],
            visible: false,
            files: [],
            folders: [],
            selected_row: false,
            keyWord: '',
            pageNumber: 1,
            pageSize: 24,
            userid: null,
            name: null,
            modal_visible: false,
            preview_visible: false,
            preview_url: null,
            preview_name: null,
            raw_url: null,
            preview_type: null,
            preview_renderer: null
        }
    }

    componentDidMount() {
        this.handleGetData()
    }

    handleGetData = async () => {
        let query = 'page=' + this.state.pageNumber + '&page_size=' + this.state.pageSize + '&table_name=' + this.props.tableName + '&record_id=' + this.props.recordId;
        // let result = await api.getFiles(query)
        const [filesResult, foldersResult] = await Promise.all([api.getFiles(query), api.getFolders(query)]);
        jsforceResponse(filesResult, '', false, true);
        jsforceResponse(foldersResult, '', false, true);

        if (filesResult.status === 200 && foldersResult.status === 200) {
            this.setState({
                files: _.get(filesResult, 'data.results'),
                folders: _.get(foldersResult, 'data.results')
            });
        }

    }

    handleSearchFiles = async (e) => {
        let keyWord = e.target.value
        let query = 'page=' + this.state.pageNumber + '&page_size=' +
            this.state.pageSize + '&table_name=' + this.props.tableName + '&record_id=' + this.props.recordId + '&name=' + keyWord;

        let result = await api.getFiles(query)
        jsforceResponse(result, '', false, true)

        if (_.get(result, 'status') === 200) {
            this.setState({
                keyWord: keyWord,
                files: _.get(result, 'data.results')
            })
        }
    }

    handleSubmit = async (e) => {
        e['table_name'] = this.props.tableName;
        e['record_id'] = this.props.recordId;

        let files = _.get(e, 'file')

        console.log('file', e)
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                e['name'] = files[i].name
                e['image'] = files[i].originFileObj
                e['type'] = files[i].type
                e['parent'] = '4'
                let result1 = await api.uploadFile(e);
                jsforceResponse(result1, '', false, true)
                console.log('result1', result1)
                if (result1.status >= 200 && result1.status < 300) {
                    // this.props.setModalVisible(false);
                    await this.handleGetData();
                }
            }
        } else {
            notification2['error']({
                message: 'Please upload a file',
                description: ''
            });
        }
        this.setModalVisible(false)
    }

    setSelect = (id) => {
        this.setState({
            selected_row: id
        })
    }

    handleDownload = async (e, record) => {
        let downloadUrl = record.image

        let generate_url = await api.generate_url(downloadUrl, false).then(
            response => {
                let dataSource = response.data.url
                return dataSource
            }
        ).catch(error => (
            error
        ));

        console.log('generate_url', downloadUrl, generate_url)

        const link = document.createElement('a');
        link.href = generate_url;
        link.setAttribute('download', record.name);
        document.body.appendChild(link);
        link.click();
    }

    handleUrlDownload = async (name, url) => {
        let downloadUrl = url

        let generate_url = await api.generate_url(downloadUrl, false).then(
            response => {
                let dataSource = response.data.url
                return dataSource
            }
        ).catch(error => (
            error
        ));

        console.log('generate_url', downloadUrl, generate_url)

        const link = document.createElement('a');
        link.href = generate_url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    handleOpenNewTab = async (name, url) => {
        console.log('name, url', name, url)
        let downloadUrl = url

        let generate_url = await api.generate_url(downloadUrl, true).then(
            response => {
                let dataSource = response.data.url
                return dataSource
            }
        ).catch(error => (
            error
        ));

        console.log('generate_url', downloadUrl, generate_url)

        const link = document.createElement('a');
        link.href = generate_url;
        link.setAttribute('download', name);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    handlePreview = async (e, record) => {

        let downloadUrl = record.image
        
        let generate_url = await api.generate_url(downloadUrl, true).then(
            response => {
                let dataSource = response.data.url
                return dataSource
            }
        ).catch(error => (
            error
        ));

        this.setState({
            preview_url: generate_url,
            preview_type: record.type,
            preview_name: record.name,
            preview_visible: true,
            raw_url: record.image
        })

    }

    setVisible = (e, externalId, name) => {
        this.setState({
            isVisible: e,
            externalId: externalId,
            name: name
        })
    }

    closeVersion = (e) => {
        this.setState({
            isVisible: e
        })
    }

    setModalVisible = (e) => {
        this.setState({
            modal_visible: e
        })
    }

    setPreviewVisible = (e) => {
        this.setState({
            preview_visible: e
        })
    }


    deleteSelected = async (e, body) => {
        let result = await api.bulkDeleteFiles(body)
        // console.log('delete', result)

        this.actionRef.current.clearSelected();
        this.handleGetData()
        this.setState({
            visible: false
        })
    }

    deleteRecord = async (id) => {
        await api.deleteFile(id)

        this.actionRef.current.reload();
    }

    showPopconfirm = (e) => {
        this.setState({
            visible: true
        })
    }
    handleConfirmCancel = (e) => {
        this.setState({
            visible: false
        })
    }

    // folder
    handleCreateFolder = async () => {
        const folderName = prompt("Enter folder name:");
        if (folderName) {
            try {
                const result = await api.createFolder({
                    name: folderName,
                    table_name: this.props.tableName,
                    record_id: this.props.recordId
                });
                if (result.status === 201) {
                    notification2.success({
                        message: 'Folder created successfully',
                        description: ''
                    });
                    this.handleGetData();
                } else {
                    notification2.error({
                        message: 'Failed to create folder',
                        description: result.data.message || ''
                    });
                }
            } catch (error) {
                notification2.error({
                    message: 'Failed to create folder',
                    description: error.message || ''
                });
            }
        }
    }

    render() {
        const fields = [
            ...this.state.folders.map(folder => ({
                key: folder.id,
                id: folder.id,
                name: folder.name,
                type: 'folder',
                created_by: folder.createdby_name,
                created_date: moment(folder.created_date).format("MM-DD-YYYY HH:mm:ss"),
                modified_by: folder.modifiedby_name,
                modified_date: moment(folder.modified_date).format("MM-DD-YYYY HH:mm:ss"),
            })),
            ...this.state.files.map(file => ({
                key: file.id,
                id: file.id,
                name: file.name,
                type: file.type,
                image: _.split(file.image, 'https://facilitykpiclientfile.s3.us-east-2.amazonaws.com/facilitykpiclientfile/')[1],
                record_id: file.record_id,
                modified_by: file.lastModified_name,
                modified_date: moment(file.modified_date).format("MM-DD-YYYY HH:mm:ss"),
                created_by: file.createdby_name,
                created_date: moment(file.created_date).format("MM-DD-YYYY HH:mm:ss"),
            }))
        ];
        console.log('fields', fields)
        let columns = [];

        columns = [
            {
                title: 'key',
                dataIndex: 'id',
                key: 'id',
                hideInTable: true,
                editable: false,
                search: false,
                importIngore: true,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => {
                    let collator = new Intl.Collator();
                    return collator.compare(a.name, b.name);
                },
                render: text => (<a>{text}</a>)
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: text => (<a>{text ? text.split('.')[text.split('.').length - 1] : ''}</a>)
            },
            {
                title: 'Modified Date',
                dataIndex: 'modified_date',
                width: '8rem',
                key: 'modified_date',
                sorter: (a, b) => {
                    let collator = new Intl.Collator();
                    return collator.compare(a.modified_date, b.modified_date);
                },
                render: text => (<div>{text}</div>)
            },
            {
                title: 'Modified By',
                dataIndex: 'modified_by',
                width: '5rem',
                key: 'modified_by',
                sorter: (a, b) => {
                    let collator = new Intl.Collator();
                    return collator.compare(a.modified_by, b.modified_by);
                },
                render: text => (<div>{text}</div>)
            },
            {
                title: 'Create Date',
                dataIndex: 'createddate',
                key: 'createddate',
                width: '8rem',
                sorter: (a, b) => {
                    let collator = new Intl.Collator();
                    return collator.compare(a.createddate, b.createddate);
                },
                render: text => (<div>{text}</div>)
            },
            {
                title: 'Create By',
                width: '5rem',
                dataIndex: 'accloud__uploaded_by__c',
                key: 'accloud__uploaded_by__c',
                sorter: (a, b) => {
                    let collator = new Intl.Collator();
                    return collator.compare(a.accloud__uploaded_by__c, b.accloud__uploaded_by__c);
                },
                render: text => (<div>{text}</div>)
            },
            {
                title: '',
                valueType: 'option',
                width: '4rem',
                importIngore: true,
                render: (text, record, _, action) => (
                    <div className={'flex item-center content-center'}>
                        <a onClick={() => this.handleDownload(text, record)} className={'mr-2 w-full'}>
                            <div className="flex items-center text-orange-500 relative justify-evenly">
                                <div>
                                    <i className="fas fa-download" style={{fontSize: '14px'}}></i>
                                </div>
                            </div>
                        </a>
                        <a onClick={() => this.handlePreview(text, record)} className={'mr-2 w-full'}>
                            <div className="flex items-center text-orange-500 relative justify-evenly">
                                <div>
                                    <i className="far fa-eye" style={{fontSize: '14px'}}></i>
                                </div>
                            </div>
                        </a>


                    </div>
                ),
            },
        ]

        const fileprops = {
            multiple: true,
        };

        return (

            <FileManagerWrapper>
                <div style={{padding: '10px'}}>
                    <div className="slds-grid slds-grid_vertical">
                        <Card
                            title={
                                <div className={'flex justify-between'}>
                                    <Input placeholder="Search..." onChange={this.handleSearchFiles}
                                           style={{width: 200}} suffix={<i className="fas fa-search"></i>}/>


                                    <div className={'flex'}>
                                        <a onClick={() => this.setModalVisible(true)}
                                           style={{color: '#1890ff', fontSize: '14px'}}>
                                            <i className="fas fa-upload" style={{fontSize: '14px'}}></i>&nbsp;Add Files
                                        </a>
                                        <a onClick={this.handleCreateFolder}
                                           style={{marginLeft: '15px', fontSize: '14px'}}>
                                            <i className="fas fa-folder-plus" style={{fontSize: '14px'}}></i>&nbsp;New
                                            Folder
                                        </a>
                                        <a onClick={this.handleGetData} style={{marginLeft: '15px', fontSize: '14px'}}>
                                            <i className="fas fa-sync-alt" style={{fontSize: '14px'}}></i>&nbsp;Refresh
                                        </a>

                                    </div>
                                </div>
                            }
                        >
                            <ConfigProvider locale={enUSIntl}>
                                <ProTable
                                    request={async (params = {}, sort, filter) => {
                                        if (this.state.recordId) {
                                            this.setState({
                                                pageNumber: params.current,
                                                pageSize: params.pageSize
                                            }, function () {
                                                this.handleGetData()
                                            })
                                        }

                                    }}
                                    className={'filemanager-table'}
                                    actionRef={this.actionRef}
                                    rowClassName={(record) => this.state.selected_row === record.id ? 'selected_row' : ''}
                                    rowKey="id"
                                    dataSource={fields}
                                    columns={columns}
                                    search={false}
                                    toolBarRender={false}
                                    rowSelection={{
                                        selections: false,
                                        onChange: this.onSelectChange,
                                    }}
                                    pagination={{
                                        showQuickJumper: true,
                                        pageSize: 25,
                                        showTotal: (total, range) => (
                                            <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
                                        ),
                                    }}
                                    onRow={(record) => {
                                        return {onClick: event => this.setSelect(record.id)}
                                    }}
                                    tableAlertOptionRender={(e) => {
                                        let rowIds = _.get(e, 'selectedRowKeys', []);
                                        let rows = _.get(e, 'selectedRows', []);

                                        if (!rows[0]) {
                                            rows = []
                                            if (rowIds) {
                                                rowIds.map(e => {
                                                    return rows.push(e)
                                                })
                                            } else {
                                                rows = []
                                            }
                                        }

                                        // console.log('rows', exportRow)

                                        return (
                                            <Space size={16}>
                                                <Popconfirm
                                                    title="Do you want to delete this record"
                                                    visible={this.state.visible}
                                                    onConfirm={() => this.deleteSelected(e, rows)}
                                                    onCancel={() => this.handleConfirmCancel()}
                                                >
                                                    <Button type={'primary'} onClick={() => this.showPopconfirm()}>Delete
                                                        Selected
                                                    </Button>
                                                </Popconfirm>
                                            </Space>
                                        );
                                    }}
                                />
                            </ConfigProvider>
                        </Card>
                    </div>
                    <ConfigProvider locale={enUS}>
                        <ModalForm
                            onFinish={this.handleSubmit}
                            visible={this.state.modal_visible}
                            autoFocusFirstInput
                            title={'Upload a File'}
                            modalProps={{
                                destroyOnClose: true,
                                onCancel: () => {
                                    this.setModalVisible(false)
                                },
                            }}
                        >

                            <ProFormUploadDragger
                                {...fileprops}
                                label="Upload File"
                                name="file"
                                title="Upload File"
                            />
                        </ModalForm>
                    </ConfigProvider>

                    <ConfigProvider locale={enUS}>
                        <Modal width={'80%'}
                               title={<div className="flex justify-content">
                                   <Button type={'primary'} onClick={() =>
                                       this.handleUrlDownload(this.state.preview_name, this.state.raw_url)}>
                                       <i className="fas fa-download pr-2" style={{fontSize: '14px'}}></i>
                                       Download
                                   </Button>
                                   <Button type={''} onClick={() =>
                                       this.handleOpenNewTab(this.state.preview_name, this.state.raw_url)}>
                                       <i className="fas fa-external-link-alt pr-2" style={{fontSize: '14px'}}></i>
                                       Open in a New Tab
                                   </Button>
                               </div>}
                               bodyStyle={{height: 1000}}
                               footer={false}
                               visible={this.state.preview_visible}
                               onCancel={() => this.setPreviewVisible(false)}
                        >

                            {
                                this.state.preview_type?.startsWith('image') || this.state.preview_type?.endsWith('pdf') ?
                                    <iframe src={this.state.preview_url} width={'100%'} height={'100%'}/> :
                                    <DocViewer pluginRenderers={DocViewerRenderers}
                                               style={{width: '100%', height: '100%'}}
                                               prefetchMethod="GET"
                                               documents={[
                                                   {uri: this.state.preview_url, fileType: this.state.preview_type}
                                               ]}/>
                            }


                        </Modal>
                    </ConfigProvider>

                </div>
            </FileManagerWrapper>

        )
    }
}

const mapStateToProps = state => {
    // console.log('props', state)
    return {
        authToken: _.get(state.Auth, 'access')
    }
}
const mapDispatchToProps = dispatch => {
    return {
        // setModalVisible: (e) => dispatch(authActions.SetModalVisible(e))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileManager);
