import React from "react";
import { DatePicker, Input, Popover, Segmented, Select, Space } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from 'lodash';
import moment from "moment";
// import SelectAddNew from "@/components/SelectAddNew";
import { useControllableValue } from 'ahooks'

const options = [{
  value: 'Baseline Average',
  label: 'Baseline Average'
}, {
  value: 'Post-Period Average',
  label: 'Post-Period Average'
},
{
  value: 'Custom',
  label: 'Custom'
}
]

const SelectAddNewFn = (props) => {


  const onNameChange = event => {
    const name = event.target.value;
    props.onSelect('Custom', name);
};

const handleChange = (value) => {
  props.onSelect(value, null)
}
  return <div className="flex">
    <Select placeholder={props.placeholder} value={props.value} onChange={handleChange} options={options} />
    {props.value === 'Custom' ? <Input prefix={'$ '} style={{width:'80px'}} value={props.name} onChange={onNameChange}/>:''}
  </div>
}

const dateValuesFormatMoment = (values) => {
  if (!Array.isArray(values)) {
    return []
  }
  return values.map(v => v ? moment(v) : v)
}

export const SavingAnalysisForm = (props) => {

  const [state = {}, setState] = useControllableValue(props, {
    // defaultValue: {
    //   elec_Option: 'Baseline Average',
    //   gas_Option: 'Baseline Average',
    // }
  })



  const setCuurentState = (values) => {
    setState({
      ...state,
      ...values,
    })
  }

  // useEffect(() => {
  //   if (typeof onChange === 'function') {
  //     onChange(state)
  //   }
  // }, [state])

  const onDateChange = (dates, key) => {
    setCuurentState({
      [key]: Array.isArray(dates) ? dates.map(i => i.format('YYYY-MM')) : [],
    })
  }

  const onNSegmentGasChange = (e) => {
    setCuurentState({
      gas_n_segments: e,
      gas_breakpoint0: null,
      gas_breakpoint1: null,
      gas_breakpoint2: null,
      gas_breakpoint3: null
    })
  }

  const onNSegmentElecChange = (e) => {
    setCuurentState({
      elec_n_segments: e,
      elec_breakpoint0: null,
      elec_breakpoint1: null,
      elec_breakpoint2: null,
      elec_breakpoint3: null
    })
  }
  const onGasBreakpointSegmentChange = (e) => {
    // console.log('onBreakpointSegmentChange', e)
    if (e === 'Segments') {
        setCuurentState({
            gas_usedModuleValue: e,
            gas_breakpoint0: null,
            gas_breakpoint1: null,
            gas_breakpoint2: null,
            gas_breakpoint3: null
        })
    } else {
      setCuurentState({
            gas_usedModuleValue: e,
            gas_n_segments: 2,
        })
    }
}

  const handlePriceSelect = (price_type, utility_type, custom_price) => {
    // let base_data_elec = [], post_data_elec = [],
    //   base_data_gas = [], post_data_gas = [];

    if (utility_type === 'Gas') {
      // base_data_gas = state.gas_base
      // post_data_gas = state.gas_post
      // const {
      //   price_type: price_type_gas,
      //   average_price: average_price_gas
      // } = handlePriceTypeCal(price_type, custom_price, base_data_elec, post_data_elec)

      setCuurentState({
        // gas_Price: average_price_gas,
        gas_Option: price_type,
        gas_Custom_Price: custom_price
      })
    }
    if (utility_type === 'Elec') {
      // base_data_elec = state.elec_base
      // post_data_elec = state.elec_post
      // const {
      //   price_type: price_type_elec,
      //   average_price: average_price_elec
      // } = handlePriceTypeCal(price_type, custom_price, base_data_elec, post_data_elec)

      setCuurentState({
        // elec_Price: average_price_elec,
        elec_Option: price_type,
        elec_Custom_Price: custom_price
      })
    }

  }

  const onElecBreakpointSegmentChange = (e) => {
    // console.log('onBreakpointSegmentChange', e)
    if (e === 'Segments') {
      setCuurentState({
        elec_usedModuleValue: e,
        elec_breakpoint0: null,
        elec_breakpoint1: null,
        elec_breakpoint2: null,
        elec_breakpoint3: null
      })
    } else {
      setCuurentState({
        elec_usedModuleValue: e,
        elec_n_segments: 2,
      })
    }
  }

  const onElecBreakpointChange = (e, index) => {
    const Breakpoint = ['elec_breakpoint0', 'elec_breakpoint1', 'elec_breakpoint2', 'elec_breakpoint3'];
    setCuurentState({
      [Breakpoint[index]]: e.target.value * 1,
    });
  }
  const onGasBreakpointChange = (e, index) => {
    const Breakpoint = ['gas_breakpoint0', 'gas_breakpoint1', 'gas_breakpoint2', 'gas_breakpoint3'];
    setCuurentState({
        [Breakpoint[index]]: e.target.value * 1,
    });
}
  return (
    <div style={{ width: '100%' }} className={'w-full flex justify-between'}>

      <div className={'flex flex-wrap'}>
        <div className={'w-80 mb-4'}>
          <div className={'flex items-center'}>Elec Baseline Period
            <Popover content={
              <div>
                {'elec available from ' + _.get(state?.daterange, 'date_range_elec_min') + ' to ' + _.get(state?.daterange, 'date_range_elec_max')}
              </div>
            } trigger="hover"
              className={''}
            >
              <InfoCircleOutlined style={{ color: 'orange', marginLeft: 4 }} />
            </Popover>
          </div>
          <DatePicker.RangePicker picker='month'
            value={dateValuesFormatMoment(state.elec_Baseline_Date)}
            onChange={(d) => onDateChange(d, 'elec_Baseline_Date')} />
        </div>

        <div className={'w-80 ml-2'}>
          <div className={'flex items-center'}>Electricity Post
            Period <Popover
              content={
                <div>
                  <div>
                    {'elec available from ' + _.get(state.daterange, 'date_range_elec_min') + ' to ' + _.get(state.daterange, 'date_range_elec_max')}
                  </div>
                </div>
              } trigger="hover"
              className={'pl-1'}
            >
              <InfoCircleOutlined style={{ color: 'orange', marginLeft: 4 }} />
            </Popover></div>
          <DatePicker.RangePicker picker='month'
            value={dateValuesFormatMoment(state.elec_Post_Date)}
            onChange={(d) => onDateChange(d, 'elec_Post_Date')} />
        </div>

        <div className={'ml-2'}>
          <div className={''}>Elec Price</div>
          <SelectAddNewFn
            placeholder={'select elec price'}
            default={'Baseline Average'}
            type='Elec'
            value={state.elec_Option}
            name={state.elec_Custom_Price}
            onSelect={(e, customPrice) => handlePriceSelect(e, 'Elec', customPrice)}
          />
        </div>

        <div className={'mr-2'}>
          <div className={''}>How to Break Line</div>
          <div>
            <Select
              onChange={onElecBreakpointSegmentChange}
              value={state.elec_usedModuleValue}
              defaultValue={'Segments'}
            >
              <Select.Option value="Segments">Segments</Select.Option>
              <Select.Option value="Breakpoints">Breakpoints</Select.Option>
            </Select>
          </div>
        </div>
        <div>

          {state.elec_usedModuleValue === 'Breakpoints' ?
            <div className={''}>
              <div className={'flex items-center'}>Specify
                Breakpoints<Popover
                  content={
                    <div>
                      transition points between different linear segments,
                      cannot
                      combined with segements
                    </div>
                  } trigger="hover"
                  className={''}
                >
                  <InfoCircleOutlined style={{ color: 'orange', marginLeft: 4 }} />
                </Popover></div>

              <div className={'flex'}>
                <Space.Compact>
                  <Input
                    type='integer'
                    allowClear
                    style={{ width: '8rem' }}
                    value={state.elec_breakpoint1}
                    onChange={(e) => onElecBreakpointChange(e, 1)}
                  />
                  <Input
                    type='integer'
                    allowClear
                    style={{ width: '8rem' }}
                    value={state.elec_breakpoint2}
                    onChange={(e) => onElecBreakpointChange(e, 2)}
                  />
                  <Input
                    type='integer'
                    allowClear
                    style={{ width: '8rem' }}
                    value={state.elec_breakpoint3}
                    onChange={(e) => onElecBreakpointChange(e, 3)}
                  />
                </Space.Compact>
              </div>
            </div> : ''}

          {state.elec_usedModuleValue === 'Segments' ? <div className={''}>
            <div className={'font-medium pl-1 flex items-center'}>Segments<Popover
              content={
                <div>
                  use segments will ignore breakpoints, cannot combined with
                  breakpoints
                </div>
              } trigger="hover"
            >
              <InfoCircleOutlined style={{ color: 'orange', marginLeft: 4 }} />
            </Popover></div>
            <Segmented
              defaultValue={2}
              options={[1, 2, 3]}
              value={state.elec_n_segments}
              onChange={onNSegmentElecChange}
            />
          </div> : ''}
        </div>

      </div>


      <div className={'flex flex-wrap'}>
        <div className={'w-80 mb-4'}>
          <div className={'flex items-center'}>Gas Baseline Period
            <Popover content={
              <div>
                {'elec available from ' + _.get(state.daterange, 'date_range_gas_min') + ' to ' + _.get(state.daterange, 'date_range_gas_max')}
              </div>
            } trigger="hover"
              className={''}
            >
              <InfoCircleOutlined style={{ color: 'orange', marginLeft: 4 }} />
            </Popover>
          </div>
          <DatePicker.RangePicker picker='month'
            value={dateValuesFormatMoment(state.gas_Baseline_Date)}
            onChange={(d) => onDateChange(d, 'gas_Baseline_Date')} />
        </div>

        <div className={'w-80 ml-2'}>
          <div className={'flex items-center'}>Electricity Post
            Period <Popover
              content={
                <div>
                  <div>
                    {'gas available from ' + _.get(state.daterange, 'date_range_gas_min') + ' to ' + _.get(state.daterange, 'date_range_gas_max')}
                  </div>
                </div>
              } trigger="hover"
              className={'pl-1'}
            >
              <InfoCircleOutlined style={{ color: 'orange', marginLeft: 4 }} />
            </Popover></div>
          <DatePicker.RangePicker picker='month'
            value={dateValuesFormatMoment(state.gas_Post_Date)}
            onChange={(d) => onDateChange(d, 'gas_Post_Date')} />
        </div>

        <div className={'ml-2'}>
          <div className={''}>Gas Price</div>
          <SelectAddNewFn
            placeholder={'select gas price'}
            default={'Baseline Average'}
            type='Gas'
            value={state.gas_Option}
            name={state.gas_Custom_Price}
            onSelect={(e, customPrice) => handlePriceSelect(e, 'Gas', customPrice)}
          />
        </div>

        <div className={'mr-2'}>
          <div className={''}>How to Break Line</div>
          <div>
            <Select
              onChange={onGasBreakpointSegmentChange}
              value={state.gas_usedModuleValue}
              defaultValue={'Segments'}
            >
              <Select.Option value="Segments">Segments</Select.Option>
              <Select.Option value="Breakpoints">Breakpoints</Select.Option>
            </Select>
          </div>
        </div>
        <div>

          {state.gas_usedModuleValue === 'Breakpoints' ?
            <div className={''}>
              <div className={'flex items-center'}>Specify
                Breakpoints<Popover
                  content={
                    <div>
                      transition points between different linear segments,
                      cannot
                      combined with segements
                    </div>
                  } trigger="hover"
                >
                  <InfoCircleOutlined style={{ color: 'orange', marginLeft: 4 }} />
                </Popover></div>

              <div className={'flex'}>
                <Space.Compact>
                  <Input
                    type='integer'
                    allowClear
                    style={{ width: '8rem' }}
                    value={state.gas_breakpoint1}
                    onChange={(e) => onGasBreakpointChange(e, 1)}
                  />
                  <Input
                    type='integer'
                    allowClear
                    style={{ width: '8rem' }}
                    value={state.gas_breakpoint2}
                    onChange={(e) => onGasBreakpointChange(e, 2)}
                  />
                  <Input
                    type='integer'
                    allowClear
                    style={{ width: '8rem' }}
                    value={state.gas_breakpoint3}
                    onChange={(e) => onGasBreakpointChange(e, 3)}
                  />
                </Space.Compact>
              </div>
            </div> : ''}

          {state.elec_usedModuleValue === 'Segments' ? <div className={''}>
            <div className={'font-medium pl-1 flex items-center'}>Segments<Popover
              content={
                <div>
                  use segments will ignore breakpoints, cannot combined with
                  breakpoints
                </div>
              } trigger="hover"
            >
              <InfoCircleOutlined style={{ color: 'orange', marginLeft: 4 }} />
            </Popover></div>
            <Segmented
              defaultValue={2}
              options={[1, 2, 3]}
              value={state.gas_n_segments}
              onChange={onNSegmentGasChange}
            />
          </div> : ''}
        </div>

      </div>
    </div>
  );
}
