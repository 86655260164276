import React, {Component, PureComponent} from 'react';
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import LayoutContent from "../../components/utility/layoutContent";
import GlobalSearchInput from "@components/GlobalSearchInput";
import {Tag} from "antd";
import ProList from '@ant-design/pro-list';
import _ from "lodash";

class FAQ extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
        }
    }

    onSearch = (data) => {

        let dataSource = [];
        data.forEach(e => {
            let tags = JSON.parse(_.get(e, 'tag', '[]'))

            dataSource.push({
                title: <a>{e.issue ? e.issue : (e.name ? e.name : '')}</a>,
                description: <>
                    {tags ? tags.map(t => <Tag>{t.content}</Tag>) : ''}
                </>,
                content: <div> {e.description}</div>
            })
        })
        // console.log('onSearch3', dataSource)
        this.setState({
            dataSource
        })
    }

    render() {

        const dataSource = this.state.dataSource;

        return (
            <LayoutContentWrapper style={{height: '100vh'}}>
                <LayoutContent>
                    <div className={'mt-5'}>
                        <GlobalSearchInput onSearch={this.onSearch}/>
                    </div>
                    <article className="markdown">

                        <ProList
                            itemLayout="vertical"
                            rowKey="id"
                            headerTitle="Result"
                            dataSource={dataSource}
                            pagination={{
                                pageSizeOptions: [10, 20, 50, 100, 1000],
                                showQuickJumper: true,
                                pageSize: 24,
                                showTotal: (total, range) => (
                                    <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
                                ),
                            }}
                            metas={{
                                title: {},
                                description: {},
                                content: {},
                            }}
                        />

                    </article>
                </LayoutContent>
            </LayoutContentWrapper>
        );
    }
}

export default FAQ;
