import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import {Checkbox, Select, Tag} from "antd";
import Input from "antd/es/input";
import {ProFormSelect} from "@ant-design/pro-form";
import _ from "lodash";
import CompanyLookup from "@containers/Lookup/CompanyLookup";
import {roleOptions} from "@containers/management/UserManagement/Profile/ProfileFormFields";

const {Option} = Select;
const facility_items = store.getState().Facility.data;
const company_items = store.getState().Company.data;

export const CustomUserProps = {
    title: 'User',
    name: 'user',
    description: 'Create User or Update User Profile',
}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}


export const CustomUserColumns =()=>{
    return  [
        {
            title: 'key',
            dataIndex: 'id',
            key: 'id',
            valueType: 'indexBorder',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: true,
            width: '4%'
        },
        {
            title: 'UserName',
            dataIndex: 'username',
            key: 'username',
            search: false,
            edit: true,
            type: 'text',
            width: '15rem',
            sorter: (a, b) => a.name?.localeCompare(b.name),
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: 'required',
                    },
                ],
            },
            render: (text, record, _, action) => (
                <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
            )
        },
        {
            title: 'Is_Active',
            dataIndex: 'is_active',
            key: 'is_active',
            search: false,
            edit: true,
            width: '9rem',
            type: 'text',
            sorter: (a, b) => a?.is_active - b?.is_active,
            render:  (text, row) => {
                return <Checkbox checked={row.is_active}/>
            }
        },

        {
            title: 'Name',
            dataIndex: 'first_name',
            key: 'first_name',
            edit: true,
            required: true,
            search: false,
            width: '15rem',
            type: 'text',
            sorter: (a, b) => a?.first_name.localeCompare(b?.first_name),
            render: (text, row) => (
                <span>{row.first_name} {row.middle_name && row.middle_name !== 'null' ? row.middle_name : ''} {row.last_name}</span>
            )
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            edit: true,
            required: true,
            search: false,
            sorter: (a, b) => a?.role -  b?.role,
            valueType: 'select',
            width: '12rem',
            valueEnum: {
                1: {text: 'Super User'},
                2.1: {text: 'Account Manager'},
                2.2: {text: 'Portfolio Manager'},
                3: {text: 'Company Admin'},
                4: {text: 'Standard User'}
            },
        },
        {
            title: 'Profile',
            dataIndex: 'profile',
            key: 'profile',
            edit: true,
            required: true,
            search: false,
            type: 'Lookup',
            width: '15rem',
            sorter: (a, b) => a?.profile_name?.name.localeCompare(b?.profile_name),
            render: (text, row) => {
                return <div>{_.get(row, 'profile_name')}</div>
            }
        },
        {
            title: 'Profile',
            dataIndex: 'profile_name',
            key: 'profile_name.name',
            edit: false,
            hideInTable: true,
            search: false,
            type: 'Lookup'
        },
        // {
        //     title: 'Email',
        //     dataIndex: 'email',
        //     key: 'email',
        //     edit: true,
        //     required: true,
        //     search: false,
        //     type: 'text',
        //     sorter: (a, b) => a?.email.localeCompare(b?.email),
        //     hideInTable: window.innerWidth < 760,
        //     input: <Input prefix={<div style={{color: 'red'}}>*</div>}/>
        // },
        {
            title: 'Company',
            dataIndex: 'company_name.id',
            key: 'company',
            edit: true,
            required: true,
            search: false,
            hideInTable: true,
            sorter: (a, b) => a?.company_name.localeCompare(b?.company_name),
            type: 'Lookup',
            render: (text, row) => {
                return <div className={'word-wrap'}>{_.get(row, 'company_name.name')}</div>
            },
            renderFormItem: () => <CompanyLookup/>
        },
        {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company',
            edit: true,
            required: true,
            search: false,
            type: 'Lookup',
            width: '15rem',
            ellipsis: true,
            hideInTable: window.innerWidth < 760,
            render: (text, row) => {
                return <div>{_.get(row, 'company_name')}</div>
            },
            renderFormItem: (_, {isEditable}) => {
                let record = _.entity?.company_name;
                return <CompanyLookup record={record}/>
            },
        },
        // {
        //     title: 'Facility',
        //     dataIndex: 'user',
        //     key: 'user',
        //     required: true,
        //     search: false,
        //     edit: true,
        //     type: 'select',
        //     valueType: 'select',
        //     hideInTable: window.innerWidth < 760,
        //     width: '20%',
        //     render: (text, row) => {
        //         // console.log('row', row)
        //         let facilities = row?.user;
        //         return <ul className={'max-h-48 overflow-y-auto shadow-inner'}>{facilities.map((facility) => {
        //             return <li key={facility} className={'text-base text-slate-600'}>{facility.length > 0 ? facility.split('_')[1] : ''}</li>
        //         })}</ul>
        //     },
        //     input: (<ProFormSelect options={facility_items.map(item => (
        //         {value: item.Id, label: item.value, key: item.Id}
        //     ))} style={{width: '16rem'}} mode="multiple" label={'Facility'} name={'user'}/>)
        // },

    ];
}
