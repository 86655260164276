import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import _ from 'lodash';
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import CompanyLookup from "@containers/Lookup/CompanyLookup";
import {TwoDecimalFormat} from "@components/Format";

const company_items = store.getState().Auth.company;

export const FacilityProps = {
    title: 'Facility',
    name: 'facility',
    description: ''
}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}


export const FacilityColumns =()=> {
    return [
        {
            title: 'key',
            dataIndex: 'id',
            key: 'id',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: true,
            width: '4%'
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
            hideInTable: true,
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
            hideInTable: true,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            search: false,
            edit: true,
            width: 150,
            sorter: (a, b) => a.name?.localeCompare(b.name),
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: 'required',
                    },
                ],
            },
            renderFormItem: () => <TextArea rows={1}/>,
            render: (text, record, _, action) => (
                <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
            )
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            edit: true,
            required: true,
            search: false,
            hideInTable: true,
            type: 'Lookup',
            render: (text, row) => {
                return <>{_.get(row, 'company_name.name')}</>
            },
            renderFormItem: () => <CompanyLookup/>
        },
        {
            title: 'Company',
            dataIndex: 'company_name',

            key: 'company',
            edit: true,
            required: true,
            search: false,
            type: 'Lookup',
            width: '150px',
            hideInTable: window.innerWidth < 760,
            render: (text, row) => {
                return <>{_.get(row, 'company_name')}</>
            },
            renderFormItem: (_, {isEditable}) => {
                let record = _.entity.company_name ? _.entity.company_name : '';
                return <CompanyLookup record={record}/>
            },
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            edit: true,
            required: true,
            filters: true,
            onFilter: true,
            search: false,
            valueType: 'select',
            valueEnum: {
                Hospital: {text: 'Hospital'},
                Office: {text: 'Office'},
                'Medical Office Building': {text: 'Medical Office Building'},
                'Manufacturing': {text: 'Manufacturing'},
                'Dormitory': {text: 'Dormitory'},
                'Clinic / Outpatient Health': {text: 'Clinic / Outpatient Health'},
                'College/University': {text: 'College/University'},
                'Fast Food': {text: 'Fast Food'},
                'Grocery Store': {text: 'Grocery Store'},
                'Hotel/Motel/Inn': {text: 'Hotel/Motel/Inn'},
                'K-12 School': {text: 'K-12 School'},
                'Library': {text: 'Library'},
                'Mall': {text: 'Mall'},
                'Nursing/Assisted Living': {text: 'Nursing/Assisted Living'},
                'Religious Worship / Church': {text: 'Religious Worship / Church'},
                'Restaurant': {text: 'Restaurant'},
                'Retail (non-Mall)': {text: 'Retail (non-Mall)'},
                Other: {text: 'Other'},
            },
            hideInTable: window.innerWidth < 760,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            edit: true,
            required: true,
            search: false,
            width: '250px',
            hideInTable: window.innerWidth < 760,
        },

        // {
        //     title: 'Tag',
        //     dataIndex: 'tag',
        //     key: 'tag',
        //     search: false,
        //     editable: false,
        //     render: (text, record) => {
        //         console.log('tag text:', text, typeof (text))
        //         let tags = (text && text !== '-' && typeof (text) !== 'object') ? JSON.parse(text) : [];
        //         console.log('tags', tags)
        //         return <TagInputWrapper>
        //             <div className="AddAndDelete">
        //                 <div className="main">
        //                     <DraggableArea
        //                         tags={tags}
        //                         render={({tag, index}) => (
        //                             <div className="tag">
        //                                 <img
        //                                     className="delete"
        //                                     srcSet={`${deleteBtn2x} 2x`}
        //                                 />
        //                                 {tag.content}
        //                             </div>
        //                         )}
        //                     />
        //                 </div>
        //             </div>
        //         </TagInputWrapper>
        //     }
        // },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
            render: (text, record, _, action) => (
                <>{TwoDecimalFormat(text)}</>
            )
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
            render: (text, record, _, action) => (
                <>{TwoDecimalFormat(text)}</>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
            renderFormItem: () => <TextArea rows={1}/>,
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
        },
        {
            title: 'Modified Date',
            dataIndex: 'modified_date',
            key: 'modified_date',
            editable: false,
            importIngore: true,
            width: '150px',
            hideInTable: window.innerWidth < 760,
            sorter: (a, b) => a.modified_date - b.modified_date,
            render: (text, record) => (
                moment(record.modified_date).format('YYYY-MM-DD')
            )
        },
        {
            title: '',
            valueType: 'option',
            width: '6rem',
            importIngore: true,
            render: (text, record, _, action) => (
                <div className={'space-around'}>
                    {/*<a*/}
                    {/*    key="editable"*/}
                    {/*    onClick={() => (action?.startEditable?.(record.id))}*/}
                    {/*>*/}
                    {/*    <i className="fas fa-edit text-lg"></i>*/}
                    {/*</a>*/}
                    <a onClick={(e) => {
                        e.stopPropagation();
                        return action ? renderDrawer(text, record.id) : ''
                    }}>
                        <i className="fas fa-expand-arrows-alt text-lg"></i>
                    </a>
                </div>
            ),
        },


    ];
}

