import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import MapGL, {Marker, NavigationControl} from 'react-map-gl'
import Geocoder from 'react-map-gl-geocoder'
import mapboxgl from 'mapbox-gl';
import {Tooltip} from "antd";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapboxMaps = (props) => {
    const [viewport, setViewport] = useState({
        latitude: 35.7577,
        longitude: -100.4376,
        zoom: props.zoom
    });
    const mapRef = useRef();
    const handleViewportChange = useCallback(
        (newViewport) => setViewport(newViewport),
        []
    );
    const [markers, setMarkers] = useState([])

    useEffect(() => {
            let data = [];
            // console.log('MapboxMaps 2', props.data)
            if (props.data.length > 0) {
                for (let i = 0; i < props.data.length; i++) {
                    if (props.data[i].latitude && props.data[i].longitude) {
                        data.push({
                            name: props.data[i].name,
                            isCurrent: props.data[i].isCurrent,
                            latitude: props.data[i].latitude * 1,
                            longitude: props.data[i].longitude * 1,
                            zoom: 10
                        })
                    }
                }
                // console.log('MapboxMaps 2', data)
                setMarkers(data)
            }

        }, [props.data]
    )

    // const [weatherPoints, setWeatherPoints] = useState([]);

    // useEffect(() => {
    //     // Assuming fetchWeatherData is imported and set up correctly
    //     const fetchData = async () => {
    //         try {
    //             const points = await fetchWeatherData(10);
    //             setWeatherPoints(points);
    //         } catch (error) {
    //             console.error("Error fetching weather data: ", error);
    //             setWeatherPoints([])
    //         }
    //     };
    //     fetchData();
    // }, []);

    // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
    const handleGeocoderViewportChange = useCallback(
        (newViewport) => {
            const geocoderDefaultOverrides = {transitionDuration: 1000};

            return handleViewportChange({
                ...newViewport,
                ...geocoderDefaultOverrides
            });
        },
        [handleViewportChange]
    );

    const size = 20;
    const pinStyle = {
        cursor: "pointer",
        fill: "#4090FF",
        stroke: "none"
    };

    const pinStyleCurrent = {
        cursor: "pointer",
        fill: "#FDCDE1",
        stroke: "none"
    };

    const ICON = (color) => {
        return <i className="fas fa-map-pin" style={{color: color}}></i>
    };

    // console.log('markers', markers)
    return (

        <>
            {/*<div style={{borderBottom: '1px solid #e9edf4', marginBottom: '1rem'}}>*/}
            {/*    <div className={'small-title_piechart'}>Facility</div>*/}

            {/*</div>*/}
            <div style={{height: props.height}}>
                <MapGL
                    ref={mapRef}
                    {...viewport}
                    width="100%"
                    height="100%"
                    onViewportChange={handleViewportChange}
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    mapStyle="mapbox://styles/vincentzhang2020/clo4rjece00gz01oycs6p0bhg"
                    dragPan={!props.isZoomEnabled}
                    dragRotate={props.isZoomEnabled}
                    scrollZoom={props.isZoomEnabled}
                    touchZoom={props.isZoomEnabled}
                    touchRotate={props.isZoomEnabled}
                    keyboard={props.isZoomEnabled}
                    doubleClickZoom={props.isZoomEnabled}
                >
                    <div style={{position: 'absolute', top: 10, right: 30}}>
                        <NavigationControl showCompass={false}/>
                    </div>
                    <Geocoder
                        mapRef={mapRef}
                        onViewportChange={handleGeocoderViewportChange}
                        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                        position="top-left"
                    />

                    {markers.map((marker, index) => {
                        return (
                            <div key={index}>

                                <Marker longitude={marker.longitude} latitude={marker.latitude}>
                                    <Tooltip title={marker.name} color={'orange'} key={index}>
                                        {
                                            marker.isCurrent ?
                                                ICON('orange') :
                                                ICON('red')
                                        }
                                    </Tooltip>
                                </Marker>

                            </div>
                        );
                    })}


                    {/*{weatherPoints.length > 0 && (*/}
                    {/*    <>*/}
                    {/*        <Source*/}
                    {/*            id="weatherData"*/}
                    {/*            type="geojson"*/}
                    {/*            data={{*/}
                    {/*                type: 'FeatureCollection',*/}
                    {/*                features: weatherPoints.map(point => ({*/}
                    {/*                    type: 'Feature',*/}
                    {/*                    properties: { temperature: point.val },*/}
                    {/*                    geometry: {*/}
                    {/*                        type: 'Point',*/}
                    {/*                        coordinates: [point.lng, point.lat]*/}
                    {/*                    }*/}
                    {/*                }))*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*        <Layer*/}
                    {/*            id="temperature"*/}
                    {/*            source="weatherData"*/}
                    {/*            type="heatmap"*/}
                    {/*            // You may need to adjust or add more heatmap paint properties*/}
                    {/*            paint={{*/}
                    {/*                // Add your heatmap styling here*/}
                    {/*                'heatmap-weight': ['get', 'temperature'],*/}
                    {/*                'heatmap-intensity': [0, 1],*/}
                    {/*                'heatmap-color': [*/}
                    {/*                    'interpolate',*/}
                    {/*                    ['linear'],*/}
                    {/*                    ['heatmap-density'],*/}
                    {/*                    0, 'blue',*/}
                    {/*                    0.5, 'yellow',*/}
                    {/*                    1, 'red'*/}
                    {/*                ],*/}
                    {/*                'heatmap-radius': 15*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </>*/}
                    {/*)}*/}

                </MapGL>
            </div>


        </>


    );
};

export default MapboxMaps
