import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import {Popover, Progress, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

export const EquipmentProps = {
    title: 'Equipment',
    name: 'equipment',
    description: '',

}

const renderDrawer = (text, id, record, handleSelectedRecord) => {
    handleSelectedRecord(record)
    store.dispatch(actions.OpenDrawer(true, id))
}


export const EquipmentColumns = (handleSelectedRecord) => {
    return ([
        {
            title: 'key',
            dataIndex: 'id',
            key: 'id',
            editable: false,
            search: false,
            fixed: 'left',
            importIngore: true,
            hideInTable: false,
            width: '5rem'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            search: false,
            edit: true,
            fixed: 'left',
            width: 100,
            sorter: (a, b) => a.name?.localeCompare(b.name),
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: 'required',
                    },
                ],
            },
            render: (text, record, _, action) => {
                return (
                    <Tooltip title={text}>
                    <a onClick={() => action ? renderDrawer(text, record.id, record, handleSelectedRecord) : ''}>{text}</a>
                    </Tooltip>
                )
            },
            renderFormItem: () => <TextArea rows={1}/>,
        },

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 150,
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
            render: text => (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            ),
            renderFormItem: () => <TextArea rows={1}/>,
        },
        {
            title: 'Building',
            dataIndex: 'building',
            key: 'building',
            edit: true,
            width: 100,
            // hideInTable: window.innerWidth < 760,
            sorter: (a, b) => a.building?.localeCompare(b.building),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            edit: true,
            width: 150,
            render: text => (
                <Tooltip title={text}>
                    <span>{text}</span>
                </Tooltip>
            ),
            // hideInTable: window.innerWidth < 760,
            sorter: (a, b) => a.location?.localeCompare(b.location),
        },
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
            width: 150,
            edit: true,
            // hideInTable: window.innerWidth < 760,
            sorter: (a, b) => a.service?.localeCompare(b.service),
        },
        {
            title: 'Condition',
            dataIndex: 'status',
            key: 'status',
            filters: true,
            onFilter: true,
            edit: true,
            width: 100,
            sorter: (a, b) => a.status?.localeCompare(b.status),
            valueEnum: {
                Failed: {text: 'Failed'},
                Fair: {text: 'Fair',},
                Good: {text: 'Good',},
                Excellent: {text: 'Excellent',}
            },
            render: (text, record) => {
                return <span className="text-center">{text}</span>
            },
            formItemProps: {
                initialValue: 'New'
            },
        },

        // {
        //     title: 'install_date',
        //     dataIndex: 'install_date',
        //     key: 'install_date',
        //     width:'8%',
        //     editable: false,
        //     search: false,
        //     hideInTable: window.innerWidth < 760,
        // },

        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 120,
            editable: false,
            search: false,
        },
        {
            title: 'key_spec_1_name',
            dataIndex: 'key_spec_1_name',
            key: 'key_spec_1_name',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_1_value',
            dataIndex: 'key_spec_1_value',
            key: 'key_spec_1_value',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_2_name',
            dataIndex: 'key_spec_2_name',
            key: 'key_spec_2_name',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_2_value',
            dataIndex: 'key_spec_2_value',
            key: 'key_spec_2_value',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_3_name',
            dataIndex: 'key_spec_3_name',
            key: 'key_spec_3_name',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_3_value',
            dataIndex: 'key_spec_3_value',
            key: 'key_spec_3_value',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_4_name',
            dataIndex: 'key_spec_4_name',
            key: 'key_spec_4_name',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_4_value',
            dataIndex: 'key_spec_4_value',
            key: 'key_spec_4_value',
            editable: false,
            search: false,
            hideInTable: true,
        },

        {
            title: 'key_spec_5_name',
            dataIndex: 'key_spec_5_name',
            key: 'key_spec_5_name',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_5_value',
            dataIndex: 'key_spec_5_value',
            key: 'key_spec_5_value',
            editable: false,
            search: false,
            hideInTable: true,
        },

        {
            title: 'key_spec_6_name',
            dataIndex: 'key_spec_6_name',
            key: 'key_spec_6_name',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_6_value',
            dataIndex: 'key_spec_6_value',
            key: 'key_spec_6_value',
            editable: false,
            search: false,
            hideInTable: true,
        },

        {
            title: 'key_spec_7_name',
            dataIndex: 'key_spec_7_name',
            key: 'key_spec_7_name',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_7_value',
            dataIndex: 'key_spec_7_value',
            key: 'key_spec_7_value',
            editable: false,
            search: false,
            hideInTable: true,
        },

        {
            title: 'key_spec_8_name',
            dataIndex: 'key_spec_8_name',
            key: 'key_spec_8_name',
            editable: false,
            search: false,
            hideInTable: true,
        },
        {
            title: 'key_spec_8_value',
            dataIndex: 'key_spec_8_value',
            key: 'key_spec_8_value',
            editable: false,
            search: false,
            hideInTable: true,
        },

        {
            title: 'Modified',
            dataIndex: 'modified_date',
            key: 'modified_date',
            width: 100,
            editable: false,
            importIngore: true,
            hideInTable: window.innerWidth < 760,
            sorter: (a, b) => a.modified_date - b.modified_date,
            render: (text, record) => (
                moment(record.modified_date).format('YYYY-MM-DD')
            )
        },{
            title: 'Last Function Test',
            dataIndex: 'recent_functionform',
            key: 'recent_functionform',
            width: 150,
            editable: false,
            importIngore: true,
            hideInTable: window.innerWidth < 760,
            sorter: (a, b) => a.recent_functionform - b.recent_functionform,
            render: (text, record) => (
                record.recent_functionform?moment(record.recent_functionform).format('YYYY-MM-DD'):''
            )
        },


        {
            title: 'CreatedBy',
            dataIndex: 'createdby_name',
            key: 'createdby_name',
            editable: false,
            search: false,
            hideInTable: false,
            importIngore: true
        },
        {
            title: 'created_date',
            dataIndex: 'created_date',
            key: 'created_date',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: true,
        },
        {
            title: 'ModifiedBy',
            dataIndex: 'lastModified_name',
            key: 'lastModified_name',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: false,
        },
        {
            title: 'modified_date',
            dataIndex: 'modified_date',
            key: 'modified_date',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: true,
        },
        {
            title:'Outstanding WO',
            dataIndex: 'outstanding_workers',
            key: 'outstanding_workers',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: false,
            width:'14rem',
            render: (text, record) => {
                return (
                    <span>
                    <Progress
                        percent={text*10}
                        steps={5}
                        showInfo={false}
                        strokeColor={[
                            'orange',
                            'orange',
                            'orange',
                            'orange',
                            'orange',
                        ]}
                    /> {text} </span>
                )
            }
        },
        {
            title: <Popover content={
                <div>
                    <span>Number of Workorder created in the last 90 days: Completed/Total</span>
                </div>
            } trigger="hover"
            >
                <span className={'flex align-center'}>
                90 days WO
                <InfoCircleOutlined style={{color: 'orange'}}/>
                    </span>
            </Popover>,
            dataIndex: 'recent_workorders_count',
            key: 'recent_workorders_count',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: false,
            width:'12rem',
            render: (text, record) => {
                return (
                    <span>
                    <Progress
                        percent={record.recent_workorders_notcompleted_count/text*100}
                        steps={5}
                        showInfo={false}
                        strokeColor={[
                            'green',
                            'green',
                            'green',
                            'green',
                            'green',
                        ]}
                    /> {record?.recent_workorders_notcompleted_count +'/'+ text} </span>
                )
            }
        },
    ])
};
