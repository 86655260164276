import React, {useRef, useState} from 'react';
import {Avatar, Button, Comment, Form, Input, List, Tooltip} from 'antd';
import moment from 'moment';
import {useSelector} from "react-redux";
import _ from "lodash";
import {api} from "@redux/api";
import notification2 from "@components/Notification2";

const {TextArea} = Input;

export const EquipmentComment = (equipmentId) => {

    const messagesEndRef = useRef(null)
    const [comments, setComments] = useState([]);

    const userImage = useSelector((state) => state.Auth.image);
    const userName = useSelector((state) => state.Auth.user);
    const userId = useSelector((state) => state.Auth.userId);
    const [openEditor, setOpenEditor] = useState(false);
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        if (equipmentId.id) {
            getEquipmentComments(equipmentId.id)
        }
    }, [equipmentId.id]);

    function getEquipmentComments(equipmentId) {
        setLoading(true)
        return api.getEquipmentComment(equipmentId).then(items => {
            if (_.get(items, 'status') === 200) {
                let data = items?.data.results;
                if (data) {
                    data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
                }
                setComments(data ? data : [])
            } else {
                notification2['error']({
                    message: 'error',
                    description: _.get(items, 'response.statusText'),
                });
            }
        }).finally(() => setLoading(false))
    }

    const CommentContent = (comments) => {
        // console.log('comments', comments)
        let data = comments.comment;
        const deleteEquipmentComment = (commentId) => {
            setLoading(true)
            api.deleteEquipmentComment(commentId).then(
                items => {
                    getEquipmentComments(equipmentId.id)
                }
            ).finally(() => setLoading(false))
        }

        return (

            <Comment
                // actions={actions}
                author={<a>{_.get(data, 'createdby_name')}</a>}
                avatar={
                    <Avatar
                        src={_.get(data, 'createdby_image')}
                    />
                }
                content={
                    _.get(data, 'content')
                }
                datetime={
                    [<Tooltip title={moment(_.get(data, 'modified_date')).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(_.get(data, 'modified_date')).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </Tooltip>,
                        <Tooltip key="comment-delete" title="delete">
                            <a key="comment-delete" onClick={() => deleteEquipmentComment(data.id)}><i
                                className="ml-4 fas fa-trash-alt"></i></a>
                        </Tooltip>]
                }
            />
        )
    }

    // const actions = openEditor ? [<span key="comment-basic-reply-to" onClick={(e) => handleReplyTo(e, false)}>Close Reply</span>]
    //     : [<span key="comment-basic-reply-to" onClick={(e) => handleReplyTo(e, true)}>Reply</span>];

    // function handleReplyTo(e, status) {
    //     // console.log('status: ' + status)
    //     setOpenEditor(status)
    // }

    const Editor = () => {
        const [value, setValue] = useState(null);

        function handleChange(e) {
            setValue(e.target.value)
        }

        function handleSubmit() {
            setLoading(true)
            let row = {
                content: value,
                Equipment: equipmentId.id
            }
            console.log('handleSubmit', equipmentId, row)
            return api.createEquipmentComment(row).then(
                response => {
                    getEquipmentComments(response.data.equipment)
                }
            ).catch(error => (
                error
            )).finally(
                () => setLoading(false)
            );

        }


        return (
            <Comment
                avatar={
                    <Avatar
                        src={userImage}
                        alt={userName}
                    />
                }
                content={
                    <>
                        <Form.Item>
                            <TextArea rows={3} onChange={handleChange} value={value}/>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" onClick={handleSubmit} type="primary">
                                Add Comment
                            </Button>
                        </Form.Item>
                    </>
                }
            />
        )
    }

    return (
        <>
            <List
                ref={messagesEndRef}
                style={{height: '80%', overflowY: 'auto'}}
                dataSource={comments}
                header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                itemLayout="horizontal"
                renderItem={e => <CommentContent comment={e}/>}
            />
            <div ref={messagesEndRef}/>
            <Editor/>
        </>
    );
};

