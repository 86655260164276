import React, {useRef, useState} from 'react';
import {Avatar, Button, Comment, Form, Input, List, Tooltip} from 'antd';
import moment from 'moment';
import {useSelector} from "react-redux";
import _ from "lodash";
import {api} from "@redux/api";
import notification from "antd/es/notification";

const {TextArea} = Input;


export const ProjectComment = (ProjectId) => {

    const messagesEndRef = useRef(null)
    const [comments, setComments] = useState([]);

    const userImage = useSelector((state) => state.Auth.image);
    const userName = useSelector((state) => state.Auth.user);
    const userId = useSelector((state) => state.Auth.userId);
    const [openEditor, setOpenEditor] = useState(false);

    React.useEffect(() => {
        if (ProjectId.id) {
            getProjectComments(ProjectId.id)
        }
    }, [ProjectId.id]);

    function getProjectComments(ProjectId) {
        return api.getProjectComment(ProjectId).then(items => {
                if (_.get(items, 'status') === 200) {
                    setComments(_.get(items, 'data.results'))
                } else {
                    notification['error']({
                        message: 'error',
                        description: _.get(items, 'response.statusText'),
                    });
                }
            }
        )
    }

    const CommentContent = (comments) => {
        console.log('comments', comments)
        let data = comments.comment;
        // const actions = [
        //     <Tooltip key="comment-delete" title="delete">
        //         <span key="comment-delete"><i className="fas fa-trash-alt"></i></span>
        //     </Tooltip>
        // ];
        const deleteProjectComment = (commentId) => {
            api.deleteProjectComment(commentId).then(items => {
                    getProjectComments(ProjectId.id)
                }
            )
        }

        return (

            <Comment
                // actions={actions}
                author={<a>{_.get(data, 'createdby_name')}</a>}
                avatar={
                    <Avatar
                        src={_.get(data, 'author.userImage')}
                    />
                }
                content={
                    _.get(data, 'content')
                }
                datetime={
                    [<Tooltip title={moment(_.get(data, 'modified_date')).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(_.get(data, 'modified_date')).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </Tooltip>,
                        <Tooltip key="comment-delete" title="delete">
                            <span key="comment-delete" onClick={() => deleteProjectComment(data.id)}><i
                                className="ml-4 fas fa-trash-alt"></i></span>
                        </Tooltip>]
                }
            />
        )
    }

    // const actions = openEditor ? [<span key="comment-basic-reply-to" onClick={(e) => handleReplyTo(e, false)}>Close Reply</span>]
    //     : [<span key="comment-basic-reply-to" onClick={(e) => handleReplyTo(e, true)}>Reply</span>];

    // function handleReplyTo(e, status) {
    //     // console.log('status: ' + status)
    //     setOpenEditor(status)
    // }

    const Editor = () => {
        const [value, setValue] = useState(null);

        function handleChange(e) {
            setValue(e.target.value)
        }

        function handleSubmit() {
            let row = {
                content: value,
                project: ProjectId.id
            }
            console.log('handleSubmit', ProjectId, row)
            return api.createProjectComment(row).then(
                response => {
                    getProjectComments(response.data.project)
                }
            ).catch(error => (
                error
            ));

            // handleSubmit(result)
            scrollToBottom()
        }


        return (
            <Comment
                avatar={
                    <Avatar
                        src={userImage}
                        alt={userName}
                    />
                }
                content={
                    <>
                        <Form.Item>
                            <TextArea rows={3} onChange={handleChange} value={value}/>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" onClick={handleSubmit} type="primary">
                                Add Comment
                            </Button>
                        </Form.Item>
                    </>
                }
            />
        )
    }

    const scrollToBottom = () => {
        return messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }

    return (
        <>
            <List
                ref={messagesEndRef}
                style={{height: '30vh', overflowY: 'auto'}}
                dataSource={comments}
                header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
                itemLayout="horizontal"
                renderItem={e => <CommentContent comment={e}/>}
            />
            <Editor/>
        </>
    );
};

