import React from "react";
import "antd/dist/antd.css";
import {notification as antdNotification} from "antd";
import {CheckCircleFilled, CloseCircleFilled, InfoCircleFilled} from "@ant-design/icons";

antdNotification.config({
    placement: "topLeft",
    duration: 4.5
});

const notification2 = {
    error: ({message, description}) => {
        message&&antdNotification.error({
            message,
            description,
            className: "notification",
            style: {
                width: 600,
                minWidth: 320,
                maxWidth: 568,
                backgroundColor: "#fff1f0",
                border: "1px solid #ffa39e",
                margin: 0,
                boxShadow: "unset"
            },
            icon: <CloseCircleFilled style={{color: "#f5222e"}}/>
        });
    },
    warning: ({message, description}) => {
        message&&antdNotification.warning({
            message,
            description,
            className: "notification",
            style: {
                width: 600,
                minWidth: 320,
                maxWidth: 568,
                backgroundColor: "#fffbe6",
                border: "1px solid #ffe58f",
                margin: 0,
                boxShadow: "unset"
            },
            icon: <InfoCircleFilled style={{color: "#f9bf02"}}/>
        });
    },
    success: ({message, description}) => {
        antdNotification.success({
            message,
            description,
            className: "notification",
            style: {
                width: 600,
                minWidth: 320,
                maxWidth: 568,
                backgroundColor: "#F6FFED",
                border: "1px solid #B7EB8F",
                margin: 0,
                boxShadow: "unset"
            },
            icon: <CheckCircleFilled style={{color: "#52C51A"}}/>
        });
    }
};

export default notification2;
