import React, {Component} from 'react';
import {Col, Divider, Row, Table} from "antd";
import {connect} from "react-redux";
import {NationalBenchmarkStyles} from "@containers/facility/Benchmark/NationalBenchmark/NationalBenchmarkStyle";
import heating_map from '@assets/images/heatingmap.jpeg';
import HERS_zone_map from '@assets/images/Geo_US_map_regions.png';
import dashboard from "@assets/icons/bar-chart.png";
import {asOfDate} from "@components/help";
import {api} from "@redux/api";
import {jsforceResponse} from "@config/httpService";
import _ from "lodash";
import BenchmarkBarChart from "@containers/UtilityData/Charts/BenchmarkBarChart";

class NationalBenchmark extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filter_dates: [],
            selected_months: [],
            selected_id: [],

            Building_Floorspace_data: [],
            Principal_Building_Activity: [],
            Year_Constructed: [],
            Census_Region_Division: [],
            Climate_Zone_data: [],
            Establishments_data: [],
            Energy_Sources: [],
            Energy_End_Uses: [],
            All_Building: [],

            Building_Floorspace_selected: [],
            Principal_Building_Activity_selected: [],
            Year_Constructed_selected: [],
            Census_Region_Division_selected: [],
            Climate_Zone_selected: [],
            Establishments_selected: [],
            Energy_Sources_selected: [],
            Energy_End_Uses_selected: [],

            chart_eui_size_data: [],
            chart_eui_category_data: [],
            chart_eui_year_data: [],
            chart_eui_region_data: [],
            chart_eui_climatezone_data: [],

        }
    }

    async componentDidMount() {

        let result = await api.getNationalBenchmark();
        result = jsforceResponse(result);
        result = _.get(result, 'data.results')
        console.log('getNationalBenchmark', result)
        let all_facilities = result;

        let current_facilities = this.props.CurrentFacility;
        let Building_Floorspace_data = [],
            principal_building_activity = [],
            year_constructed = [],
            census_region_division = [],
            climate_zone = [],
            establishments = [],
            energy_sources = [],
            energy_end_user = [],
            All_Building = [],

            chart_eui_size_data = [],
            chart_eui_category_data = [],
            chart_eui_year_data = [],
            chart_eui_region_data = [],
            chart_eui_climatezone_data = [],

            Building_Floorspace_current = current_facilities?.area,
            Principal_Building_Activity_current = current_facilities?.category,
            Year_Constructed_current = current_facilities?.year,
            Census_Region_Division_current = current_facilities?.census_region_division,
            Climate_Zone_current = current_facilities?.climate_zone,
            Establishments_current = current_facilities?.establishments,
            Energy_Sources_current = current_facilities?.energy_sources,
            Energy_End_Uses_current = current_facilities?.energy_end_uses,
            Current_EUI = current_facilities?.eui
        ;

        let item = all_facilities;
        for (let i = 0; i < all_facilities.length; i++) {

            if (item[i]?.name === 'All Buildings ') {
                All_Building.push(item[i])
            } else if (item[i]?.name === 'Building Floorspace') {


                if (Building_Floorspace_current > item[i]?.lowerlimit * 1 && Building_Floorspace_current < item[i]?.upperlimit * 1) {
                    Building_Floorspace_data.push({...item[i], selected: true})
                    chart_eui_size_data.push({
                        xAxis: Current_EUI,
                        yAxis: item[i].category,
                        isCurrent: 'Current EUI'
                    })
                }
                Building_Floorspace_data.push({...item[i], selected: false})
                chart_eui_size_data.push({
                    xAxis: item[i].total,
                    yAxis: item[i].category,
                    isCurrent: 'Benchmark EUI'
                })


            } else if (item[i]?.name === 'Principal Building Activity') {


                if (Principal_Building_Activity_current === item[i]?.category) {
                    principal_building_activity.push({...item[i], selected: true})
                    chart_eui_category_data.push({
                        xAxis: Current_EUI,
                        yAxis: item[i].category,
                        isCurrent: 'Current EUI'
                    })
                }
                principal_building_activity.push({...item[i], selected: false})
                chart_eui_category_data.push({
                    xAxis: item[i].total,
                    yAxis: item[i].category,
                    isCurrent: 'Benchmark EUI'
                })

            } else if (item[i]?.name === 'Year Constructed') {

                console.log('item[i]', item[i], Year_Constructed_current, current_facilities)
                if (Year_Constructed_current > item[i]?.lowerlimit * 1 && Year_Constructed_current < item[i]?.upperlimit * 1) {

                    year_constructed.push({...item[i], selected: true})
                    chart_eui_year_data.push({
                        xAxis: Current_EUI,
                        yAxis: item[i].category,
                        isCurrent: 'Current EUI'
                    })
                }
                year_constructed.push({...item[i], selected: false})
                chart_eui_year_data.push({
                    xAxis: item[i].total,
                    yAxis: item[i].category,
                    isCurrent: 'Benchmark EUI'
                })

            } else if (item[i]?.name === 'Census Region and Division') {

                if (Census_Region_Division_current && Census_Region_Division_current === item[i]?.category) {

                    census_region_division.push({...item[i], selected: true})
                    chart_eui_region_data.push({
                        xAxis: Current_EUI,
                        yAxis: item[i].category,
                        isCurrent: 'Current EUI'
                    })
                }
                census_region_division.push({...item[i], selected: false})
                chart_eui_region_data.push({
                    xAxis: item[i].total,
                    yAxis: item[i].category,
                    isCurrent: 'Benchmark EUI'
                })

            } else if (item[i]?.name === "Climate Zone: 30-Year Average") {


                if (Climate_Zone_current > item[i]?.lowerlimit * 1 && Climate_Zone_current < item[i]?.upperlimit * 1) {
                    climate_zone.push({...item[i], selected: true})
                    chart_eui_climatezone_data.push({
                        xAxis: Current_EUI,
                        yAxis: item[i].category,
                        isCurrent: 'Current EUI'
                    })
                }
                climate_zone.push({...item[i], selected: false})
                chart_eui_climatezone_data.push({
                    xAxis: item[i].total,
                    yAxis: item[i].category,
                    isCurrent: 'Benchmark EUI'
                })

            } else if (item[i]?.name === 'Number of Establishments') {

                if (Establishments_current > item[i]?.lowerlimit * 1 && Establishments_current < item[i]?.upperlimit * 1) {
                    establishments.push({...item[i], selected: true})
                } else {
                    establishments.push({...item[i], selected: false})
                }
            } else if (item[i]?.name === 'Energy Sources') {
                if (Energy_Sources_current && Energy_Sources_current === item[i]?.category) {

                    energy_sources.push({...item[i], selected: true})
                } else {
                    energy_sources.push({...item[i], selected: false})
                }
            } else if (item[i]?.name === 'Energy End Uses') {

                if (Energy_End_Uses_current && Energy_End_Uses_current === item[i]?.category) {
                    energy_end_user.push({...item[i], selected: true})
                } else {
                    energy_end_user.push({...item[i], selected: false})
                }
            }
        }
        this.setState({
            All_Building: All_Building,
            Building_Floorspace_data: Building_Floorspace_data,
            Principal_Building_Activity: principal_building_activity,
            Year_Constructed: year_constructed,
            Census_Region_Division: census_region_division,
            Climate_Zone_data: climate_zone,
            Establishments_data: establishments,
            Energy_Sources: energy_sources,
            Energy_End_Uses: energy_end_user,

            chart_eui_size_data,
            chart_eui_category_data,
            chart_eui_year_data,
            chart_eui_region_data,
            chart_eui_climatezone_data
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    columns = (title) =>
        ([
            {
                title: title,
                dataIndex: 'CPostperiodData',
                key: 'CPostperiodData',
                className: 'title text-2xl',
                children: [
                    {
                        title: 'Name',
                        dataIndex: 'category',
                        key: 'category',
                        width: '20%',
                    },
                    {
                        title: 'Total',
                        dataIndex: 'total',
                        key: 'total',
                    },
                    {
                        title: 'Space Heating',
                        dataIndex: 'space_heating',
                        key: 'space_heating',
                    },
                    {
                        title: 'Cooling',
                        dataIndex: 'cooling',
                        key: 'cooling',
                    },
                    {
                        title: 'Ventilation',
                        dataIndex: 'ventilation',
                        key: 'ventilation',
                    },
                    {
                        title: 'Water Heating',
                        dataIndex: 'water_heating',
                        key: 'water_heating',
                    },
                    {
                        title: 'Lighting',
                        dataIndex: 'lighting',
                        key: 'lighting',
                    },
                    {
                        title: 'Cooking',
                        dataIndex: 'cooking',
                        key: 'cooking',
                    },
                    {
                        title: 'Refrigeration',
                        dataIndex: 'refrigeration',
                        key: 'refrigeration',
                    },
                    {
                        title: 'Office Equipment',
                        dataIndex: 'office_equipment',
                        key: 'office_equipment',
                    },
                    {
                        title: 'Computers',
                        dataIndex: 'computers',
                        key: 'computers',
                    },
                    {
                        title: 'Others',
                        dataIndex: 'others',
                        key: 'others',
                    }
                ]
            }

        ]);

    render() {


        return (
            <NationalBenchmarkStyles>

                <Row className={'w-full'}>
                    {/*Building Floorspace*/}

                    <Col xs={24} md={24} xl={24} className={' p-6'}>

                        <div className={'w-full lg:w-auto flex items-center my-10 px-3'}>
                            <div className={'inline-flex justify-center items-center w-10 h-10 mr-4 rounded'}>
                                <img src={dashboard}/>
                            </div>
                            <div className={''}>
                                <h2 className="mb-1 text-lg font-bold">Table E2A. Major Fuel Consumption (Btu)
                                    Intensities by End Use for All Buildings,
                                    2003</h2>
                                <p>As of {asOfDate()}</p>
                            </div>
                        </div>


                    </Col>

                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <div className={'mr-4'}>
                            <Table bordered dataSource={this.state.Building_Floorspace_data} pagination={false}
                                   rowClassName={(record) => record.selected ? 'selected_row' : ''}
                                   columns={this.columns('Building Floorspace')}/>
                        </div>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>

                        <BenchmarkBarChart data={this.state.chart_eui_size_data}
                                           title='EUI for Different Size Buildings'
                                           height={280}/>

                    </Col>

                    {/*Principal Building Activity*/}
                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <div className={'mr-4'}>
                            <Table bordered dataSource={this.state.Principal_Building_Activity} pagination={false}
                                   rowClassName={(record) => record.selected ? 'selected_row' : ''}
                                   columns={this.columns('Principal Building Activity')}/>
                        </div>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>
                        <BenchmarkBarChart data={this.state.chart_eui_category_data}
                                           title='EUI for Different Building Categories' height={600}/>
                    </Col>

                    {/*Year Constructed*/}
                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <div className={'mr-4'}>
                            <Table bordered dataSource={this.state.Year_Constructed} pagination={false}
                                   rowClassName={(record) => record.selected ? 'selected_row' : ''}
                                   columns={this.columns('Year Constructed')}/>
                        </div>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>
                        <BenchmarkBarChart data={this.state.chart_eui_year_data}
                                           title='EUI for Different Year Constructed'
                                           height={280}/>
                    </Col>
                    {/*Census Region and Division*/}
                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <div className={'mr-4'}>
                            <Table bordered dataSource={this.state.Census_Region_Division} pagination={false}
                                   rowClassName={(record) => record.selected ? 'selected_row' : ''}
                                   columns={this.columns('Census Region and Division')}/>
                        </div>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>
                        <BenchmarkBarChart data={this.state.chart_eui_region_data} title='EUI for Different Region'
                                           height={320}/>
                    </Col>
                    {/*Climate Zone: 30-Year Average*/}
                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <div className={'mr-4'}>
                            <Table bordered dataSource={this.state.Climate_Zone_data} pagination={false}
                                   rowClassName={(record) => record.selected ? 'selected_row' : ''}
                                   columns={this.columns('Climate Zone: 30-Year Average')}/>
                        </div>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>
                        <BenchmarkBarChart data={this.state.chart_eui_climatezone_data}
                                           title='EUI for Different Climate Zones'
                                           height={190}/>
                    </Col>
                    {/*Number of Establishments*/}
                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <div className={'mr-4'}>
                            <Table bordered dataSource={this.state.Establishments_data} pagination={false}
                                   rowClassName={(record) => record.selected ? 'selected_row' : ''}
                                   columns={this.columns('Number of Establishments')}/>
                        </div>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>
                        <img src={HERS_zone_map} style={{width: '100%'}}/>
                        {/*<BarChart data={this.state.chart3_data} title='EUI (kBtu/sq-yr)' height={380}/>*/}
                    </Col>
                    {/*Energy Sources */}
                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <div className={'mr-4'}>
                            <Table bordered dataSource={this.state.Energy_Sources} pagination={false}
                                   rowClassName={(record) => record.selected ? 'selected_row' : ''}
                                   columns={this.columns('Energy Sources')}/>
                        </div>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>
                        {/*<BarChart data={this.state.chart3_data} title='EUI (kBtu/sq-yr)' height={380}/>*/}
                        <img src={heating_map} style={{width: '100%'}}/>
                    </Col>
                    {/*Energy End Uses */}
                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <div className={'mr-4'}>
                            <Table bordered dataSource={this.state.Energy_End_Uses} pagination={false}
                                   rowClassName={(record) => record.selected ? 'selected_row' : ''}
                                   columns={this.columns('Energy End Uses')}/>
                        </div>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>

                    </Col>


                    <Divider style={{margin: '4px 0'}}/>
                    <Col xs={24} md={16} xl={16} className={' p-6 mb-4'}>
                        <p className={'text-sm'}>
                            See "Guide to the Tables" or "Glossary" for further explanations of the terms used in this
                            table. Both can be accessed from the CBECS web site - http://www.eia.doe.gov/emeu/cbecs.
                            (*)=Value rounds to zero in the units displayed.
                            Q=Data withheld because fewer than 20 buildings were sampled for any cell, or because the
                            Relative Standard Error (RSE) was greater than 50 percent for a cell in the "Total" column.
                        </p>

                        <p className={'text-sm'}>
                            Note: Due to rounding, data may not sum to totals.
                            Source: Energy Information Administration, Office of Energy Markets and End Use, Form
                            EIA-871A, C, and E of the 2003 Commercial Buildings Energy Consumption Survey.
                        </p>
                    </Col>
                    <Col xs={24} md={8} xl={8} className={' p-6 mb-4'}>

                    </Col>
                </Row>


            </NationalBenchmarkStyles>
        );
    }
}


const mapStateToProps = state => {
    return {
        CurrentFacility: _.get(state.Facility, 'selected.record')
    }
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NationalBenchmark);
