import React from 'react';
import { lazy } from 'react';
import { facilityRoutes } from '@/router/facilityRoutes';
import { companyRoutes } from '@/router/companyRoutes';

const SignIn = lazy(() => import('@/containers/LoginPages/SignIn/SignIn'));
const ResetPassword = lazy(() => import('@/containers/LoginPages/ResetPassword/ResetPassword'));
const SignUp = lazy(() => import('@/containers/LoginPages/SignUp/SignUp'));
const Help = lazy(() => import('@/containers/Help/help'));
const LayoutWrapper = lazy(() => import('@/layout'));

export const routes = [
  {
    path: '/login',
    element: <SignIn />,
  },
  {
    path: '/password/reset/confirm/:uid/',
    element: <ResetPassword />,
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  {
    path: '/help',
    element: <Help />,
  },
  {
    path: '/',
    element: <LayoutWrapper />,
    children: [
      ...facilityRoutes,
      ...companyRoutes
    ]
  }
]