import React from "react";
import { useMount, useRequest } from "ahooks";
import { AutoComplete, Input, Select } from "antd";

export const AutoSearchSelect = ({request, ...props}) => {

    const {data: options = [], loading, run} = useRequest(request, {

    })

    useMount(() => {
        run('')
    })

    const handleSearch = (value) => {
        run(value)
    }

    return (
        <AutoComplete
            showSearch
            // allowClear
            onSearch={handleSearch}
            loading={loading}
            options={options}
            notFoundContent={null}
            filterOption={false}
            size="medium"
            style={{minWidth: 120}}
            {...props}
        // placeholder={this.props.label}
        // style={this.props.style}
        // options={this.state.options}
        // onFocus={this.handleFocus}
        // onSelect={this.handleSelect}
        // onSearch={this.handleSearch}
        // // onChange={this.handleOnChange}
        // onBlur={() => {
        //     this.setState({dropdownVisible: false});
        // }}
        // value={this.state.value}
        // defaultValue={this.state.value}
        // dropdownMatchSelectWidth={false}
        // placeholder={this.props.placeholder}
        // open={this.state.dropdownVisible}
        // loading={this.props.loading}
    >
        <Input.Search
            size="medium"
            autosize={true}
            style={{textAlign: 'center'}}
                    //   onSearch={this.handleEnterSearch}
            allowClear
            enterButton
        />
    </AutoComplete>

        // <Select
        //     showSearch
        //     allowClear
        //     options={options}
        //     loading={loading}
        //     filterOption={false}
        //     onSearch={handleSearch}
        //     notFoundContent={null}
        //     {...props}
        // >
        // </Select>
    )
}
