import Input from "antd/es/input";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import {store} from "@redux/store";
import {Select} from "antd";

const company_items = store.getState().Auth.company;
const {Option} = Select;

export const ProjectRelated = []

export const ProjectFormFields = [
    {
        title: 'key',
        name: 'id',
        order: 0,
        visible: false,
        length: 0,
        type: 'text',
    },
    {
        title: 'Name',
        name: 'name',
        order: 1,
        visible: true,
        length: "w-full",
        type: 'text',
        required: true,
        render: <Input/>
    },
    {
        title: 'Status',
        name: 'status',
        order: 5,
        visible: true,
        length: "w-full",
        type: 'text',
        render: (
            <Select>
                <Option value={'Open'}>Open</Option>
                <Option value={'New'}>New</Option>
                <Option value={'Pending'}>Pending</Option>
                <Option value={'Completed'}>Completed</Option>
                <Option value={'Closed'}>Closed</Option>
            </Select>
        )

    },
    {
        title: 'Priority',
        name: 'priority',
        order: 5,
        visible: true,
        length: "w-full",
        type: 'text',
        render: (
            <Select>
                <Option value={'High'}>High</Option>
                <Option value={'Medium'}>Medium</Option>
                <Option value={'Low'}>Low</Option>
            </Select>
        )
    },
    {
        title: 'External URL',
        name: 'externalUrl',
        order: 1,
        visible: true,
        length: "w-full",
        type: 'JSON',
        render: <Input/>
    },
    {
        title: 'Description',
        name: 'description',
        order: 2,
        visible: true,
        length: "w-full",
        type: 'textarea',


        render: <TextArea rows={2}/>
    },
    {
        title: 'Comment',
        name: 'comment',
        order: 2,
        visible: true,
        length: "w-full",
        type: 'textarea',


        render: <TextArea rows={2}/>
    },


    // {
    //     title: 'Separator',
    //     name: 'Separator',
    // },
    //
    //
    // {
    //     title: 'Baseline Start Date',
    //     name: 'baseline_start_date',
    //     order: 6,
    //     visible: true,
    //     length: "md:w-1/2 lg:w-1/2 sm:w-full",
    //     type: 'Date',
    //     render: <Input type={'Date'}/>
    // },
    // {
    //     title: 'Baseline End Date',
    //     name: 'baseline_end_date',
    //     order: 6,
    //     visible: true,
    //     length: "md:w-1/2 lg:w-1/2 sm:w-full",
    //     type: 'Date',
    //     render: <Input type={'Date'}/>
    // },
    // {
    //     title: 'Post Start Date',
    //     name: 'post_start_date',
    //     order: 7,
    //     visible: true,
    //     length: "md:w-1/2 lg:w-1/2 sm:w-full",
    //     type: 'Date',
    //     render: <Input type={'Date'}/>
    // },
    // {
    //     title: 'Post End Date',
    //     name: 'post_end_date',
    //     order: 7,
    //     visible: true,
    //     length: "md:w-1/2 lg:w-1/2 sm:w-full",
    //     type: 'Date',
    //     render: <Input type={'Date'}/>
    // },
    // {
    //     title: 'Electricity_price',
    //     name: 'electricity_price',
    //     order: 8,
    //     visible: true,
    //     length: "md:w-1/2 lg:w-1/2 sm:w-full",
    //     type: 'text',
    //     render: <Input type={'Number'}/>
    // },
    // {
    //     title: 'Gas_price',
    //     name: 'gas_price',
    //     order: 8,
    //     visible: true,
    //     length: "md:w-1/2 lg:w-1/2 sm:w-full",
    //     type: 'text',
    //     render: <Input type={'Number'}/>
    // },

    // {
    //     title: 'Separator',
    //     name: 'Separator',
    // },

    {
        title: 'Created By',
        name: 'createdby_name',
        order: 17,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input disabled/>
    },
    {
        title: 'Created Date',
        name: 'created_date',
        order: 18,
        visible: true,
        length: "w-full",
        type: 'Date',
        render: <Input disabled/>
    },
    {
        title: 'Modified By',
        name: 'lastModified_name',
        order: 19,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input disabled/>
    },
    {
        title: 'Modified Date',
        name: 'modified_date',
        order: 20,
        visible: true,
        length: "w-full",
        type: 'Date',
        render: <Input disabled/>
    },

]
