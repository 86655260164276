import moment from "moment";
import React, { useState, useEffect } from "react"
import { api } from "@redux/api";
import Histgram from "@containers/UtilityData/Charts/Histgram";
import UtilityDualAxes from "@containers/UtilityData/Charts/UtilityDualAxes";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import LineChart from "@/containers/UtilityData/Charts/LineChart";
import styled from "styled-components";

const noop = () => null

export const PeridCardIdMappings = {
  ElectricityNormalized: {
    id: 'ChartId_ElectricityNormalized',
    title: 'Electricity Normalized Consumption Vs. Weather'
  },
  GasNormalized: {
    id: 'ChartId_GasNormalized',
    title: 'Gas Normalized Consumption Vs. Weather'
  },
  MonthlyElectricity: {
    id: 'ChartId_MonthlyElectricity',
    title: 'Electricity Monthly Consumption and Costs'
  },
  MonthlyGas: {
    id: 'ChartId_MonthlyGas',
    title: 'Monthly Gas Consumption and Costs'
  },
  MonthlyElectricityPrices: {
    id: 'ChartId_MonthlyElectricityPrices',
    title: 'Monthly Electricity Prices'
  },
  MonthlyGasPrices: {
    id: 'ChartId_MonthlyGasPrices',
    title: 'Monthly Gas Prices'
  },
  // MonthlyEUI: {
  //     id: 'ChartId_MonthlyEUI',
  //     title: 'Monthly EUI (kBtu/sqft-yr)'
  // },
}

const EnergyConsumption = ({ Gas_Data, Electricity_Data, Area }) => {
  return (
    <div id="AnalysisCostAndEUIFilterContentContainer">
      <div className={"mr-4 mt-4"}>
        <Histgram
          area={Area}
          data={Gas_Data}
          container="Normalized"
          title="Gas Normalized Consumption Vs. Weather"
          // handleChartSelect={noop}
          leftTitle={"BTU/hr-sqft"}
          leftVar={"ScfDaySqft"}
          height={250}
          pointSize={8}
          color={"brown"}
        />
      </div>

      <div className={"mr-4 mt-4"}>
        <UtilityDualAxes
          data={Electricity_Data}
          // handleChartSelect={noop}
          x={"service_month"}
          y={["usage", "costs"]}
          height={250}
          pointSize={6}
          container="ElectricityCosts"
          title="Electricity Monthly Consumption and Costs"
          leftTitle="kWh"
          rightTitle="kWh"
          color={"blue"}
        />
      </div>
    </div>
  );
};

const Gird = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 500px);
  grid-gap: 50px;
`

Gird.Item = styled.div`
  width: 500px;
`

const PeriodChartList = ({ Gas_Data, Electricity_Data, Area, period }) => {
  return (
    <>
    <div  className="flex justify-center" style={{color: '#396598', fontWeight: 700, fontSize: 16, margin: 10, marginTop: 0}}>
      <span style={{marginRight: 20}}>Energy consumption, Cost and EUI</span>
      <span className="chartId_PeriodTitle">{moment(period[0]).format('YYYY-MM-DD')} - {moment(period[1]).format('YYYY-MM-DD')}</span>
    </div>
    <div className="flex justify-center">
      <Gird>
        <Gird.Item id={PeridCardIdMappings.ElectricityNormalized.id}>
          <Histgram
            area={Area}
            data={Electricity_Data}
            container='Normalized'
            title={PeridCardIdMappings.ElectricityNormalized.title}
            handleChartSelect={noop}
            leftTitle={'Watt / sqft'}
            leftVar={'WattSqft'}
            height={250}
            pointSize={8}
            color={'blue'}
          />
        </Gird.Item>

        <Gird.Item id={PeridCardIdMappings.GasNormalized.id}>
          <Histgram
            area={Area}
            data={Gas_Data}
            container='Normalized'
            title={PeridCardIdMappings.GasNormalized.title}
            handleChartSelect={noop}
            leftTitle={'BTU/hr-sqft'}
            leftVar={'ScfDaySqft'}
            height={250}
            pointSize={8}
            color={'brown'}
          />
        </Gird.Item>

        <Gird.Item id={PeridCardIdMappings.MonthlyElectricity.id}>
          <UtilityDualAxes
            data={Electricity_Data}
            handleChartSelect={noop}
            x={'service_month'}
            y={['usage', 'costs']}
            height={250}
            pointSize={6}
            container='ElectricityCosts'
            title={PeridCardIdMappings.MonthlyElectricity.title}
            leftTitle='kWh'
            rightTitle='kWh'
            color={'blue'}
          />
        </Gird.Item>

        <Gird.Item id={PeridCardIdMappings.MonthlyGas.id}>
          <UtilityDualAxes
            data={Gas_Data}
            handleChartSelect={noop}
            x={'service_month'}
            y={['usage', 'costs']}
            container='Gas Costs'
            height={250}
            pointSize={6}
            title={PeridCardIdMappings.MonthlyGas.title}
            leftTitle='MMBtu'
            rightTitle='MMBtu'
            color={'brown'}
          />
        </Gird.Item>

        <Gird.Item id={PeridCardIdMappings.MonthlyElectricityPrices.id}>
          <LineChart
            height={250}
            pointSize={6}
            data={Electricity_Data}
            handleChartSelect={noop}
            x={'service_month'}
            y={['costs', 'usage']}
            container='ElectricityPrices'
            title={PeridCardIdMappings.MonthlyElectricityPrices.title}
            leftTitle='$/kWh'
          />
        </Gird.Item>

        <Gird.Item id={PeridCardIdMappings.MonthlyGasPrices.id}>
          <LineChart
            height={250}
            pointSize={6}
            data={Gas_Data}
            handleChartSelect={noop}
            x={'service_month'}
            y={['costs', 'usage']}
            container='Gas Prices'
            title={PeridCardIdMappings.MonthlyGasPrices.title}
            leftTitle='$/kWh'
          />
        </Gird.Item>
        {/* <Col xs={24} md={24} xl={24} id={PeridCardIdMappings.MonthlyEUI.id}>
        <div className={'mr-4 mt-4'}>
            {this.renderLastMonthEUI(
                this.state.total_eui,
                this.state.total_cost_breakdown
            )}
        </div>
    </Col> */}
      </Gird>
    </div>
    </>
  )
}

export const EnergyConsumptionChart = ({ period }) => {
  const [eleData, setEleData] = useState(null);
  const [gasData, setGasData] = useState(null);

  const Area = useSelector((state) => state.Facility.selected.record.area);

  useEffect(() => {
    if (period && period[0] && period[1]) {
      let query =
        "start_month=" +
        moment(period[0]).format("YYYY-MM-") +
        "01" +
        "&end_month=" +
        moment(period[1]).endOf("month").format("YYYY-MM-DD");

      let fetchElectricity = api.filterElectricity(query).then((response) => {
        let dataSource = response.data.results;

        return dataSource;
      });

      let fetchGas = api.filterGas(query).then((response) => {
        let dataSource = response.data.results;

        return dataSource;
      });

      Promise.all([fetchElectricity, fetchGas])
        .then(([eleData, gasData]) => {
          setEleData(eleData);
          setGasData(gasData);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [period]);
  return (
    <PeriodChartList
      Gas_Data={gasData || []}
      Electricity_Data={eleData || []}
      Area={Area}
      period={period}
    />
  );
}
