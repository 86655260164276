import {Button, DatePicker, Form} from "antd";
import React, {useState} from "react";

export const GasFilters = (props) => {

    const [startModifiedDate, setStartModifiedDate] = useState(null);
    const [endModifiedDate, setEndModifiedDate] = useState(null);

    const formRef = React.useRef()

    const startModifiedDateFilter = () => {
        return (
            <DatePicker
                allowClear
                placeholder="Start Date"
                value={startModifiedDate}
                getPopupContainer={trigger => trigger.parentNode}
                onChange={setStartModifiedDate}
            />
        )
    }

    const endModifiedDateFilter = () => {
        return (
            <DatePicker
                allowClear
                placeholder="End Date"
                value={endModifiedDate}
                getPopupContainer={trigger => trigger.parentNode}
                onChange={setEndModifiedDate}
            />
        )
    }

    function handleSubmit(e) {
        console.log('submit', e)
        props.handleFilter(e)
    }

    return <Form
        id="myForm-bt0"
        layout="horizontal"
        onFinish={handleSubmit}
        ref={formRef}
    >

        <Form.Item name={'startModifiedDate'} className={'md:w-60 lg:w-60 sm:w-full'}>
            {startModifiedDateFilter()}
        </Form.Item>

        <Form.Item name={'endModifiedDate'} className={'md:w-60 lg:w-60 sm:w-full'}>
            {endModifiedDateFilter()}
        </Form.Item>

        <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
    </Form>

}
