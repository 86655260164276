import React, {Component} from 'react';
import {connect} from "react-redux";

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {CaretDownOutlined} from "@ant-design/icons";


class CompanyFacilityLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            value: null
        }
    }

    handleFocus = async (e) => {
        console.log('options', this.props.options)
        this.setState({
            options: this.props.options
        })
    }

    handleSearch = (e) => {
        let value = e;
        if (value && value.length > 0) {
            console.log('e', value);
            let data = this.props.options.filter(e => e.label.toLowerCase().includes(value.toLowerCase()));
            this.setState({
                value,
                options: data
            })
        } else {
            this.setState({
                value,
                options: []
            })
        }

    }

    handleSelect = (e, record) => {
        // console.log('handleSelect', e, record)
        this.setState({
            value: e
        })
        this.props.handleLookupChange(e, record)
    }

    handleClear = () => {
        this.setState({
            value: null
        })
        this.props.handleLookupChange(null, null)
    }

    render() {

        return (
            <AutoComplete
                style={{width: '100%'}}
                options={this.state.options}
                onSelect={this.handleSelect}
                onSearch={this.handleSearch}
                onFocus={this.handleFocus}
                value={this.state.value}
                dropdownMatchSelectWidth={false}
                allowClear={true}
                onClear={this.handleClear}
            >
                <Input size="medium" autosize={true}
                       autoComplete="off"
                       placeholder={'search a company'}
                       suffix={<CaretDownOutlined/>}/>
            </AutoComplete>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {
        // SelectedCOMPANY: (e) => dispatch(actions.SelectedCOMPANY(e))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyFacilityLookup);
