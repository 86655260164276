import React, {Component} from 'react';
import {connect} from "react-redux";
import GanttChart from "@containers/facility/ProjectManagement/TaskChart";
import {api} from "@redux/api";
import moment from "moment";

class Planning extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            selected_projectId: null,
            totalCompleted: null,
        }
    }

    componentDidMount() {
        if (this.props.selected_projectId) {
            this.getData(this.props)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selected_projectId !== this.props.selected_projectId) {
            this.getData(nextProps)
        }
    }

    getData = (props) => {
        let query = 'taskid=' + props?.selected_projectId + '&project_name=' + props?.selected_project_name
        // if (!this.state.selected_projectId) {
        //     return null
        // }
        api.alltasks(query).then(
            response => {
                console.log(response)
                this.setState({
                    data: response.data,
                    isLoading: false
                })
            }
        ).catch(error => {
            console.log(error)
        })
    }

    render() {

        return (
            <div className="outerCardWrapper">
                {this.state.data.tasks && this.state.data.tasks.length > 0 && <GanttChart
                    tasks={this.state.data.tasks}
                    project_name={this.state.data.project_name}
                    minTaskDate={moment(this.state.data.min_task_date)}
                    maxTaskDate={moment(this.state.data.max_task_date)}
                />}
            </div>

        );
    }
}


const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Planning);

