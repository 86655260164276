import {Button, Checkbox, Col, DatePicker, Divider, Drawer, Form, Input, InputNumber, Row, Segmented, Select} from "antd";
import React, {useEffect, useRef, useState} from "react";
import moment from "moment";

const {Option} = Select;
export const ScenarioModal = (props) => {

    const formRef = useRef()

    const [elec_use_breakpoints, set_elec_use_breakpoints] = useState(props.elec_use_breakpoints || 'Baseline Average')
    const [gas_use_breakpoints, set_gas_use_breakpoints] = useState(props.gas_use_breakpoints || 'Baseline Average')

    

    useEffect(() => {
        if (formRef.current && props.visible) {
            console.log('props', props)
            formRef.current.setFieldsValue({
                id: props.isNewScenario ? null : props.id,
                name: props.isNewScenario ? null : props.scenario_name,

                gas_baseline_start_date: props.gas_baseline_start_date ? moment(props.gas_baseline_start_date) : null,
                gas_baseline_end_date: props.gas_baseline_end_date ? moment(props.gas_baseline_end_date) : null,
                gas_post_start_date: props.gas_post_start_date ? moment(props.gas_post_start_date) : null,
                gas_post_end_date: props.gas_post_end_date ? moment(props.gas_post_end_date) : null,
                gas_price: props.gas_price,
                gas_type: props.gas_option,
                gas_breakpoint1: props.gas_breakpoint1,
                gas_breakpoint2: props.gas_breakpoint2,
                gas_breakpoint3: props.gas_breakpoint3,
                gas_segment: props.gas_segment,
                gas_use_breakpoints: props.gas_use_breakpoints || 'Breakpoints',
                gas_range1: props.gas_range1,
                gas_range0: props.gas_range0,

                elec_baseline_start_date: props.elec_baseline_start_date ? moment(props.elec_baseline_start_date) : null,
                elec_baseline_end_date: props.elec_baseline_end_date ? moment(props.elec_baseline_end_date) : null,
                elec_post_start_date: props.elec_post_start_date ? moment(props.elec_post_start_date) : null,
                elec_post_end_date: props.elec_post_end_date ? moment(props.elec_post_end_date) : null,
                elec_price: props.elec_price,
                elec_type: props.elec_option,
                elec_breakpoint1: props.elec_breakpoint1,
                elec_breakpoint2: props.elec_breakpoint2,
                elec_breakpoint3: props.elec_breakpoint3,
                elec_segment: props.elec_segment,
                elec_use_breakpoints: props.elec_use_breakpoints || 'Breakpoints',
                elec_range1: props.elec_range1,
                elec_range0: props.elec_range0,

                public: props.public,
            });
        }
        onValuesChange({

        })

    }, [props]);

    function handleSubmit(e) {
        console.log('submit', e)
        let formattedData = {
            ...e,
            gas_baseline_start_date: e.gas_baseline_start_date ? moment(e.gas_baseline_start_date).format('YYYY-MM-DD') : null,
            gas_baseline_end_date: e.gas_baseline_end_date ? moment(e.gas_baseline_end_date).format('YYYY-MM-DD') : null,
            gas_post_start_date: e.gas_post_start_date ? moment(e.gas_post_start_date).format('YYYY-MM-DD') : null,
            gas_post_end_date: e.gas_post_end_date ? moment(e.gas_post_end_date).format('YYYY-MM-DD') : null,

            elec_baseline_start_date: e.elec_baseline_start_date ? moment(e.elec_baseline_start_date).format('YYYY-MM-DD') : null,
            elec_baseline_end_date: e.elec_baseline_end_date ? moment(e.elec_baseline_end_date).format('YYYY-MM-DD') : null,
            elec_post_start_date: e.elec_post_start_date ? moment(e.elec_post_start_date).format('YYYY-MM-DD') : null,
            elec_post_end_date: e.elec_post_end_date ? moment(e.elec_post_end_date).format('YYYY-MM-DD') : null,
        }
        props.onScenarioSubmit(formattedData)
    }

    const onValuesChange = (values) => {
        if(values.gas_use_breakpoints) {
            set_gas_use_breakpoints(values.gas_use_breakpoints)

        }
        if(values.elec_use_breakpoints) {
            set_elec_use_breakpoints(values.elec_use_breakpoints)

        }
    }

    return (

        <Drawer
            width={'900'}
            placement="right"
            closable={false}
            onClose={props.onCancel}
            visible={props.visible}
            destroyOnClose={true}
            autoFocus={true}
        >
            <Form
                id="myForm"
                layout="vertical"
                onFinish={handleSubmit}
                onValuesChange={onValuesChange}
                ref={formRef}
            >
                <Form.Item label="id" name={'id'} hidden={true}>
                    <Input/>
                </Form.Item>

                <Row className={'w-full'} gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Name" name={'name'} rules={[
                            {
                                required: true,
                                message: 'this field is required',
                            },
                        ]}>
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="is Public or Private" name={'public'} valuePropName="checked">
                            <Checkbox>Public</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                <div className={'small-title'}>Electricity </div>

                <Row className={'w-full'} gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Baseline Start Date" name={'elec_baseline_start_date'} className={'w-full'}>
                            <DatePicker picker={'month'}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Baseline End Date" name={'elec_baseline_end_date'} className={'w-full'}>
                            <DatePicker picker={'month'}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Post Start Date" name={'elec_post_start_date'} className={'w-full'}>
                            <DatePicker picker={'month'}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Post End Date" name={'elec_post_end_date'} className={'w-full'}>
                            <DatePicker picker={'month'}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Price Type" name={'elec_type'}>
                            <Select placeholder="Select a price type">
                                <Option value="Baseline Average">Baseline Average</Option>
                                <Option value="Post-Period Average">Post-Period Average</Option>
                                <Option value="Custom">Custom</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.elec_type !== currentValues.elec_type}>
                            {({ getFieldValue }) => {
                                return getFieldValue('elec_type') === 'Custom' ? (
                                    <Form.Item
                                        label="Electricity Price"
                                        name="elec_price"
                                    >
                                        <InputNumber precision={3} style={{ width: '100%' }} />
                                    </Form.Item>
                                ) : null;
                            }}
                        </Form.Item>
                    </Col>

                    <Col className={"ml-2"}>
                        <Form.Item label="How to Break Line" name="elec_use_breakpoints">
                        <Select
                    //   onChange={this.onElecBreakpointSegmentChange}
                    //   value={this.state.elec_usedModuleValue}
                      defaultValue={"Segments"}
                    >
                      <Select.Option value="Segments">Segments</Select.Option>
                      <Select.Option value="Breakpoints">
                        Breakpoints
                      </Select.Option>
                    </Select>
                        </Form.Item>
                </Col> 
                    {
                        elec_use_breakpoints === 'Breakpoints' && <>
                                            <Col span={4}>
                        <Form.Item label="Breakpoint 1" name={'elec_breakpoint1'}>
                            <InputNumber precision={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Breakpoint 2" name={'elec_breakpoint2'}>
                            <InputNumber precision={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Breakpoint 3" name={'elec_breakpoint3'}>
                            <InputNumber precision={0}/>
                        </Form.Item>
                    </Col>
                        </>
                    }

                    {
                        elec_use_breakpoints === 'Segments' && <Col span={4}>
                        <Form.Item label="Segment" name={'elec_segment'}>
                        <Segmented
                        defaultValue={2}
                        options={[1, 2, 3]}
                        // value={this.state.elec_n_segments}
                        // onChange={this.onNSegmentElecChange}
                      />
                        </Form.Item>
                    </Col>
                    }

                    {/* <Col span={3}>
                        <Form.Item label="Range0" name={'elec_range0'}>
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Range1" name={'elec_range1'}>
                            <InputNumber/>
                        </Form.Item>
                    </Col> */}
                </Row>

                <div className={'small-title'}>GAS </div>

                <Row className={'w-full'} gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Baseline Start Date" name={'gas_baseline_start_date'} className={'w-full'}>
                            <DatePicker picker={'month'}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Baseline End Date" name={'gas_baseline_end_date'} className={'w-full'}>
                            <DatePicker picker={'month'}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Post Start Date" name={'gas_post_start_date'} className={'w-full'}>
                            <DatePicker picker={'month'}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Post End Date" name={'gas_post_end_date'} className={'w-full'}>
                            <DatePicker picker={'month'}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Price Type" name={'gas_type'}>
                            <Select placeholder="Select a price type">
                                <Option value="Baseline Average">Baseline Average</Option>
                                <Option value="Post-Period Average">Post-Period Average</Option>
                                <Option value="Custom">Custom</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.gas_type !== currentValues.gas_type}>
                            {({ getFieldValue }) => {
                                return getFieldValue('gas_type') === 'Custom' ? (
                                    <Form.Item
                                        label="Gas Price"
                                        name="gas_price"
                                    >
                                        <InputNumber precision={3} style={{ width: '100%' }} />
                                    </Form.Item>
                                ) : null;
                            }}
                        </Form.Item>
                    </Col>
                    <Col className={"ml-2"}>
                        <Form.Item label="How to Break Line" name="gas_use_breakpoints">
                        <Select
                    //   onChange={this.onElecBreakpointSegmentChange}
                    //   value={this.state.elec_usedModuleValue}
                      defaultValue={"Segments"}
                    >
                      <Select.Option value="Segments">Segments</Select.Option>
                      <Select.Option value="Breakpoints">
                        Breakpoints
                      </Select.Option>
                    </Select>
                        </Form.Item>
                </Col> 
                    {
                        gas_use_breakpoints === 'Breakpoints' && <>
                                            <Col span={4}>
                        <Form.Item label="Breakpoint 1" name={'gas_breakpoint1'}>
                            <InputNumber precision={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Breakpoint 2" name={'gas_breakpoint2'}>
                            <InputNumber precision={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Breakpoint 3" name={'gas_breakpoint3'}>
                            <InputNumber precision={0}/>
                        </Form.Item>
                    </Col>
                        </>
                    }
                    {
                        gas_use_breakpoints === 'Segments' && <Col span={4}>
                        <Form.Item label="Segment" name={'gas_segment'}>
                        <Segmented
                        defaultValue={2}
                        options={[1, 2, 3]}
                        // value={this.state.elec_n_segments}
                        // onChange={this.onNSegmentElecChange}
                      />
                        </Form.Item>
                    </Col>
                    }
                </Row>

                <Form.Item>
                    <Button type="primary" htmlType="submit">Save</Button>
                </Form.Item>
            </Form>
        </Drawer>

    )

}
