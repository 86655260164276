import React from "react";
import {Input, Select, DatePicker, Image} from "antd";

const { TextArea } = Input;
const { Option } = Select;

// const renderDetails = (record) => {
//     const { type, createdby_name, message, ...details } = record;
//
//     const detailEntries = Object.entries(details).map(([key, value], idx) => {
//         // Check if the key is 'params' and handle it accordingly
//         if (key === 'params') {
//             let parsedValue;
//             try {
//                 parsedValue = JSON.parse(value);
//             } catch (e) {
//                 console.error('Error parsing params:', e);
//                 return (
//                     <div key={idx} style={{ display: 'flex', justifyContent: 'space-between' }}>
//                         <span>{`${key}: `}</span>
//                         <span>{value}</span>
//                     </div>
//                 );
//             }
//             // If parsing is successful, display each key-value pair on separate lines
//             return Object.entries(parsedValue).map(([paramKey, paramValue], paramIdx) => (
//                 <div key={`${idx}_${paramIdx}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
//                     <span>{`${paramKey}: `}</span>
//                     <span>{typeof paramValue === 'object' ? JSON.stringify(paramValue, null, 2) : paramValue}</span>
//                 </div>
//             ));
//         } else {
//             // Handle non-params keys normally
//             return (
//                 <div key={idx} style={{ display: 'flex', justifyContent: 'space-between' }}>
//                     <span>{`${key}: `}</span>
//                     <span>{typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</span>
//                 </div>
//             );
//         }
//     });
//
//     return <div>{detailEntries}</div>;
// };

export const NotificationFormFields = () => {
    return [
        {
            title: 'Key',
            name: 'id',
            order: 0,
            visible: true,
            length: 0,
            type: 'text',
            render: <Input disabled />,
        },
        {
            title: 'Type',
            name: 'type_of_notification',
            order: 1,
            visible: true,
            length: 100,
            type: 'select',
            render: (
                <Select>
                    <Option value="updated">Updated</Option>
                    <Option value="created">Created</Option>
                    <Option value="deleted">Deleted</Option>
                </Select>
            ),
        },
        {
            title: 'Description',
            name: 'description',
            order: 2,
            visible: true,
            length: 500,
            type: 'textarea',
            render: <TextArea rows={4} />,
        },
        {
            title: 'Sender',
            name: 'sender_name',
            order: 3,
            visible: true,
            length: 100,
            type: 'text',
            render: <Input disabled />,
        },
        {
            title: 'Status',
            name: 'status',
            order: 4,
            visible: true,
            length: 50,
            type: 'select',
            render: (
                <Select>
                    <Option value="unread">Unread</Option>
                    <Option value="read">Read</Option>
                </Select>
            ),
        },
        {
            title: 'Date',
            name: 'created_date',
            order: 5,
            visible: true,
            length: 100,
            type: 'date',
            render: <DatePicker showTime />,
        },
        // {
        //     title: 'Image',
        //     name: 'image',
        //     order: 6,
        //     visible: true,
        //     length: 100,
        //     type: 'File',
        //     render: <Image />,
        // },
        // {
        //     title: 'Log',
        //     name: 'log',
        //     order: 6,
        //     visible: true,
        //     length: 100,
        //     type: 'custom_type',
        //     render: (text) => {
        //         return <div>{text && renderDetails(text)}</div>
        //     },
        // },
    ];
};
