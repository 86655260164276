import {Drawer} from "antd";

import React, {Component} from "react";
import Detail from "@components/Table/Detail";

class Clone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenNew: true,
            selectedTemplateType: null,
            selectedTypeOption: null,
        }
    }

    onClose = () => {
        this.props.toggleCloneDrawer(false)
    }

    onCreateRecords = (e) => {
        this.props.toggleCloneDrawer(false)
        this.props.createRecords(e)
    }

    render() {
        return(
            <>
                <Drawer
                    width={window.innerWidth > 900 ? '90%' : '100%'}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.props.isCloneOpen}
                    destroyOnClose={true}
                    autoFocus={true}
                >
                    <div>
                        <Detail
                            key={'tablecontent_detail2'}
                            formKey={'tablecontent_detail2'}
                            formFields={this.props.formFields}
                            columnProps={this.props.columnProps}
                            createRecords={this.onCreateRecords}
                            formClassName={this.props.formClassName}
                            isNewRecords={false}
                            onClose={this.onClose}
                            formRefCallback={this.formRefCallback}
                            data={this.props.data}
                            isClone={true}
                        />
                    </div>
                </Drawer>
            </>
        )
    }
}

export default Clone;
