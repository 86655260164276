import { PDFPageContainer } from "../components/PageContainer";
import { fullHeight, fullWidth } from "./utils";
import React from "react";

const HomePage = (_, { pdfImages, facilityName, companyName, Date }) => {
  const backgroundImage = pdfImages.backgroundImage
  const layerImage = pdfImages.layerImage
  return (
    <PDFPageContainer>
      <p-img
        width={fullWidth}
        height={fullHeight}
        src={backgroundImage}
        absolutePosition={{ x: 0, y: 0 }}
      ></p-img>
      <p-img
        src={layerImage}
        width={fullWidth * 4/ 5}
        height={(fullHeight * 2) / 3}
        absolutePosition={{ x: 0, y: 140 }}
      ></p-img>
      <p-stack absolutePosition={{x: 40, y: 320}}>
        <p-text color="#fff" fontSize={28} bold={true}>{companyName}</p-text>
        <p-text color="#fff" fontSize={28} bold={true} margin={[0, 20]}>{facilityName}</p-text>
        <p-text color="#fff" fontSize={28} margin={[80, 0]}>{Date}</p-text>
      </p-stack>
    </PDFPageContainer>
    
  );
};

export default HomePage;
