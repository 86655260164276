import React, {Component} from 'react';
import {Pie} from '@antv/g2plot';
import {NoDecimalFormat} from "@components/Format";
import styled from 'styled-components';

const ChartWrap = styled.div`
    .g2-html-annotation {
        opacity: 0;
    }
`

class EUIPie extends Component {

    chartRef = React.createRef();

    constructor(props) {
        super(props);

    }

    // componentDidMount() {
    //     this.draw(this.props)
    // }

    componentWillReceiveProps(nextProps) {
        this.chartRef.current.innerHTML = '';
        this.draw(nextProps)
    }

    draw = (props) => {
        const chartDom = this.chartRef.current;

        // console.log('data', props.data)

        const piePlot = new Pie(chartDom, {
            appendPadding: 10,
            data: props.data,
            angleField: 'value',
            colorField: 'type',
            height: props.height,
            radius: 1,
            innerRadius: 0.6,
            color: ['blue', 'brown'],
            annotations: [
                {
                    type: 'text',
                    position: ['50%', '42%'],
                    content: `Total`,
                    animate: true,
                    style: {
                        textAlign: 'center',
                        fontSize: 16,
                        fill: '#000',
                        fontWeight: 600,
                    },
                },
                {
                    type: 'text',
                    position: ['50%', '58%'],
                    content: `${Math.round(props.data.reduce((p, v) => p + v.value, 0))}`,
                    animate: true,
                    style: {
                        textAlign: 'center',
                        fontSize: 24,
                        fill: '#000',
                        fontWeight: 600,
                    },
                },
            ],
            legend: {
                layout: 'horizontal',
                position: 'bottom'
            },
            meta: {
                value: {
                    formatter: (v) => `${NoDecimalFormat(v)}`,
                },
            },
            interactions: [{type: 'element-selected'}, {type: 'element-active'}],
            statistic: {
                title: false,
                content: {
                    style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: 24
                    },
                }
            },
        });

        piePlot.render();
    }

    render() {
        return (
            <>
                <ChartWrap ref={this.chartRef}/>
            </>
        );
    }
}

export default EUIPie;
