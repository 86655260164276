import React, { Component } from 'react';
import {api} from "@redux/api";
import {Button, Spin} from "antd";
import Input from "antd/es/input";
import {TwoDecimalFormat} from "@components/Format";

class ServiceDateTemperature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            temperature: null,
            avgOat: null,
            loading: false,
            service_start_date: null,
            end_start_date: null,
        };
    }

    componentDidMount() {
        this.fetchTemperature(this.props.service_start_date, this.props.end_start_date);
        if (this.props.record) {
            this.setState({ avgOat: this.props.record });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.service_start_date !== this.props.service_start_date) {
            this.fetchTemperature(nextProps.service_start_date, nextProps.end_start_date );
        }
    }

    fetchTemperature = (service_start_date, end_start_date) => {
        // console.log('ServiceDateTemperature4', serviceDate)
        if (!service_start_date) return;

        this.setState({ loading: true, service_start_date, end_start_date });

        api.get_station_temperature(service_start_date, end_start_date)
            .then(response => {
                console.log('response', response)
                let temperature = response.data?TwoDecimalFormat(response.data):null;
                this.setState({ temperature, loading: false });
            })
            .catch(error => {
                // Handle errors if needed
                this.setState({ loading: false });
            });
    };

    setServiceDateTemperature = () => {
        const { temperature } = this.state;
        this.setState({ loading: true, avgOat: temperature });

        if (this.props.formRef) {
            this.props.formRef.setFieldsValue({
                avgoat: temperature
            });
        }

        setTimeout(() => {
            this.setState({ loading: false });
        }, 200);
    };

    onChange = (e) => {
        if (this.props.formRef) {
            this.props.formRef.setFieldsValue({
                avgoat: e.target.value
            });
        }
        this.setState({ avgOat: e.target.value });
    }
    render() {
        const { temperature, avgOat, loading } = this.state;

        return (
            <Spin spinning={loading}>
                <div className={'flex'}>
                    <Input type={'number'} value={avgOat || ''} onChange={(e) => this.onChange(e)} />
                    {temperature && (
                        <Button
                            type="primary"
                            className={'ml-5'}
                            onClick={this.setServiceDateTemperature}
                            icon={<i className="fa-solid fa-temperature-high pr-4"></i>}
                        >
                            {"Station Temperature: " + temperature}
                        </Button>
                    )}
                </div>
            </Spin>
        );
    }
}

export default ServiceDateTemperature;
