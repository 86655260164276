import React, {Component} from 'react';
import "react-csv-importer/dist/index.css";


import {connect} from "react-redux";
import FacilityBenchmarkTable from "@containers/facility/Benchmark/FacilityBenchmarkTable/FacilityBenchmarkTable";

class FacilityBenchmarkTableContent extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
        this.state = {
            searchString: null,
            filter_dates: [],
            triggered: false
        }
    }

    handleFresh = (e) => {
        this.tableRef.current.handleFresh()
    }

    createRecords = (e) => {
        return this.props.createRecords(e);
    }

    deleteRecord = (body) => {
        return this.props.deleteRecord(body)
    }

    bulkInsert = (rows) => {
        return this.props.bulkInsert(rows)
    }

    bulkDelete = (body) => {
        return this.props.bulkDelete(body)
    }

    editRecords = (key, row) => {
        this.props.editRecords(key, row)
    }

    handOnlineSave = (key, row) => {
        return this.props.handOnlineSave(key, row)
    }

    handleFilter = (query) => {
        return this.props.handleFilter(query)
    }

    handleSelect = (e) => {
        e = e ? e : null
        this.setState({
            searchString: e
        })
    }

    getRecord = (data) => {
        this.props.getRecord(data)
    }

    setFilterDates = (dates) => {
        this.setState({
            filter_dates: dates,
            triggered: !this.state.triggered
        }, function () {
            this.props.setFilterDates(dates)
        })

    }

    refresh = (needRequest = true) => {
        this.tableRef.current.refresh(needRequest);
    };

    render() {

        return (

            <section className="bg-white cardWrapper p-5 outerCardWrapper">
                <>

                    {typeof this.props.renderFilter !== "undefined" ? this.props.renderFilter() : ''}

                    <FacilityBenchmarkTable
                        ref={this.tableRef}
                        data={this.props.data}
                        columnProps={this.props.columnProps}
                        columns={this.props.columns}
                        postComment={this.props.postComment}
                        formFields={this.props.formFields}
                        getRecord={this.getRecord}
                        createRecords={this.createRecords}
                        deleteRecord={this.deleteRecord}
                        bulkInsert={this.bulkInsert}
                        bulkDelete={this.bulkDelete}
                        handOnlineSave={this.handOnlineSave}

                        handleFresh={this.handleFresh}
                        handleFilter={this.handleFilter}

                        searchString={this.state.searchString}
                        handleGlobalSearch={this.handleSelect}
                        formRelated={this.props.formRelated}
                        relatedList={this.props.relatedList}
                        filter_dates={this.props.filter_dates}
                        triggered={this.state.triggered}
                        isRowClickable={this.props.isRowClickable}
                        isRowSelectable={this.props.isRowSelectable}
                        selected_row={this.props.selected_row}
                        textAlign={this.props.textAlign}
                        userPermission={this.props.userPermission}

                        relatedObject={this.props.relatedObject}
                        relatedId={this.props.relatedId}
                        isChat={this.props.isChat}
                        isRelated={this.props.isRelated}
                        isAttachments={this.props.isAttachments}
                        hide_search={this.props.hide_search}
                        isRowSelection={this.props.isRowSelection}
                    />

                </>
            </section>

        );
    }

}


const mapStateToProps = state => {
    return {
        // loading: state.EditableTable.load
    }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(FacilityBenchmarkTableContent);


