import { PDFPageContainer } from "../components/PageContainer";
import { PdfTitle } from "../components/title";
import { col_2, convertTo2DArray, fullHeight, fullWidth, pdfFontSize } from "./utils";
import React from "react";

const PDFSummary = (_, {pdfFormValue, pdfImages}) => {
  const summary = pdfImages.summary;
  if(summary.length === 0) {
    return null
  }
  const images2d = convertTo2DArray(summary)
  return (
    <PDFPageContainer lastPage={true}>
      <PdfTitle w={140} title="Utility Summary" subTitle={pdfImages.chartId_SummaryTitle}></PdfTitle>
      {
        images2d.map((imgs, index) => {
          return (
            <p-col columnGap={40} key={index}>
              {
                imgs.map((img, idx) => {
                  if(!img) return <p-text></p-text>
                  return (
                      <p-stack key={idx} width={col_2} margin={[0, 15]}  unbreakable={true}>
                        <p-text color="#396598" fontSize={pdfFontSize.H4} margin={[0, 10, 0, 2]} bold={true}>{img.pdfChart_Title}</p-text>
                        <p-text color="#396598" fontSize={pdfFontSize.H5} margin={[0, 2]} bold={true}>{img.pdfChart_SubTitle}</p-text>
                        <p-img  dw={400} dh={250} width={250}  src={img.img}></p-img>
                    </p-stack>
                  );
                })
              }
            </p-col>
          )
        })
      }

    </PDFPageContainer>
    
  );
};

export default PDFSummary;
