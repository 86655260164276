import React, {Component} from 'react';
import {Tabs} from "antd";

import CustomUser from "@containers/management/UserManagement/CustomUser/CustomUser";
import Profile from "@containers/management/UserManagement/Profile/Profile";
import PermissionControl from "@components/Permission/Permission";
import _ from "lodash";
import {connect} from "react-redux";

const {TabPane} = Tabs;

class UserManagementTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: '1'
        }
    }

    onTabChange = (key) => {
        console.log('selectedTab', key)
        this.setState({
            selectedTab: key
        })
    }

    render() {
        return (
            <div className={'px-4'}>
                <Tabs defaultActiveKey="1" className='rounded-lg' animated={true} forcerender={false}
                      onChange={this.onTabChange}>
                    <TabPane
                        tab={<div className={'flex items-center'}><i className={'far fa-user-circle mr-2'}/> User</div>}
                        key="1">
                        {this.state.selectedTab === '1' ? <CustomUser/> : ''}
                    </TabPane>

                    <TabPane
                        tab={<div className={'flex items-center'}><i className={'far fa-address-card mr-2'}/> Profile
                        </div>} key="2">
                        <PermissionControl allowedPermissions={'1:Profile'} returnBool={false} role={this.props.Role}>
                            {this.state.selectedTab === '2' ? <Profile/> : ''}
                        </PermissionControl>
                    </TabPane>

                </Tabs>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] * 1 : null,
    }
}

export default connect(
    mapStateToProps,
    null
)(UserManagementTabs);
