import Input from "antd/es/input";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import {Select} from "antd";
import EquipmentCategoryLookup from "@containers/Lookup/EquipmentCategoryLookup";

const {Option} = Select;


export const EquipmentFormFields = [
    {
        title: 'key',
        name: 'id',
        order: 0,
        visible: false,
        length: 0,
        type: 'text',
    },
    {
        title: 'Name',
        name: 'name',
        order: 1,
        visible: true,
        length: "w-1/2",
        type: 'text',
        required: true,
        render: <Input/>
    },
    {
        title: 'Category',
        name: 'category',
        order: 7,
        visible: true,
        length: "w-1/2",
        type: 'Lookup_text',
        render: (value) => {
            return <EquipmentCategoryLookup value={value}/>
        }
    },
    {
        title: 'Service',
        name: 'service',
        order: 8,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Condition',
        name: 'status',
        order: 5,
        visible: true,
        length: "w-1/2",
        type: 'text',
        initialValue: 'Unknown',
        render: <Select>
            <Option value="Unknown">Unknown</Option>
            <Option value="Failed">Failed</Option>
            <Option value="Fair">Fair</Option>
            <Option value="Good">Good</Option>
            <Option value="Excellent">Excellent</Option>
        </Select>
    },


    // {
    //     title: 'Tag',
    //     name: 'tag',
    //     order: 3,
    //     visible: true,
    //     length: "w-1/2",
    //     type: 'Tag',
    //     render: <TagInput/>
    // },
    {
        title: 'Building',
        name: 'building',
        order: 6,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Location',
        name: 'location',
        order: 6,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },

    {
        title: 'Install Date',
        name: 'install_date',
        order: 4,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input type={'date'}/>
    },

    // {
    //     title: 'Separator',
    //     name: 'Separator',
    // },

    {
        title: 'Manufacturer',
        name: 'Manufacturer',
        order: 6,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Equipment Model',
        name: 'Equipment_Model',
        order: 6,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },


    {
        title: 'Equipment SN',
        name: 'Equipment_SN',
        order: 6,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Description',
        name: 'description',
        order: 2,
        visible: true,
        length: "w-full",
        type: 'textarea',
        render: <TextArea/>
    },


    {
        title: 'Key Spec 1 Name',
        name: 'key_spec_1_name',
        order: 9,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled={true}/>
    },
    {
        title: 'Key Spec 1 Value',
        name: 'key_spec_1_value',
        order: 10,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },
    {
        title: 'Key Spec 2 Name',
        name: 'key_spec_2_name',
        order: 11,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled={true}/>
    },
    {
        title: 'Key Spec 2 Value',
        name: 'key_spec_2_value',
        order: "md:w-1/3 lg:w-1/3 sm:w-full",
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },

    {
        title: 'Key Spec 3 Name',
        name: 'key_spec_3_name',
        order: 13,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled={true}/>
    },
    {
        title: 'Key Spec 3 Value',
        name: 'key_spec_3_value',
        order: 14,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },

    {
        title: 'Key Spec 4 Name',
        name: 'key_spec_4_name',
        order: 15,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled={true}/>
    },
    {
        title: 'Key Spec 4 Value',
        name: 'key_spec_4_value',
        order: 16,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },

    {
        title: 'Key Spec 5 Name',
        name: 'key_spec_5_name',
        order: 15,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled={true}/>
    },
    {
        title: 'Key Spec 5 Value',
        name: 'key_spec_5_value',
        order: 16,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },

    {
        title: 'Key Spec 6 Name',
        name: 'key_spec_6_name',
        order: 15,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled={true}/>
    },
    {
        title: 'Key Spec 6 Value',
        name: 'key_spec_6_value',
        order: 16,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },

    {
        title: 'Key Spec 7 Name',
        name: 'key_spec_7_name',
        order: 15,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled={true}/>
    },
    {
        title: 'Key Spec 7 Value',
        name: 'key_spec_7_value',
        order: 16,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },

    {
        title: 'Key Spec 8 Name',
        name: 'key_spec_8_name',
        order: 15,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled={true}/>
    },
    {
        title: 'Key Spec 8 Value',
        name: 'key_spec_8_value',
        order: 16,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input/>
    },

    {
        title: 'Created By',
        name: 'createdby_name',
        order: 17,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled/>
    },

    {
        title: 'Created Date',
        name: 'created_date',
        order: 18,
        visible: true,
        length: "w-1/2",
        type: 'Date',
        render: <Input disabled/>
    },
    {
        title: 'Modified By',
        name: 'lastModified_name',
        order: 19,
        visible: true,
        length: "w-1/2",
        type: 'text',
        render: <Input disabled/>
    },
    {
        title: 'Modified Date',
        name: 'modified_date',
        order: 20,
        visible: true,
        length: "w-1/2",
        type: 'Date',
        render: <Input disabled/>
    },

]
