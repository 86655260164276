import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import TextArea from "antd/es/input/TextArea";

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}
export const functionformProps = {
    title: 'functionform',
    name: 'functionform',
    description: ''
}


export const FunctionalFormColumns = [
    {
        title: 'key',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: true,
        width: '4%'
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        search: false,
        edit: true,
        sorter: (a, b) => a.name?.localeCompare(b.name),
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        render: (text, record, _, action) => (
            <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
        ),
        renderFormItem: () => <TextArea rows={1}/>,
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        edit: true,
        hideInTable: window.innerWidth < 760,
        sorter: (a, b) => a.type?.localeCompare(b.type),
    },
    {
        title: '',
        valueType: 'option',
        width: '6rem',
        importIngore: true,
        render: (text, record, _, action) => (
            <div className={'space-around'}>
                {/*<a*/}
                {/*    key="editable"*/}
                {/*    onClick={() => (action?.startEditable?.(record.id))}*/}
                {/*>*/}
                {/*    <i className="fas fa-edit text-lg"></i>*/}
                {/*</a>*/}
                <a onClick={(e) => {
                    e.stopPropagation();
                    return action ? renderDrawer(text, record.id) : ''
                }}>
                    <i className="fas fa-expand-arrows-alt text-lg"></i>
                </a>
            </div>
        ),
    }

];
