import React, {Component} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {ProfileColumns, ProfileProps} from "@containers/management/UserManagement/Profile/ProfileColumns";

import {connect} from "react-redux";
import {api} from "@redux/api";
import {Button, Select, Spin} from "antd";
import ProfileForm from "@containers/management/UserManagement/Profile/ProfileFormFields";
import _ from "lodash";

const {Option} = Select;

class Profile extends Component {

    constructor(props) {

        super(props);
        this.tableRef = React.createRef();
        this.formRef = React.createRef();
        this.state = {
            data: [],
            register: false,
            fileList: [],
            refresh: true,
            isLoading: false,
            isDrawOpen: false,
            drawId: null,
        }
    }

    componentDidMount() {

        let role = this.props.Role;
        role = role ? Object.keys(role)[0] : '';
        this.setState({
            role: role
        })
    }

    handleCreateProfile = (body) => {
        return api.createUserpermissions(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }


    handOnlineSave = (key, row) => {


    }


    handleFilter = (query) => {
        this.setState({
            isLoading: true
        })
        return api.filterProfile(query).then(
            response => {
                let dataSource = response.data.results;
                // console.log('Profile', dataSource)
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getProfile = (data) => {
        this.setState({
            isLoading: false,
            data
        })
    }

    handleDrawOpen = (e, id) => {
        this.setState({
            isDrawOpen: e,
            drawId: id
        })
    }

    handleRefresh = () => {
        this.tableRef.current.handleFresh()
    }

    render() {

        return (
            <Spin spinning={this.state.isLoading}>

                <div className=" lg:w-auto ml-auto px-3 flex flex-wrap justify-end m-5 gap-5">

                    <Button key="button1" type="primary"
                            className={'inline-flex items-center reload-refresh-btn'}
                            onClick={this.handleRefresh}>
                        <i className="fas fa-sync pr-3"></i> Refresh
                    </Button>

                    <Button key="button2" type="primary"
                            disabled={this.props.Role === '2.2' || this.props.Role === '4.0'}
                            className={'inline-flex items-center  '}
                            onClick={() => this.handleDrawOpen(true, null)}>
                        <i className="fas fa-plus pr-3"></i> New
                    </Button>


                </div>

                <div className="">


                    {this.state.refresh ? <EditableTable
                        ref={this.tableRef}
                        columns={ProfileColumns(this.handleDrawOpen)}
                        data={this.state.data}
                        columnProps={ProfileProps}
                        formRelated={false}
                        getRecord={this.getProfile}
                        createRecords={this.handleCreateProfile}
                        deleteRecord={this.handleDeleteProfile}
                        bulkInsert={this.handleBulkInsert}
                        bulkDelete={this.handleBulkDelete}
                        handOnlineSave={this.handOnlineSave}
                        handleFilter={this.handleFilter}
                        relatedList={this.props.relatedList}
                        hide_date={true}
                        hide_imports={true}
                        userPermission={false}
                        isRowClickable={false}
                        hide_search={true}
                        isResetPassword={'true'}
                        isHideRefreshNews={true}
                    /> : ''}

                    {this.state.isDrawOpen && <ProfileForm
                        handleDrawOpen={this.handleDrawOpen}
                        handleRefresh={this.handleRefresh}
                        data={this.state.data.filter(e => e.id === this.state.drawId)[0]}
                        isDrawOpen={this.state.isDrawOpen}/>}
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        // Profile: _.get(state.Profile, 'data'),
        // SelectedCompany: _.get(state.Company, 'selectedCompany'),
        // SelectedFacility: _.get(state.Facility, 'selectedFacility', 'id')
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] : null,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        // setProfileRequest: (e) => dispatch(actions.ProfileRequest(e))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);
