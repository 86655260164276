import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

const company_items = store.getState().Auth.company;

export const FacilityProps = {
    title: 'Facility',
    name: 'facility',
    description: ''
}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}


export const FacilityColumnsCompact =() =>{
  return  [
        {
            title: 'key',
            dataIndex: 'id',
            key: 'id',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: true,
            width: '4%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            search: false,
            edit: true,
            sorter: (a, b) => a.name?.localeCompare(b.name),
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: 'required',
                    },
                ],
            },
            renderFormItem: () => <TextArea rows={1}/>,
            render: (text, record, _, action) => (
                <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
            )
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            edit: true,
            required: true,
            search: false,
            hideInTable: window.innerWidth < 760,
            renderFormItem: () => <TextArea rows={1}/>,
        },
        {
            title: 'Modified Date',
            dataIndex: 'modified_date',
            key: 'modified_date',
            editable: false,
            importIngore: true,
            hideInTable: window.innerWidth < 760,
            sorter: (a, b) => a.modified_date - b.modified_date,
            render: (text, record) => (
                moment(record.modified_date).format('YYYY-MM-DD')
            )
        },
        {
            title: '',
            valueType: 'option',
            width: '6rem',
            importIngore: true,
            render: (text, record, _, action) => (
                <div className={'space-around'}>
                    <a
                        key="editable"
                        onClick={() => (action?.startEditable?.(record.id))}
                    >
                        <i className="fas fa-edit text-lg"></i>
                    </a>

                </div>
            ),
        },


    ];
}

