import React, {Component, PureComponent} from 'react';
import {Col, Collapse, Row, Spin} from "antd";
import BarChart from "@containers/facility/Benchmark/Chart/BarChart";
import GoogleMaps from "@components/GoogleMaps";
import {colors} from "@containers/facility/Benchmark/Chart/colors";
import _ from "lodash";
import actions from "@redux/facilitybenchmark/actions";
import EditableTableActions from "@redux/EditableTable/actions";
import {connect} from "react-redux";
import moment from "moment";
import {api} from "@redux/api";

import {
    FacilityBenchmarkColumns,
    FacilityBenchmarkProps
} from "@containers/facility/Benchmark/FacilityBenchmark/FacilityBenchmarkColumns";
import {FacilityBenchmarkStyles} from "@containers/facility/Benchmark/FacilityBenchmark/FacilityBenchmarkStyle";
import dashboard from "@assets/icons/bar-chart.png";
import {asOfDate, removeDuplicates} from "@components/help";
import {jsforceResponse} from "@config/httpService";

import FacilityBenchmarkTableContent
    from "@containers/facility/Benchmark/FacilityBenchmarkTable/FacilityBenchmarkTableContent";
import {FacilityBenchmarkFilters,} from "@containers/facility/Benchmark/FacilityBenchmark/FacilityBenchmarkFilters";
import BubblePlot from "@containers/facility/Benchmark/Chart/BubblePlot";


const {Panel} = Collapse;

class FacilityBenchmark extends PureComponent {

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            filter_dates: [],
            selected_months: [],
            selected_id: [],
            chart1_data: [],
            chart2_data: [],
            chart3_data: [],
            chart4_data: [],
            colors_type: null,

            filters: [],
            filtered_start_year: null,
            filtered_end_year: null,
            filtered_category: [],
            filtered_region: [],
            filtered_climate_zone: [],
            filtered_company: [],
            visible: false,
            isLoading: false,
            end_max_year: null,
            start_min_year: null
        }
    }

    async componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    handleCreate = () => {

    }

    handleDeleteFacilityBenchmark = (id) => {

    }

    handleBulkInsert = (body) => {

    }

    handleBulkDelete = (body) => {

    }

    handOnlineSave = (key, row) => {
        return api.editFacilityBenchmark(key, row).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = async (query) => {

        this.setState({
            isLoading: true
        })
        // make query string from filters
        let query_new = (this.state.filtered_start_year ? ('&start_year=' + (this.state.filtered_start_year)) : '') +
            (this.state.filtered_end_year ? ('&end_year=' + (this.state.filtered_end_year)) : '') +
            (this.state.filtered_company?.length > 0 ? ('&company=' + (this.state.filtered_company)) : '') +
            (this.state.filtered_region?.length > 0 ? ('&region=' + (this.state.filtered_region).join(",")) : '') +
            (this.state.filtered_category?.length > 0 ? ('&category=' + (this.state.filtered_category).join(",")) : '') +
            (this.state.filtered_climate_zone?.length > 0 ? ('&climate_zone=' + (this.state.filtered_climate_zone).join(",")) : '');

        let result = await api.getFacilityBenchmark(query_new);
        result = jsforceResponse(result);
        result = _.get(result, 'data.results')

        let all_facilities = result;
        let chart1_data = [], chart2_data = [], chart3_data = [], colors_type = [];
        let isCurrentIndex = 0, isCurrentId = 0;

        for (let i = 0; i < all_facilities.length; i++) {

            if (all_facilities[i]['facility_id'] === this.props.CurrentFacility) {
                isCurrentId = all_facilities[i]['id'];
                all_facilities[i]['isCurrent'] = true
            }

            colors_type[all_facilities[i]['category']] = colors[i]

            const year = all_facilities[i]['year'] ? + all_facilities[i]['year'] + '' : '';
            const eui = all_facilities[i]['eui'] ? + all_facilities[i]['eui'] : '';
            const energy_costs_sqft = all_facilities[i]['energy_costs_sqft'] ? + all_facilities[i]['energy_costs_sqft'] : '';
            const size = all_facilities[i]['size'] ? + all_facilities[i]['size'] : '';
            const category = all_facilities[i]['category'] ? all_facilities[i]['category'] : '';
            const isCurrent = all_facilities[i]['facility_id'] === this.props.CurrentFacility;
            // for mock
            // const isCurrent = all_facilities[i]['facility_id'] === this.props.CurrentFacility || all_facilities[i]['id'] < 60;

            if(year && eui && size) {
                chart1_data.push({
                    key: i,
                    xAxis: year,
                    yAxis: eui,
                    size,
                    type: category,
                    isCurrent,
                })
            }

            if(year && energy_costs_sqft && size) {
                chart2_data.push({
                    key: i,
                    xAxis: year,
                    yAxis: energy_costs_sqft,
                    size,
                    type: category,
                    isCurrent,
                })
            }
            // chart2_data.push({
            //     key: i,
            //     xAxis: all_facilities[i]['year'],
            //     yAxis: all_facilities[i]['energy_costs_sqft'],
            //     size: parseInt(all_facilities[i]['size']),
            //     type: all_facilities[i]['category'],
            //     isCurrent: all_facilities[i]['facility_id'] === this.props.CurrentFacility
            // })

            chart3_data.push({
                key: i.toString(),
                xField: all_facilities[i]['facility'],
                yField: all_facilities[i]['eui'] ? all_facilities[i]['eui'] : 0,
                type: all_facilities[i]['category'],
                isCurrent: all_facilities[i]['facility_id'] === this.props.CurrentFacility
            })

        }

        chart1_data = chart1_data.sort((a, b) => a.xAxis - b.xAxis)
        chart2_data = chart2_data.sort((a, b) => a.xAxis - b.xAxis)
        chart3_data = chart3_data.sort((a, b) => b.yField - a.yField)

        // isCurrentIndex = chart3_data.findIndex(e => e.id === isCurrentId)

        // chart3_data = chart3_data

        this.setState({
            data: all_facilities,
            chart1_data,
            chart2_data,
            chart3_data,
            colors_type,
            isLoading: false
        })
        return {
            data: all_facilities,
            success: true
        }
    }

    getData = (data) => {

        let climate_zone_options = [], category_data = [], region_data = [], start_min_year = null, end_max_year = null;

        // select all
        climate_zone_options.push({label: 'All', value: ''});
        category_data.push({label: 'All', value: ''});
        region_data.push({label: 'All', value: ''});

        for (let i = 0; i < data.length; i++) {
            climate_zone_options.push({label: data[i].eui_climate_zoneng, value: data[i].eui_climate_zoneng});
            category_data.push({label: data[i].category, value: data[i].category});
            region_data.push({label: data[i].region, value: data[i].region});
            if (start_min_year) {
                start_min_year = Math.min(start_min_year, data[i].year)
            } else {
                start_min_year = data[i].year
            }
            if (end_max_year) {
                end_max_year = Math.max(end_max_year, data[i].year)
            } else {
                end_max_year = data[i].year
            }
        }

        this.setState({
            climate_zone_options: removeDuplicates(climate_zone_options),
            category_options: removeDuplicates(category_data),
            region_options: removeDuplicates(region_data),
            company_options: [
                {label: 'Current Company', value: 'CurrentCompany'},
                {label: 'Anonymous', value: 'Anonymous'}
            ],
            start_min_year: start_min_year,
            end_max_year: end_max_year
        })
    }

    setFilterDates = (dates) => {
        this.setState({
            filter_dates: dates
        })
    }

    handleChartSelect = (e) => {
        this.setState({
            selected_months: [e]
        })
        let id = _.get(this.state.data.filter(f => moment(f['service_month']).format('YY-MMM') === e)[0], 'id')
        this.setState({
            selected_id: id
        })
    }

    handleSelectFacilities = (e) => {

    }

    renderHeader = (e) => {
        return (
            <div className={'w-full lg:w-auto flex items-center my-10  px-3 flex-wrap'}>
                <div className={'inline-flex justify-center items-center w-10 h-10 mr-4 rounded'}>
                    <img src={dashboard}/>
                </div>
                <div className={''}>
                    <h2 className="text-lg font-bold">Comparing All Facilities</h2>
                    <p>As of {asOfDate()}</p>
                </div>
            </div>
        )
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    setFilters = (e) => {

        this.setState({
            filtered_start_year: e.start_year ? moment(e.start_year).year() : '',
            filtered_end_year: e.end_year ? moment(e.end_year).year() : '',
            filtered_region: e.region ? e.region : [],
            filtered_climate_zone: e.climate_zone ? e.climate_zone : '',
            filtered_category: e.category ? e.category : '',
            filtered_company: e.company ? e.company : ''
        }, function () {
            this.refresh(false)
        })
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    renderFilter = () => {
        return <FacilityBenchmarkFilters
            start_min_year={this.state.start_min_year}
            end_max_year={this.state.end_max_year}
            climate_zone_options={this.state.climate_zone_options}
            category_options={this.state.category_options}
            region_options={this.state.region_options}
            company_options={this.state.company_options}
            handleFilter={this.setFilters}
        />
    }

    render() {
        return (

            <FacilityBenchmarkStyles>
                <Spin spinning={this.state.isLoading}>

                    {/*{this.renderHeader()}*/}

                    {/*dashboard*/}
                    <Row className={'w-full'}>
                        <Col xs={24} md={12} xl={8} className={' p-6 mb-4'}>
                            <div className={'mr-4'}>

                                <BubblePlot fillOpacity={0.5}
                                            opacity={0.65}
                                            data={this.state.chart1_data} title='EUI (kBtu/sq-yr)  by Year'
                                            xLabel='Year' yLabel='EUI'
                                            colors={this.state.colors_type}/>

                            </div>
                        </Col>
                        <Col xs={24} md={12} xl={8} className={' p-6 mb-4'}>
                            <div className={'mr-4'}>

                                <BubblePlot fillOpacity={0.5}
                                            opacity={0.65}
                                            xLabel='Year' yLabel='Costs ($/SQFT)  by Year'
                                            data={this.state.chart2_data} title='Energy Costs ($/SQFT)'
                                            colors={this.state.colors_type}/>

                            </div>
                        </Col>
                        <Col xs={24} md={12} xl={8} className={' p-6 mb-4'}>

                            <BarChart data={this.state.chart3_data}
                                      title='EUI (kBtu/sq-yr)'
                                      fillOpacity={1}
                                      opacity={1}
                                      xLabel='Facility' yLabel='EUI'
                                      height={400}/>

                        </Col>

                    </Row>

                    {/*table*/}
                    {/*<Collapse defaultActiveKey={['col0']} ghost>*/}
                    {/*    <Panel key={'col0'} header={<Divider/>}>*/}
                    <div id={'compact-form'}>


                        <FacilityBenchmarkTableContent
                            ref={this.tableRef}
                            columns={FacilityBenchmarkColumns(this.state.data)}
                            data={this.state.data}
                            columnProps={FacilityBenchmarkProps}
                            formFields={''}
                            formRelated={''}
                            postComment={''}
                            getRecord={this.getData}
                            createRecords={this.handleCreate}
                            deleteRecord={this.handleDelete}
                            bulkInsert={this.handleBulkInsert}
                            bulkDelete={this.handleBulkDelete}
                            handOnlineSave={this.handOnlineSave}
                            handleFilter={this.handleFilter}
                            renderFilter={this.renderFilter}
                            setFilters={this.setFilters}

                            isRowClickable={false}
                            selected_row={this.props.CurrentFacility}
                            relatedList={this.props.relatedList}
                            relatedId={this.props.relatedId}
                            relatedObject={this.props.relatedObject}
                            toolBarRender={true}
                            isChat={true}
                            isRelated={false}
                            isAttachments={false}
                            hide_imports={true}
                            hide_date={true}
                            hide_search={false}
                            isRowSelection={true}
                        />
                    </div>
                    {/*    </Panel>*/}
                    {/*</Collapse>*/}

                    <Row className={'w-full mt-5'}>
                        <Col xs={24} md={12} xl={24} className={' p-6 mb-4'}>
                            <GoogleMaps data={this.state.data} height={'350px'}
                                        zoom={3.5}
                                isZoomEnabled={false}
                            />
                        </Col>
                    </Row>

                </Spin>
            </FacilityBenchmarkStyles>

        );
    }
}


const mapStateToProps = state => {
    return {
        FacilityBenchmark: _.get(state.FacilityBenchmark, 'data'),
        Selected_Rows: _.get(state.EditableTable, 'selected_rows'),
        CurrentFacility: _.get(state.Facility, 'selected.Id')
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setFacilityBenchmarkRequest: (e) => dispatch(actions.FacilityBenchmarkRequest(e)),
        setFacilityBenchmarkSuccess: (e) => dispatch(actions.FacilityBenchmarkSuccess(e)),
        SelectedRows: (ids) => dispatch(EditableTableActions.SelectedRows(ids))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FacilityBenchmark);
