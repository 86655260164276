import { PDFPageContainer } from "../components/PageContainer";
import { PdfTitle } from "../components/title";
import { col_2, convertTo2DArray, fullHeight, fullWidth, pdfFontSize } from "./utils";
import React from "react";

const isTowSubTitle = (id) => {
  return id === 'ChartId_MonthlyElectricity' || id === 'ChartId_MonthlyGas'
}

const PDFPeriod = (_, { pdfFormValue, pdfImages }) => {

  const period = pdfImages.period
  if (period.length === 0) {
    return null
  }

  const images2d = convertTo2DArray(period)

  return (
    <PDFPageContainer lastPage={true}>
      <PdfTitle w={310} title="Energy consumption, Cost and EUI" subTitle={pdfImages.chartId_PeriodTitle}></PdfTitle>
      {
        images2d.map((imgs, index) => {
          return (
            <p-col columnGap={40} key={index}>
              {
                imgs.map((img, idx) => {
                  if(!img) return <p-text></p-text>
                  return (
                    <>
                      <p-stack key={idx} width={col_2} margin={[0, 15]} unbreakable={true}>
                      {
                        isTowSubTitle(img.id) ? <p-col>
                          <p-text width="65%" color="#396598" fontSize={pdfFontSize.H4} margin={[0, 10, 0, 2]} bold={true}>{img.pdfChart_Title}</p-text>
                          <p-stack width="*" margin={[0, 10, 0, 0]}>
                            <p-text color="#396598" fontSize={pdfFontSize.H5} margin={[0, 2]} alignment="right" bold={true}>{img.pdfChart_SubTitle1}</p-text>
                            <p-text color="#396598" fontSize={pdfFontSize.H5} margin={[0, 2]} alignment="right" bold={true}>{img.pdfChart_SubTitle2}</p-text>
                          </p-stack>
                        </p-col> : <p-text color="#396598" fontSize={pdfFontSize.H4} margin={[0, 10, 0, 2]} bold={true}>{img.pdfChart_Title}</p-text>
                      }

                      <p-img dw={400} dh={250} width={250} src={img.img}></p-img>
                    </p-stack>
                    </>

                  );
                })
              }
            </p-col>
          )
        })
      }
    </PDFPageContainer>

  );
};

export default PDFPeriod;
