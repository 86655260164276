import axios from "axios";
import {store} from "@redux/store";
import authActions from "@redux/auth/actions";
import moment from "moment";
import _ from "lodash";
import notification2 from "@components/Notification2";
import {ENV} from '@config/env'

const {backendUrl} = ENV;

//request interceptor to add the auth token header to requests
axios.interceptors.request.use((config) => {

    const access = localStorage.getItem("access");
    const facility_id = store.getState().Facility.selected.Id;
    const facility_area = store.getState().Facility.selected?.record?.area;
    // if (facility_id === undefined || facility_id === null) {
    //     notification2['error']({
    //         message: 'Please select a facility',
    //         description: 'Please select a facility', placement: 'topLeft'})
    //     return null
    // }
    if (access) {
        config.headers["Authorization"] = 'Bearer ' + access;
        config.params = {...config.params, facility: facility_id, facility_area: facility_area}
    }

    return config;
}, (error) => {
    Promise.reject(error);
},);

//response interceptor to refresh token on receiving token expired
axios.interceptors.response.use((response) => {
    // if (response.config.method !== 'get' && !response.config.url.includes('log') && !response.config.url.includes('token/refresh')) {
    //
    //     // api.createLog({
    //     //     status: response.status,
    //     //     type: response.config.method,
    //     //     message: typeof response.config.data === 'string' ? response.config.data : '{}',
    //     //     params: JSON.stringify(response.config.params),
    //     //     url: response.config.url,
    //     //     company: store.getState().Facility.selected.record?.company_id
    //     // })
    // }
    return response;
}, function (error) {
    // console.log('axios error: ', error.response)
    if (!error.response) {
        return null
    }
    const originalRequest = error.config;
    let refresh = localStorage.getItem("refresh");
    let access = localStorage.getItem("access");
    if (error.response?.status === 401 && originalRequest.url === backendUrl + `/auth/token/refresh`) {
        // console.log('error', error.response.data)
        // notification['error']({
        //     message: error.response.data.code,
        //     description:  error.response.data.detail
        // });
        if (error.response.data.code === 'token_not_valid') {
            store.dispatch(authActions.logout(refresh, access));
        }
        return Promise.reject(error);
    } else if (refresh && error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axios
            .post(backendUrl + `/auth/token/refresh`, {refresh: refresh})
            .then((res) => {

                localStorage.setItem("access", res.data.access);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access;

                return axios(originalRequest);

            }).catch(refreshError => {
                store.dispatch(authActions.logout(refresh, access));
                return Promise.reject(refreshError);
            });
    } else if (error.response?.status === 401) {
        store.dispatch(authActions.logout(refresh, access));
        return Promise.reject(error);
    } else if (error.response?.status === 400 || error.response?.status > 401) {
        console.log('error.response.data', error.response.data)
        let error_data = error.response.data;
        let error_data_response = []

        if (typeof error_data !== 'object' && error.response.data?.startsWith('<!DOCTYPE html>')) {
            error_data_response = 'Server Error'
            notification2['error']({
                message: error.response.statusText, description: error_data_response.toString(),
            });
        } else if (typeof error_data === 'object') {
            Object.keys(error_data).forEach(key => {
                notification2['error']({
                    message: error.response.statusText, description: JSON.stringify(error_data[key]),
                });
            })
        } else {
            error_data_response = error_data;
            notification2['error']({
                message: error.response.statusText, description: error_data_response.toString(),
            });
        }

        if (_.get(error.response, 'data.detail') === 'User has no permission.') {
            window.location.href = '/dashboard';
            window.alert(_.get(error.response, 'data.detail'));
        }

        // api.createLog({
        //     status: error.response.status,
        //     type: error.config.method,
        //     message: typeof error.config.data === 'string' ? error.config.data : '{}',
        //     params: JSON.stringify(error.config.params),
        //     url: error.config.url,
        //     company: store.getState().Facility.selected.record?.company_id
        // })

    }

    return Promise.reject(error);
});


export const RquestSignUp = async (e) => {

    e['date_of_birth'] = moment(e['date_of_birth']).format('YYYY-MM-DD')
    if (typeof e['image'] !== 'object') {
        delete e['image']
    }
    var myHeaders = new Headers();
    myHeaders.append("content-type", "multipart/form-data");

    var data = new FormData();
    for (const name in e) {
        data.append(name, e[name]);
    }
    var result = [];
    try {
        var config = {
            method: 'post', url: backendUrl + '/auth/signup/', headers: {
                'content-type': 'multipart/form-data'
            }, data: data
        };

        return await axios(config)
            .then(function (response) {

                return response;
            })
            .catch(function (error) {
                let err = {
                    error: 400, message: error.response.data
                }
                return err;
            });

    } catch (e) {

        result = e;
    }
    return result;
}

export const update_profile = async (id, e) => {
    if (e['date_of_birth']) {
        e['date_of_birth'] = moment(e['date_of_birth']).format('YYYY-MM-DD')
    }

    if (typeof e['image'] !== 'object') {
        delete e['image']
    }

    var myHeaders = new Headers();
    myHeaders.append("content-type", "multipart/form-data");

    var data = new FormData();
    for (const name in e) {
        data.append(name, e[name]);
    }
    var result = [];
    try {
        var config = {
            method: 'put', url: backendUrl + `/api/v2/update_profile/` + id + '/', headers: {
                'content-type': 'multipart/form-data'
            }, data: data
        };

        return await axios(config)
            .then(function (response) {

                return response;
            })
            .catch(function (error) {
                let err = {
                    error: 400, message: error.response.data
                }
                return err;
            });

    } catch (e) {

        result = e;
    }
    return result;
}

export const api = {
    get_station_temperature: (service_start_date, end_start_date) => {
        return axios.get(backendUrl + '/get_station_temperature?service_start_date=' + service_start_date+'&end_start_date='+end_start_date)
    },
    run_station_playwright: (year, month) => {
        return axios.get(backendUrl + '/run_station_playwright?year=' + year+'&month='+month)
    },

    getSubscription: (query) => {
        return axios.get(backendUrl + '/api/v2/subscription/?' + query)
    },
    editSubscription: (key, row) => {
        return axios.put(backendUrl + '/api/v2/subscription/' + key + '/', row)
    },
    getScheduledJob: (query) => {
        return axios.get(backendUrl + '/api/v2/scheduledJob/?' + query)
    },
    editScheduledJob: (key, row) => {
        return axios.put(backendUrl + '/api/v2/scheduledJob/' + key + '/', row)
    },
    getScheduledJobLog: (query) => {
        return axios.get(backendUrl + '/api/v2/scheduledJobLog/?' + query)
    },
    editScheduledJobLog: (key, row) => {
        return axios.put(backendUrl + '/api/v2/scheduledJobLog/' + key + '/', row)
    },

    getLog: (query) => {
        return axios.get(backendUrl + '/api/v2/log/?' + query)
    },
    createLog: (body) => {
        return axios.post(backendUrl + '/api/v2/log/', body)
    },
    modifyFile: (id, body, needFolder =false) => {

        const { name, record_id, table_name, type, description, category, tags, folder, file } = body;

        if(file) {
            const formData = new FormData();

            formData.append('name', name);
            formData.append('type', type);
            formData.append('description', description);
            formData.append('category', category);
            tags.forEach(tag => {
                formData.append('tags_ids', tag?.id ? tag.id : tag);
            })
            // formData.append('tags_ids', tags.map(item => item?.id ? item.id : item));
            formData.append('image', file.originFileObj);
            if(needFolder) {
                formData.append('folder', folder);
            }
            

            return axios.put(backendUrl + '/api/v2/file/' + id + '/', formData, {
                params: {
                    record_id,
                    table_name
                }
            })
        }

        return axios.put(backendUrl + '/api/v2/file/' + id + '/', {
            name,
            type,
            description,
            category,
            tags_ids: tags.map(item => item?.id ? item.id : item), 
            ...(needFolder ? {folder} : {}),
            // ...(file ? {image: file.originFileObj} : {})
        }, {
            params: {
                record_id,
                table_name
            }
        })
    },
    getFiles: (query) => {
        return axios.get(backendUrl + '/api/v2/file/?' + query)
    },

    bulkDeleteFiles: (body) => {
        return axios.patch(backendUrl + '/api/v2/file/bulk_delete/', body)
    },
    deleteFile: (id, params) => {
        return axios.delete(backendUrl + '/api/v2/file/' + id + '/', {
            params
        })
    },

    uploadFile: (body) => {
            // delete body['image']
            // return axios.post(backendUrl + '/api/v2/file/', body)
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in body) {
                if(name === 'files') {
                    for (const file of body.files) {
                        data.append('files', file.originFileObj);
                    }
                }else {
                    if(body[name]) {
                        data.append(name, body[name]);
                    }
                }

            }
            var config = {
                method: 'post', url: backendUrl + '/api/v2/file/', headers: {
                    'content-type': 'multipart/form-data'
                },
                data: data
            };

            return axios(config)
                .then(function (response) {

                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400, message: error.response.data
                    }
                    return err;
                });

    },
    getFolders: (query) => {
        return axios.get(`${backendUrl}/api/v2/folder/?${query}`);
    },
    createFolder: (body) => {
        return axios.post(backendUrl + '/api/v2/folder/', body)
    },
    deleteFolder: (id, params) => {
        return axios.delete(backendUrl + '/api/v2/folder/' + id + '/', params)
    },
    modifyFolder: (id, body, needFolder) => {
        if(needFolder) {
            const newBody = {...body};
            newBody.parent = newBody.folder
            delete newBody.folder
            return axios.patch(backendUrl + '/api/v2/folder/' + id + '/', newBody)
        }
        return axios.patch(backendUrl + '/api/v2/folder/' + id + '/', body)
    },
    generate_url: (key_name, is_preview) => {
        return axios.get(backendUrl + '/generate_public_url?key_name=' + key_name + '&is_preview=' + is_preview)
    },

    hello: () => {
        return axios.get(backendUrl + `/hello`);
    },
    daterange: () => {
        return axios.get(backendUrl + `/daterange`);
    },
    linearreg: (x, y, post_x, post_y, range0, range1, breakpoint0, breakpoint1, breakpoint2, breakpoint3, n_segments) => {
        let body = {
            x: x,
            y: y,
            post_x: post_x,
            post_y: post_y,
            range0: range0,
            range1: range1,
            breakpoints: [...(breakpoint0 !== null ? [breakpoint0] : []), ...(breakpoint1 !== null ? [breakpoint1] : []), ...(breakpoint2 !== null ? [breakpoint2] : []), ...(breakpoint3 !== null ? [breakpoint3] : []),],
            n_segments: n_segments
        };

        return axios.post(backendUrl + `/linearreg`, body);
    },
    login: (body) => {
        return axios.post(backendUrl + `/auth/login`, body);
    },

    forgetpassword: (email) => {
        return axios.post(backendUrl + `/auth/users/reset_password/`, {email: email});
    },

    refreshToken: (body) => {
        return axios.post(backendUrl + `/auth/token/refresh`, body);
    },
    refreshUserInfo: () => {
        return axios.post(backendUrl + `/refreshUserInfo`, {});
    },
    logout: (body, accessToken) => {
        let config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken
            }
        }
        return axios.post(backendUrl + `/logout`, body, config);
    },
    getProtected: () => {
        return axios.get(backendUrl + `/protected_resource`);
    },
    changePassword: (id, body) => {
        return axios.post(backendUrl + `/api/v2/change_password/` + id + '/', body);
    },
    alltasks: (query) => {
        return axios.get(backendUrl + `/alltasks?` + query);
    },
    allworkorderview: (query) => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // console.log(timeZone);
        return axios.get(backendUrl + `/allworkorderview?` + query + '&time_zone=' + timeZone);
    },
    allequipmentview: (query) => {
        return axios.get(backendUrl + `/allequipmentview?` + query);
    },

    /**
     overview
     **/
    get_electricity_summary: (duration_month) => {
        return axios.get(backendUrl + `/electricity_summary?&month=` + duration_month);
    },
    get_gas_summary: (duration_month) => {
        return axios.get(backendUrl + `/gas_summary?&month=` + duration_month);
    },
    /**
     analysis
     **/
    get_electricity_eui_analysis: (start_month, end_month) => {
        return axios.get(backendUrl + `/electricity_eui_analysis?&start_month=` + start_month + '&end_month=' + end_month);
    },
    get_gas_eui_analysis: (start_month, end_month) => {
        return axios.get(backendUrl + `/gas_eui_analysis?&start_month=` + start_month + '&end_month=' + end_month);
    },

    /**
     summary
     **/
    getFacilityConsumption: (company_id) => {
        return axios.get(backendUrl + `/getfacilityconsumption?company_id=` + company_id);
    },
    getAllElecGasComparisionView: () => {
        return axios.get(backendUrl + `/allelecgascomparision`);
    },
    getElecGas: () => {
        return axios.get(backendUrl + `/elecgas`);
    },
    /**
     user facility
     **/
    getFacilityRelatedUsers: (parent_facility_id) => {
        return axios.get(backendUrl + `/api/v2/userfacilities/?parent_facility_id=` + parent_facility_id)
    },

    /*
    * profile
    * */
    /**
     Profile crud
     **/
    getProfile: () => {
        return axios.get(backendUrl + '/api/v2/userpermissions/?page=1&page_size=24')
    },
    createProfile: (body) => {
        return axios.post(backendUrl + '/api/v2/userpermissions/', body)
    },
    bulkProfileInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/userpermissions/bulk_insert/', body)
    },
    bulkDeleteProfile: (body) => {
        return axios.patch(backendUrl + '/api/v2/userpermissions/bulk_delete/', body)
    },
    deleteProfile: (id) => {
        return axios.delete(backendUrl + '/api/v2/userpermissions/' + id + '/')
    },
    editProfile: (key, row) => {

        return axios.put(backendUrl + '/api/v2/userpermissions/' + key + '/', row)
    },
    filterProfile: (query) => {
        return axios.get(backendUrl + '/api/v2/userpermissions/?' + query)
    },
    searchGlobalProfile: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/userpermissions/?search=' + queryStr)
    },


    /**
     company crud
     **/
    // getCompany: (accessToken) => {
    //     let config = {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + accessToken
    //         }
    //     }
    //     return axios.get(backendUrl + '/api/v2/companies/?', config)
    // },

    /**
     user crud
     **/
    getCustomUsers: () => {
        return axios.get(backendUrl + '/api/v2/customusers/?page=1&page_size=24')
    },
    createCustomUser: (body) => {
        if (typeof body['image'] !== 'object') {
            delete body['image']
            return axios.post(backendUrl + '/api/v2/customusers/', body)
        } else {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in body) {
                data.append(name, body[name]);
            }
            var config = {
                method: 'post', url: backendUrl + '/api/v2/customusers/', headers: {
                    'content-type': 'multipart/form-data'
                }, data: data
            };

            return axios(config)
                .then(function (response) {

                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400, message: error.response.data
                    }
                    return err;
                });
        }
    },
    bulkCustomUserInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/customusers/bulk_insert/', body)
    },
    bulkDeleteCustomUser: (body) => {
        return axios.patch(backendUrl + '/api/v2/customusers/bulk_delete/', body)
    },
    deleteCustomUser: (id) => {
        return axios.delete(backendUrl + '/api/v2/customusers/' + id + '/')
    },
    editCustomUser: (key, row) => {

            return axios.put(backendUrl + '/api/v2/customusers/' + key + '/', row)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400, message: error.response.data
                    }
                    return err;
                });
    },
    changeCustomUserSubscription: (key, row) => {
        return axios.patch(backendUrl + `/api/user/${key}/subscribe_workorder_report/`, row).then(
            response => {
                return response;
            }
        ).catch(function (error) {
            let err = {
                error: 400, message: error.response.data
            }
            return err;
        });
    },
    filterCustomUsers: (query) => {
        console.log('filterCustomUsers', query)
        return axios.get(backendUrl + '/api/v2/customusers/?' + query)
    },
    searchGlobalCustomUsers: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/customusers/?search=' + queryStr)
    },

    // userpermissions
    filterUserPermissions: (query) => {
        return axios.get(backendUrl + '/api/v2/userpermissions/?' + query)
    },

    getUserpermissions: (selecteduser) => {
        return axios.get(backendUrl + '/api/v2/userpermissions/?selecteduser=' + selecteduser)
    },
    editUserpermissions: (key, row) => {

        return axios.put(backendUrl + '/api/v2/userpermissions/' + key + '/', row)
    },
    deleteUserpermissions: (id) => {
        return axios.delete(backendUrl + '/api/v2/userpermissions/' + id + '/')
    },
    createUserpermissions: (body) => {
        return axios.post(backendUrl + '/api/v2/userpermissions/', body)
    },

    /**
     EquipmentCategory crud
     **/
    getEquipmentCategory: () => {
        return axios.get(backendUrl + '/api/v2/equipmentcategories/?page=1&page_size=24')
    },
    createEquipmentCategory: (body) => {
        return axios.post(backendUrl + '/api/v2/equipmentcategories/', body)
    },
    bulkEquipmentCategoryInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/equipmentcategories/bulk_insert/', body)
    },
    bulkDeleteEquipmentCategory: (body) => {
        return axios.patch(backendUrl + '/api/v2/equipmentcategories/bulk_delete/', body)
    },
    deleteEquipmentCategory: (id) => {
        return axios.delete(backendUrl + '/api/v2/equipmentcategories/' + id + '/')
    },
    editEquipmentCategory: (key, row) => {

        return axios.put(backendUrl + '/api/v2/equipmentcategories/' + key + '/', row)
    },
    filterEquipmentCategory: (query) => {
        return axios.get(backendUrl + '/api/v2/equipmentcategories/?' + query)
    },
    searchGlobalEquipmentCategory: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/equipmentcategories/?search=' + queryStr)
    },

    /**
     Company crud
     **/
    getCompany: () => {
        return axios.get(backendUrl + '/api/v2/companies/?page=1&page_size=24')
    },
    createCompany: (body) => {
        return axios.post(backendUrl + '/api/v2/companies/', body)
    },
    bulkCompanyInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/companies/bulk_insert/', body)
    },
    bulkDeleteCompany: (body) => {
        return axios.patch(backendUrl + '/api/v2/companies/bulk_delete/', body)
    },
    deleteCompany: (id) => {
        return axios.delete(backendUrl + '/api/v2/companies/' + id + '/')
    },
    editCompany: (key, row) => {

        return axios.put(backendUrl + '/api/v2/companies/' + key + '/', row)
    },
    filterCompany: (query) => {
        return axios.get(backendUrl + '/api/v2/companies/?' + query)
    },
    searchGlobalCompany: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/companies/?search=' + queryStr)
    },

    /**
     Equipment crud
     **/
    getEquipment: () => {
        return axios.get(backendUrl + '/api/v2/equipments/?page=1&page_size=24')
    },
    createEquipment: (body) => {
        return axios.post(backendUrl + '/api/v2/equipments/', body)
    },
    bulkEquipmentInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/equipments/bulk_insert/', body)
    },
    bulkDeleteEquipment: (body) => {
        return axios.patch(backendUrl + '/api/v2/equipments/bulk_delete/', body)
    },
    deleteEquipment: (id) => {
        return axios.delete(backendUrl + '/api/v2/equipments/' + id + '/')
    },
    editEquipment: (key, row) => {
        return axios.put(backendUrl + '/api/v2/equipments/' + key + '/', row)
    },
    filterEquipment: (query) => {
        return axios.get(backendUrl + '/api/v2/equipments/?' + query)
    },
    searchGlobalEquipment: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/equipments/?search=' + queryStr)
    },

    filterFunctionalTestingView: (query) => {
        return axios.get(backendUrl + '/api/v2/FunctionalTestingView/?' + query)
    },
    getFunctionalTestingView: (equipment_id) => {
        return axios.get(backendUrl + '/api/v2/FunctionalTestingView/?page=1&page_size=24&equipment_id=' + equipment_id)
    },
    createFunctionalTestingView: (body) => {
        return axios.post(backendUrl + '/api/v2/FunctionalTestingView/', body)
    },
    editFunctionalTestingView: (key, row) => {
        return axios.put(backendUrl + '/api/v2/FunctionalTestingView/' + key + '/', row)
    },
    deleteFunctionalTestingView: (id) => {
        return axios.delete(backendUrl + '/api/v2/FunctionalTestingView/' + id + '/')
    },
    bulkFunctionalTestingView: (body) => {
        return axios.patch(backendUrl + '/api/v2/FunctionalTestingView/bulk_delete/', body)
    },

    filterFunctionalFormView: (query) => {
        return axios.get(backendUrl + '/api/v2/FunctionalFormView/?' + query)
    },
    bulkFunctionalFormView: (body) => {
        return axios.patch(backendUrl + '/api/v2/FunctionalFormView/bulk_delete/', body)
    },
    getFunctionalFormView: (type) => {
        return axios.get(backendUrl + '/api/v2/FunctionalFormView/?page=1&page_size=24&type=' + type)
    },
    createFunctionalFormView: (body) => {
        return axios.post(backendUrl + '/api/v2/FunctionalFormView/', body)
    },
    editFunctionalFormView: (key, row) => {
        return axios.put(backendUrl + '/api/v2/FunctionalFormView/' + key + '/', row)
    },
    deleteFunctionalFormView: (id) => {
        return axios.delete(backendUrl + '/api/v2/FunctionalFormView/' + id + '/')
    },
    filterTags: (query) => {
    return axios.get(backendUrl + '/api/v2/tags/?' + query)
    },
    bulkTags: (body) => {
        return axios.patch(backendUrl + '/api/v2/tags/bulk_delete/', body)
    },
    getTags: (type) => {
        return axios.get(backendUrl + '/api/v2/tags/?page=1&page_size=24&type=' + type)
    },
    createTags: (body) => {
        return axios.post(backendUrl + '/api/v2/tags/', body)
    },
    editTags: (key, row) => {
        return axios.put(backendUrl + '/api/v2/tags/' + key + '/', row)
    },
    deleteTags: (id) => {
        return axios.delete(backendUrl + '/api/v2/tags/' + id + '/')
    },
    /**
     electricity crud
     **/
    getElectricity: () => {
        return axios.get(backendUrl + '/api/v2/electricities/?page=1&page_size=24')
    },
    createElectricity: (body) => {
        return axios.post(backendUrl + '/api/v2/electricities/', body)
    },
    bulkElectricityInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/electricities/bulk_insert/', body)
    },
    bulkDeleteElectricity: (body) => {
        return axios.patch(backendUrl + '/api/v2/electricities/bulk_delete/', body)
    },
    deleteElectricity: (id) => {
        return axios.delete(backendUrl + '/api/v2/electricities/' + id + '/')
    },
    editElectricity: (key, row) => {

        return axios.put(backendUrl + '/api/v2/electricities/' + key + '/', row)
    },
    filterElectricity: (query) => {
        return axios.get(backendUrl + '/api/v2/electricities/?' + query)
    },
    searchGlobalElectricity: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/electricities/?search=' + queryStr)
    },

    /**
     facility crud
     **/
    getFacility: () => {
        return axios.get(backendUrl + '/api/v2/facilities/?page=1&page_size=24')
    },
    createFacility: (body) => {
        if (typeof body['image'] !== 'object') {
            delete body['image']
            return axios.post(backendUrl + '/api/v2/facilities/', body)
        } else {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in body) {
                data.append(name, body[name]);
            }
            var config = {
                method: 'post', url: backendUrl + '/api/v2/facilities/', headers: {
                    'content-type': 'multipart/form-data'
                }, data: data
            };

            return axios(config)
                .then(function (response) {

                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400, message: error.response.data
                    }
                    return err;
                });
        }
    },
    bulkFacilityInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/facilities/bulk_insert/', body)
    },
    bulkDeleteFacility: (body) => {
        return axios.patch(backendUrl + '/api/v2/facilities/bulk_delete/', body)
    },
    deleteFacility: (id) => {
        return axios.delete(backendUrl + '/api/v2/facilities/' + id + '/')
    },
    editFacility: (key, row) => {
        if (typeof row['image'] !== 'object' || !row['image']) {
            delete row['image']
            return axios.put(backendUrl + '/api/v2/facilities/' + key + '/', row)
        } else {
            var myHeaders = new Headers();
            myHeaders.append("content-type", "multipart/form-data");

            var data = new FormData();
            for (const name in row) {
                data.append(name, row[name]);
            }
            var config = {
                method: 'put', url: backendUrl + '/api/v2/facilities/' + key + '/', headers: {
                    'content-type': 'multipart/form-data'
                }, data: data
            };

            return axios(config)
                .then(function (response) {

                    return response;
                })
                .catch(function (error) {
                    let err = {
                        error: 400, message: error.response.data
                    }
                    return err;
                });
        }


    },
    filterFacility: (query) => {
        return axios.get(backendUrl + '/api/v2/facilities/?' + query)
    },
    searchGlobalFacility: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/facilities/?search=' + queryStr)
    },

    /**
     gas crud
     **/
    getGas: () => {
        return axios.get(backendUrl + '/api/v2/gases/?page=1&page_size=24')
    },
    createGas: (body) => {
        return axios.post(backendUrl + '/api/v2/gases/', body)
    },
    bulkGasInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/gases/bulk_insert/', body)
    },
    bulkDeleteGas: (body) => {
        return axios.patch(backendUrl + '/api/v2/gases/bulk_delete/', body)
    },
    deleteGas: (id) => {
        return axios.delete(backendUrl + '/api/v2/gases/' + id + '/')
    },
    editGas: (key, row) => {

        return axios.put(backendUrl + '/api/v2/gases/' + key + '/', row)
    },
    filterGas: (query) => {
        return axios.get(backendUrl + '/api/v2/gases/?' + query)
    },
    searchGlobalGas: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/gases/?search=' + queryStr)
    },

    /**
     water crud
     **/
    getWater: () => {
        return axios.get(backendUrl + '/api/v2/waters/?page=1&page_size=24')
    },
    createWater: (body) => {
        return axios.post(backendUrl + '/api/v2/waters/', body)
    },
    bulkWaterInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/waters/bulk_insert/', body)
    },
    bulkDeleteWater: (body) => {
        return axios.patch(backendUrl + '/api/v2/waters/bulk_delete/', body)
    },
    deleteWater: (id) => {
        return axios.delete(backendUrl + '/api/v2/waters/' + id + '/')
    },
    editWater: (key, row) => {

        return axios.put(backendUrl + '/api/v2/waters/' + key + '/', row)
    },
    filterWater: (query) => {
        return axios.get(backendUrl + '/api/v2/waters/?' + query)
    },
    searchGlobalWater: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/waters/?search=' + queryStr)
    },


    /**
     workorder crud
     **/
    getWorkorder: () => {
        return axios.get(backendUrl + '/api/v2/workorders/?page=1&page_size=24')
    },
    createWorkorder: (body) => {
        return axios.post(backendUrl + '/api/v2/workorders/', body)
    },
    bulkWorkorderInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/workorders/bulk_insert/', body)
    },
    bulkDeleteWorkorder: (body) => {
        return axios.patch(backendUrl + '/api/v2/workorders/bulk_delete/', body)
    },
    deleteWorkorder: (id) => {
        return axios.delete(backendUrl + '/api/v2/workorders/' + id + '/')
    },
    editWorkorder: (key, row) => {

        return axios.put(backendUrl + '/api/v2/workorders/' + key + '/', row)
    },
    filterWorkorder: (query) => {
        return axios.get(backendUrl + '/api/v2/workorders/?' + query)
    },
    searchGlobalWorkorder: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/workorders/?search=' + queryStr)
    },

    /**
     WorkorderComment crud
     **/
    getWorkorderComment: (workorderid) => {
        return axios.get(backendUrl + '/api/v2/workordercomments/?workorderid=' + workorderid + '&page=1&page_size=24')
    },
    createWorkorderComment: (body) => {
        return axios.post(backendUrl + '/api/v2/workordercomments/', body)
    },

    deleteWorkorderComment: (id) => {
        return axios.delete(backendUrl + '/api/v2/workordercomments/' + id + '/')
    },
    updateWorkorderComment: (id, body) => {
        return axios.put(backendUrl + '/api/v2/workordercomments/' + id + '/', body)
    },

    /**
     EquipmentComment crud
     **/
    getEquipmentComment: (Equipmentid) => {
        return axios.get(backendUrl + '/api/v2/equipmentcomments/?Equipmentid=' + Equipmentid + '&page=1&page_size=24')
    },
    createEquipmentComment: (body) => {
        return axios.post(backendUrl + '/api/v2/equipmentcomments/', body)
    },

    deleteEquipmentComment: (id) => {
        return axios.delete(backendUrl + '/api/v2/equipmentcomments/' + id + '/')
    },
    editEquipmentComment: (row) => {

        return axios.put(backendUrl + '/api/v2/equipmentcomments/', row)
    },
    filterEquipmentComment: (query) => {
        return axios.get(backendUrl + '/api/v2/equipmentcomments/?' + query)
    },

    /**
     ProjectComment crud
     **/
    getProjectComment: (projectid) => {
        return axios.get(backendUrl + '/api/v2/projectcomments/?projectid=' + projectid + '&page=1&page_size=24')
    },
    createProjectComment: (body) => {
        return axios.post(backendUrl + '/api/v2/projectcomments/', body)
    },

    deleteProjectComment: (id) => {
        return axios.delete(backendUrl + '/api/v2/projectcomments/' + id + '/')
    },
    editProjectComment: (row) => {

        return axios.put(backendUrl + '/api/v2/projectcomments/', row)
    },
    filterProjectComment: (query) => {
        return axios.get(backendUrl + '/api/v2/projectcomments/?' + query)
    },

    /**
     gas crud
     **/
    getPicklist: () => {
        return axios.get(backendUrl + '/api/v2/picklists/?page=1&page_size=24')
    },
    createPicklist: (body) => {
        return axios.post(backendUrl + '/api/v2/picklists/', body)
    },
    bulkPicklistInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/picklists/bulk_insert/', body)
    },
    bulkDeletePicklist: (body) => {
        return axios.patch(backendUrl + '/api/v2/picklists/bulk_delete/', body)
    },
    deletePicklist: (id) => {
        return axios.delete(backendUrl + '/api/v2/picklists/' + id + '/')
    },
    editPicklist: (key, row) => {
        // console.log('editPicklist', key, row)
        return axios.put(backendUrl + '/api/v2/picklists/' + key + '/', row)
    },
    filterPicklist: (query) => {
        return axios.get(backendUrl + '/api/v2/picklists/?' + query)
    },
    searchGlobalPicklist: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/picklists/?search=' + queryStr)
    },

    /**
     project crud
     **/
    getProjects: () => {
        return axios.get(backendUrl + '/api/v2/projects/?page=1&page_size=24')
    },
    createProject: (body) => {
        return axios.post(backendUrl + '/api/v2/projects/', body)
    },
    bulkProjectInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/projects/bulk_insert/', body)
    },
    bulkDeleteProject: (body) => {
        return axios.patch(backendUrl + '/api/v2/projects/bulk_delete/', body)
    },
    deleteProject: (id) => {
        return axios.delete(backendUrl + '/api/v2/projects/' + id + '/')
    },
    editProject: (key, row) => {
        // console.log('editProject', key, row)
        return axios.put(backendUrl + '/api/v2/projects/' + key + '/', row)
    },
    filterProject: (query) => {
        return axios.get(backendUrl + '/api/v2/projects/?' + query)
    },
    searchGlobalProjects: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/projects/?search=' + queryStr)
    },


    /**
     task crud
     **/
    getTasks: () => {
        return axios.get(backendUrl + '/api/v2/tasks/?page=1&page_size=24')
    },
    createTask: (body) => {
        return axios.post(backendUrl + '/api/v2/tasks/', body)
    },
    bulkTaskInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/tasks/bulk_insert/', body)
    },
    bulkDeleteTask: (body) => {
        return axios.patch(backendUrl + '/api/v2/tasks/bulk_delete/', body)
    },
    deleteTask: (id) => {
        return axios.delete(backendUrl + '/api/v2/tasks/' + id + '/')
    },
    editTask: (key, row) => {
        // console.log('editTask', key, row)
        return axios.put(backendUrl + '/api/v2/tasks/' + key + '/', row)
    },
    filterTask: (query) => {
        return axios.get(backendUrl + '/api/v2/tasks/?' + query)
    },
    searchGlobalTasks: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/tasks/?search=' + queryStr)
    },


    /**
     subtask crud
     **/
    getSubTasks: () => {
        return axios.get(backendUrl + '/api/v2/subtasks/?page=1&page_size=24')
    },
    createSubTask: (body) => {
        return axios.post(backendUrl + '/api/v2/subtasks/', body)
    },
    bulkSubTaskInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/subtasks/bulk_insert/', body)
    },
    bulkDeleteSubTask: (body) => {
        return axios.patch(backendUrl + '/api/v2/subtasks/bulk_delete/', body)
    },
    deleteSubTask: (id) => {
        return axios.delete(backendUrl + '/api/v2/subtasks/' + id + '/')
    },
    editSubTask: (key, row) => {
        return axios.put(backendUrl + '/api/v2/subtasks/' + key + '/', row)
    },
    filterSubTask: (query) => {
        return axios.get(backendUrl + '/api/v2/subtasks/?' + query)
    },
    searchGlobalSubTasks: (queryStr) => {
        return axios.get(backendUrl + '/api/v2/subtasks/?search=' + queryStr)
    },


//    benchmark
    getFacilityBenchmark: (query) => {
        return axios.get(backendUrl + '/allfacilitybenchmark?' + query)
    },

    getNationalBenchmark: () => {
        return axios.get(backendUrl + '/api/v2/nationalbenchmark/')
    },


    createStationTemperature: (body) => {
        return axios.post(backendUrl + '/api/v2/stationtemperature/', body)
    },
    bulkStationTemperatureInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/stationtemperature/bulk_insert/', body)
    },
    bulkDeleteStationTemperature: (body) => {
        return axios.patch(backendUrl + '/api/v2/stationtemperature/bulk_delete/', body)
    },
    deleteStationTemperature: (id) => {
        return axios.delete(backendUrl + '/api/v2/stationtemperature/' + id + '/')
    },
    editStationTemperature: (key, row) => {
        return axios.put(backendUrl + '/api/v2/stationtemperature/' + key + '/', row)
    },
    filterStationTemperature: (query) => {
        return axios.get(backendUrl + '/api/v2/stationtemperature/?' + query)
    },

    filterNotification: (query) => {
        return axios.get(backendUrl + '/api/v2/notifications/?' + query)
    },
    updateNotificationStatus: (id, body) => {
        return axios.put(backendUrl + '/api/v2/notifications/' + id + '/', body)
    },
    deleteBulkNotification: () => {
        return axios.patch(backendUrl + '/api/v2/notifications/bulk_delete/')
    },
    deleteNotification: (id, query) => {
        return axios.delete(backendUrl + '/api/v2/notifications/' + id + '/'+query)
    },
    markAsRead: (body) => {
        return axios.post(backendUrl+'/api/v2/notifications/mark-as-read/', body);
    },

    filterWorkerorderNotifications: (query) => {
        return axios.get(backendUrl + '/api/v2/workordernotifications/?' + query)
    },
    updateWorkerorderNotifications: (id, body) => {
        return axios.put(backendUrl + '/api/v2/workordernotifications/' + id + '/', body)
    },
    bulkDeleteWorkerorderNotifications: (workorder_id, body) => {
        return axios.patch(backendUrl + '/api/v2/workordernotifications/bulk_delete/?workorder_id=' + workorder_id, body)
    },

    filterProjectNotifications: (query) => {
        return axios.get(backendUrl + '/api/v2/projectnotifications/?' + query)
    },
    updateProjectNotifications: (id, body) => {
        return axios.put(backendUrl + '/api/v2/projectnotifications/' + id + '/', body)
    },


    createScenario: (body) => {
        return axios.post(backendUrl + '/api/v2/scenario/', body)
    },
    bulkScenarioInsert: (body) => {
        return axios.patch(backendUrl + '/api/v2/scenario/bulk_insert/', body)
    },
    bulkDeleteScenario: (body) => {
        return axios.patch(backendUrl + '/api/v2/scenario/bulk_delete/', body)
    },
    deleteScenario: (id) => {
        return axios.delete(backendUrl + '/api/v2/scenario/' + id + '/')
    },
    editScenario: (key, row) => {
        return axios.put(backendUrl + '/api/v2/scenario/' + key + '/', row)
    },
    filterScenario: (query) => {
        return axios.get(backendUrl + '/api/v2/scenario/?' + query)
    },

};


export const uploadFile = async (data, auth, recordId, domain, userid) => {

    var result = [];
    // let ext = '74bc8700-1307-4c25-b8c5-472236672c9b'
    console.log(data)
    let fileList = _.get(data, 'file', []);
    const formData = new FormData();

    fileList.forEach((file) => {   // fileList 是要上传的文件数组
        formData.append('file', file.originFileObj);
    });

    console.log('formData', fileList, formData)
    try {
        var config = {
            method: 'post', url: domain + '/api/v1/services/obj/file-management/share/' + recordId, headers: {
                'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + auth
            }, data: formData, params: {
                linkTo: recordId,
                shareToClient: _.get(data, 'ShareToClient'),
                category: 'Other',
                asOfDate: moment().format('YYYY-MM-DD'),
                createdBy: userid
            }
        };

        return await axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log('error', error.response.data)
                return error;
            });

    } catch (e) {
        result = e;
    }
    return result;
}

export const getFiles = async (auth, recordId, domain, fileName, pageNumber, pageSize, paged) => {
    var result = [];
    try {
        var config = {
            method: 'get', url: domain + '/api/v1/services/obj/file-management/linkedTo/' + recordId, headers: {
                'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + auth
            }, params: {
                fileName: fileName, pageSize: pageSize, pageNumber: pageNumber, paged: paged
            }
        };

        return await axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log('error', error.response.data)
                return error;
            });

    } catch (e) {
        result = e;
    }
    return result;
}


export const getVersions = async (auth, domain, externalId, pageNumber, pageSize, paged) => {
    var result = [];
    try {
        var config = {
            method: 'get',
            url: domain + '/api/v1/services/obj/file-management/files/' + externalId + '/versions',
            headers: {
                'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + auth
            },
            params: {
                externalId: externalId, pageSize: pageSize, pageNumber: pageNumber, paged: paged
            }
        };

        return await axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                console.log('error', error.response.data)
                return error;
            });

    } catch (e) {
        result = e;
    }
    return result;
}

export const getPdfReportList = () => {
    return axios.get(backendUrl + '/api/v2/custom_report/')
}

export const createReport = (data) => {
    const facility_id = store.getState().Facility.selected.Id;
    data.append('facility', facility_id)
    return axios.post(backendUrl + '/api/v2/custom_report/', data)
}

export const deleteReport = (id) => {
    return axios.delete(backendUrl + `/api/v2/custom_report/${id}/`)
}



