import React, {Component, PureComponent} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {EquipmentColumns, EquipmentProps} from "@containers/facility/Equipment/EquipmentColumns";
import _ from "lodash";
import actions from "@redux/equipment/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {EquipmentFormFields} from "@containers/facility/Equipment/EquipmentFormFields";
import {EquipmentComment} from "@containers/facility/Equipment/EquipmentComment";
import {DatePicker, Form, Select, Tabs} from "antd";
import FunctionalTesting from "@containers/facility/Equipment/FunctionalTesting/FunctionalTesting";
import SearchLookup from "@components/SearchLookup";
import moment from "moment";
import queryString from "query-string";
import {withRouter} from "@/router/withRouter";
import PermissionControl from "../../../components/Permission/Permission";
import RelatedWorkorder from "@containers/facility/Equipment/RelatedWorkorder/RelatedWorkorder";

const {TabPane} = Tabs;

const {Option} = Select;

class Equipment extends PureComponent {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.filterFormRef = React.createRef();
        this.state = {
            data: [],
            name: '',
            condition: '',
            building: '',
            location: '',
            service: '',
            Equipment_Model: '',
            Equipment_SN: '',
            isLoading: false,
            query: null,
            selectedRecord: null,
            install_date: [null, null],
            created_date: [null, null],
            name_keyword: '',
            location_keyword: '',
            service_keyword: '',
            building_keyword: '',
            equipment_model_keyword: '',
            equipment_sn_keyword: '',
            category_keyword: '',
            selectedTab: '1',
            eq_id: '',
        }
    }

    componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        console.log('params', params)
        if (params?.condition) {
            this.setState({
                condition: params.condition
            })
        }
        if (params?.category) {
            this.setState({
                category: params.category
            })
        }
        if (params?.id) {
            this.setState({
                eq_id: params.id
            })
        }
    }

    handleCreateEquipment = (body) => {
        return api.createEquipment(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteEquipment = (id) => {
        return api.deleteEquipment(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkEquipmentInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteEquipment(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        return api.editEquipment(key, row).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {


        this.setState({
            isLoading: true,
        });

        let query_new = query +
            (this.state.eq_id ? ('&id=' + (this.state.eq_id)) : '') +
            (this.state.name ? ('&name=' + (this.state.name)) : '') +
            (this.state.condition ? ('&status=' + (this.state.condition)) : '') +
            (this.state.building ? ('&building=' + (this.state.building)) : '') +
            (this.state.category ? ('&category=' + (this.state.category)) : '') +
            (this.state.location ? ('&location=' + (this.state.location)) : '') +
            (this.state.service ? ('&service=' + (this.state.service)) : '') +
            (this.state.Equipment_Model ? ('&equipment_model=' + (this.state.Equipment_Model)) : '') +
            (this.state.Equipment_SN ? ('&equipment_sn=' + (this.state.Equipment_SN)) : '') +
            (this.state.install_date[0] ? ('&install_date_start=' + (moment(this.state.install_date[0]).format('YYYY-MM-DD'))) : '') +
            (this.state.install_date[1] ? ('&install_date_end=' + (moment(this.state.install_date[1]).format('YYYY-MM-DD'))) : '') +
            (this.state.created_date[0] ? ('&created_date_start=' + (moment(this.state.created_date[0]).format('YYYY-MM-DD'))) : '') +
            (this.state.created_date[1] ? ('&created_date_end=' + (moment(this.state.created_date[1]).format('YYYY-MM-DD'))) : '')


        return api.filterEquipment(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getEquipment = (data) => {

        this.setState({
            isLoading: false,
            data
        })

        this.props.setEquipmentSuccess(data)
    }

    EquipmentRelated = (relatedId) => {
        return <>{relatedId ?
            <RelatedWorkorder relatedList={true} parentEquipment={relatedId} relatedObject={'equipment'}/> : ''}</>
    }

    handlePostComment = (e) => {

        return <EquipmentComment id={e}/>
    }


    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    handleLookupFilter = (e) => {

        let query_new = (this.state.name_keyword ? ('name=' + this.state.name_keyword) : '') +
            (this.state.location_keyword ? ('location=' + this.state.location_keyword) : '') +
            (this.state.category_keyword ? ('category=' + this.state.category_keyword) : '') +
            (this.state.service_keyword ? ('service=' + this.state.service_keyword) : '') +
            (this.state.equipment_model_keyword ? ('equipment_model=' + this.state.equipment_model_keyword) : '') +
            (this.state.equipment_sn_keyword ? ('equipment_sn=' + this.state.equipment_sn_keyword) : '') +
            (this.state.building_keyword ? ('building_keyword=' + this.state.building_keyword) : '') +
            '&limit=15';

        return api
            .filterEquipment(query_new)
            .then((response) => {
                let dataSource = response.data.results;
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count,
                };
            })
            .catch((error) => {
                return error;
            });
    }

    handleSelect = (e, type) => {
        this.setState({
            [type]: e,
            limit: null
        }, function () {
            this.refresh(false);
        })
    }

    handleSearchSelect = (e, type) => {
        this.setState({
            [type]: e,
            limit: null
        }, function () {
            this.refresh(false);
        })
    }

    handleSearch = (e, type) => {
        this.setState({
            [type + '_keyword']: e
        });
        return this.handleLookupFilter();
    }

    onDateChange = (dates, state, index) => {
        let bDate = this.state[state];
        bDate[index] = dates

        this.setState({
            [state]: bDate
        }, function () {
            this.refresh(false);
        })

    }

    renderFilter = () => {
        return   <SearchLookup
            placeholder={'name'}
            name={'name'}

            SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'name')}
            Lookup_CommonupRequest={(e) => this.handleSearch(e, 'name')}
        />
    }

    resetFilters = () => {
        this.filterFormRef.current.resetFields();
        this.setState({
            condition: '',
            building: '',
            location: '',
            category: '',
            service: '',
            install_date: [null, null]
        }, function () {
            this.refresh(false)
        })

    }
    renderSearches = () => {

        return (
            <Form ref={this.filterFormRef} className={'filter-form'}>

                <Form.Item label={'Location'} name="location">
                <SearchLookup
                    placeholder={'location'}
                    name={'location'}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'location')}
                    Lookup_CommonupRequest={(e) => this.handleSearch(e, 'location')}
                />
                </Form.Item>

                <Form.Item label={'Building'} name={'building'}>
                <SearchLookup
                    placeholder={'building'}
                    name={'building'}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'building')}
                    Lookup_CommonupRequest={(e) => this.handleSearch(e, 'building')}
                />
                </Form.Item>

                <Form.Item label={'Service'} name={'service'}>
                <SearchLookup
                    placeholder={'service'}
                    name={'service'}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'service')}
                    Lookup_CommonupRequest={(e) => this.handleSearch(e, 'service')}
                />
                </Form.Item>

                <Form.Item label={'Category'} name={'category'}>
                <SearchLookup
                    placeholder={'category'}
                    name={'category'}
                    defaultValue={this.state.category}
                    SelectedLookup_CommonRequest={(e) => this.handleSearchSelect(e, 'category')}
                    Lookup_CommonupRequest={(e) => this.handleSearch(e, 'category')}
                />
                </Form.Item>

                <Form.Item label={'Condition'} name={'condition'}>
                <Select className={'w-full'} placeholder={'condition'} value={this.state.condition} name={'condition'}
                        onChange={(e) => this.handleSearchSelect(e, 'condition')}>
                    <Option value=""></Option>
                    <Option value="Failed">Failed</Option>
                    <Option value="Fair">Fair</Option>
                    <Option value="Good">Good</Option>
                    <Option value="Excellent">Excellent</Option>
                </Select>
                </Form.Item>

                <Form.Item name={'install_date_start'} label={'Install Date Start'}>
                    <DatePicker
                        name={'install_date_start'}
                        className={'border-right-6'}
                        value={this.state.install_date[0]}
                        placeholder={'install start date'}
                        onChange={(dates) => this.onDateChange(dates, 'install_date', 0, '')}
                    />
                </Form.Item>

                <Form.Item name={'install_date_end'} label={'Install Date End'}>
                    <DatePicker
                        name={'install_date_end'}
                        className={'border-left-6'}
                        value={this.state.install_date[1]}
                        placeholder={'install end date'}
                        onChange={(dates) => this.onDateChange(dates, 'install_date', 1, '')}
                    />
                </Form.Item>

            </Form>
        )
    }

    renderAdditionalForm = (data) => {
        console.log('record: ', data)
        return (
            <>{data && <FunctionalTesting selectedRecord={data}/>}</>
        )
    }

    handleSelectedRecord = (record) => {
        this.setState({
            selectedRecord: record
        })
    }

    handleTab = (key) => {
        this.setState({
            selectedTab: key
        })
    }

    render() {
        return (

            <div className={'p-5'}>
                <Tabs defaultActiveKey="1" className='tab_blue_bottom' animated={true} value={this.state.selectedTab}
                      onChange={this.handleTab}>
                    <TabPane tab="Equipment" key="1">
                        {this.state.selectedTab === '1' ? <EditableTable
                            ref={this.tableRef}
                            columns={EquipmentColumns(this.handleSelectedRecord)}
                            data={this.state.data}
                            columnProps={EquipmentProps}
                            formFields={EquipmentFormFields}
                            formRelated={this.EquipmentRelated}
                            getRecord={this.getEquipment}
                            createRecords={this.handleCreateEquipment}
                            deleteRecord={this.handleDeleteEquipment}
                            bulkInsert={this.handleBulkInsert}
                            bulkDelete={this.handleBulkDelete}
                            handOnlineSave={this.handOnlineSave}
                            handleFilter={this.handleFilter}
                            postComment={this.handlePostComment}
                            renderFilter={this.renderFilter}
                            resetFilters={this.resetFilters}
                            relatedList={this.props.relatedList}

                            hide_search={true}
                            isRowClickable={false}
                            // isChat={true}
                            isRelated={true}
                            isAttachments={true}

                            setFilters={this.setFilters}
                            formClassName={'flex-form'}
                            filterDescription={this.state.filterDescription}

                            renderSearches={this.renderSearches}

                            renderAdditionalForm={this.renderAdditionalForm}
                            isAdditionalForm={true}
                            defaultDisplayColumns={{
                                "description": {
                                    "show": false
                                }, "modified_date": {
                                    "show": false
                                },
                                "createdby_name": {
                                    "show": false
                                }, "lastModified_name": {
                                    "show": false
                                }
                            }}
                        /> : ''}
                    </TabPane>

                    <TabPane tab="Functional Test Form" key="2">
                        {this.state.selectedTab === '2' ? <PermissionControl allowedPermissions={'1:functiontesting'}>
                            <FunctionalTesting/>
                        </PermissionControl> : ''}
                    </TabPane>


                </Tabs>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        Equipment: _.get(state.Equipment, 'data'),
    }
}
const mapDispatchToProps = dispatch => {
    return {

        setEquipmentRequest: (e) => dispatch(actions.EquipmentRequest(e)),
        setEquipmentSuccess: (e) => dispatch(actions.EquipmentSuccess(e)),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Equipment));
