import Input from "antd/es/input";
import React from "react";
import {Select} from "antd";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

export const CompanyRelated = []

export const CompanyFormFields = [
    {
        title: 'key',
        name: 'id',
        order: 0,
        visible: false,
        length: 0,
        type: 'text',
    },
    {
        title: 'Name',
        name: 'name',
        order: 1,
        visible: true,
        length: "w-full",
        type: 'text',
        required: true,
        render:
            <Input
                autoComplete="off"
                list="name-suggestions"
                allowClear
            />

    },
    {
        title: 'Comment',
        name: 'comment',
        order: 1,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <TextArea/>
    },
    // {
    //     title: 'Separator',
    //     name: 'Separator',
    // },
    {
        title: 'Created By',
        name: 'createdby_name',
        order: 17,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input disabled/>
    },
    {
        title: 'Created Date',
        name: 'created_date',
        order: 18,
        visible: true,
        length: "w-full",
        type: 'Date',
        render: <Input disabled/>
    },
    {
        title: 'Modified By',
        name: 'lastModified_name',
        order: 19,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input disabled/>
    },
    {
        title: 'Modified Date',
        name: 'modified_date',
        order: 20,
        visible: true,
        length: "w-full",
        type: 'Date',
        render: <Input disabled/>
    },

]
