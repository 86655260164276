import React, {useEffect, useState} from 'react';
import {store} from "@redux/store";
import CompanyFacilityLookup from "@containers/Lookup/CompanyFacilityPicker/CompanyFacilityLookup";
import CascadingRecordList from "@components/CascadingRecordList";
import {usePrevious} from "@components/help";


function CompanyFacilityPicker(props) {
    const [companyOptions, setCompanyOptions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [parentDefault, setParentDefault] = useState([]);
    const [childrenObjDefault, setchildrenObjDefault] = useState([]);
    const [role, setRole] = useState(null);
    const {formRef, fields, readOnly} = props;

    useEffect(() => {

        if (formRef) {
            // console.log('formRef')
            if (formRef.current) {
                setRole(formRef.current.getFieldValue('role'));
            } else if (formRef.getFieldsValue('role')) {
                setRole(formRef.getFieldValue('role'));
            }
        }

        let all_facilities = store.getState().Facility.data;
        let company_obj = [];
        for (let i = 0; i < all_facilities.length; i++) {
            let facility = all_facilities[i];
            let company = facility.record;
            let company_id = company.company_id;
            company_obj.push({
                id: company_id,
                name: company.company,
                childrenObj: {id: facility.Id, name: facility.value, parentId: company_id}
            })
        }
        // console.log('company_obj', company_obj, all_facilities);
        let uniqueCompanies = [];

        // if modified user role 3, 4; company filter by company_id

        if (role === 3 || role === 4) {
            company_obj = company_obj.filter(e => e.id === fields.find(e => e.name === 'company')?.value);
        }

        company_obj.forEach((company) => {
            // Find the index of the company in the uniqueCompanies array
            let index = uniqueCompanies.findIndex((c) => c.id === company.id);

            // If the company does not exist in the uniqueCompanies array, create a new object for it
            if (index === -1) {
                // need to have key property for antd table to expand and close one row at a time
                uniqueCompanies.push({
                    key: company.id,
                    id: company.id,
                    label: company.name,
                    value: company.name,
                    name: company.name,
                    childrenObj: [company.childrenObj]
                });
            } else {
                // If the company exists in the uniqueCompanies array, append the facilities to its "facilities" array
                uniqueCompanies[index].childrenObj = uniqueCompanies[index].childrenObj.concat(company.childrenObj);
            }
        });
        setCompanyOptions(uniqueCompanies);
        // console.log('uniqueCompanies', uniqueCompanies)
        // find the selected company and selected facilities
        console.log('fields', fields)
        if (fields.length > 0) {
            let facility_strs = fields.filter(e => e.name === 'user')[0].value;

            let isArrayNumbers = Array.isArray(facility_strs) && facility_strs.every(e => typeof e === 'number');
            let selectedFacilities = [];
            if (isArrayNumbers) {
                selectedFacilities = facility_strs;
            } else {
                selectedFacilities = facility_strs.map(e => (e.split('_')[0] * 1));
            }


            // {id: facility.Id, name: facility.value, parentId: company_id   }
            let defaultFacilties = selectedFacilities.map(e => {
                let facility = all_facilities.find(f => f.Id === e);
                let company_id = facility?.record.company_id;
                return {id: e, name: facility?.value, parentId: company_id}
            });
            setchildrenObjDefault(defaultFacilties);

            let selectedCompanies = uniqueCompanies.filter(e => defaultFacilties.find(f => f.parentId === e.id));

            setParentDefault(selectedCompanies);
        } else {
            setParentDefault([]);
            setchildrenObjDefault([]);
        }


    }, [fields, formRef, role]);

    const newRoles = usePrevious(role);
    useEffect(() => {
        if (newRoles && newRoles !== role && role !== 1) {
            // console.log('changed')
            setParentDefault([]);
            setchildrenObjDefault([]);
        }
    }, [newRoles, role],)


    const handleLookupChange = (e, record) => {
        // console.log('handleLookupChange', e, record)
        if (e) {
            setSelectedItem(record);
        }
    }

    const handleSelectionChange = (f, c) => {
        // console.log('formRef', formRef)
        let companies = c.map(company => company.id);
        let facilities = f.map(facility => facility.id);

        if (formRef) {
            if (formRef.setFieldsValue) {
                formRef.setFieldsValue({companies: companies, user: facilities});
            } else {
                formRef.current.setFieldsValue({companies: companies, user: facilities});
            }
        }
    }
    return (
        <div>
            <div className={'md:w-1/2 lg:w-1/2 sm:w-full'}>
                {readOnly?'':<CompanyFacilityLookup
                    options={companyOptions}
                    handleLookupChange={handleLookupChange}
                />}
            </div>
            {(selectedItem || parentDefault) && <CascadingRecordList lookupValue={selectedItem}
                                                                     role={role}
                                                                     parentDefault={parentDefault}
                                                                     childrenObjDefault={childrenObjDefault}
                                                                     onSelectionChange={handleSelectionChange}
                                                                     readOnly={readOnly}
            />}
        </div>

    );
}

export default CompanyFacilityPicker;
