import {Button, Form, Steps} from 'antd';
import React, {useRef, useState} from "react";

const {Step} = Steps;

const MultiStepForm = (props) => {
    const {steps} = props;
    const formRef = useRef(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState(props.defaultValue);


    const handleNext = () => {
        let isError = props.isCurrentStepValid(currentStep, formRef);
        if (!isError) {
            setCurrentStep(currentStep + 1);
        }

    };

    const handleFormSubmit = (values) => {
        // Combine form data from all steps
        const data = {...formData, ...values};

        // Send data to server
        props.handleSubmit(data);
    };

    const handleFormChange = (changedValues) => {
        // console.log('changedValues', changedValues)
        // Update form data
        setFormData({...formData, ...changedValues});
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    return (
        <div>
            <Steps current={currentStep} size="small" type="navigation">
                {steps.map((step) => (
                    <Step key={step.title} title={step.title}/>
                ))}
            </Steps>
            <div style={{marginLeft: '50px', marginTop: '5rem'}}>
                <Form
                    name="basic"
                    initialValues={props.defaultValue}
                    autoComplete="off"
                    layout="vertical"
                    onValuesChange={handleFormChange}
                    onFinish={handleFormSubmit}
                    ref={formRef}
                    id="myForm"
                    preserve={true}
                    // form={form}
                >
                    {steps[currentStep].content(formRef)}
                    <div style={{marginTop: '5rem'}} className={'flex justify-center w-full'}>
                        {currentStep > 0 && (
                            <Button style={{marginRight: '8px'}} onClick={handlePrev}>
                                Back
                            </Button>
                        )}
                        {currentStep < steps.length - 1 && (
                            <Button type="primary" onClick={handleNext}>
                                Next
                            </Button>
                        )}
                        {currentStep === steps.length - 1 && (
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        )}
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default MultiStepForm;
