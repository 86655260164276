import React from 'react';
import { Tag } from 'antd';

export const CategoryOptions = [
  {
    label: 'Uncategorized',
    value: 'Uncategorized'
  },
  {
    label: 'Manual and specsheet',
    value: 'Manual and specsheet'
  },
  {
    label: 'Invoice and Receipt',
    value: 'Invoice and Receipt'
  },
  {
    label: 'Drawings',
    value: 'Drawings'
  },
  {
    label: 'Reports',
    value: 'Reports'
  },
  {
    label: 'Photos',
    value: 'Photos'
  },
  {
    label: 'Notes',
    value: 'Notes'
  }
]

export const CategoryTag = ({ type }) => {
  // 定义不同类型的标签颜色
  const colorMap = {
    Uncategorized: 'default',
    'Manual and specsheet': 'blue',
    'Invoice and Receipt': 'green',
    Drawings: 'orange',
    Reports: 'purple',
    Photos: 'red',
    Notes: 'magenta',
  };

  // 获取对应类型的颜色
  const color = colorMap[type] || 'default';

  return type ? <Tag color={color}>type</Tag> : null;
};
