import React, {Component} from 'react';
import {connect} from "react-redux";

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {CaretDownOutlined} from "@ant-design/icons";
import styled from "styled-components";

export const AnalysisLookupWrapper = styled.div`
  //.ant-input-affix-wrapper {
  //  border-radius: 1.5rem;
  //  //border: 1.9px solid #1890ff !important;
  //  overflow: hidden;
  //}

`

class AnalysisLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: 1,
            Contact: null,
            value: 'Energy consumption, Cost and EUI',
            options: [
                {label: 'Energy consumption, Cost and EUI', value: '0', id: '0'},
                {label: 'Baseline and Energy Saving analysis', value: '1', id: '1'}
            ],
            newOptions: [
                {label: 'Energy consumption, Cost and EUI', value: '0', id: '0'},
                {label: 'Baseline and Energy Saving analysis', value: '1', id: '1'}
            ]
        }
    }


    handleSearch = (e) => {
        let options = this.state.options.filter(item => item.label.includes(e))
        this.setState({
            newOptions: options
        })
    }

    handleSelect = (e, record) => {
        this.setState({
            value: record.label
        })
        this.props.handleLabel(e)
    }

    render() {

        return (
            <AnalysisLookupWrapper>
                <div className={'Center'}>
                    <AutoComplete
                        style={{width: '450px'}}
                        options={this.state.newOptions}
                        onSelect={this.handleSelect}
                        onSearch={this.handleSearch}
                        value={this.state.value}
                    >
                        <Input size="medium" autosize={true} allowClear
                               placeholder={'Search a Chart'}
                               autoComplete="off"
                               prefix={<div style={{color: 'orange'}}><i className="fas fa-chart-pie"></i></div>}
                               suffix={<CaretDownOutlined/>}/>
                    </AutoComplete>
                </div>
            </AnalysisLookupWrapper>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalysisLookup);
