import React, {Component} from 'react';
import {DraggableArea} from 'react-draggable-tags';
import deleteBtn from '@assets/images/delete.png';
import deleteBtn2x from '@assets/images/delete@2x.png';
import {Select} from "antd";
import {TagInputWrapper} from "@components/TagInput/TagInputWrapper";
import notification2 from "@components/Notification2";

const {Option} = Select;


class TagInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tags: [],
            suggestions: [
                {id: 1, content: 'Company'},
                {id: 2, content: 'Hospital'}
            ],
            value: []
        }

    }

    componentDidMount() {
        this.setState({
            tags: this.props.value ? this.props.value : []
        })
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                tags: nextProps.value ? nextProps.value : []
            })
        }
    }

    handleClickDelete(tag) {
        const tags = this.state.tags.filter(t => tag.id !== t.id);
        this.setState({tags});
    }

    deleteBtn = (e) => {

    }
    onChange = (e) => {
        // console.log('onChange', e)

        if (e.length < 1) return;

        if (this.state.tags.filter(t => t.content === e).length > 0) {
            return notification2['error']({
                message: 'Error',
                description: 'Tag already included'
            });
        }

        const tags = this.state.tags.slice();
        tags.push({id: Math.floor(Math.random() * 100), content: e});
        this.setState({
            tags,
            value: null,
        });
        if (this.props.handleTag) {
            this.props.handleTag(tags);
        }

    }

    render() {

        return (
            <TagInputWrapper>
                <div className="AddAndDelete">
                    <div className="main">
                        <DraggableArea
                            tags={this.state.tags}
                            render={({tag, index}) => (
                                <div className="tag">
                                    <img
                                        className="delete"
                                        src={deleteBtn}
                                        srcSet={`${deleteBtn2x} 2x`}
                                        onClick={() => this.handleClickDelete(tag)}
                                    />
                                    {tag.content}
                                </div>
                            )}
                            onChange={tags => this.setState({tags})}
                        />
                    </div>
                    <div className="inputs">
                        <Select
                            value={this.state.value}
                            onChange={this.onChange}
                        >
                            {this.state.suggestions.map(e => {
                                return <Option value={e.content} key={e.key}>{e.content}</Option>
                            })}
                        </Select>
                    </div>
                </div>
            </TagInputWrapper>
        );
    }
}

export default TagInput;
