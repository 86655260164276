const actions = {
    NOTIFICATION_REQUEST: 'NOTIFICATION_REQUEST',
    NOTIFICATION_SUCCESS: 'NOTIFICATION_SUCCESS',
    SELECTED_NOTIFICATION: 'SELECTED_NOTIFICATION',

    notificationRequest: () => ({
        type: actions.NOTIFICATION_REQUEST,
        payload: {},
    }),
    notificationSuccess: (data) => ({
        type: actions.NOTIFICATION_SUCCESS,
        payload: { data },
    }),
    selectedNotification: (data) => ({
        type: actions.SELECTED_NOTIFICATION,
        payload: { data },
    }),
};

export default actions;
