import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Divider, Input, Select} from "antd";

const {Option} = Select;

function mapStateToProps(state) {
    return {};
}

let index = 0;

class SelectAddNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: ['Baseline Average', 'Post-Period Average', 'Custom'],
            name: null,
        }
    }

    onNameChange = event => {
        this.setState({
            name: event.target.value,
        });
        this.props.onSelect('Custom', event.target.value)
    };

    handleChange = (value) => {
        this.props.onSelect(value, null)
    }

    render() {
        const {items, name} = this.state;
        return (
            <div className={'flex'}>
                <Select
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.default}
                    value={this.props.value}
                    dropdownRender={menu => (
                        <div>
                            {menu}

                        </div>
                    )}
                >
                    {items.map(item => (
                        <Option key={item}>{item}</Option>
                    ))}
                </Select>

                {this.props.value === 'Custom'?<Input prefix={'$ '} style={{width:'80px'}} value={name} onChange={this.onNameChange}/>:''}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(SelectAddNew);
