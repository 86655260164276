import React, {Component, PureComponent} from 'react';
import {Collapse, PageHeader, Tabs} from "antd";
import styled from "styled-components";
import FacilityBenchmark from "@containers/facility/Benchmark/FacilityBenchmark/FacilityBenchmark";
import NationalBenchmark from "@containers/facility/Benchmark/NationalBenchmark/NationalBenchmark";

const {Panel} = Collapse;
const {TabPane} = Tabs;

const DividerWrapper = styled.div`
  .site-collapse-custom-panel .ant-collapse-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex;
    align-items: center;
    padding-left: 4rem !important;
  }

  .site-collapse-custom-panel .ant-page-header-content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .ant-collapse-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex;
    align-items: center;
  }


  .ant-tabs-extra-content {
    padding-right: 2rem;
  }
`

class Benchmark extends PureComponent {

    handleTab = (e) => {
        this.setState({
            selected: e
        })
    }

    renderProfile = (e) => {

        return (
            <div className={'p-4'}>

                <Tabs defaultActiveKey="1" className='tab_blue_bottom' animated={true}
                      forcerender={false} onChange={this.handleTab}>
                    <TabPane tab="Facility Comparison" key="1">
                        <FacilityBenchmark/>
                    </TabPane>

                    <TabPane tab="National Comparison" key="2">
                        <NationalBenchmark/>
                    </TabPane>

                </Tabs>
            </div>
        )
    }

    render() {

        return (
            <>

                <PageHeader
                    style={{width: '100%'}}
                    className="site-page-header"
                    // title={'Energy Saving Analysis'}
                    subTitle={''}
                    footer={this.renderProfile()}
                />


            </>
        )
    }
}

export default Benchmark;
