import React, {Component} from 'react';
import {Gauge} from "@antv/g2plot";
import {NoDecimalFormat} from "@components/Format";

class EuiGuage extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this.draw(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (this.chartRef.current) {
            this.chartRef.current.innerHTML = '';
            this.draw(nextProps)
        }
    }

    draw(props) {
        const chartDom = this.chartRef.current;
        // console.log('gauge', props.gauge, _.get(props.gauge, 'value')/50000)
        const ticks = [0, 1 / 3, 2 / 3, 1];
        const color = ['#30BF78', '#FAAD14', '#F4664A'];
        const gauge = new Gauge(chartDom, {
            percent: props.gauge / 500,
            innerRadius: 0.75,
            type: 'meter',
            height: props.height,
            // 自定义 meter 总步数 以及 step 占比
            meter: {
                steps: 50,
                stepRatio: 0.6,
            },
            range: {
                ticks: [0, 1],
                color: ['l(0) 0:#30BF78 0.5:#FAAD14 1:#F4664A'],
            },
            axis: {
                label: {
                    formatter(v) {
                        return Number(v) * 500;
                    },
                },
            },
            indicator: {
                pointer: {
                    style: {
                        stroke: '#D0D0D0',
                    },
                },
                pin: {
                    style: {
                        stroke: '#D0D0D0',
                    },
                },
            },
            statistic: {
                title: {
                    style: ({percent}) => {
                        return {
                            fontSize: '36px',
                            lineHeight: 1,
                            color: percent < ticks[1] ? color[2] : percent < ticks[2] ? color[1] : color[0],
                        };
                    },
                },
                content: {
                    offsetY: 36,
                    style: {
                        fontSize: '16px',
                        color: '#4B535E',
                    },
                    formatter: () => (NoDecimalFormat(props.gauge)),
                },
            },
        });

        gauge.render();
    }

    render() {
        return (

            <>
                <div ref={this.chartRef}/>
                <div className={'pb-5'}></div>
            </>
        );
    }
}

export default EuiGuage;
