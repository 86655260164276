import React from 'react';
import { api } from "@/redux/api";
import { useSafeState } from "ahooks";
import { Empty, message, Upload } from "antd";
import { useEffect } from "react";

export const getFileExtension = (filename) => {
  return (filename || '').split('.').pop().toLowerCase();
}

export const getPathsByCurrentTree = (currentTreeKey) => {
  let arr = [];
  let cur = currentTreeKey;

  while (cur) {
    arr.unshift(cur);
    cur = cur?.parentFolder;
  }

  return arr
}

const FOLDER_KEY = 'folder-';
const FILE_KEY = 'file-';

export const formatFilesToTreeData = (files, folders, id) => {
  const cloneFiles = [...files];
  const cloneFolders = [...folders];
  const root = {
    isRoot: true,
    isFolder: true,
    parentFolder: null,
    key: 'root',
    title: 'root',
    children: [],
    nodes: []
  }

  const FileMapping = new Map();

  for (const folder of cloneFolders) {
    const key = FOLDER_KEY + folder.id;
    folder.key = key;
    folder.isFolder = true;
    folder.title = folder.name;
    folder.children = [];
    folder.nodes = [];
    FileMapping.set(key, folder)
  }

  for (const file of cloneFiles) {
    const key = FILE_KEY + file.id;
    file.key = key;
    file.isFolder = false;
    file.title = file.name;
    FileMapping.set(key, file)
  }


  for (const file of [...cloneFolders, ...cloneFiles]) {
    if (file.parent === null || file.folder === null) {
      if (file.isFolder) {
        root.children.push(file)
      }

      root.nodes.push(file);
      file.parentFolder = root;

    } else {

      const parentId = file.isFolder ? file.parent : file.folder;

      const key = FOLDER_KEY + parentId;
      if (FileMapping.has(key)) {
        const parent = FileMapping.get(key);
        if (file.isFolder) {
          parent.children.push(file);
        }
        parent.nodes.push(file);
        file.parentFolder = parent;
      }
    }
  }
  return [
    root,
    id ? cloneFolders.find(item => item.id === id) : root,
    {
      files: cloneFiles,
      folders: cloneFolders
    }
  ]
}

export const filterFolders = (nodes) => {
  return nodes.filter(node => node.isFolder).map(node => ({
    ...node,
    children: node.children ? filterFolders(node.children) : undefined,
    nodes: node.children ?? []
  }));
};

export const getSafeUrl = (url = '') => {
  if(url.startsWith('https://facilitykpiclientfile.s3.us-east-2.amazonaws.com/facilitykpiclientfile')) {
    return url.slice(79)
  }
  return url
}

export const usePreviewUrl = (currentOpenFile) => {
  const [previewUrl, setPreviewUrl] = useSafeState();
  const [isFileViewLoading, setIsFileViewLoading] = useSafeState(false)

  const run = async () => {
    setIsFileViewLoading(true)
    let generate_url = await api.generate_url(getSafeUrl(currentOpenFile.image), true).then(
      response => {
        let dataSource = response.data.url
        return dataSource
      }
    ).catch(error => (
      error
    )).finally(() => {
      setIsFileViewLoading(false)
    });
    setPreviewUrl(generate_url)
  }

  useEffect(() => {
    setPreviewUrl('')
    if(currentOpenFile?.image) {
      run()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpenFile])

  return [currentOpenFile?.image ? previewUrl : '', isFileViewLoading];
}
const regex = /\/[^\/?#]+\.(\w+)(?=[?#]|$)/i

const getExtensionByUrl = (url = '') => {
  return url.match(regex)?.[1] ?? ''
}

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
export const isImage = (fileName = '') => {
  const extension = getExtensionByUrl(fileName);
  
  if (imageExtensions.includes(extension)) {
    return true;
  }
  return false
}

const docExtensions = [
  "doc",    // Word 97-2003 文档
  "docx",   // Microsoft Word 文档
  "ppt",    // PowerPoint 演示文稿
  "pptx",   // Microsoft PowerPoint 演示文稿
  "xls",    // Excel 97-2003 工作簿
  "xlsx"    // Microsoft Excel 工作簿
];

export const isDoc = (fileName = '') => {

  const extension = getExtensionByUrl(fileName);
  if (docExtensions.includes(extension)) {
    return true;
  }
  return false
}

const mediaExtensions = ['mp4', 'mov', 'avi', 'flv', 'rmvb', 'wmv']

export const isMedia = (fileName = '') => {

  const extension = getExtensionByUrl(fileName);
  
  if (mediaExtensions.includes(extension)) {
    return true;
  }
  return false
}

const audioExtensions = ['mp3', 'wav', 'flac', 'aac', 'ogg', 'wma'];
export const isAudio = (fileName = '') => {
  const extension = getExtensionByUrl(fileName);
  
  if (audioExtensions.includes(extension)) {
    return true;
  }
  return false
}


const supportIframeExtensions = ['pdf', 'text', 'json', 'html', 'txt'];

export const isSupportIframe = (fileName = '') => {
  const extension = getExtensionByUrl(fileName);
  
  if (supportIframeExtensions.includes(extension)) {
    return true;
  }
  return false
}

export const tablelocale = (isSearch) => ({
  emptyText: (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={isSearch ? 'No files found.' : 'This folder is empty.'}
    />
  )
});

const suportOpenAppFiles = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx', 'pdf'];

export const canOpenInApp = (record) => {
  if(record.isFolder) {
    return false
  }
  return suportOpenAppFiles.includes(record.name.split('.').pop())
}

export const openAppByUrl = (url) => {
  const aEl = document.createElement('a');
  switch (getExtensionByUrl(url)) {
    case 'xlsx':
    case 'xls':
    aEl.href = `ms-excel:ofv|u|${url}`
      break;
    case 'pptx':
    case 'ppt':
      aEl.href = `ms-powerpoint:ofv|u|${url}`
      break;
    case 'docx':
    case 'doc':
      aEl.href = `ms-word:ofv|u|${url}`
      break;
    case 'pdf':
      aEl.href = `ms-pdf:ofv|u|${url}`
      break;
    default:
      return
  }
  document.body.appendChild(aEl);
  aEl.click();
  document.body.removeChild(aEl);
}

export const beforeUpload= (file) => {
  const isLessThan20M = file.size / 1024 / 1024 < 20;
  if (!isLessThan20M) {
    message.error('File must be less than 20MB');
    return Upload.LIST_IGNORE;
  }
  return false;
}


export const allowedFileTypes = [
  ...imageExtensions,
  ...docExtensions,
  ...mediaExtensions,
  ...audioExtensions,
  ...supportIframeExtensions
]