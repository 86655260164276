import { html } from "react-jsx2pdf";
import { PDFPageContainer } from "../components/PageContainer";
import { col_2, convertTo2DArray } from "./utils";
import React from "react";
import { PdfTitle } from "../components/title";

export const PDFDashboardCards = (_, { pdfFormValue, pdfImages }) => {

  const dashboardCard = pdfImages.dashboardCard;
  if (dashboardCard.length === 0) {
    return null
  }

  const images2d = convertTo2DArray(dashboardCard)
  return (
    <PDFPageContainer lastPage={true}>
      <PdfTitle w={88} title="Summary"></PdfTitle>
      {
        images2d.map(imgs => {
          return (
            <p-col columnGap={40} margin={[0,10,0,10]}>
              {
                imgs.map(img => {
                  if (!img) return <p-text></p-text>
                  return (
                    <p-stack width={col_2} unbreakable={true}>
                      <p-svg>
                        {html`
                          <svg width="250" height="150">
                          <!-- <rect x="0" y="0" width="250" height="150" rx="4" stroke="#396598" stroke-width="1" fill="none" /> -->
                            <text x="10" y="30" font-size="16" font-weight="600" fill="#396598">${img.pdfChart_Title}</text>
                            <text x="10" y="80" font-size="26" font-weight="600" fill="#000000">${img.pdfChart_Total}</text>
                            <text x="130" y="80" font-size="16" font-weight="600" fill="#000000">${img.pdfChart_Unit}</text>
                            <rect x="190" y="62" width="54" height="24" rx="15" fill="${parseFloat(img.pdfChart_Percent) > 0 ? 'red' : 'green'}"></rect>
                            <text x="195" y="79" font-size="12" font-weight="600" fill="#ffffff">${img.pdfChart_Percent}</text>
                            <rect x="10" y="100" width="220" height="3" fill="#a668e7" />
                            <text font-size="12" x="10" y="135" >${img.pdfChart_DateStart}</text>
                            <text font-size="12" x="100" y="135" >${img.pdfChart_DateEnd}</text>
                            ${parseFloat(img.pdfChart_Percent) > 0 ? '<polygon points="74,135 83,125 92,135" fill="red"/>' : '<polygon points="74,125 83,135 92,125" fill="green"/>'}
                          </svg>
                        `}
                      </p-svg>
                  </p-stack>
                  );
                })
              }
            </p-col>
          );
        })
      }
    </PDFPageContainer>
  );
};

