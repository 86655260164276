import React, {Component} from 'react';
import _ from 'lodash'
import {connect} from "react-redux";

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {jsforceResponse} from "@/config/httpService";
import {PlusOutlined} from "@ant-design/icons";
import {api} from "@redux/api";
import actions from "@redux/equipment/actions";
import {Button} from "antd";
import WorkorderModal from "@containers/facility/Equipment/WorkorderModal";


class EquipmentLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: null,
            Contact: null,
            value: null,
            isVisible: false
        }
        this.debouncedSearch = _.debounce(this.performSearch, 300);
        this.throttledSetState = _.throttle(this.setState.bind(this), 1000);
    }

    componentDidMount() {
        console.log('componentDidMount', this.props.record, this.props.formRef)
        this.setState({
            value: this.props.record,
            isModalVisible: false
        })
    }

    handleFocus = async (e) => {

        const result1 = await api.filterEquipment('');
        const res1 = jsforceResponse(result1, '', false, true)
        let data = _.get(res1, 'data.results', []);
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i].name,
                value: data[i].name,
                id: data[i].id,
                record: {Id: data[i].id, value: data[i].name},
            })
        }
        this.setState({
            options
        })
    }

    performSearch = async (query) => {
        try {
            const result = await api.filterEquipment(`name=${query}`);
            const res = jsforceResponse(result, '', false, true);
            const data = _.get(res, 'data.results', []);

            const options = data.map(item => ({
                label: item.name,
                value: item.name,
                id: item.id,
                record: { Id: item.id, value: item.name },
            }));

            this.throttledSetState({ options });
        } catch (error) {
            console.error('Search error:', error);
        }
    };

    handleSearch = async (e) => {
        this.setState({ value: e });
        this.debouncedSearch(e);
    }

    handleSelect = (e, record) => {
        console.log('handleSelect', this.props.formRef, record)

        this.setState({
            selected: record.record,
            value: e
        })
        this.props.formRef.setFieldsValue({
            equipment: record.id,
            equipment_name: record.label
        })
    }

    handleAddEquipment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isVisible: true
        })
    }
    renderAddButton = () => {
        return (
            <div>
                <Button type="primary" shape="circle" icon={<PlusOutlined/>} size={'middle'}
                        onClick={(e) => this.handleAddEquipment(e)}></Button>
            </div>
        )
    }

    render() {
        console.log('equipment', this.state.value)
        return (
            <>
                <div className={'flex items-center justify-center'}>
                    <AutoComplete
                        style={{width: '100%'}}
                        options={this.state.options}
                        onFocus={this.handleFocus}
                        onSelect={this.handleSelect}
                        onSearch={this.handleSearch}
                        value={this.state.value}
                        dropdownMatchSelectWidth={false}
                    >
                        <Input size="medium" autosize={true}
                               autoComplete="off"

                        />
                    </AutoComplete>
                    {this.renderAddButton()}
                </div>
                <WorkorderModal
                    isVisible={this.state.isVisible}
                    onClose={(e) => this.setState({isVisible: false})}
                />
            </>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {
        SelectedEquipment: (e) => dispatch(actions.SelectedEquipment(e))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EquipmentLookup);
