const actions = {
    Baseline_Start_REQUEST: 'Baseline_Start_REQUEST',
    Baseline_End_REQUEST: 'Baseline_End_REQUEST',
    Post_Start_REQUEST: 'Post_Start_REQUEST',
    Post_End_REQUEST: 'Post_End_REQUEST',
    Electricity_Price_REQUEST: 'Electricity_Price_REQUEST',
    Gas_Price_REQUEST: 'Gas_Price_REQUEST',
    Range0_REQUEST: 'Range0_REQUEST',
    Range1_REQUEST: 'Range1_REQUEST',
    Breakpoint0_REQUEST: 'Breakpoint0_REQUEST',
    Breakpoint1_REQUEST: 'Breakpoint1_REQUEST',
    Breakpoint2_REQUEST: 'Breakpoint2_REQUEST',

    BaselineStartREQUEST: (data) => ({
        type: actions.Baseline_Start_REQUEST,
        payload: {data},
    }),
    BaselineEndREQUEST: (data) => ({
        type: actions.Baseline_End_REQUEST,
        payload: {data},
    }),
    PostStartREQUEST: (data) => ({
        type: actions.Post_Start_REQUEST,
        payload: {data},
    }),
    PostEndREQUEST: (data) => ({
        type: actions.Post_End_REQUEST,
        payload: {data},
    }),
    ElectricityPriceREQUEST: (data) => ({
        type: actions.Electricity_Price_REQUEST,
        payload: {data},
    }),
    GasPriceREQUEST: (data) => ({
        type: actions.Gas_Price_REQUEST,
        payload: {data},
    }),
    Range0REQUEST: (data) => ({
        type: actions.Range0_REQUEST,
        payload: {data},
    }),
    Range1REQUEST: (data) => ({
        type: actions.Range1_REQUEST,
        payload: {data},
    }),
    Breakpoint0REQUEST: (data) => ({
        type: actions.Breakpoint0_REQUEST,
        payload: {data},
    }),
    Breakpoint1REQUEST: (data) => ({
        type: actions.Breakpoint1_REQUEST,
        payload: {data},
    }),
    Breakpoint2REQUEST: (data) => ({
        type: actions.Breakpoint2_REQUEST,
        payload: {data},
    })

};
export default actions;
