import Input from "antd/es/input";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import {store} from "@redux/store";
import {DatePicker, Select} from "antd";

import AddressLookup from "@components/AddressLookup";
import CompanyLookup from "@containers/Lookup/CompanyLookup";
import CustomDatePicker from "@components/CustomDatePicker";

const company_items = store.getState().Auth.company;
const {Option} = Select;
const {YearPicker} = DatePicker;

export const facility_category_options = [
    {label: 'Hospital', value: 'Hospital'},
    {label: 'Office', value: 'Office'},
    {label: 'Medical Office Building', value: 'Medical Office Building'},
    {label: 'Manufacturing', value: 'Manufacturing'},
    {label: 'Dormitory', value: 'Dormitory'},
    {label: 'Clinic / Outpatient Health', value: 'Clinic / Outpatient Health'},
    {label: 'College/University', value: 'College/University'},
    {label: 'Fast Food', value: 'Fast Food'},
    {label: 'Grocery Store', value: 'Grocery Store'},
    {label: 'Hotel/Motel/Inn', value: 'Hotel/Motel/Inn'},
    {label: 'K-12 School', value: 'K-12 School'},
    {label: 'Library', value: 'Library'},
    {label: 'Mall', value: 'Mall'},
    {label: 'Nursing/Assisted Living', value: 'Nursing/Assisted Living'},
    {label: 'Religious Worship / Church', value: 'Religious Worship / Church'},
    {label: 'Restaurant', value: 'Restaurant'},
    {label: 'Retail (non-Mall)', value: 'Retail (non-Mall)'},
    {label: 'Other', value: 'Other'}
];


export const FacilityFormFields = (formRef) => (
    [
        {
            title: 'key',
            name: 'id',
            order: 0,
            visible: false,
            length: 0,
            type: 'text',
        },
        {
            title: 'Name',
            name: 'name',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            required: true,
            render: <Input
                autoComplete="off"
                list="name-suggestions"
                allowClear
            />
        },
        {
            title: 'Category',
            name: 'category',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'select',
            required: true,
            render: (
                <Select>
                    {facility_category_options.map(option => (
                        <Option key={option.value} value={option.value}>{option.label}</Option>
                    ))}
                </Select>
            )
        },
        {
            title: 'Company',
            name: 'company_name',
            required: true,
            order: 1,
            visible: true,
            length: "w-full",
            type: 'Lookup_text',
            render: (value) => {
                console.log('company', formRef)
                return <CompanyLookup formRef={formRef} record={value}/>
            }
        },
        {
            title: 'Company',
            name: 'company',
            order: 1,
            visible: false,
            length: "w-full",
            type: 'text',
        },
        {
            title: 'Area',
            name: 'area',
            order: 1,
            required: true,
            visible: true,
            length: "w-full",
            type: 'text',
            render:
                <Input
                    autoComplete="off"
                    list="name-suggestions2"
                    allowClear
                    type="number"
                    min="0"
                />

        },
        {
            title: 'Year',
            name: 'year',
            order: 8,
            required: true,
            visible: true,
            length: "w-full",
            // type: 'text',
            // render: <Input type="number" min="0"/>
            type: 'text',
            render: <CustomDatePicker format='YYYY' type='year'/>
        },
        {
            title: 'Latitude',
            name: 'latitude',
            order: 8,
            visible: false,
            length: "w-full",
            type: 'text',
            render: <Input/>
        },
        {
            title: 'Longitude',
            name: 'longitude',
            order: 8,
            visible: false,
            length: "w-full",
            type: 'text',
            render: <Input/>
        },

        {
            title: 'Description',
            name: 'description',
            order: 2,
            visible: true,
            length: "w-full",
            type: 'textarea',


            render: <TextArea rows={2}/>
        },

        {
            title: 'Separator',
            name: 'Separator',
        },
        {
            title: 'Created By',
            name: 'createdby_name',
            order: 17,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <Input disabled/>
        },
        {
            title: 'Created Date',
            name: 'created_date',
            order: 18,
            visible: true,
            length: "w-full",
            type: 'Date',
            render: <Input disabled/>
        },
        {
            title: 'Modified By',
            name: 'lastModified_name',
            order: 19,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <Input disabled/>
        },
        {
            title: 'Modified Date',
            name: 'modified_date',
            order: 20,
            visible: true,
            length: "w-full",
            type: 'Date',
            render: <Input disabled/>
        },
        {
            title: 'Facility Image',
            name: 'image',
            order: 9,
            visible: true,
            length: "sm:w-full",
            type: 'File'
        },
        {
            title: 'Address',
            name: 'address',
            order: 8,
            visible: true,
            length: "w-full",
            type: 'Lookup_text',
            render: (value) => {
                return <AddressLookup formRef={formRef} value={value} fieldName={'address'}/>
            }
        },
        {
            title: 'Station',
            name: 'station',
            order: 8,
            visible: true,
            length: "w-full",
            type: 'text',
            render: <Input/>
        },
    ]
)
