import React, {Component} from 'react';
import actions from "@redux/EditableTable/actions";
import {connect} from "react-redux";
import enUS from "antd/lib/locale/en_US";
// import ProTable from "@ant-design/pro-table";
import _ from "lodash";
import Space from "antd/es/space";
import {Button, ConfigProvider, Drawer, Popconfirm} from "antd";
import {CSVLink} from "react-csv";
import ResizableProTable from "@components/Table/ResizableProTable";

import moment from "moment";
import {defaultActionRender} from "@ant-design/pro-utils/es/useEditableArray";
import ID from "@components/UUID";
import {RedoOutlined, SettingFilled} from "@ant-design/icons";
import PermissionControl from "@components/Permission/Permission";

class FunctionTestingTableContent extends Component {

    actionRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isImportCSVModal: false,
            isNewModalOpen: false,
            searchString: null,
            filter_dates: [null, null],
            editableKeys: [],
            visible: false,
            isOutSideVisible: false,
            data: [],
            valueChanged: false,
            newValues: [],
            open: true
        }

        this.box = React.createRef();
    }

    componentDidMount() {

    }


    componentWillReceiveProps(nextProps) {

    }


    deleteRecord = async (e) => {
        let result = await this.props.deleteRecord(e);
        this.actionRef.current.reload();

    }


    handOnlineSave = async (key, row) => {

        let keys = this.state.data.map(e => e.id)
        let result;
        // console.log('handOnlineSave', keys, key, row, keys.includes(key))
        if (!keys.includes(key)) {
            // console.log('row', row)
            result = await this.props.createRecords(row);
        } else {
            result = await this.props.handOnlineSave(key, row);
        }

        this.setState({
            valueChanged: false
        }, function () {
            this.actionRef.current.reload();
        })

        return result
    }

    createRecords = async (e) => {
        let result = await this.props.createRecords(e);
        this.actionRef.current.reload();
    }

    handleSaveComment = async (key, row) => {
        let result;
        result = await this.props.handOnlineSave(key, row);
        // console.log('handOnlineSave', result);
    }

    deleteSelected = async (e, body) => {
        let result = await this.props.bulkDelete(body)
        // console.log('delete', result)
        this.actionRef.current.reload();
        this.actionRef.current.clearSelected();
        this.setState({
            visible: false
        })
    }

    handleFresh = (e) => {
        // console.log('refresh')
        this.props.handleGlobalSearch('')
        this.setState({
            searchString: null
        }, function () {
            this.actionRef.current.reload();
        })

    }

    refresh = (needRequest = true) => {
        this.setState({
            needRequest,
        }, this.actionRef.current.reload)
    };

    formRefCallback = (formRef) => {

        this.props.getFormRef(formRef);
    }

    onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        //when selected, disable edit
        this.setState({selectedRowKeys, editableKeys: []});
    };


    setEditableRowKeys = (e, row) => {
        this.setState({
            editableKeys: e
        })
    }
    showPopconfirm = (e) => {
        this.setState({
            visible: true
        })
    }
    render() {

        let columns = this.props.columns;
        if (this.props.relatedList) {
            columns = columns.filter(e => e.title !== 'Operation')
        }

        let params = [
            this.state.searchString,
        ]

        return (
            <>
                {this.state.open ? <div className={'editable'} ref={this.box}>
                    <ConfigProvider locale={enUS}>

                            <ResizableProTable
                                key={'FunctionTestingTableContent_editable' + ID}
                                columnsState={{
                                    persistenceKey: 'pro-table-singe-demos',
                                    persistenceType: 'localStorage',
                                    defaultValue: this.props.defaultDisplayColumns,
                                    onChange: (e) => {
                                        console.log(e)
                                    }
                                }}

                                toolBarRender={() => (
                                    this.props.pageHeader
                                )}
                                options={{
                                    density: false,
                                    reload: false,
                                    refreshIcon: <RedoOutlined/>,
                                    setting: {
                                        settingIcon: <SettingFilled/>,
                                    }
                                }}
                                expandedRowRender={this.props.expandedRowRender}
                                editable={{
                                    type: 'multiple',
                                    onDelete: this.deleteRecord,
                                    deletePopconfirmMessage: 'delete this row',
                                    onSave: (keys, row) => this.handOnlineSave(keys, row),
                                    // editableKeys: this.state.editableKeys,
                                    onChange: this.setEditableRowKeys,
                                    onlyAddOneLineAlertMessage: 'only add one line',
                                    actionRender: (row, config) => {
                                        const configLocal = {...config};
                                        configLocal.saveText = <i className="fas fa-save mr-2 ml-2 text-lg"></i>;
                                        configLocal.deleteText = <i className="fas fa-trash-alt text-lg"></i>;
                                        configLocal.cancelText =
                                            <i className="fas fa-times-circle mr-2 ml-2 text-lg"></i>;
                                        return defaultActionRender(row, configLocal);
                                    },
                                    onValuesChange: (record, recordList) => this.handleValueClicked(record, recordList)
                                }}
                                value={this.state.data}
                                showSorterTooltip={false}
                                recordCreatorProps={false}
                                rowClassName={(record) => {
                                    let rowClass = '';
                                    if (
                                        this.props.selected_row === record.id ||
                                        (_.get(record, 'facility_id') && this.props.selected_row === _.get(record, 'facility_id'))
                                    ) {
                                        rowClass += ' selected_row';
                                    }
                                    return rowClass;
                                }}
                                columns={columns}
                                actionRef={this.actionRef}
                                scroll={this.props.scroll ? this.props.scroll : {x: 800}}
                                params={params}
                                postData={(data) => {
                                    this.props.getRecord(data)
                                    return data
                                }}
                                request={async (params = {}, sort = {}, filter) => {
                                    console.log("params", params, ' sort', sort, 'filter: ', filter);

                                    let query = 'page=' + params.current + '&page_size=' + params.pageSize + '&';

                                    for (const [key, value] of Object.entries(sort)) {
                                        if (value === 'descend') {
                                            query += "ordering=-" + key + "&";
                                        } else if (value === 'ascend') {
                                            query += "ordering=" + key + "&";
                                        }
                                    }

                                    if (this.props.relatedList && this.props.relatedId && this.props.relatedObject) {
                                        query += '&relatedId=' + this.props.relatedId + '&relatedObject=' + this.props.relatedObject;
                                    }
                                    let result = await this.props.handleFilter(query);
                                    let result_data = _.get(result, 'data', []);

                                    this.setState({
                                        data: result_data,
                                    })

                                    return result
                                }}
                                rowSelection={
                                    {
                                        selections: false,
                                        onChange: this.onSelectChange,
                                    }}
                                tableAlertRender={(e) => {

                                    return <Space size={24}>
                                      <span>
                                        Selected {e.selectedRowKeys.length}
                                          <a style={{marginLeft: 8}} onClick={e.onCleanSelected}>
                                          Cancel Selection
                                        </a>
                                      </span>
                                    </Space>
                                }}
                                tableAlertOptionRender={(e) => {
                                    let rowIds = _.get(e, 'selectedRowKeys', []);
                                    let rows = _.get(e, 'selectedRows', []);

                                    if (!rows[0]) {
                                        rows = []
                                        if (rowIds) {
                                            rowIds.map(e => {
                                                return rows.push(e)
                                            })
                                        } else {
                                            rows = []
                                        }
                                    }

                                    // console.log('rows', exportRow)

                                    return (
                                        <Space size={16}>

                                            <Popconfirm
                                                title="Do you want to delete this record"
                                                visible={this.state.visible}
                                                onConfirm={() => this.deleteSelected(e, rows)}
                                                onCancel={() => this.handleConfirmCancel()}
                                            >
                                                <PermissionControl
                                                    allowedPermissions={'3:' + this.props.columnProps?.name}>
                                                    <Button type={'primary'} onClick={() => this.showPopconfirm()}>Delete
                                                        Selected</Button>
                                                </PermissionControl>
                                            </Popconfirm>
                                        </Space>
                                    );
                                }}
                                rowKey="id"
                                search={false}
                                pagination={{
                                    showSizeChanger: true,
                                    pageSizeOptions: [10, 20, 50, 100, 1000],
                                    showQuickJumper: true,
                                    showTotal: (total, range) => (
                                        <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
                                    ),
                                }}

                                onRow={(record, recordIndex) => {
                                    // when each row cell is clicked
                                    console.log('selected', record)
                                    if (!this.props.permission[this.props.columnProps.name] > 1 || this.props.Role === '2.2' || this.props.Role === '4.0') {
                                        return null
                                    } else {
                                        return {

                                            onDoubleClick: event => {
                                                this.props.handleDetailDrawer(true, record.id)
                                                // this.renderDrawer(record, {'id': record.id})
                                            }
                                        }
                                    }


                                }}
                            />

                    </ConfigProvider>
                </div> : ''}
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        facility_id: _.get(state.Facility, 'selected.Id'),
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] : null,
        permission: state.Auth.permission,
    }
}
const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(FunctionTestingTableContent);
