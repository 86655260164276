import React, { useEffect, useRef, useState } from "react";
import { Button, message } from "antd";
import { MenuActionsWrap } from "@/containers/PdfReport/components/MenuActions";
import { usePdfContext } from "@/containers/PdfReport/context";
import { jsxToPdfDocument } from "react-jsx2pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import { createReport } from "@/redux/api";
import { store } from "@/redux/store";
import { Document } from './document'
import { useSelector } from "react-redux";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getPdfMakeInstance = (document, ctx) => {

    return pdfMake.createPdf(
        jsxToPdfDocument(document, {
            ctx,
        })
    );
};

export const ReviewPdf = () => {
    const { pdfFormValue, closeDrawer, goToLoadingStep, pdfImages, reloadTable } = usePdfContext();
    const iframeRef = useRef();
    const fileRef = useRef();
    const instanceRef = useRef();
    const [saveLoading, setSaveLoading] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const facilityName = useSelector((state) => state?.Facility?.selected?.value ?? '')
    const companyName = useSelector((state) => state?.Facility?.selected?.record?.company ?? '')

    useEffect(() => {

        instanceRef.current = getPdfMakeInstance(<Document />, {
            pdfFormValue,
            pdfImages,
            facilityName,
            companyName,
            Date: moment().format("MM/DD/YYYY"),
        });

        // instanceRef.current = getPdfMakeInstance(<DocumentDemo />, {
        //   pdfFormValue,
        //   pdfImages
        // });
        instanceRef.current.getBlob((blob) => {
            if (iframeRef.current) {
                iframeRef.current.src = URL.createObjectURL(blob)
                fileRef.current = new File([blob], pdfFormValue.name + ".pdf", { type: "application/pdf" })

            }
        })
    }, [])

    const onSave = async () => {
        setSaveLoading(true)
        const fileName = pdfFormValue.name
        const File = fileRef.current;
        const facility_id = store.getState().Facility.selected.Id;
        const formData = new FormData();
        formData.append("attachment", File);
        formData.append("name", fileName);
        formData.append("template_name", fileName);
        formData.append("facility", facility_id);
        createReport(formData).then(res => {
            if (res && res.status >= 200 && res.status < 300) {
                message.success('Saved')
                closeDrawer()
                reloadTable();
            } else {
                message.error('Save failed')
            }
        }).catch(() => {
            message.error('Save failed')
        }).finally(() => {
            setSaveLoading(false)

        })
    }
    const onDownload = () => {
        // setDownloadLoading(true)
        // instanceRef.current.download(() => {
        //     setDownloadLoading(false)
        //     message.success('download successfully!')
        // })
        const url = iframeRef.current.src;
        const a = document.createElement('a');
        a.href = url;
        a.download = pdfFormValue.name + '.pdf';

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a)
    }

    return (
        <>
            <MenuActionsWrap>
                <Button className="mr-10" onClick={goToLoadingStep}>Back</Button>
                <Button className="mr-10" onClick={onDownload} loading={downloadLoading}>Download</Button>
                <Button type='primary' loading={saveLoading} onClick={onSave}>Save</Button>
            </MenuActionsWrap>

            <iframe title="pdf" ref={iframeRef} width="100%" height={1000}></iframe>

        </>

    );
}

