import React from "react";
import {FormatDate} from "@components/Format";
import moment from "moment";
import {Tooltip} from "antd";

export const functiontestingProps = {
    title: 'functiontesting',
    name: 'functiontesting',
    description: ''
}
export const FunctionalTestingColumns = (
    handleDetailDrawer
) => ([
    {
        title: 'key',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: true,
        width: '4%'
    },
    {
        title: 'Equipment',
        dataIndex: 'equipment__name',
        key: 'equipment__name',
        editable: false,
        search: false,
        hideInTable: false,
        sorter: (a, b) => a.equipment__name?.localeCompare(b.equipment__name),
        render: (text, record) => {
            return (
             <Tooltip title={text}>
                <span>{record.equipment__name}</span>
            </Tooltip>)
        }
    },
    {
        title: 'EquipmentID',
        dataIndex: 'equipment__id',
        key: 'equipment__id',
        editable: false,
        search: false,
        hideInTable: false,
        sorter: (a, b) => (a.equipment__id-b.equipment__id),
        render: (text, record) => {
            return record.equipment__id
        }
    },
    {
        title: 'Test Date',
        dataIndex: 'record_date',
        key: 'record_date',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: false,
        sorter: (a, b) => (a.record_date - b.record_date),
        render: (text, record) => {
            console.log('record_date', record)
            return text && text !== '-' ? moment(text).format('YYYY-MM-DD h:mm:ss') : ''
        }
    },
    {
        title: 'Test By',
        dataIndex: 'record_by',
        key: 'record_by',
        editable: false,
        search: false,
        hideInTable: false,
        sorter: (a, b) => a.record_by?.localeCompare(b.record_by),
        render: (text, record) => {
            return record.record_by_name
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        editable: false,
        search: false,
        render: text => (
            <Tooltip title={text}>
                <span>{text}</span>
            </Tooltip>
        ),
        hideInTable: false,
    },
    {
        title: 'Created Date',
        dataIndex: 'created_date',
        key: 'created_date',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: false,
        sorter: (a, b) => (a.created_date - b.created_date),
        render: (text, record) => {
            return FormatDate(text)
        }
    },
    {
        title: 'Created By',
        dataIndex: 'createdby__username',
        key: 'createdby__username',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: false,
        render: text => (
            <Tooltip title={text}>
                <span>{text}</span>
            </Tooltip>
        ),
        sorter: (a, b) => a.createdby__username?.localeCompare(b.createdby__username),
    },
    {
        title: '',
        valueType: 'option',
        width: '6rem',
        importIngore: true,
        render: (text, record, _, action) => (
            <a onClick={() => handleDetailDrawer(true, record.id)}>
                <i className="fas fa-expand-arrows-alt text-lg"></i>
            </a>
        ),
    }

]);
