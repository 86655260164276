import styled from "styled-components";

export const TagInputWrapper = styled.div`
  .AddAndDelete {
    span.ant-input-group-addon {
      display: block !important;
    }

    .main {
      border: 1px solid #E9E9E9;
      border-radius: 4px;
      padding: 5px;
      width: 100%;
    }

    .tag {
      position: relative;
      margin: 3px;
      border: 1px dashed #3b9de9;
      border-radius: 4px;
      padding: 0 8px;
      line-height: 30px;
      color: #666666;
      background: rgba(255, 255, 255, 0.7);
    }

    .delete {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    .inputs {
      margin-top: 10px;
    }

    input {
      position: relative;
      top: -1px;
      width: 10rem !important;
      box-sizing: border-box;
      margin: 0;
      padding: 5px !important;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', "tnum";
      position: relative;
      display: inline-block;
      min-width: 0;
      color: rgba(0, 0, 0, 0.85);
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s;
    }

    button {
      margin-left: 10px;
      background-color: #3789c7;
      border: none;
      color: white;
      padding: 5px 15px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      border-radius: 2px;
    }
  }
`;
