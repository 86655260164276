import React from 'react';
import {Modal} from 'antd';
import Detail from "@components/Table/Detail";

const DetailModal = ({
                         isVisible,
                         formFields,
                         hanldeCreateRecords,
                         formRefCallback,
                         data,
                         modalTitle,
                         handleClose
                     }) => {

    const onClose = () => {
        return handleClose();
    }

    const createRecords = (body) => {
        return hanldeCreateRecords(body);
    }

    return (
        <div>
            <Modal
                key={'detailmodal'}
                width={window.innerWidth > 900 ? '50%' : '80%'}
                footer={null}
                title={modalTitle}
                onCancel={onClose}
                open={isVisible}>
                <Detail formFields={formFields}
                        columnProps={'equipment'}
                        key={'detailmodal'}
                        formKey={'detailmodal'}
                        formClassName={'flex-form'}
                        onClose={onClose}
                        postComment={null}
                        formRelated={null}
                        handOnlineSave={null}
                        createRecords={createRecords}
                        handleSaveComment={null}
                        handleDelete={null}
                        userPermission={false}
                        isNewRecords={true}
                        isLookupCreateNew={true}
                        isChat={false}
                        isRelated={false}
                        isAttachments={false}
                        isResetPassword={false}
                        formRefCallback={formRefCallback}
                        handleRegister={null}
                        data={data}/>
            </Modal>
        </div>
    );
};

export default DetailModal;
