import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import TextArea from "antd/es/input/TextArea";
import moment from "moment";

export const CompanyProps = {
    title: 'Company',
    description: '',
    name: 'company'
}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}


export const CompanyColumns = [
    {
        title: 'key',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: true,
        width: '4%'
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        search: false,
        edit: true,
        sorter: (a, b) => a.name?.localeCompare(b.name),
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        render: (text, record, _, action) => (
            <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
        ),
        renderFormItem: () => <TextArea rows={1}/>,
    },
    {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        search: false,
        edit: true,
        renderFormItem: () => <TextArea rows={1}/>,
    },

    {
        title: 'Modified Date',
        dataIndex: 'modified_date',
        key: 'modified_date',
        hideInTable: window.innerWidth < 760,
        editable: false,
        importIngore: true,
        sorter: (a, b) => a.modified_date - b.modified_date,
        render: (text, record) => (
            moment(record.modified_date).format('YYYY-MM-DD')
        )
    },
    {
        title: 'Created Date',
        dataIndex: 'created_date',
        key: 'created_date',
        hideInTable: window.innerWidth < 760,
        editable: false,
        importIngore: true,
        sorter: (a, b) => a.created_date - b.created_date,
        render: (text, record) => (
            moment(record.created_date).format('YYYY-MM-DD HH:mm:ss')
        )
    },
    {
        title: '',
        valueType: 'option',
        width: '6rem',
        importIngore: true,
        render: (text, record, _, action) => (
            <div className={'space-around'}>
                <a onClick={(e) => {
                    e.stopPropagation();
                    return action ? renderDrawer(text, record.id) : ''
                }}>
                    <i className="fas fa-expand-arrows-alt text-lg"></i>
                </a>
            </div>
        ),
    },
];
