import React, { useRef, useEffect } from "react";
import LayoutContent from "@components/utility/layoutContent";
import { Column } from '@antv/g2plot';

const BarChart = (props) => {
    const chartElementRef = useRef(null);
    const chartInstanceRef = useRef(null);
    useEffect(() => {
        if(chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
        draw(props);
        return () => {
            // 清理函数，如果需要的话
        };
    }, [props.data, props.height]);

    const draw = (props) => {
        const avgValue = props.data.reduce((sum, d) => sum + d.yField, 0) / props.data.length;
        const chartDom = chartElementRef.current;
        const chart = new Column(chartDom, {
            data: props.data,
            height: props.height,

            label: false,
            seriesField: 'isCurrent',

            legend: false,
            xField: 'key',
            yField: 'yField',
            xAxis: {
                label: false
            },
            tooltip: {
                customContent: function (x, data) {
                    console.log('tooltip', data)
                    return `<ul style='margin:10px; padding:10px'>
                       <li style="padding-bottom:5px">Category: ${data[0]?.data.type}</li>
                       <li>EUI: ${data[0]?.data.yField}</li>
                    </ul>`;
                },
            },
            annotations: [
                {
                    type: 'line',
                    start: ['min', avgValue],
                    end: ['max', avgValue],
                    style: {
                        stroke: '#f00',
                        lineWidth: 2,
                        lineDash: [4, 4],
                    },
                },
            ],
        });


        chart.render();
        chartInstanceRef.current = chart;
    };
    console.log('BarChart')
    return (
        <LayoutContent className={"outerCardWrapper"}>
            <div style={{marginBottom: '1rem'}}>
                <div className={'small-title_piechart'}>{props.title}</div>
                <span style={{top: '10px', right: '15px'}}
                      className="absolute p-2 mt-2 mr-2 rounded text-xs text-white">
                </span>
            </div>
            <div ref={chartElementRef}/>
        </LayoutContent>
    );
};

export default BarChart;
