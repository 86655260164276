import React, {Component} from 'react';
import {Button, Drawer, Modal, Popover, Select, Tooltip} from "antd";
import actions from "@redux/EditableTable/actions";
import {connect} from "react-redux";
import {FilterFilled, FilterOutlined, ImportOutlined} from "@ant-design/icons";

import _ from "lodash";
import appActions from "@redux/app/actions";
import FunctionalTestingDetail from "@containers/facility/Equipment/FunctionalTesting/FunctionalTestingDetail";
import {api} from "@redux/api";


class FunctionTestingPageHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            value: [],
            filter_dates: [],
            isTemplateSelector:false,
            selectedTypeOption: [{
                key: '',
                value: 'Generic',
                label: 'Blank Template(Default)',
                record: ''
            }],
            tableRef: this.props.tableRef,
            selectedTemplate: '',
        }
    }

    async componentDidMount() {
        let type_data = [], type_options = []
        let equipment_type = this.props.selectedRecord?.category
        let query = ''
        if (equipment_type === undefined) {
            query = ''
        } else {
            query = '&type=' + equipment_type
        }
        const response = await api.filterFunctionalFormView(query);
        type_data = response.data.results;

        type_options.push({
            key: '',
            value: 'Generic',
            label: 'Blank Template(Default)',
            record: ''
        })

        for (let i = 0; i < type_data.length; i++) {
            type_options.push({
                key: type_data[i].id,
                value: type_data[i].name,
                label: type_data[i].name+' ('+type_data[i].type+')',
                record: type_data[i]
            })
        }

        this.setState({
            type_options,
        })
    }
    handleRefresh = (e) => {
        this.props.handleFresh()
    }

    handleOpenNew = (e) => {
        this.setState({
            // isOpenNew: true,
            isTemplateSelector: true
        })
    }

    onClose = (e) => {
        this.setState({
            isOpenNew: false
        })
    }

    handOnlineSave = async (key, row) => {
        let result = await this.props.createRecords(row);
        // console.log('result', result)
        if (!(result?.response?.status > 299)) {
            this.setState({
                isOpenNew: false
            })
            this.props.handleFresh();
        }
    }

    formRefCallback = (formRef) => {
        // console.log('formRefCallback', formRef)
        this.props.getFormRef(formRef);
    }

    renderDrawer = () => {

        return (
            <>
                <Drawer
                    width={window.innerWidth > 900 ? '90%' : '100%'}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.isOpenNew}
                    destroyOnClose={true}
                    autoFocus={true}
                >
                    <div>
                         <FunctionalTestingDetail
                                    selectedTemplateType={this.state.selectedTemplateType}
                                    selectedTypeOption={this.state.selectedTypeOption}
                                    selectedRecord={this.props.selectedRecord}
                                    handOnlineSave={this.handOnlineSave}
                                    columnProps={this.props.columnProps}
                                    formClassName={this.props.formClassName}
                                    isNewRecords={true}
                                    onClose={this.onClose}
                                    formRefCallback={this.formRefCallback}
                            />
                    </div>
                </Drawer>
            </>)
    }


    handleOpenFilters = () => {
        this.setState({
            isOpenFilters: true
        })
    }

    handleSearchSelect = (e, record) => {
        let data = record.record;
        console.log('handleSearchSelect', e, record.record)
        this.setState({
            selectedTypeOption: data?.fields,
            selectedTemplate: e,
            selectedTemplateType: data?.type?data.type:'Generic'
        })
    }

    onTemplateSelectorNext = () => {
        this.setState({
            isTemplateSelector: false,
            isOpenNew: true,
        })
    }
    renderTemplateSelector = () => {

        return (
            <Modal
                key={'detailmodal'}
                width={520}
                height={400}
                footer={<Button key="Next" type={'primary'} className={'my-5'} onClick={this.onTemplateSelectorNext}>Next</Button>}
                className={'flex items-center justify-center p-5'}
                title={'Select a Template for Functional Testing'}
                // onCancel={() => this.onTemplateSelectorClose()}
                closable={false}
                open={this.state.isTemplateSelector}>
                <Select style={{width: '500px', paddingTop:'2rem', paddingBottom:'2rem'}} placeholder={'Choose a Template'} value={this.state.selectedTemplate}
                        onChange={this.handleSearchSelect} options={this.state.type_options}/>
            </Modal>

        )
    }

    render() {

        return (

            <div className=" -mx-3 items-center w-full">
                <div className=" lg:w-auto flex items-center justify-between px-3">


                    <div>
                        {this.props.filterDescription}
                    </div>

                </div>
                <div className=" lg:w-auto ml-auto px-3 flex flex-wrap justify-between mt-2">


                    <div className='flex  filter-item justify-between w-full items-center'>
                        <div className={'flex flex-wrap'}>
                            {typeof this.props.renderFilter !== "undefined" ? this.props.renderFilter() : ''}

                        </div>

                        {!this.props.isHideRefreshNews && <div className={'flex '}>


                            <Button key="button1" type=""
                                    className={'inline-flex items-center px-3  font-medium   mr-5 reload-refresh-btn'}
                                    onClick={this.handleRefresh}>
                                <i className="fas fa-sync pr-3"></i> Refresh
                            </Button>

                            {!this.props.hid_new ? <Button key="button2" type="primary"
                                                           className={'inline-flex items-center px-3  font-medium   '}
                                                           onClick={this.handleOpenNew}
                            >
                                <i className="fas fa-plus pr-3"></i> New
                            </Button> : ''}




                            <Popover content={

                                <div className="mt-3 text-center">
                                    <div className="flex justify-between items-center border-b-2 py-2">
                                        <div></div>
                                        <button className="text-blue-600 font-bold uppercase px-6 py-2 rounded" onClick={this.props.resetFilters}>
                                            Reset
                                        </button>
                                    </div>
                                    <div className="mt-2 px-7 py-3">
                                        {/* Content will go here */}
                                        {/* Example section */}
                                        <div className="mb-4">
                                            {typeof this.props.renderSearches !== "undefined" ? this.props.renderSearches() : ''}
                                        </div>
                                        {/* Add additional sections here */}
                                    </div>

                                </div>
                            } trigger="click" placement="bottomRight"
                            >

                                {typeof this.props.resetFilters !== 'undefined'?<Button key="button4" type="text" shape={'circle'}
                                                                                        icon={<FilterFilled className={this.props.filterHasValue?'text-orange-600':'custom-blue'} />}
                                                                                        className={'inline-flex items-center px-3 ml-5 font-medium  filter-btn '}
                                                                                        onClick={this.handleOpenFilters}
                                >
                                </Button>:<div className={'ml-5 px-3'} />}


                            </Popover>

                        </div>}

                    </div>

                </div>
                {this.state.isOpenNew ? this.renderDrawer() : ''}
                {this.state.isTemplateSelector ? this.renderTemplateSelector() : ''}

            </div>

        );
    }
}

const mapStateToProps = state => {
    const role = _.get(state, 'Auth.role', {});
    const Role = role && Object.keys(role)[0];
    return {
        Role: Role,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeCurrent: (e) => dispatch(appActions.changeCurrent(e)),
        OpenDrawer: (e, id) => dispatch(actions.OpenDrawer(e, id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FunctionTestingPageHeader);

