import React from 'react'
import { paddingWidth, pdfFontSize } from '../document/utils'

export const PdfTitle = ({ w, subTitle, title }) => {
  return <p-stack unbreakable={true} margin={[0,0]}>
    <p-text margin={[0, 30, 0, 0]}>
      <p-text background="#396598" color="#fff" fontSize={pdfFontSize.H2} bold={true} margin={[10, 0]}>{title}</p-text>
      {subTitle && <p-text fontSize={pdfFontSize.H3} color="#000000" bold={true} margin={[10, 0]}>{' (' + subTitle + ')'}</p-text> }
    </p-text>
    <p-canvas relativePosition={{ x: 0, y: 2 }}>
      <p-rect x={0} y={0} w={paddingWidth - 20} h={1} color="#396598"></p-rect>
    </p-canvas>
  </p-stack>
}
