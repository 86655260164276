import React, {Component} from 'react';
import {Line} from "@antv/g2plot";
import LayoutContent from "@components/utility/layoutContent";
import {ChartWrapper} from "@containers/UtilityData/Analysis/Analysis.styles";
import {CurrencyFormat} from "@components/Format";

class MonthlyCostSavings extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this.draw(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (this.chartRef.current) {
            this.chartRef.current.innerHTML = '';
            this.draw(nextProps)
        }
    }

    draw = (props) => {

        const chartDom = this.chartRef.current;
        console.log('area', props)
        let data = props.data;
        const scatterPlot = new Line(chartDom, {
            data: data,
            height: props.height,
            xField: 'date',
            yField: 'value',
            seriesField: 'type',
            colorField: 'type',
            // smooth: true,
            color: ({type}) => {
                if (type === 'Electricity') {
                    return 'blue'
                } else {
                    return 'brown';
                }
            },
            tooltip: {
                formatter: (datum) => {
                    return {name: datum.type, value: CurrencyFormat(datum['value'])};
                },
            },
            shape: 'circle',
            pointStyle: {
                fillOpacity: 1,
            },
            xAxis: {
                nice: true,
                // type: 'time',
                label: {
                    // autoRotate: false,
                    style: {
                        fill: '#aaa',
                        fontSize: 12,
                    },
                    formatter: (name) => name.substring(0, 7),
                },
            },
            yAxis: {
                title: {text: '$'},
                label: {
                    formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                },
            },
            legend: {
                position: 'top',
            },
            point: {
                size: 5,
                style: {
                    lineWidth: 1,
                    fillOpacity: 1,
                },
                shape: (item) => {
                    if (item.type === 'Baseline') {
                        return 'circle';
                    }
                    return 'diamond';
                },
            },
        });

        scatterPlot.render();
    }

    render() {
        console.log('MonthlyCostSavings')
        return (
            <ChartWrapper className='chart-wrapper'>
                <LayoutContent className={'outerCardWrapper'}>
                    <div className={'flex  justify-between relative'}>
                        <div className={'small-title pdfChart_Title'}>{this.props.title}</div>
                        <div className={'absolute'} style={{right: 0}}>
                            <div>
                                <i className="fas fa-chart-bar" style={{color: '#409EFF'}}></i>
                            </div>
                        </div>
                    </div>
                    <div ref={this.chartRef}/>
                </LayoutContent>
            </ChartWrapper>
        );
    }
}

export default MonthlyCostSavings;
