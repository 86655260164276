import React, {Component, PureComponent} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {ScenarioColumns, ScenarioProps} from "@containers/facility/Scenario/ScenarioColumns";
import _ from "lodash";
import actions from "@redux/Scenario/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {ScenarioFormFields} from "@containers/facility/Scenario/ScenarioFormFields";
import {ScenarioFilters} from "@containers/facility/Scenario/ScenarioFilter";

class Scenario extends PureComponent {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            status_options: [],
            service_options: [],
            filtered_status: null,
            filtered_service: null,
            isLoading: false,
            filterDescription: null,
            query: null,

        }
    }

    handleCreateScenario = (body) => {
        return api.createScenario(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteScenario = (id) => {
        return api.deleteScenario(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkScenarioInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteScenario(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        return api.editScenario(key, row).then(
            response => {

                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {

        this.setState({
            isLoading: true
        })


        return api.filterScenario(this.state.query).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                }
            }
        ).catch(error => (
            error
        ));
    }

    getScenario = (data) => {

        this.setState({
            isLoading: false,
            data
        })

        this.props.setScenarioSuccess(data)
    }

    setFilters = (e) => {
        this.setState({}, function () {
            this.refresh(false)
        })
    }

    handleClearButtonClick = (e) => {
        this.setState({

            query: null,
        }, function () {
            this.refresh(false)
        })
    }

    renderFilter = () => {
        return <ScenarioFilters

            handleFilter={this.setFilters}
            handleClearButtonClick={this.handleClearButtonClick}
        />
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    render() {
        return (

            <div>
                <EditableTable
                    ref={this.tableRef}
                    columns={ScenarioColumns}
                    data={this.state.data}
                    columnProps={ScenarioProps}
                    formFields={ScenarioFormFields}
                    getRecord={this.getScenario}
                    createRecords={this.handleCreateScenario}
                    deleteRecord={this.handleDeleteScenario}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    handleFilter={this.handleFilter}
                    renderFilter={this.renderFilter}

                    hide_search={true}
                    isRowClickable={false}
                    isRelated={false}
                    isAttachments={true}
                    hide_date={true}
                    setFilters={this.setFilters}

                    filterDescription={this.state.filterDescription}

                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        Scenario: _.get(state.Scenario, 'data'),
    }
}
const mapDispatchToProps = dispatch => {
    return {

        setScenarioRequest: (e) => dispatch(actions.ScenarioRequest(e)),
        setScenarioSuccess: (e) => dispatch(actions.ScenarioSuccess(e)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Scenario);
