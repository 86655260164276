import Input from "antd/es/input";
import React from "react";
import FunctionalFormFieldsEditor from "@containers/management/FunctionalForm/FunctionalFormFieldsEditor";
import EquipmentCategoryLookup from "@containers/Lookup/EquipmentCategoryLookup";


export const FunctionalFormFormFields = (formRef) => {
    return [
        {
            title: 'key',
            name: 'id',
            order: 0,
            visible: false,
            length: 0,
            type: 'text',
        },
        {
            title: 'Name',
            name: 'name',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            required: true,
            render:
                <Input/>
        },
        {
            title: 'Type',
            name: 'type',
            order: 5,
            visible: true,
            length: "w-full",
            type: 'Lookup_text',
            render: (value) => {
                return <EquipmentCategoryLookup value={value} formRef={formRef}/>
            }
        },
        {
            title: 'Fields',
            name: 'fields',
            order: 8,
            visible: true,
            length: "w-full",
            type: 'customJSON',
            render: (value) => {
                return (<>{<FunctionalFormFieldsEditor value={value} formRef={formRef} />}</>)
            }
        },

    ]
}
