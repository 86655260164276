import { useHasPermission } from "./usePermission";

const PermissionControl = ({
                               allowedPermissions,
                               children,
                               renderNoAccess,
                               returnBool,
                           }) => {


    const hasPermission = useHasPermission();

    const permitted = hasPermission(allowedPermissions);
    if (returnBool) {
        return permitted;
    } else {
        if (permitted) {
            return children;
        } else {
            return renderNoAccess ? renderNoAccess() : null;
        }
    }


}

export default PermissionControl;
