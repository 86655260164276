import React from "react";
import {store} from '@redux/store';
import {Checkbox, Select, Tag} from "antd";
import Input from "antd/es/input";

const {Option} = Select;
const facility_items = store.getState().Facility.data;
const company_items = store.getState().Company.data;

export const ProfileProps = {
    title: 'User',
    name: 'user',
    description: 'Create User or Update User Profile',
}


const options = [
    {label: 'Disabled', value: '0'},
    {label: 'Read Only', value: '1'},
    {label: 'Editable', value: '2'},
    {label: 'Full Access', value: '3'},
];

export const ProfileColumns = (handleDrawOpen) => {

    return ([
        {
            title: 'key',
            dataIndex: 'id',
            key: 'id',
            valueType: 'indexBorder',
            editable: false,
            search: false,
            importIngore: true,
            hideInTable: true,
            width: '4%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            edit: true,
            required: true,
            search: false,
            type: 'text',
            hideInTable: window.innerWidth < 760,
            input: <Input prefix={<div style={{color: 'red'}}>*</div>}/>,
            render: (text, record, _, action) => (
                <a onClick={() => action ? handleDrawOpen(true, record.id) : ''}>{text}</a>
            )
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            edit: true,
            required: true,
            search: false,
            valueType: 'select',
            valueEnum: {
                1: {text: 'Super User'},
                2.1: {text: 'Account Manager'},
                2.2: {text: 'Portfolio Manager'},
                3: {text: 'Company Admin'},
                4: {text: 'Standard User'}
            },
        },

        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.user).map(e => e.label).join(', ');
                let value = row.user;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.company).map(e => e.label).join(', ');
                let value = row.company;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Facility',
            dataIndex: 'facility',
            key: 'facility',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.facility).map(e => e.label).join(', ');
                let value = row.facility;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Equipment Category',
            dataIndex: 'equipmentCategory',
            key: 'equipmentCategory',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.equipmentCategory).map(e => e.label).join(', ');
                let value = row.equipmentCategory;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Functional Form',
            dataIndex: 'functionform',
            key: 'functionform',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.functionform).map(e => e.label).join(', ');
                let value = row.functionform;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },

        {
            title: 'Electricity',
            dataIndex: 'electricity',
            key: 'electricity',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.electricity).map(e => e.label).join(', ');
                let value = row.electricity;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Gas',
            dataIndex: 'gas',
            key: 'gas',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.gas).map(e => e.label).join(', ');
                let value = row.gas;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Equipment',
            dataIndex: 'equipment',
            key: 'equipment',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.equipment).map(e => e.label).join(', ');
                let value = row.equipment;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Functional Testing',
            dataIndex: 'functiontesting',
            key: 'functiontesting',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.functiontesting).map(e => e.label).join(', ');
                let value = row.functiontesting;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },

        {
            title: 'Workorder',
            dataIndex: 'workorder',
            key: 'workorder',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.workorder).map(e => e.label).join(', ');
                let value = row.workorder;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Benchmark',
            dataIndex: 'benchmark',
            key: 'benchmark',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.benchmark).map(e => e.label).join(', ');
                let value = row.benchmark;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.project).map(e => e.label).join(', ');
                let value = row.project;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'CustomReport',
            dataIndex: 'customreport',
            key: 'customreport',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.customreport).map(e => e.label).join(', ');
                let value = row.customreport;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },
        {
            title: 'Log',
            dataIndex: 'log',
            key: 'log',
            valueType: 'checkbox',
            search: false,
            editable: true,
            render: (text, row) => {
                let label = options.filter(e => e.value * 1 === row.log).map(e => e.label).join(', ');
                let value = row.log;
                return <Tag
                    color={value === 0 ? 'red' : value === 1 ? 'orange' : value === 2 ? 'blue' : 'green'}>{label}</Tag>;
            },
            renderFormItem: (_, {value, onChange}) => {
                return (
                    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}/>
                );
            },
        },

    ])
};
