import Input from "antd/es/input";
import React from "react";
import CustomDatePicker from "@components/CustomDatePicker";
import ServiceDateTemperature from "@containers/UtilityData/ServiceDateTemperature";
import TextArea from "antd/es/input/TextArea";
import PermissionControl from "@components/Permission/Permission";
import {Checkbox, Radio} from "antd";
import {permissionOptions} from "@containers/management/UserManagement/Profile/ProfileFormFields";

export const ElectricityRelated = []

export const ElectricityFormFields =(formRef) => {
    const serviceDate = formRef?.getFieldValue('service_month')
    const service_start_date = formRef?.getFieldValue('service_start_date')
    const end_start_date = formRef?.getFieldValue('end_start_date')
    // console.log('ServiceDateTemperature3', serviceDate)
    return [
    {
        title: 'key',
        name: 'id',
        order: 0,
        visible: false,
        length: 0,
        type: 'text',

    },
    {
        title: 'Service Start Date',
        name: 'service_start_date',
        order: 8,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <CustomDatePicker format='YYYY-MM-DD'/>,
        required: true,
    },
    {
        title: 'Service End Date',
        name: 'end_start_date',
        order: 3,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <CustomDatePicker format='YYYY-MM-DD'/>,
        required: true,
    },
        {
            title: 'Description',
            name: 'description',
            order: 1,
            visible: true,
            length: "w-full",
            type: 'text',
            render:
                <TextArea className='w-full' rows={3}/>
        },
        {
            title: 'Service Month Manual Override',
            name: 'manual_update',
            order: 3,
            visible: true,
            length: "w-full",
            type: 'checkbox',
            render: (disable) => <Radio.Group defaultValue={false} disabled={disable}>
                <Radio value={true} > True </Radio>
                <Radio value={false} > False </Radio>
            </Radio.Group>,
            required: false,
        },
    {
        title: 'Service Month',
        name: 'service_month',
        order: 3,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <CustomDatePicker format='YYYY-MM-DD'/>,
        required: false,
    },
    {
        title: 'Usage (kWh)',
        name: 'usage',
        order: 2,
        visible: true,
        length: "w-full",
        type: 'number',
        required: true,
        render: <Input type={'number'}/>
    },
    {
        title: 'Avg. OAT(°F)',
        name: 'avgoat',
        order: 4,
        visible: true,
        length: "w-full",
        required: true,
        type: 'Lookup_text',
        render: (value) => {
            // console.log('ServiceDateTemperature2', serviceDate)
            return <ServiceDateTemperature
                service_start_date={service_start_date}
                end_start_date={end_start_date}
                formRef={formRef} record={value} />
        }
    },

    // {
    //     title: 'Area(Sqt)',
    //     name: 'area',
    //     order: 6,
    //     visible: true,
    //     length: "w-full",
    //     type: 'number',
    //     render: <Input type={'number'}/>
    // },
    {
        title: 'Costs($)',
        name: 'costs',
        order: 7,
        visible: true,
        length: "w-full",
        type: 'number',
        required: true,
        render: <Input type={'number'}/>
    },
    {
        title: 'Adjustment(KWH)',
        name: 'adjustment',
        order: 9,
        visible: true,
        length: "w-full",
        type: 'number',
        render: <Input type={'number'}/>
    },
    // {
    //     title: 'Separator',
    //     name: 'Separator',
    // },
    {
        title: 'Created By',
        name: 'createdby_name',
        order: 17,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input disabled/>
    },
    {
        title: 'Created Date',
        name: 'created_date',
        order: 18,
        visible: true,
        length: "w-full",
        type: 'Date',
        render: <Input disabled/>
    },
    {
        title: 'Modified By',
        name: 'lastModified_name',
        order: 19,
        visible: true,
        length: "w-full",
        type: 'text',
        render: <Input disabled/>
    },
    {
        title: 'Modified Date',
        name: 'modified_date',
        order: 20,
        visible: true,
        length: "w-full",
        type: 'Date',
        render: <Input disabled/>
    },

]}
