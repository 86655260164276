import {Timeline, Collapse, Tag, Pagination, Spin} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {api} from "../../redux/api";
import styled from "styled-components";

const { Panel } = Collapse;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const getTagColor = (actionType) => {
    switch (actionType) {
        case 'CREATE':
            return 'darkgreen'; // Green
        case 'DELETE':
            return 'darkred'; // Red
        case 'UPDATE':
            return 'darkblue'; // Blue
        default:
            return '#d9d9d9'; // Default color
    }
};
const RecordAuditTimeline = ({ moduleId, module }) => {
    const [data, setData] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    // handleFilter method fetches audit logs based on parentRecordId
    const handleFilter = async (page, size) => {
        try {
            setLoading(true);
            var query = `&page=${page}&page_size=${size}`
            if (module !== 'All' && moduleId !== 'All') {
                query = `module=${module}&moduleId=${moduleId}`+query;
            }
            const response = await api.getLog(query);
            let dataSource = response.data.results;
            setTotal(response.data.count);
            setData(dataSource);
        } catch (error) {
            console.error('Error fetching logs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (moduleId) {
            handleFilter(currentPage, pageSize);
        }
    }, [moduleId, currentPage, pageSize]);

    const renderDetails = (record) => {
        const { type, createdby_name, message, ...details } = record;

        const detailEntries = Object.entries(details).map(([key, value], idx) => {
            // Check if the key is 'params' and handle it accordingly
            if (key === 'params') {
                let parsedValue;
                try {
                    parsedValue = JSON.parse(value);
                } catch (e) {
                    console.error('Error parsing params:', e);
                    return (
                        <div key={idx} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{`${key}: `}</span>
                            <span>{value}</span>
                        </div>
                    );
                }
                // If parsing is successful, display each key-value pair on separate lines
                return Object.entries(parsedValue).map(([paramKey, paramValue], paramIdx) => (
                    <div key={`${idx}_${paramIdx}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{`${paramKey}: `}</span>
                        <span>{typeof paramValue === 'object' ? JSON.stringify(paramValue, null, 2) : paramValue}</span>
                    </div>
                ));
            } else {
                // Handle non-params keys normally
                return (
                    <div key={idx} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{`${key}: `}</span>
                        <span>{typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</span>
                    </div>
                );
            }
        });

        return <div>{detailEntries}</div>;
    };

    const handleExpand = (index) => {
        if (expandedItem === index) {
            setExpandedItem(null);
        } else {
            setExpandedItem(index);
        }
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    return (
        <Spin spinning={loading} tip="Loading..." >
            <ol >
                {data.map((record, index) => {
                    const {message, createdby_name, type, created_date} = record;
                    const tagColor = getTagColor(type);
                    return (
                        <li >
                            <div className="flex flex-start">

                                <div className="block p-4 bg-gray-100 mb-10 w-full -mt-2">
                                    <div className="flex justify-between">
                                        <span style={{color: tagColor}} onClick={() => handleExpand(index)}
                                              className="hover:text-blue-700 focus:text-blue-800 duration-300 transition ease-in-out">
                                           <i className="fa-solid fa-arrows-up-down pr-3"></i> {createdby_name}</span>
                                        <span
                                           className=" hover:text-blue-700 focus:text-blue-800 duration-300 transition ease-in-out ">
                                            <Tag color={tagColor}>{type}</Tag>
                                            {moment(created_date).format('YYYY-MM-DD HH:mm:ss')}
                                        </span>
                                    </div>
                                    <p className={expandedItem === index?"text-gray-700 switchTransition":"text-gray-700 hidden switchTransition"}>{renderDetails(record)}</p>
                                </div>
                            </div>
                        </li>
                    );
                })}
                <Pagination  current={currentPage}
                             total={total}
                             pageSize={pageSize} // Adjust the page size if needed
                             showSizeChanger
                             onShowSizeChange={handlePageChange}
                             onChange={handlePageChange} />
            </ol>

        </Spin>
    );
};

export default RecordAuditTimeline;
