import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Banner, Navigation} from './Profile.styles.js';
import AvatarCard from "@components/AvatarCard/AvatarCard.js";
import Container from "@components/Container/Container.js";
import login from '@assets/images/login.jpg';
import {Button, Card, Descriptions, Divider, Tabs} from 'antd';
import _ from "lodash";
import notification2 from "@components/Notification2";
import {api} from "@redux/api";
import {UnlockOutlined} from "@ant-design/icons";

const {TabPane} = Tabs;

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
        }
    }

    componentDidMount() {
        let columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                width: '10%',
            },
            {
                title: 'Username',
                dataIndex: 'username',
                width: '10%',
                editable: true,
            },
        ]
        this.setState({
            columns
        })
    }

    handleResetPassword = (e) => {

        let email = _.get(this.props.auth, 'email')

        this.setState({
            isLoading: true
        })
        api.forgetpassword(email).then(res => {
            this.setState({
                isLoading: false
            })
            return notification2['success']({
                message: 'Success',
                description: 'Password reset successfully, please check your email', placement: 'topLeft'
            });
        })

    }

    render() {

        return (
            <div>
                <Banner
                    className="profile-banner"
                    style={{backgroundImage: `url(${login})`}}
                >
                    <Container className="container">
                        <AvatarCard
                            avatar={this.props.auth.image}
                            name={this.props.auth.username}
                            username={''}
                        />
                    </Container>
                </Banner>

                <Navigation className="navigation">
                    <Container className="container">
                        <Tabs defaultActiveKey="1" centered>
                            <TabPane tab="Personal Settings" key="1">
                                <Card bordered={false}
                                      title={<Button type='primary' key="1" onClick={this.handleResetPassword}
                                                     icon={<UnlockOutlined/>}> ResetPassword</Button>}>

                                    <Descriptions
                                        title=""
                                        style={{
                                            marginBottom: 32,
                                        }}
                                        // extra={
                                        //     // <Button type="primary">Edit</Button>
                                        // }
                                    >

                                        <Descriptions.Item
                                            label="UserName">{this.props.auth.user}</Descriptions.Item>
                                        <Descriptions.Item label="Email">{this.props.auth.email}</Descriptions.Item>
                                        <Divider/>
                                        <Descriptions.Item
                                            label="First Name">{this.props.auth.first_name}</Descriptions.Item>
                                        <Descriptions.Item
                                            label="Middle Name">{this.props.auth.middle_name}</Descriptions.Item>
                                        <Descriptions.Item
                                            label="Last Name">{this.props.auth.last_name}</Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            </TabPane>
                        </Tabs>
                    </Container>
                </Navigation>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.Auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);
