import React, {useEffect, useState} from 'react';
import {Badge, Tooltip} from 'antd';

import {store} from "@redux/store";
import {api} from "@redux/api";
import {useSelector} from "react-redux";
import {WorkerCommentTooltip} from "@containers/facility/Workorder/WorkorderTooltip";

import { ENV } from '@config/env'
const { wsURL } = ENV;

const WorkorderNotification = (record) => {
    let facility_name = store.getState().Facility.selected.value;
    let facility_id = store.getState().Facility.selected.Id;
    facility_name = facility_name.replaceAll(' ', '').replaceAll('-', '');


    const [visible, setVisiblity] = useState(false);
    const [workorderNotification, setWorkorderNotification] = useState(record.record.unread_notifications_count);
    const [workorderReadNotification, setWorkorderReadNotification] = useState(record.record.read_notifications_count);
    const [socket, setSocket] = useState(null);
    const [workorderId, setWorkorderId] = useState(record.record.id);
    const [prevDrawerId, setPrevDrawerId] = useState(record.record.id)
    const user_id = useSelector(state => state.Auth.userId);
    const drawer = useSelector(state => state.EditableTable.drawer);
    const drawer_id = useSelector(state => state.EditableTable.id);

    const setReadComment = (comment_id) => {
        if (comment_id) {
            api.updateWorkorderComment(comment_id, {status: 'read'})
                .then(response => {
                    console.log(`Comment ${comment_id} marked as read`);
                    // Update the local state
                    setWorkorderReadNotification(prev => prev+workorderNotification)
                    setWorkorderNotification(0);

                })
                .catch(error => console.error('Error updating notification status:', error));
        } else {
            console.error('No notification ID provided');
        }
    };

    useEffect(() => {
        setPrevDrawerId(drawer_id)
    }, [drawer_id])


    useEffect(() => {
        if (workorderId === prevDrawerId && drawer === true) {
            console.log('read::', workorderId, drawer, prevDrawerId)
            // Call setReadComment when the previous value of drawer was true and the new value is false
            setReadComment(workorderId);
        }
    }, [drawer, workorderId, prevDrawerId]);

    useEffect(() => {
        console.log('test')
        const ws = new WebSocket(wsURL + 'kpiworkordercomment/?user_id=' + user_id + '&parent_id=' + record.record.id);

        ws.onopen = () => {
            console.log('Connected to websocket server');
        };

        ws.onmessage = e => {

            const data = JSON.parse(e.data);
            console.log('kpiworkordercomment data: ', data)
            if (data.parent_id === record.record.id) {
                if (data.type === 'deleted'){
                    setWorkorderReadNotification(prevNotification => (prevNotification - 1));
                } else {
                    setWorkorderNotification(prevNotification => (prevNotification + 1));
                }
            }
        };

        // ws.onerror = e => {
        //     console.error('WebSocket error: ', e);
        // };
        //
        // ws.onclose = e => {
        //     console.error('WebSocket closed: ', e);
        // };

        setSocket(ws);

        // Clean up on unmount
        return () => {
            ws.close();
        };
    }, [facility_name, record.record.id, user_id, facility_id]);


    return (
        <Tooltip placement={'topLeft'} trigger={['click']} overlayStyle={{maxWidth: '60rem'}}
                 onOpenChange={(open) => open && setReadComment(record.record.id)}
                 title={WorkerCommentTooltip(record.record.id)} color={'lightgrey'}>
            {(workorderReadNotification+workorderNotification)>0 ?
                <Badge count={workorderNotification} size="small">
                    <i className="fas fa-comment-dots text-primary text-xl "
                       style={{color: '#faad14', fontSize: '18px'}}></i>
                </Badge>:
                <Badge size="small">
                    <i className="fa-regular fa-comment-dots text-primary text-xl "
                       style={{color: 'grey', fontSize: '18px'}}></i>
                </Badge>
            }
        </Tooltip>
    );
}

export default WorkorderNotification;
