import React, {Component} from 'react';
import {Col, Row} from "antd";
import {NoDecimalFormat, TwoDecimalFormat} from "@components/Format";
import {WidgetWrapper} from "@components/WidgetBox/Widgets.styles";
import Histogram from "@containers/UtilityData/Summary/Charts/Histogram";
import _ from "lodash";

import { utilityCardIdMappings } from '@containers/PdfReport/PdfForm'

class UtilityCard extends Component {

    renderCard = (title, label, value, data, unit, color, alg, x, y) => {
        return (

            <WidgetWrapper>

                <div className={" p-6 mb-4 bg-white shadow rounded corner-flower-"}>
                    <div className="flex items-center justify-between">
                        <h3 className={'small-title overlay-top pdfChart_Title'}>{title + ' (' + unit + ')'}</h3>

                    </div>
                    <div className="flex items-center mb-3">
                        <span
                            className="text-2xl font-bold overlay-top pdfChart_SubTitle">{unit === '$' ? (unit + ' ' + value) : value}</span>
                    </div>
                    <Histogram data={data} unit={unit} color={color} value={y} type={x}/>
                    {/*<div className="relative w-full h-1 mb-2  rounded">*/}
                    {/*    <div className="absolute top-0 left-0 w-full h-full bg-purple-500 rounded"></div>*/}
                    {/*</div>*/}
                    {/*<p className="text-xs text-white">{label + ' (' + unit + ')'}</p>*/}
                </div>

            </WidgetWrapper>

        )
    }

    render() {

        return (

            <Col style={{minWidth: '240px'}}
                 className={'w-full '}>

                {/*<div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>*/}
                {/*    <h1 style={{marginBottom: '1rem', fontSize: '15px'}}>{this.props.title}</h1>*/}
                {/*    <span style={{marginBottom: '1rem', color: '#5BC5C2'}}>Explore details ></span>*/}
                {/*</div>*/}
                <Row type="flex" className={'w-full '}>
                    <Col xs={24} md={12} xl={8} id={utilityCardIdMappings[this.props.subject].Consumption.id}>
                        {this.renderCard(this.props.subject + ' Consumption', 'Usage', NoDecimalFormat(_.get(this.props, 'data.total_usage')),
                            _.get(this.props, 'data.' + this.props.object),
                            this.props.unit, this.props.color, 'Total', 'month', 'usage')}
                    </Col>
                    <Col xs={24} md={12} xl={8} id={utilityCardIdMappings[this.props.subject].PricePer.id}>
                        {this.renderCard(this.props.subject + ' Price' + ' Per ' + this.props.unit, 'Price',
                            TwoDecimalFormat(_.get(this.props, 'data.average_cost')),
                            _.get(this.props, 'data.' + this.props.object),
                            '$', this.props.color, 'Average', 'month', 'average_cost')}
                    </Col>
                    <Col xs={24} md={12} xl={8} id={utilityCardIdMappings[this.props.subject].Cost.id}>
                        {this.renderCard(this.props.subject + ' Cost', 'Cost', NoDecimalFormat(_.get(this.props, 'data.total_cost')),
                            _.get(this.props, 'data.' + this.props.object),
                            '$', this.props.color, 'Total', 'month', 'costs')}
                    </Col>
                </Row>

            </Col>

        );
    }
}

export default UtilityCard;
