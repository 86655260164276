import React, {Component} from 'react';
import _ from 'lodash'
import {connect} from "react-redux";

import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {jsforceResponse} from "@/config/httpService";
import {CaretDownOutlined} from "@ant-design/icons";
import {api} from "@redux/api";


class UserLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: null,
            User: null
        }
        this.debouncedSearch = _.debounce(this.performSearch, 300);
        this.throttledSetState = _.throttle(this.setState.bind(this), 1000);
    }

    handleFocus = async (e) => {
        let query = '';
        if (this.props?.company_id){
            query += '&company_id=' + this.props.company_id+'&filter=true'
        }
        const result1 = await api.filterCustomUsers(query);
        const res1 = jsforceResponse(result1, '', false, true)
        let data = _.get(res1, 'data.results', []);
        let options = [];
        for (let i = 0; i < data.length; i++) {
            options.push({
                label: data[i].username,
                value: data[i].username,
                id: data[i].id,
                record: data[i]
            })
        }
        this.setState({
            options
        })
    }


    handleSearch = (e) => {
        this.setState({ value: e });
        this.debouncedSearch(e);
    };

    performSearch = async (searchTerm) => {
        try {
            let query = `username=${searchTerm}`;
            if (this.props?.company_id) {
                query += `&company_id=${this.props.company_id}&filter=true`;
            }

            const result = await api.filterCustomUsers(query);
            const res = jsforceResponse(result, '', false, true);
            const data = _.get(res, 'data.results', []);

            const options = data.map(item => ({
                label: item.username,
                value: item.username,
                id: item.id,
                record: item
            }));

            this.throttledSetState({ options });
        } catch (error) {
            console.error('Search error:', error);
            // Optionally, update state to reflect the error
            // this.throttledSetState({ error: 'An error occurred during search' });
        }
    };

    handleSelect = (e, record) => {
        console.log('handleSelect', e, record)

        this.setState({
            selected: record.record,
            User: e,
            value: e
        })
        this.props.formRef.setFieldsValue({
            [this.props.field]: record.record.id,
            [this.props.field+'_name']: record.record.username
        })
    }

    handleClear = () => {
        this.setState({
            selected: null,
            User: null
        })
        this.props.formRef.setFieldsValue({
            [this.props.field]: '',
            [this.props.field+'_name']: ''
        })
    }

    render() {
        // console.log('User2', this.props.value)
        return (
            <AutoComplete
                style={{width: '100%'}}
                options={this.state.options}
                onFocus={this.handleFocus}
                onSelect={this.handleSelect}
                onSearch={this.handleSearch}
                value={this.state.value}
                onClear={this.handleClear}
                allowClear
                dropdownMatchSelectWidth={false}
            >
                <Input size="medium" autosize={true}
                       placeholder={'search a User'}
                       autoComplete="off"
                       suffix={<CaretDownOutlined/>}/>
            </AutoComplete>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserLookup);
