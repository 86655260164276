import React, {Component} from 'react';
import {connect} from "react-redux";
import AutoComplete from "antd/es/auto-complete";
import Input from "antd/es/input";
import {jsforceResponse} from "@/config/httpService";
import styled from "styled-components";
import {debounce} from "lodash";

export const SearchLookupWrapper = styled.div`
  //.ant-input-affix-wrapper{
  //  border-radius: 1.5rem;
  //  border: 1.9px solid #1890ff !important;
  //  overflow: hidden;
  //}

`

class SearchLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            record: null,
            value: null,
            dropdownVisible: false,
            loading: false
        }
        this.debouncedHandleSearch = debounce(this.handleSearch2, 100);
    }

    componentDidMount() {
        // console.log('default', this.props.defaultValue)
        this.setState({
            value: this.props.defaultValue
        })
    }


    handleFocus = async (e) => {
        this.setState({dropdownVisible: true, loading: true});
        let options = [];
        const result = await this.props.Lookup_CommonupRequest('');
        const res = jsforceResponse(result, '', false, true)
        console.log('res', res)
        let existingKeys = new Set();
        for (let i = 0; i < res.data?.length; i++) {
            if (!existingKeys.has(res.data[i][this.props.name])) {
                options.push({
                    key: res.data[i].id,
                    value: res.data[i][this.props.name],
                    label: res.data[i][this.props.name],
                    record: res.data[i].id
                })
                existingKeys.add(res.data[i][this.props.name]);
            }
        }
        this.setState({
            options,
            loading: false
        })
    }

    handleSearch = async (e) => {
        this.setState({dropdownVisible: true});
        this.setState({value: e})
        this.debouncedHandleSearch(e)
    }

    handleEnterSearch = async (e) => {
        this.setState({value: e, dropdownVisible: false})
        this.props.SelectedLookup_CommonRequest(e)
    }

    handleSearch2 = async (e) => {
        this.setState({
            loading: true
        })
        let options = [];
        const result = await this.props.Lookup_CommonupRequest(e);
        const res = jsforceResponse(result, '', false, true)
        let existingKeys = new Set();
        for (let i = 0; i < res.data?.length; i++) {
            if (!existingKeys.has(res.data[i][this.props.name])) {
                options.push({
                    key: res.data[i].id,
                    value: res.data[i][this.props.name],
                    label: res.data[i][this.props.name],
                    record: res.data[i].id
                })
                existingKeys.add(res.data[i][this.props.name]);
            }
        }
        this.setState({
            options,
            loading: false
        })
    }

    handleSelect = (e, record) => {
        this.setState({value: e, dropdownVisible: false})
        this.props.SelectedLookup_CommonRequest(e, record)
    }


    render() {
        // console.log('SearchLookup', this.props.options)
        return (
            <SearchLookupWrapper>
                <AutoComplete
                    // placeholder={this.props.label}
                    style={this.props.style}
                    options={this.state.options}
                    onFocus={this.handleFocus}
                    onSelect={this.handleSelect}
                    onSearch={this.handleSearch}
                    // onChange={this.handleOnChange}
                    onBlur={() => {
                        this.setState({dropdownVisible: false});
                    }}
                    value={this.state.value}
                    defaultValue={this.state.value}
                    dropdownMatchSelectWidth={false}
                    placeholder={this.props.placeholder}
                    open={this.state.dropdownVisible}
                    loading={this.props.loading}
                >
                    <Input.Search size="medium" autosize={true} style={{textAlign: 'center'}}
                                  onSearch={this.handleEnterSearch}
                                  allowClear enterButton/>
                </AutoComplete>
            </SearchLookupWrapper>
        );
    }

}

const mapStateToProps = state => {
    return {}
};
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchLookup);
